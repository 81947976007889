import {
  array_move,
  array_move_block,
} from '../LayoutEditor/components/geometryFunctions.js'
import {
  areConsecutiveShapes,
  getShapeById,
  getTextAndTableRegions,
  getTextlineParent,
  getTextLines,
  removeShapeById,
  setTextAndTableRegions,
  setTextlinesForRegion,
} from '../Model/modelUtils.ts'
import BaseAction from './BaseAction.js'

export default class ChangeOrder extends BaseAction {
  constructor(pageJson: any) {
    super(pageJson)
  }

  apply(params: any) {
    let { newParentId, newIndex, draggedIds } = params

    //If it's a region
    if (newParentId == null) {
      const regions = [...getTextAndTableRegions(this.pageJson)]
      const selectedRegionIndices = draggedIds.map(id =>
        regions.findIndex(l => l.attributes.id === id)
      )
      array_move_block(
        regions,
        Math.min(...selectedRegionIndices),
        Math.max(...selectedRegionIndices),
        newIndex
      )
      setTextAndTableRegions(regions, this.pageJson)
    } else {
      const oldParent = getTextlineParent(this.pageJson, draggedIds[0])
      const newParent = getShapeById(newParentId, this.pageJson)

      if (oldParent.attributes.id === newParent.attributes.id) {
        let lines = [...getTextLines(oldParent)]
        const selectedLineIndices = draggedIds.map(id =>
          lines.findIndex(l => l.attributes.id === id)
        )
        array_move_block(
          lines,
          Math.min(...selectedLineIndices),
          Math.max(...selectedLineIndices),
          newIndex
        )
        setTextlinesForRegion(oldParent, lines)
      } else {
        const oldParentLines = [...getTextLines(oldParent)]
        const selectedLineIndices = draggedIds.map(id =>
          oldParentLines.findIndex(l => l.attributes.id === id)
        )
        const numberOfLines = selectedLineIndices.length
        const removedLines = oldParentLines.splice(
          Math.min(...selectedLineIndices),
          numberOfLines
        )
        setTextlinesForRegion(oldParent, oldParentLines)
        const newParentLines = [...getTextLines(newParent)]
        newParentLines.splice(newIndex, 0, ...removedLines)
        setTextlinesForRegion(newParent, newParentLines)
      }
    }
  }
}
