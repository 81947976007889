import type {
  Coord,
  SplittedRegionGeometric,
  SplittedLineGeometric,
} from '../LayoutEditor/components/types.ts' // Adjust the path if needed

import {
  pointInPolygon,
  polygonMean,
  lineLength,
  Polygon as PolygonGeometric,
  Point,
} from 'geometric'
import polygonSplitter from 'polygon-splitter'
import { segment, Segment } from '@flatten-js/core'
import {
  cloneObj,
  getLineGeometrics,
  getLineJson,
  getRegionGeometrics,
  getRegionJson,
  getShapeAttributes,
  getShapeById,
  getShapeId,
  getShapeText,
  getTextAndTableRegions,
  getTextLines,
  removeLine,
  removeRegion,
  setTextAndTableRegions,
  setTextlinesForRegion,
  expectedRegionIndex,
  expectedLlineIndex,
} from '../Model/modelUtils.ts'
import { compare } from 'fast-json-patch'
import BaseAction from './BaseAction.js'

export default class Split extends BaseAction {
  constructor(pageJson?: any) {
    super(pageJson)
  }

  polygonMeanPoint(polygon: Coord[]): Point {
    return polygonMean(this.toPolygonGeometric(polygon))
  }

  distanceOfMeans(polygonA: Coord[], polygonB: Coord[]): number {
    return lineLength([
      polygonMean(this.toPolygonGeometric(polygonA)),
      polygonMean(this.toPolygonGeometric(polygonB)),
    ])
  }

  apply(params: any) {
    const activeSplitIds = params.selectedElements // Array of active shapes, must be changed to real active shapes later
    const splitCoords = params.splitLine
    const selectedRegionGeometrics = getRegionGeometrics(this.pageJson).filter(
      region => activeSplitIds.includes(region.id)
    )

    const selectedLineGeometrics = getLineGeometrics(this.pageJson).filter(
      line => activeSplitIds.includes(line.id)
    )

    const splittedTextRegions = selectedRegionGeometrics
      .map(r => {
        const splitResult = this.getSplitCoordsPolygon(
          r.regionCoords,
          splitCoords
        )
        if (splitResult == null) return
        const { polygonCoords1, polygonCoords2 } = splitResult
        return [
          {
            id: this.idGenerator.requestId('r'),
            regionCoords: polygonCoords1,
            oldId: r.id,
          },
          {
            id: this.idGenerator.requestId('r'),
            regionCoords: polygonCoords2,
            oldId: r.id,
          },
        ]
      })
      .filter(Boolean)
      .flat() as SplittedRegionGeometric[]

    this.applyRegionSplit(splittedTextRegions)

    const newRegionGeometrics = getRegionGeometrics(this.pageJson)

    const splittedTextLines = selectedLineGeometrics
      .map(l => {
        const baselineSplitResult = this.getSplitCoordsLine(
          l.baselineCoords,
          splitCoords
        )
        const polygonSplitResult = this.getSplitCoordsPolygon(
          l.polygonCoords,
          splitCoords
        )

        if (l.baselineCoords != null && baselineSplitResult == null) return
        if (l.polygonCoords != null && polygonSplitResult == null) return

        const { lineCoords1, lineCoords2 } = baselineSplitResult || {}
        let { polygonCoords1, polygonCoords2 } = polygonSplitResult || {}
        if (
          lineCoords1 != null &&
          lineCoords2 != null &&
          polygonCoords1 != null &&
          polygonCoords2 != null
        ) {
          const distance1 = this.distanceOfMeans(lineCoords1, polygonCoords1)
          const distance2 = this.distanceOfMeans(lineCoords1, polygonCoords2)
          if (distance1 > distance2) {
            ;[polygonCoords1, polygonCoords2] = [polygonCoords2, polygonCoords1]
          }
        }
        return [
          {
            id: this.idGenerator.requestId('l'),
            parentId: null,
            baselineCoords: lineCoords1,
            polygonCoords: polygonCoords1,
            oldId: l.id,
            oldParentId: l.parentId,
          },
          {
            id: this.idGenerator.requestId('l'),
            parentId: null,
            baselineCoords: lineCoords2,
            polygonCoords: polygonCoords2,
            oldId: l.id,
            oldParentId: l.parentId,
          },
        ]
      })
      .filter(Boolean)
      .flat() as SplittedLineGeometric[]

    const oldLineIds = splittedTextLines.map(l => l.oldId)
    const parentlessLines = selectedLineGeometrics
      .filter(l => !oldLineIds.includes(l.id))
      .filter(l => !newRegionGeometrics.some(r => r.id == l.parentId))
      .map(l => ({
        id: this.idGenerator.requestId('l'),
        parentId: null,
        baselineCoords: l.baselineCoords,
        polygonCoords: l.polygonCoords,
        oldId: l.id,
        oldParentId: l.parentId,
      }))

    const reAssignedLines = [...splittedTextLines, ...parentlessLines].map(
      l => {
        const lineCoords = l.polygonCoords || l.baselineCoords
        let newParentId
        if (lineCoords == null) {
          newParentId = newRegionGeometrics[0].id
        }
        if (newParentId == null) {
          newParentId = newRegionGeometrics.reduce<string | null>(
            (acc, r) =>
              pointInPolygon(
                this.polygonMeanPoint(lineCoords),
                this.toPolygonGeometric(r.regionCoords)
              )
                ? r.id
                : acc,
            null
          )
        }

        if (newParentId == null) {
          newParentId = newRegionGeometrics.reduce<{
            id: string | null
            distance: number
          }>(
            (acc, r) =>
              this.distanceOfMeans(lineCoords, r.regionCoords) < acc.distance
                ? {
                    id: r.id,
                    distance: this.distanceOfMeans(lineCoords, r.regionCoords),
                  }
                : acc,
            { id: null, distance: Infinity }
          ).id
        }
        return { ...l, parentId: newParentId }
      }
    )

    this.applyLineSplit(reAssignedLines)
  }
  toPolygonGeometric(coords: Coord[]): PolygonGeometric {
    return coords.map(c => [c.x, c.y])
  }
  getSplitCoordsPolygon(
    polygonCoords: Coord[],
    splitLine: Coord[]
  ): { polygonCoords1: Coord[]; polygonCoords2: Coord[] } | undefined {
    if (polygonCoords == null) return

    const polygon = {
      type: 'Polygon',
      coordinates: [
        [
          ...polygonCoords.map(c => [c.x, c.y]),
          [polygonCoords[0].x, polygonCoords[0].y],
        ],
      ],
    }

    const polyline = {
      type: 'LineString',
      coordinates: [...splitLine.map(c => [c.x, c.y])],
    }

    const output = polygonSplitter(polygon, polyline)

    const result1 = output?.geometry?.coordinates?.[0]?.[0]?.slice(0, -1)
    const result2 = output?.geometry?.coordinates?.[1]?.[0]?.slice(0, -1)

    if (result1 == null || result2 == null) return

    return {
      polygonCoords1: result1.map((coord: any[]) => ({
        x: coord[0],
        y: coord[1],
      })),
      polygonCoords2: result2.map((coord: any[]) => ({
        x: coord[0],
        y: coord[1],
      })),
    }
  }
  getSplitCoordsLine(
    lineCoords: Coord[],
    splitLine: Coord[]
  ): { lineCoords1: Coord[]; lineCoords2: Coord[] } | undefined {
    if (lineCoords == null) return
    const splitSegments = splitLine
      .map((c, index) => {
        const next = splitLine[index + 1]
        if (!next) return
        return segment(c.x, c.y, next.x, next.y)
      })
      .filter(Boolean) as Segment[]

    const baselineSegments = lineCoords
      .map((c, index) => {
        const next = lineCoords[index + 1]
        if (!next) return
        return segment(c.x, c.y, next.x, next.y)
      })
      .filter(Boolean) as Segment[]

    let intersectionCoord, intersectionIndex
    baselineSegments.forEach((baseline, index) => {
      splitSegments.forEach(split => {
        const intersection = split.intersect(baseline)?.[0]
        if (intersection) {
          intersectionCoord = intersection
          intersectionIndex = index
        }
      })
    })
    if (intersectionCoord == null || intersectionIndex == null) return
    return {
      lineCoords1: [
        intersectionCoord,
        ...lineCoords.slice(intersectionIndex + 1),
      ],
      lineCoords2: [
        ...lineCoords.slice(0, intersectionIndex + 1),
        intersectionCoord,
      ],
    }
  }

  applyRegionSplit(newRegions: SplittedRegionGeometric[]) {
    const round = x => Math.round(x)
    const oldRegionIds = Array.from(new Set(newRegions.map(r => r.oldId)))
    oldRegionIds.forEach(id => {
      const oldRegion = getShapeById(id, this.pageJson)
      const oldStructureType = getShapeAttributes(oldRegion)?.structureType
      removeRegion(this.pageJson, id)
      const currentNewRegions = newRegions.filter(r => r.oldId === id)
      currentNewRegions.forEach((r, i) => {
        const newRegion = getRegionJson(
          r.id,
          r.regionCoords.map(c => ({ x: round(c.x), y: round(c.y) })),
          oldStructureType
        )
        const regions = [...getTextAndTableRegions(this.pageJson)]
        const index = expectedRegionIndex(newRegion, regions)
        regions.splice(index, 0, newRegion)
        setTextAndTableRegions(regions, this.pageJson)
      })
    })
  }

  applyLineSplit(newLines: SplittedLineGeometric[]) {
    const oldLineIds = Array.from(new Set(newLines.map(r => r.oldId)))
    const newParentIds = Array.from(new Set(newLines.map(r => r.parentId)))
    const oldLines = oldLineIds.map(id => getShapeById(id, this.initPageJson))

    oldLineIds.map(lineId => {
      removeLine(this.pageJson, lineId)
    })
    newParentIds.forEach(parentId => {
      const region = getShapeById(parentId, this.pageJson)
      const currentNewLines = newLines.filter(l => l.parentId === parentId)
      currentNewLines.forEach(l => {
        const oldLine = oldLines.find(line => getShapeId(line) === l.oldId)
        const oldAttributes = getShapeAttributes(oldLine)
        const oldText = getShapeText(oldLine)
        const newLine = getLineJson(
          l.id,
          l.baselineCoords?.map(c => ({
            x: Math.round(c.x),
            y: Math.round(c.y),
          })),
          l.polygonCoords?.map(c => ({
            x: Math.round(c.x),
            y: Math.round(c.y),
          })),
          oldText,
          oldAttributes.tags,
          oldAttributes.structureType
        )
        const textLines = [...getTextLines(region)]
        const index = expectedLlineIndex(newLine, textLines)
        textLines.splice(index, 0, newLine)
        setTextlinesForRegion(region, textLines)
      })
    })
  }
}
