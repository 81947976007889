var Singleton = (function () {
  var instance

  function createInstance() {
    var object = new ModalHandler()
    return object
  }

  return {
    instance: function () {
      if (!instance) {
        instance = createInstance()
      }
      return instance
    },
  }
})()

function ModalHandler() {
  this.open = null
}

ModalHandler.prototype.setOpenFn = function (openFn) {
  this.open = openFn
}

ModalHandler.prototype.open = function (heading, text) {
  this.openFn(heading, text)
}

export { Singleton }
