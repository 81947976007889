import { Singleton as IdHandler } from '../singletons/IdHandler.js'
import Shape from './Shape.js'
import { getRotationAngle, getScaleFactor } from '../../globals.js'
import { polygonMean, pointRotate } from 'geometric'

function Word(canvas, blueprint, editorName, getActiveShapeIds) {
  Shape.call(this, canvas, editorName)

  this.selected = false
  this.polyLine = null
  this.vertices = []
  this.getActiveShapeIds = getActiveShapeIds

  if (blueprint) {
    this.attributes = blueprint.attributes
    this.elements = blueprint.elements
    this.id = blueprint.attributes.id
    IdHandler.instance(this.editorName).addId(this.id)

    this.text = blueprint.elements.text
    this.polyLine = this.myFabric.getWordPolyLine(this.elements.coords, this.id)
    this.canvas.add(this.polyLine)

    this.update()
  } else {
    this.id = IdHandler.instance(this.editorName).requestId('w')
  }
}

Word.prototype = Object.create(Shape.prototype)
Word.prototype.constructor = Word

Word.prototype.getBlueprint = function (myIndex) {
  //this.attributes.id = keepId ? this.id : `${parentId}w${myIndex + 1}`
  this.attributes.id = this.id
  this.setReadingOrder(myIndex)
  const factor = getScaleFactor()
  const rotationAngle = getRotationAngle()
  this.vertices = this.elements.coords.map(({ x, y }) => {
    const scaled = pointRotate([x * factor, y * factor], rotationAngle)
    return this.myFabric.getWordCircle(scaled[0], scaled[1], this.id)
  })
  this.elements.coords = this.vertices.map(vertex => ({
    x: vertex.left,
    y: vertex.top,
  }))
  return { attributes: this.attributes, elements: this.elements }
}

Word.prototype.getType = function () {
  return Shape.TYPE.WORD
}

Word.prototype.select = function () {
  this.selected = true
  this.update()
}

Word.prototype.unSelect = function () {
  this.selected = false
  this.update()
}

Word.prototype.mark = function () {
  this.polyLine.set({ fill: this.polyLine.color })
  this.canvas.requestRenderAll()
}

Word.prototype.unMark = function () {
  this.polyLine.set({ fill: 'transparent' })
  this.canvas.requestRenderAll()
}

Word.prototype.getGroup = function () {
  let polyLine = this.myFabric
    .clone(this.polyLine)
    .set({ fill: this.polyLine.color })

  return new fabric.Group([polyLine], {
    id: this.id,
    hasControls: true,
    cornerStyle: 'circle',
    padding: 0,
    cornerSize: 8,
    cornerColor: 'transparent',
    transparentCorners: true,
    cornerStrokeColor: 'blue',
    lockSkewingX: true,
    lockSkewingY: true,
  }).setControlsVisibility({
    mtr: false,
  })
}
Word.prototype.moveAllPoints = function ({ x, y }) {}

Word.prototype.removeFromCanvas = function () {
  this.canvas.remove(this.polyLine)
  this.canvas.remove(this.structureText)
  this.canvas.remove(this.structureColorFrame)
  this.canvas.remove(this.readingOrderText)
}

Word.prototype.getArea = function () {
  return this.vertices.map(vertex => [vertex.left, vertex.top])
}

Word.prototype.updatePolyLine = function () {
  this.canvas.remove(this.polyLine)
  const polyVertices = this.vertices.map(vertex => {
    return { x: vertex.left, y: vertex.top }
  })
  this.polyLine = this.myFabric.getWordPolyLine(polyVertices, this.id)

  if (this.selected) {
    this.polyLine.set({ fill: this.polyLine.color })
  } else {
    this.polyLine.set({ fill: 'transparent' })
  }
  this.canvas.add(this.polyLine)
  this.canvas.sendToBack(this.polyLine)
}

Word.prototype.update = function () {
  if (!this.isVisible()) {
    this.removeFromCanvas()
    return
  }
  const factor = getScaleFactor()
  const rotationAngle = getRotationAngle()
  this.vertices = this.elements.coords.map(({ x, y }) => {
    const scaled = pointRotate([x * factor, y * factor], rotationAngle)
    return this.myFabric.getWordCircle(scaled[0], scaled[1], this.id)
  })

  this.selected = this.getActiveShapeIds?.().includes(this.id)

  this.updateVertexSize()
  this.updateStructureColor()
  this.updateStructureText()
  this.updateReadingOrderText()
  this.updatePolyLine()
}

export default Word
