<template>
  <span
    @mouseup="$emit('mouseUp', $event)"
    class="flex w-full items-center text-[#7a7a7a] text-xs my-[1px]"
    :class="{ draggable }"
    @selectstart="handleSelectStart"
    :style="{
      'user-select': 'none !important',
    }"
    ><span class="h-[1px] ml-1 bg-[#DEDEDE] grow"></span>
    <span class="mx-2">{{ `Region ${index + 1}` }}</span>
    <span
      v-if="structureType"
      class="rounded py-0.5 px-1"
      :style="{ backgroundColor: getStructureColor(structureType) }"
      >{{
        `${structureType ? `${structureType}` : ''}${
          structureLevel ? `-${structureLevel}` : ''
        }`
      }}</span
    >
    <span class="h-[1px] mr-1 bg-[#DEDEDE] grow"></span>
    <span v-if="draggable" class="w-5" v-html="$svg.dragIndicator"></span
  ></span>
</template>

<script>
import { hexToRgbaString } from '../LayoutEditor/components/fabricObjects.ts'
import { Singleton as CollectionConfig } from '../LayoutEditor/components/singletons/collectionConfig.js'

export default {
  props: {
    draggable: {
      type: Boolean,
    },
    index: {
      type: Number,
    },
    structureType: {
      type: String,
    },
    structureLevel: {
      type: Number,
    },
  },
  data() {
    return {}
  },

  methods: {
    handleSelectStart(event) {
      event.preventDefault()
    },
    getStructureColor(type) {
      return hexToRgbaString(
        CollectionConfig.instance()
          .getStructureTags()
          .find(t => t.name === type)?.color || '#000000',
        0.5
      )
    },
  },
}
</script>

<style scoped lang="scss">
.draggable {
  @apply bg-[#F3F3F3] cursor-pointer p-2;
}
</style>
