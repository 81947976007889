import type { Pointer, Coord } from '../types' // Adjust the path if needed
import { Canvas, Circle, Line } from 'fabric' // Adjust the path if needed
import { getRegionSplitCircle, getRegionSplitLine } from '../fabricObjects' // Adjust the path if needed
import {
  Singleton as ActionHandler,
  ACTION_TYPE,
  SELECTION_TYPE,
} from '../singletons/actionHandler.js'
import { pointRotate, Polygon as PolygonGeometric } from 'geometric'
import model from '../../../Model/model.js'
import { getRotationAngle, getScaleFactor } from '../../globals.js'
import Split from '../../../Actions/Split.ts'

export default class StateFreeFormSplit {
  private isMouseDown: boolean
  private isKeyDown: boolean
  private splitCoords: Coord[] = []
  private fabricCircles: Circle[] = []
  private fabricLines: Line[] = []
  private canvas: Canvas
  private getActiveSplitIds: () => string[]
  private finishCallback: Function

  constructor(cavas: Canvas, getActiveSplitIds: () => string[]) {
    this.canvas = cavas
    this.getActiveSplitIds = getActiveSplitIds
    this.isMouseDown = false
    this.isKeyDown = false
  }

  onMouseDown({ pointer }: { pointer: Pointer }): void {
    this.isMouseDown = true
  }

  onMouseUp({ pointer }: { pointer: Pointer }): void {
    this.isMouseDown = false
    this.splitCoords.push({ x: pointer.x, y: pointer.y })

    this.updateFabricCircles()
    this.updateFabricLines()
  }

  onMouseMove({ pointer }: { pointer: Pointer }): void {
    this.updateFabricLines(pointer)
  }

  onDoubleClick({ pointer }: { pointer: Pointer }): void {
    this.splitCoords.pop()
    this.finishSplit()
  }

  onKeyDown(event: KeyboardEvent): void {
    this.isKeyDown = true
    if (event.key === 'Enter') {
      this.finishSplit()
    }
  }

  onKeyUp(event: KeyboardEvent): void {
    this.isKeyDown = false
  }

  updateFabricCircles() {
    this.canvas.remove(...this.fabricCircles)

    this.fabricCircles = this.splitCoords.map(c => getRegionSplitCircle(c))
    this.canvas.add(...this.fabricCircles)
  }
  updateFabricLines(currentPointer?: Pointer) {
    this.canvas.remove(...this.fabricLines)

    this.fabricLines = this.splitCoords
      .map((c, i) => {
        const nextCoord = this.splitCoords[i + 1]
        if (nextCoord) {
          return getRegionSplitLine(c.x, c.y, nextCoord.x, nextCoord.y)
        }
        if (currentPointer) {
          return getRegionSplitLine(
            c.x,
            c.y,
            currentPointer.x,
            currentPointer.y
          )
        }
      })
      .filter(Boolean) as Line[]

    this.canvas.add(...this.fabricLines)
  }
  toPolygonGeometric(coords: Coord[]): PolygonGeometric {
    return coords.map(c => [c.x, c.y])
  }

  scaleCoords(coords: Coord[]): Coord[] {
    const round = x => Math.round(x)
    const scaleFn = value => round(value * (1 / getScaleFactor()))
    const rotationFn = (point: Coord): Coord => {
      const [x, y] = pointRotate(
        [point.x, point.y],
        360 - getRotationAngle()
      ).map(c => round(c))
      return { x, y }
    }
    const scalePoint = (
      point: Coord,
      scaleFn: Function,
      rotationFn: Function
    ) => {
      return rotationFn({ x: scaleFn(point.x), y: scaleFn(point.y) })
    }
    return coords.map(c => scalePoint(c, scaleFn, rotationFn))
  }

  finishSplit() {
    this.updateFabricCircles()
    this.updateFabricLines()

    const splitParams = {
      selectedElements: this.getActiveSplitIds(),
      splitLine: this.scaleCoords(this.splitCoords),
    }
    const splitter = new Split()
    splitter.apply(splitParams)
    splitter.newAction()
    this.finishCallback?.()
  }

  reset() {
    this.canvas.remove(...this.fabricCircles)
    this.canvas.remove(...this.fabricLines)
  }

  whenFinished(callback: Function) {
    this.finishCallback = callback
  }
}
