import { JSONToHTML } from 'html-to-json-parser' // ES6
export function textToHtml(text, tags, mapping) {
  const divElement = document.createElement('div')
  const nodeStack = [divElement]
  const getTop = () => nodeStack[nodeStack.length - 1]

  ;[...text].forEach((c, index) => {
    const startingTags = tags
      .filter(t => getTagStart(t) === index)
      .sort(
        (a, b) =>
          getTagEnd(b) - getTagStart(b) - (getTagEnd(a) - getTagStart(a))
      )
    const endingTags = tags.filter(t => getTagEnd(t) - 1 === index)

    startingTags.forEach(t => {
      const styles = getTagTextStyles(t)
      const cssStyle = styles.reduce(
        (acc, s) => acc + mapping?.find(m => m.type === s)?.style,
        ''
      )

      /* const newNode = {
        node: 'element',
        tag: 'span',
        attr: {
          id: t.id,
          style: cssStyle,
          title: t.name,
          class: mapping?.find((m) => m.name === t.name)?.class,
          /*  type: type, 
        },
        child: [],
      } */
      const spanElement = document.createElement('span')
      spanElement.setAttribute('id', t.id)
      spanElement.setAttribute('style', cssStyle)
      spanElement.setAttribute('title', t.name)
      spanElement.setAttribute(
        'class',
        mapping?.find(m => m.name === t.name)?.class
      )

      getTop().appendChild(spanElement)
      nodeStack.push(spanElement)
    })

    if (getTop().lastChild !== Node.TEXT_NODE) {
      getTop().appendChild(document.createTextNode(''))
    }
    getTop().lastChild.textContent += c

    endingTags.forEach(t => {
      if (getTop().tagName.toLowerCase() !== 'div') {
        nodeStack.pop()
      }
    })
  })
  //console.log(nodeStack[0].innerHTML)
  return nodeStack[0].innerHTML
}

function getTagStart(tag) {
  const offset = parseInt(
    tag?.rules?.find(rule => rule.directive === 'offset')?.value,
    10
  )
  return offset
}

function getTagEnd(tag) {
  const length = parseInt(
    tag?.rules?.find(rule => rule.directive === 'length')?.value,
    10
  )
  return getTagStart(tag) + length
}

function getTagTextStyles(tag) {
  const objectsWithTrue = findObjectWithTrue(tag?.rules)
  return objectsWithTrue.map(({ directive }) => directive)
}

export function transformArrayToObject(array) {
  const obj = {}
  array.forEach(item => {
    const keyValue = item.split(':')
    if (keyValue[0] !== 'offset' && keyValue[0] !== 'length') {
      obj[keyValue[0]] = keyValue[1]
    }
  })
  return obj
}

function findObjectWithTrue(arr) {
  let found = []
  for (let i = 0; i < arr.length; i++) {
    const obj = arr[i]
    for (const key in obj) {
      if (obj[key] === 'true' || obj[key] === true) {
        if (obj.directive !== 'continued') {
          found.push(obj)
        }
      }
    }
  }
  return found
}

export function transformObjectToArray(obj) {
  const array = []
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      array.push({ directive: key, value: obj[key] })
    }
  }
  return array
}

export function removeForbiddenNonePrintableChars(str) {
  if (typeof str !== 'string') return str
  //g modifier: global. All matches (don't return after first match)
  return str.replace(/[\x02]/g, '')
}

export function decodeCSSChars(str) {
  if (typeof str === 'string' || typeof str === 'number') {
    return String(str).replace(/\\u([\d\w]{4})/gi, (match, grp) =>
      String.fromCharCode(parseInt(grp, 16))
    )
  }
}
export function encodeHTMLEntities(s) {
  var el = document.createElement('div')
  el.innerText = el.textContent = s
  s = el.innerHTML
  return s
}

/* function decodeHTMLEntities(text) {
  const entitiesMap = {
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&#39;': "'",
    '&apos;': "'",
    '&nbsp;': ' ',
    '&copy;': '©',
    '&reg;': '®',
    '&euro;': '€',
    '&pound;': '£',
    '&yen;': '¥',
    '&cent;': '¢',
    '&trade;': '™',
    '&sect;': '§',
    '&laquo;': '«',
    '&raquo;': '»',
    '&hellip;': '…',
    '&mdash;': '—',
    '&ndash;': '–',
    '&bull;': '•',
    '&permil;': '‰',
    '&micro;': 'µ',
    '&middot;': '·',
    '&deg;': '°',
    '&ordm;': 'º',
    '&ordf;': 'ª',
    '&alpha;': 'α',
    '&beta;': 'β',
    '&gamma;': 'γ',
    '&delta;': 'δ',
    '&epsilon;': 'ε',
    '&zeta;': 'ζ',
    '&eta;': 'η',
    '&theta;': 'θ',
    '&iota;': 'ι',
    '&kappa;': 'κ',
    '&lambda;': 'λ',
    '&mu;': 'μ',
    '&nu;': 'ν',
    '&xi;': 'ξ',
    '&omicron;': 'ο',
    '&pi;': 'π',
    '&rho;': 'ρ',
    '&sigma;': 'σ',
    '&tau;': 'τ',
    '&upsilon;': 'υ',
    '&phi;': 'φ',
    '&chi;': 'χ',
    '&psi;': 'ψ',
    '&omega;': 'ω',
    '&Agrave;': 'À',
    '&Aacute;': 'Á',
    '&Acirc;': 'Â',
    '&Atilde;': 'Ã',
    '&Auml;': 'Ä',
    '&Aring;': 'Å',
    '&AElig;': 'Æ',
    '&Ccedil;': 'Ç',
    '&Egrave;': 'È',
    '&Eacute;': 'É',
    '&Ecirc;': 'Ê',
    '&Euml;': 'Ë',
    '&Igrave;': 'Ì',
    '&Iacute;': 'Í',
    '&Icirc;': 'Î',
    '&Iuml;': 'Ï',
    '&ETH;': 'Ð',
    '&Ntilde;': 'Ñ',
    '&Ograve;': 'Ò',
    '&Oacute;': 'Ó',
    '&Ocirc;': 'Ô',
    '&Otilde;': 'Õ',
    '&Ouml;': 'Ö',
    '&Oslash;': 'Ø',
    '&Ugrave;': 'Ù',
    '&Uacute;': 'Ú',
    '&Ucirc;': 'Û',
    '&Uuml;': 'Ü',
    '&Yacute;': 'Ý',
    '&THORN;': 'Þ',
    '&szlig;': 'ß',
    '&agrave;': 'à',
    '&aacute;': 'á',
    '&acirc;': 'â',
    '&atilde;': 'ã',
    '&auml;': 'ä',
    '&aring;': 'å',
    '&aelig;': 'æ',
    '&ccedil;': 'ç',
    '&egrave;': 'è',
    '&eacute;': 'é',
    '&ecirc;': 'ê',
    '&euml;': 'ë',
    '&igrave;': 'ì',
    '&iacute;': 'í',
    '&icirc;': 'î',
    '&iuml;': 'ï',
    '&eth;': 'ð',
    '&ntilde;': 'ñ',
    '&ograve;': 'ò',
    '&oacute;': 'ó',
    '&ocirc;': 'ô',
    '&otilde;': 'õ',
    '&ouml;': 'ö',
    '&oslash;': 'ø',
    '&ugrave;': 'ù',
    '&uacute;': 'ú',
    '&ucirc;': 'û',
    '&uuml;': 'ü',
    '&yacute;': 'ý',
    '&thorn;': 'þ',
    '&yuml;': 'ÿ',
  }

  return text.replace(/&[^;]+;/g, match => entitiesMap[match] || match)
} */

/* export function htmlToText(html) {
  const json = html2json(html)
  let text = ''
  const tags = []

  const parseElement = element => {
    let tag = null
    if (element.node === 'text') {
      const decodedText = decodeHTMLEntities(element.text)
      text += decodedText.replace(/&nbsp;/g, ' ')
      return
    }
    if (element.node === 'element' && element.tag === 'span') {
      tag = { id: element.attr.id, start: text.length }
    }

    element?.child?.forEach(element => {
      parseElement(element)
    })
    if (element.node === 'element' && element.tag === 'span') {
      tag.end = text.length
      tags.push(tag)
    }
  }
  parseElement(json)

  return { text, tags }
} */

//https://stackoverflow.com/questions/4811822/get-a-ranges-start-and-end-offsets-relative-to-its-parent-container
export function getSelectionCharacterOffsetWithin(element) {
  if (!element) {
    return { start: 0, end: 0 }
  }

  var start = 0
  var end = 0
  var doc = element.ownerDocument || element.document
  var win = doc.defaultView || doc.parentWindow
  var sel
  if (typeof win.getSelection != 'undefined') {
    sel = win.getSelection()
    if (sel.rangeCount > 0) {
      var range = win.getSelection().getRangeAt(0)
      var preCaretRange = range.cloneRange()
      preCaretRange.selectNodeContents(element)
      preCaretRange.setEnd(range.startContainer, range.startOffset)
      start = preCaretRange.toString().length
      preCaretRange.setEnd(range.endContainer, range.endOffset)
      end = preCaretRange.toString().length
    }
  } else if ((sel = doc.selection) && sel.type != 'Control') {
    var textRange = sel.createRange()
    var preCaretTextRange = doc.body.createTextRange()
    preCaretTextRange.moveToElementText(element)
    preCaretTextRange.setEndPoint('EndToStart', textRange)
    start = preCaretTextRange.text.length
    preCaretTextRange.setEndPoint('EndToEnd', textRange)
    end = preCaretTextRange.text.length
  }
  return { start: start, end: end }
}

//https://stackoverflow.com/questions/4811822/get-a-ranges-start-and-end-offsets-relative-to-its-parent-container
export function getCaretCharacterOffsetWithin(element) {
  var caretOffset = 0
  var doc = element?.ownerDocument || element?.document
  var win = doc?.defaultView || doc?.parentWindow
  var sel
  if (typeof win?.getSelection != 'undefined') {
    sel = win.getSelection()
    if (sel.rangeCount > 0) {
      var range = win.getSelection().getRangeAt(0)
      var preCaretRange = range.cloneRange()
      preCaretRange.selectNodeContents(element)
      preCaretRange.setEnd(range.endContainer, range.endOffset)
      caretOffset = preCaretRange.toString().length
    }
  } else if ((sel = doc?.selection) && sel?.type != 'Control') {
    var textRange = sel.createRange()
    var preCaretTextRange = doc.body.createTextRange()
    preCaretTextRange.moveToElementText(element)
    preCaretTextRange.setEndPoint('EndToEnd', textRange)
    caretOffset = preCaretTextRange.text.length
  }
  return caretOffset
}

//https://stackoverflow.com/questions/2920150/insert-text-at-cursor-in-a-content-editable-div
export function insertTextAtCaret(text) {
  var sel, range
  if (window.getSelection) {
    sel = window.getSelection()
    if (sel.getRangeAt && sel.rangeCount) {
      range = sel.getRangeAt(0)
      range.deleteContents()
      range.insertNode(document.createTextNode(text))
    }
  } else if (document.selection && document.selection.createRange) {
    document.selection.createRange().text = text
  }
}

//https://stackoverflow.com/questions/2920150/insert-text-at-cursor-in-a-content-editable-div
export function saveSelection() {
  if (window.getSelection) {
    const sel = window.getSelection()
    if (sel.getRangeAt && sel.rangeCount) {
      return sel.getRangeAt(0)
    }
  } else if (document.selection && document.selection.createRange) {
    return document.selection.createRange()
  }
  return null
}

//https://stackoverflow.com/questions/2920150/insert-text-at-cursor-in-a-content-editable-div
export function restoreSelection(range) {
  if (range) {
    if (window.getSelection) {
      const sel = window.getSelection()
      sel.removeAllRanges()
      sel.addRange(range)
    } else if (document.selection && range.select) {
      range.select()
    }
  }
}

// Credit to Liam (Stack Overflow)
// https://stackoverflow.com/a/41034697/3480193
// https://stackoverflow.com/questions/6249095/how-to-set-the-caret-cursor-position-in-a-contenteditable-element-div
export class Cursor {
  static getCurrentCursorPosition(parentElement) {
    var selection = window.getSelection(),
      charCount = -1,
      node

    if (selection.focusNode) {
      if (Cursor._isChildOf(selection.focusNode, parentElement)) {
        node = selection.focusNode
        charCount = selection.focusOffset

        while (node) {
          if (node === parentElement) {
            break
          }

          if (node.previousSibling) {
            node = node.previousSibling
            charCount += node.textContent.length
          } else {
            node = node.parentNode
            if (node === null) {
              break
            }
          }
        }
      }
    }

    return charCount
  }

  static setCurrentCursorPosition(chars, element) {
    if (chars >= 0) {
      //If statement added by Johannes
      if (chars > element.innerText.length) {
        chars = element.innerText.length
      }

      var selection = window.getSelection()

      let range = Cursor._createRange(element, { count: chars })

      if (range) {
        range.collapse(false)
        selection.removeAllRanges()
        selection.addRange(range)
      }
    }
  }

  static _createRange(node, chars, range) {
    if (!range) {
      range = document.createRange()
      range.selectNode(node)
      range.setStart(node, 0)
    }

    if (chars.count === 0) {
      range.setEnd(node, chars.count)
    } else if (node && chars.count > 0) {
      if (node.nodeType === Node.TEXT_NODE) {
        if (node.textContent.length < chars.count) {
          chars.count -= node.textContent.length
        } else {
          range.setEnd(node, chars.count)
          chars.count = 0
        }
      } else {
        for (var lp = 0; lp < node.childNodes.length; lp++) {
          range = Cursor._createRange(node.childNodes[lp], chars, range)

          if (chars.count === 0) {
            break
          }
        }
      }
    }

    return range
  }

  static _isChildOf(node, parentElement) {
    while (node !== null) {
      if (node === parentElement) {
        return true
      }
      node = node.parentNode
    }

    return false
  }
}

export function isRTL(text) {
  // Regular expression to match RTL characters
  var rtlCharacters =
    /[\u0590-\u05FF\u0600-\u06FF\u0700-\u074F\u0750-\u077F\u0780-\u07BF\u07C0-\u07FF\uFB1D-\uFB4F\uFB50-\uFDFF\uFE70-\uFEFF\u0591-\u05BD\u05BF-\u05C7\u05D0-\u05EA\u05F0-\u05F4\u060B-\u060B\u060D-\u061A\u061C-\u061C\u061E-\u061E\u0620-\u063F\u0641-\u064A\u0656-\u065F\u066A-\u066F\u0671-\u06DC\u06DE-\u06FF\u0750-\u076D\uFB1D-\uFB4F\uFB50-\uFDFF\uFE70-\uFEFF\uFB1D-\uFB4F\uFB50-\uFDFF\uFE70-\uFEFF\uFD3E-\uFD3F\uFDFD\uFE70-\uFEFC\uFEFF]/

  // Check if any RTL characters exist in the text
  return rtlCharacters.test(text)
}
