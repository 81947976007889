import defaultConfig from '~/assets/tag-config.json'
import { useCollections } from '~/composables/api/useCollections.ts'
import * as Sentry from '@sentry/vue'

var Singleton = (function () {
  var instance

  function createInstance() {
    var object = new CollectionConfig()
    return object
  }

  return {
    instance: function () {
      if (!instance) {
        instance = createInstance()
      }
      return instance
    },
  }
})()

function CollectionConfig() {
  this.eventHandlers = {
    update: [],
  }

  this.config = null
  const route = useRoute()
  this.collId = route.params.collectionid
  this.timeOut = null

  this.fetchConfig(this.collId)
}

CollectionConfig.prototype.setConfig = async function (config) {
  if (config == null) return

  this.config = config
  this.emit('update', {
    relationTags: this.getRelationTags(),
    textualTags: this.getTextualTags(),
    structureTags: this.getStructureTags(),
    characters: this.getCharacters(),
  })
}

CollectionConfig.prototype.fetchConfig = async function (collId) {
  let response

  try {
    if (!collId) {
      response = defaultConfig
    } else {
      response = await useCollections().fetchTagDef({ collId })
    }

    this.config = { ...response }

    if (this.config?.definitions?.structure) {
      const newDefaults = defaultConfig.definitions.structure?.filter(
        t => !this.config.definitions.structure.find(tag => tag.name === t.name)
      )
      this.config.definitions.structure = [
        ...(newDefaults || []),
        ...(this.config.definitions.structure || []),
      ]
    }
    if (this.config?.definitions?.relations) {
      const newDefaults = defaultConfig.definitions.relations?.filter(
        t => !this.config.definitions.relations.find(tag => tag.name === t.name)
      )
      this.config.definitions.relations = [
        ...(newDefaults || []),
        ...(this.config.definitions.relations || []),
      ]
    }
    this.emit('update', {
      relationTags: this.getRelationTags(),
      textualTags: this.getTextualTags(),
      structureTags: this.getStructureTags(),
      characters: this.getCharacters(),
    })
  } catch (err) {
    console.error(err)
    Sentry.captureException(err)
  }
}

CollectionConfig.prototype.updateAndSaveConfig = async function () {
  console.log(`%ccollection config changed: %o`, 'color: lime', this.config)
  this.emit('update', {
    relationTags: this.getRelationTags(),
    textualTags: this.getTextualTags(),
    structureTags: this.getStructureTags(),
    characters: this.getCharacters(),
  })

  clearTimeout(this.timeOut)
  this.timeOut = setTimeout(async () => {
    try {
      await useCollections().saveTagDef({
        def: this.config,
        collId: this.collId,
      })
    } catch (err) {
      Sentry.captureException(err)
    }
  }, 1000)
}

CollectionConfig.prototype.setStructureTags = function (structureTags) {
  if (structureTags == null) return
  this.config.definitions.structure = [...structureTags]
  this.updateAndSaveConfig()
}

CollectionConfig.prototype.setTextualTags = function (textualTags) {
  if (textualTags == null) return
  this.config.definitions.annotations = [...textualTags]
  this.updateAndSaveConfig()
}

CollectionConfig.prototype.setRelationTags = function (relationTags) {
  if (relationTags == null) return
  this.config.definitions.relations = [...relationTags]
  this.updateAndSaveConfig()
}

CollectionConfig.prototype.setCharacters = function (characters) {
  if (characters == null) return
  this.config.definitions.characters = [...characters]
  this.updateAndSaveConfig()
}

CollectionConfig.prototype.getStructureTags = function () {
  return cloneObj(this.config?.definitions?.structure || [])
}

CollectionConfig.prototype.getTextualTags = function () {
  return cloneObj(this.config?.definitions?.annotations || [])
}

CollectionConfig.prototype.getRelationTags = function () {
  return cloneObj(this.config?.definitions?.relations || [])
}

CollectionConfig.prototype.getCharacters = function () {
  return cloneObj(this.config?.definitions?.characters || [])
}

CollectionConfig.prototype.hasFetched = function () {
  return this.config != null
}

CollectionConfig.prototype.on = function (key, handler) {
  this.eventHandlers[key].push(handler)
}

CollectionConfig.prototype.off = function (key, handler) {
  const index = this.eventHandlers[key].indexOf(handler)
  if (index > -1) {
    this.eventHandlers[key].splice(index, 1)
  }
}

CollectionConfig.prototype.emit = function (key, event) {
  this.eventHandlers[key].map(h => h(event))
}

export { Singleton }
