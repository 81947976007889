import { compare } from 'fast-json-patch'
import { cloneObj } from '../Model/modelUtils.ts'
import IdGenerator from './IdGenerator.js'
import model from '../Model/model.js'

export default class BaseEditor {
  protected initPageJson: any
  protected pageJson: any
  protected idGenerator: IdGenerator

  constructor(pageJson: any) {
    const initPageJson = pageJson || model.getPageJson()

    this.initPageJson = structuredClone(initPageJson)
    this.pageJson = structuredClone(initPageJson)
    this.idGenerator = new IdGenerator(this.initPageJson)
  }

  getPageJson() {
    return this.pageJson
  }

  getPatch() {
    return compare(
      structuredClone(this.initPageJson),
      structuredClone(this.pageJson)
    )
  }

  getReversedPatch() {
    return compare(
      structuredClone(this.pageJson),
      structuredClone(this.initPageJson)
    )
  }

  newAction() {
    model.newAction(this.getPatch(), this.getReversedPatch())
  }

  logXml() {
    console.log(model.jsonToXmlString(this.pageJson))
  }

  apply(params: any) {}
}
