export const ACTION_TYPE = Object.freeze({
  ADD_POINT: 1,
  REMOVE_POINT: 2,
  MOVE_POINT: 3,
  MOVE_SHAPE: 4,
  MOVE_POLYGON_POINT: 5,
  MOVE_POLYGON_POINTS: 6,
  MULTI_ACTION: 11,
  REMOVE_SHAPE: 12,
  ADD_SHAPE: 13,
  ADD_TABLE_COLUM: 14,
  REMOVE_TABLE_COLUM: 15,
  ADD_TABLE_ROW: 16,
  REMOVE_TABLE_ROW: 17,
  ADD_CELL: 18,
  REMOVE_CELL: 19,
  CHANGE_CELL_SPAN: 20,
  CHANGE_PARENT: 21,
  MOVE_SHAPE_WITH_VECTOR: 22,
  SET_STRUCTUR_TYPE: 23,
  CHANGE_READING_ORDER: 24,
  POSITION_MOVE: 25,
  ADD_RELATION: 26,
  REMOVE_RELATION: 27,
  UPDATE_RELATION_IDS: 28,
  SET_TEXT: 29,
  ADD_TAG: 30,
  REMOVE_TAG: 31,
  SET_TAGS: 32,
  SET_ROTATION: 33,
  RESIZE: 34,
  ADD_BASELINE: 35,
  ADD_REGION: 36,
  ADD_TABLE: 37,
  REMOVE_BASELINE: 38,
  REMOVE_REGION: 39,
  REMOVE_TABLE: 40,
  MOVE_POINTS: 41,
  SPLIT_TABLE_COL: 42,
  SPLIT_TABLE_ROW: 43,
  MERGE_TABLE_COLS: 44,
  MERGE_TABLE_ROWS: 45,
  CHANGE_STATUS: 46,
  ADD_POLYGON_POINT: 47,
  REMOVE_POLYGON_POINT: 48,
  MOVE_MULTIPLE_POINTS: 49,
  SET_METADATA_PROPERTIES: 50,
  UPDATE_CELL_COORDS: 51,
  UPDATE_CELLS: 52,
  MOVE_POINT_TABLE: 53,
  PATCH: 54,
})

export const afterEditorAction = (editor, action) => {
  editor.updateTextEditor()
}

export const actionMethods = [
  {
    type: ACTION_TYPE.SET_METADATA_PROPERTIES,
    reverse: action => {
      return {
        type: ACTION_TYPE.SET_METADATA_PROPERTIES,
        old_properties: action.new_properties,
        new_properties: action.old_properties,
      }
    },
    handleEditor: (editor, action) => {
      editor.setMetadataProperties(action.new_properties)
    },
  },
  {
    type: ACTION_TYPE.MOVE_POLYGON_POINT,
    reverse: action => {
      return {
        type: ACTION_TYPE.MOVE_POLYGON_POINT,
        shape_id: action.shape_id,
        point_index: action.point_index,
        vector: { x: action.vector.x * -1, y: action.vector.y * -1 },
      }
    },
    handleEditor: (editor, action) => {
      const shape = editor.getShape(action.shape_id)
      shape && shape.movePolygonPoint(action)
      editor.updateRelationArrows()
    },
  },
  {
    type: ACTION_TYPE.MOVE_POLYGON_POINTS,
    reverse: action => {
      return {
        type: ACTION_TYPE.MOVE_POLYGON_POINTS,
        shape_id: action.shape_id,
        vectors: action.vectors.map(v => ({ x: v.x * -1, y: v.y * -1 })),
      }
    },
    handleEditor: (editor, action) => {
      const shape = editor.getShape(action.shape_id)
      shape && shape.movePolygonPoints(action)
      editor.updateRelationArrows()
    },
  },
  {
    type: ACTION_TYPE.ADD_POLYGON_POINT,
    reverse: action => {
      return {
        ...action,
        type: ACTION_TYPE.REMOVE_POLYGON_POINT,
      }
    },
    handleEditor: (editor, action) => {
      const shape = editor.getShape(action.shape_id)
      const newVertex = shape.getPolygonVertex(
        action.point_coord.x,
        action.point_coord.y
      )
      shape.addPolygonVertex(newVertex, action.point_index)
    },
  },
  {
    type: ACTION_TYPE.REMOVE_POLYGON_POINT,
    reverse: action => {
      return {
        ...action,
        type: ACTION_TYPE.ADD_POLYGON_POINT,
      }
    },
    handleEditor: (editor, action) => {
      const shape = editor.getShape(action.shape_id)
      const [v] = shape.polygonVertices.splice(action.point_index, 1)
      shape.canvas.remove(v)
      shape.update()
    },
  },
  {
    type: ACTION_TYPE.MOVE_MULTIPLE_POINTS,
    reverse: action => {
      return {
        type: ACTION_TYPE.MOVE_MULTIPLE_POINTS,
        shape_id: action.shape_id,
        point_indices: action.point_indices,
        vector: { x: action.vector.x * -1, y: action.vector.y * -1 },
      }
    },
    handleEditor: (editor, action) => {
      const shape = editor.getShape(action.shape_id)
      shape && shape.moveMultiplePoints(action)
      editor.updateRelationArrows()
    },
  },
  {
    type: ACTION_TYPE.CHANGE_READING_ORDER,
    reverse: action => {
      return {
        type: ACTION_TYPE.CHANGE_READING_ORDER,
        old_parent_id: action.new_parent_id,
        new_parent_id: action.old_parent_id,
        old_index: action.new_index,
        new_index: action.old_index,
      }
    },
    handleEditor: (editor, action) => {
      editor.handleReadingOrderChange(action)
    },
  },
  {
    type: ACTION_TYPE.PATCH,
    reverse: action => {
      return {
        type: ACTION_TYPE.PATCH,
        ops: action.reversedOps,
        reversedOps: action.ops,
      }
    },
    handleEditor: (editor, action) => {
      editor.handleReadingOrderChange(action)
    },
  },
]
