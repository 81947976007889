import simplify from 'simplify-js'

import { isEmpty } from 'lodash'
import { pointRotate } from 'geometric'
import {
  Singleton as ActionHandler,
  ACTION_TYPE,
  SELECTION_TYPE,
} from './singletons/actionHandler.js'

const getUnSupportedElements = json => {
  let allRegions = getPage(json).elements
  let foundTableRegion = allRegions.find(
    region => region.name === 'TableRegion'
  )
  if (foundTableRegion) return 'TableRegion'
}

//https://stackoverflow.com/questions/175739/how-can-i-check-if-a-string-is-a-valid-number !!Changed parseFloat to parseInt
const isNumeric = str => {
  if (typeof str != 'string') return false // we only process strings!
  return (
    !isNaN(str) && !isNaN(parseInt(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
  ) // ...and ensure strings of whitespace fail
}

const substituteValues = selector => {
  const sub = value => {
    if (value === 'true') {
      return true
    }
    if (value === 'false') {
      return false
    }
    if (isNumeric(value)) {
      return parseFloat(value)
    }
    return value
  }
  return {
    ...selector,
    rules: selector.rules.map(r => ({
      ...r,
      value: sub(r.value),
    })),
  }
}

const parseCustom = string => {
  if (process.client) {
    const customObject = {}
    const rules = string
      .split('}')
      .filter(Boolean)
      .map(rule => {
        const [selector, styles] = rule.split('{').map(str => str.trim())
        const styleRules = styles
          .split(';')
          .filter(Boolean)
          .map(style => {
            const [directive, value] = style.split(':').map(str => str.trim())
            return { directive, value }
          })
        return { selector, rules: styleRules }
      })

    rules.forEach(({ selector, rules }) => {
      switch (selector) {
        case 'readingOrder':
          customObject.readingOrder = rules[0].value
          break
        case 'structur':
        case 'structure':
          customObject.structureType =
            rules.find(r => r.directive === 'type')?.value || ''
          customObject.structureObject = { selector, rules }
          break
        case 'relationType':
          customObject.relationType = rules[0]?.value || ''
          break
        case 'relationName':
          customObject.relationName = rules[0]?.value || ''
          break
        case 'id':
          customObject.id = rules[0]?.value || ''
          break
        default: {
          if (!('tags' in customObject)) {
            customObject.tags = []
          }
          customObject.tags.push({ selector, rules })
        }
      }
    })

    return customObject
  }
}

const stringifyCustom = custom => {
  //log(custom)
  let string = ''
  if ('readingOrder' in custom) {
    string += ` readingOrder {index:${custom.readingOrder};}`
  }
  if ('structureType' in custom) {
    let asString = ''
    if (custom.structureObject != null) {
      const otherProperties = custom.structureObject.rules.filter(
        o => o.directive != 'type'
      )
      asString = otherProperties.reduce(
        (acc, p) => acc + `${p.directive}:${p.value};`,
        ''
      )
    }
    string += ` structure {type:${custom.structureType};${asString}}`
  }
  if ('relationType' in custom) {
    string += ` relationType {value:${custom.relationType};}`
  }
  if ('relationName' in custom) {
    string += ` relationName {value:${custom.relationName};}`
  }
  if ('id' in custom) {
    string += ` id {value:${custom.id};}`
  }
  if ('tags' in custom) {
    custom.tags.map(tag => {
      const ruleString = tag.rules.reduce(
        (acc, { directive, value }) => acc + `${directive}:${value};`,
        ''
      )
      string += ` ${tag.selector} {${ruleString}}`
    })
  }

  string = string.substring(1)
  return string
}

const jsonToBlueprint = (globalJson, scaleFactor) => {
  const sortByReadingOrder = regions => {
    regions = regions.sort(
      (a, b) =>
        a.attributes.custom?.readingOrder - b.attributes.custom?.readingOrder
    )
    const sortLines = lines => {
      return lines.sort(
        (a, b) =>
          a.attributes.custom?.readingOrder - b.attributes.custom?.readingOrder
      )
    }
    regions.map(region => {
      if (region.elements.textLines) {
        region.elements.textLines = sortLines(region.elements.textLines)
        return
      }
      region.elements.cells.map(cell => {
        cell.elements.textLines = sortLines(cell.elements.textLines)
      })
    })
    return regions
  }

  const estimateBaselineCoords = polyCoords => {
    const isClockwise = coords => {
      //source: https://stackoverflow.com/questions/14505565/detect-if-a-
      //set-of-points-in-an-array-that-are-the-vertices-of-a-complex-polygon
      let area = 0
      coords.map((_, i) => {
        const j = (i + 1) % coords.length
        area += coords[i].x * coords[j].y
        area -= coords[j].x * coords[i].y
      })
      return area > 0
    }

    const getNextCoord = (coord, coords) => {
      const index = coords.findIndex(c => c === coord)
      return coords[(index + 1) % coords.length]
    }
    const collectCoords = (collection, coords, coord, endCoord) => {
      if (coord === endCoord) {
        return [...collection, coord]
      }
      return collectCoords(
        [...collection, coord],
        coords,
        getNextCoord(coord, coords),
        endCoord
      )
    }
    const getMostLeft = coords => {
      const [left1, left2] = [...coords].sort((a, b) => a.x - b.x)
      if (left1.x === left2.x) {
        if (left1.y < left2.y) return left2
        return left1
      }
      return left1
    }
    const getMostRight = coords => {
      const [right1, right2] = [...coords].sort((a, b) => b.x - a.x)
      if (right1.x === right2.x) {
        if (right1.y < right2.y) return right2
        return right1
      }
      return right1
    }

    const clockwise = isClockwise(polyCoords)
    let coords = clockwise ? [...polyCoords].reverse() : [...polyCoords]
    const tolerance = 2

    let result = collectCoords(
      [],
      coords,
      getMostLeft(coords),
      getMostRight(coords)
    )
    result = simplify(result, tolerance)
    return result
  }

  const getMetadataBlueprint = metadata => {
    //attributes
    //log(relations)
    let attributes = getAttributesBlueprint(metadata?.attributes)

    //elements
    let elements = {
      properties: [],
    }
    metadata?.elements?.forEach(e => {
      switch (e.name) {
        case 'Property':
          elements.properties.push(getPropertyBlueprint(e))
          break
      }
    })
    return { attributes: attributes, elements: elements }
  }

  const getPropertyBlueprint = property => {
    let attributes = getAttributesBlueprint(property.attributes)
    return { attributes: attributes, elements: [] }
  }

  const getRelationsBlueprint = relations => {
    //attributes
    //log(relations)
    let attributes = getAttributesBlueprint(relations.attributes)

    //elements
    let elements = {
      relations: [],
    }
    relations.elements.map(e => {
      switch (e.name) {
        case 'Relation':
          elements.relations.push(getRelationBlueprint(e))
          break
      }
    })
    return { attributes: attributes, elements: elements }
  }

  const getRelationBlueprint = relation => {
    //attributes
    let attributes = getAttributesBlueprint(relation.attributes)

    //elements
    let elements = {
      ids: [],
    }
    relation.elements.map(e => {
      switch (e.name) {
        case 'RegionRef':
          elements.ids.push(getIdFromRegionRef(e))
          break
      }
    })
    return { attributes: attributes, elements: elements }
  }

  const getIdFromRegionRef = regionRef => {
    return regionRef.attributes?.regionRef
  }

  const getRegionBlueprint = textRegion => {
    //attributes
    let attributes = getAttributesBlueprint(textRegion.attributes)

    //elements
    let elements = {
      coords: [],
      textLines: [],
      text: '',
    }
    textRegion.elements.map(e => {
      switch (e.name) {
        case 'Coords':
          elements.coords = getCoordsBlueprint(e)
          break
        case 'TextLine':
          elements.textLines.push(getTextLineBlueprint(e))
          break
        case 'TextEquiv':
          elements.text = getTextEquivBlueprint(e)

          break
      }
    })
    return { attributes: attributes, elements: elements }
  }

  const getTableBlueprint = tableRegion => {
    //attributes
    let attributes = getAttributesBlueprint(tableRegion.attributes)

    //elements
    let elements = {
      coords: [],
      cells: [],
      text: '',
    }
    tableRegion.elements.map(e => {
      switch (e.name) {
        case 'Coords':
          elements.coords = getCoordsBlueprint(e)
          break
        case 'TableCell':
          elements.cells.push(getCellBlueprint(e))
          break
        case 'TextEquiv':
          elements.text = getTextEquivBlueprint(e)
          break
      }
    })
    return { attributes: attributes, elements: elements }
  }

  const getCellBlueprint = cell => {
    //attributes
    let attributes = getAttributesBlueprint(cell.attributes)

    //elements
    let elements = {
      coords: [],
      textLines: [],
      cornerPoints: [],
    }
    cell.elements.map(e => {
      switch (e.name) {
        case 'Coords':
          elements.coords = getCoordsBlueprint(e)
          break
        case 'TextLine':
          elements.textLines.push(getTextLineBlueprint(e))
          break
        case 'CornerPts':
          elements.cornerPoints = getCornerPtsBlueprint(e)
          break
      }
    })
    return { attributes: attributes, elements: elements }
  }

  const getCornerPtsBlueprint = points => {
    return points.elements[0].text.split(' ').map(string => parseInt(string))
  }

  const getTextLineBlueprint = textLine => {
    //attributes
    let attributes = getAttributesBlueprint(textLine.attributes)

    //elements
    let elements = {
      polyCoords: [],
      baselineCoords: [],
      words: [],
      text: '',
    }
    textLine.elements.map(e => {
      switch (e.name) {
        case 'Coords':
          elements.polyCoords = getCoordsBlueprint(e)
          break
        case 'Baseline':
          elements.baselineCoords = getBaselineBlueprint(e)
          break
        case 'Word':
          elements.words.push(getWordBlueprint(e))
          break
        case 'TextEquiv':
          elements.text = getTextEquivBlueprint(e)
          break
      }
    })
    if (elements.baselineCoords.length === 0) {
      elements.baselineCoords = estimateBaselineCoords(elements.polyCoords)
    }
    return { attributes: attributes, elements: elements }
  }

  const getWordBlueprint = word => {
    //attributes
    let attributes = getAttributesBlueprint(word.attributes)

    //elements
    let elements = {
      coords: [],
      text: '',
    }
    word.elements.map(e => {
      switch (e.name) {
        case 'Coords':
          elements.coords = getCoordsBlueprint(e)
          break
        case 'TextEquiv':
          elements.text = getTextEquivBlueprint(e)
          break
      }
    })
    return { attributes: attributes, elements: elements }
  }

  const getAttributesBlueprint = attributes => {
    return {
      ...attributes,
      ...(attributes?.custom ? { custom: parseCustom(attributes.custom) } : {}),
    }
  }

  const getCoordsBlueprint = coords => {
    return scalePointList(
      getPointListfromString(coords.attributes.points),
      scaleFactor
    )
  }

  const getBaselineBlueprint = baseline => {
    return scalePointList(
      getPointListfromString(baseline.attributes.points),
      scaleFactor
    )
  }

  const getTextEquivBlueprint = textEquiv => {
    if (
      textEquiv.elements.length > 0 &&
      textEquiv.elements[0].elements.length > 0
    ) {
      /* const escapeSymbols = unsafe => {
        return unsafe.replace(/</g, '&lt;').replace(/>/g, '&gt;')
      } */
      return textEquiv.elements[0].elements[0].text
    }
    return ''
  }
  // log(globalJson)
  let rootElements = getPage(globalJson).elements

  const metaData = getMetadata(globalJson)
  let metadataBlueprint = getMetadataBlueprint(metaData)
  //log('root')
  //log(rootElements)

  let regions = rootElements.filter(
    e => e.name === 'TextRegion' || e.name === 'TableRegion'
  )

  //log(regions)

  let relationsBlueprint
  const relations = rootElements.find(e => e.name === 'Relations')
  if (relations != null) {
    relationsBlueprint = getRelationsBlueprint(relations)
  }

  let unSortedRegions = regions.map(region => {
    switch (region.name) {
      case 'TextRegion':
        return getRegionBlueprint(region)
      case 'TableRegion':
        return getTableBlueprint(region)
    }
  })

  //log(unSortedRegions)

  let regionBlueprints = sortByReadingOrder(unSortedRegions)
  return { regionBlueprints, relationsBlueprint, metadataBlueprint }
}

const blueprintToJson = (
  globalJson,
  regionBlueprints,
  relationsBlueprint,
  metadataBlueprint,
  scaleFactor,
  rotationAngle
) => {
  let globalJsonCopy = JSON.parse(JSON.stringify(globalJson))
  let otherRegions = getPage(globalJsonCopy).elements.filter(
    region =>
      region.name != 'TextRegion' &&
      region.name != 'TableRegion' &&
      region.name != 'Relations' &&
      region.name != 'ReadingOrder'
  )

  const getRelationsJson = blueprint => {
    //attributes
    let attributes = getAttributesJson(blueprint.attributes)

    //elements
    let elements = []
    blueprint.elements.relations.map(relation => {
      elements.push(getRelationJson(relation))
    })
    return {
      attributes: attributes,
      elements: elements,
      name: 'Relations',
      type: 'element',
    }
  }

  const getRelationJson = blueprint => {
    //attributes
    let attributes = getAttributesJson(blueprint.attributes)

    //elements
    let elements = []
    blueprint.elements.ids.map(id => {
      elements.push(getRegionRefFromId(id))
    })
    return {
      attributes: attributes,
      elements: elements,
      name: 'Relation',
      type: 'element',
    }
  }

  const getMetadataJson = blueprint => {
    //attributes
    let attributes = getAttributesJson(blueprint.attributes)

    //elements
    let elements = []
    blueprint.elements.properties.map(p => {
      elements.push(getPropertyJson(p))
    })
    return {
      attributes: attributes,
      elements: elements,
      name: 'TranskribusMetadata',
      type: 'element',
    }
  }

  const getPropertyJson = blueprint => {
    return {
      attributes: blueprint.attributes,
      elements: [],
      name: 'Property',
      type: 'element',
    }
  }

  const getRegionRefFromId = id => {
    return {
      attributes: { regionRef: id },
      elements: [],
      name: 'RegionRef',
      type: 'element',
    }
  }

  const getRegionJson = blueprint => {
    //attributes
    let attributes = getAttributesJson(blueprint.attributes)

    //elements
    let elements = []
    elements.push(getCoordsJson(blueprint.elements.coords))
    blueprint.elements.textLines.map(line => {
      elements.push(getTextLineJson(line))
    })
    elements.push(getTextEquivJson(blueprint.elements.text))

    return {
      attributes: attributes,
      elements: elements,
      name: 'TextRegion',
      type: 'element',
    }
  }

  const getTableJson = blueprint => {
    //attributes
    let attributes = getAttributesJson(blueprint.attributes)

    //elements
    let elements = []
    elements.push(getCoordsJson(blueprint.elements.coords))
    blueprint.elements.cells.map(cell => {
      elements.push(getCellJson(cell))
    })
    elements.push(getTextEquivJson(blueprint.elements.text))
    return {
      attributes: attributes,
      elements: elements,
      name: 'TableRegion',
      type: 'element',
    }
  }

  const getCellJson = blueprint => {
    //attributes
    let attributes = getAttributesJson(blueprint.attributes)

    //elements
    let elements = []
    elements.push(getCoordsJson(blueprint.elements.coords))
    blueprint.elements.textLines.map(line => {
      elements.push(getTextLineJson(line))
    })
    elements.push(getCornerPtsJson(blueprint.elements.cornerPoints))
    return {
      attributes: attributes,
      elements: elements,
      name: 'TableCell',
      type: 'element',
    }
  }

  const getTextLineJson = blueprint => {
    //attributes
    let attributes = getAttributesJson(blueprint.attributes)

    //elements
    let elements = []
    elements.push(getCoordsJson(blueprint.elements.polyCoords))
    elements.push(getBaselineJson(blueprint.elements.baselineCoords))
    blueprint.elements.words.map(word => {
      elements.push(getWordJson(word))
    })
    elements.push(getTextEquivJson(blueprint.elements.text))

    //removes invalid Baseline elements
    // elements = elements.filter((e) => {
    //   const isBaseline = e.name === 'Baseline'
    //   const pointsInvalid =
    //     e.points === null || e.points === undefined || e.points.length === 0
    //   if (isBaseline && pointsInvalid) return false
    //   return true
    // })

    return {
      attributes: attributes,
      elements: elements,
      name: 'TextLine',
      type: 'element',
    }
  }

  const getWordJson = blueprint => {
    //attributes
    let attributes = getAttributesJson(blueprint.attributes)

    //elements
    let elements = []
    elements.push(getCoordsJson(blueprint.elements.coords))
    elements.push(getTextEquivJson(blueprint.elements.text))

    return {
      attributes: attributes,
      elements: elements,
      name: 'Word',
      type: 'element',
    }
  }

  const getAttributesJson = blueprintAttributes => {
    //log(blueprintAttributes)
    const attributes = { ...blueprintAttributes }
    if ('custom' in blueprintAttributes) {
      attributes.custom = stringifyCustom(blueprintAttributes.custom)
    }
    return attributes
  }

  const getCoordsJson = coords => {
    return {
      attributes: {
        points: getStringfromPointList(
          unScalePointList(coords, scaleFactor, rotationAngle)
        ),
      },
      elements: [],
      name: 'Coords',
      type: 'element',
    }
  }

  const getBaselineJson = coords => {
    return {
      attributes: {
        points: getStringfromPointList(
          unScalePointList(coords, scaleFactor, rotationAngle)
        ),
      },
      elements: [],
      name: 'Baseline',
      type: 'element',
    }
  }

  const getCornerPtsJson = points => {
    return {
      elements: [
        {
          type: 'text',
          text: points.join(' '),
        },
      ],
      name: 'CornerPts',
      type: 'element',
    }
  }

  const getTextEquivJson = text => {
    return {
      elements: [
        {
          elements: text.length <= 0 ? [] : [{ type: 'text', text: text }],
          name: 'Unicode',
          type: 'element',
        },
      ],
      name: 'TextEquiv',
      type: 'element',
    }
  }

  let regionJsons = regionBlueprints.map(b =>
    b.type === 'text' ? getRegionJson(b) : getTableJson(b)
  )
  let relationsJson = getRelationsJson(relationsBlueprint)

  let allRegions = [...otherRegions, relationsJson, ...regionJsons]
  getPage(globalJsonCopy).elements = allRegions
  const metadataJson = getMetadataJson(metadataBlueprint)

  setMetadata(globalJsonCopy, metadataJson)
  return globalJsonCopy
}

const getPage = globalJson => {
  const pcgts = globalJson.elements.find(e => e.name === 'PcGts')
  if (pcgts == null) {
    warn('Error: not PcGts tag in pageXml')
    return
  }
  const page = pcgts.elements.find(e => e.name === 'Page')
  if (page == null) {
    warn('Error: not Page tag in pageXml')
    return
  }
  return page
}

const getMetadata = globalJson => {
  const pcgts = globalJson.elements.find(e => e.name === 'PcGts')
  if (pcgts == null) {
    warn('Error: not PcGts tag in pageXml')
    return
  }
  const metadata = pcgts.elements.find(e => e.name === 'Metadata')
  if (metadata == null) {
    warn('Error: no Metadata tag in pageXml')
    return
  }
  const transkribusMetadata = metadata.elements.find(
    e => e.name === 'TranskribusMetadata'
  )
  if (transkribusMetadata == null) {
    warn('Error: no TranskribusMetadata tag in pageXml')
    return
  }
  return transkribusMetadata
}

const setMetadata = (globalJson, transkribusMetadataJson) => {
  const pcgts = globalJson.elements.find(e => e.name === 'PcGts')
  if (pcgts == null) {
    warn('Error: not PcGts tag in pageXml')
    return
  }
  const metadata = pcgts.elements.find(e => e.name === 'Metadata')
  if (metadata == null) {
    warn('Error: no Metadata tag in pageXml')
    return
  }
  metadata.elements = metadata.elements.filter(
    e => e.name !== 'TranskribusMetadata'
  )
  metadata.elements.push(transkribusMetadataJson)
}

const scalePointList = (points, scaleFactor) => {
  const rounded = x => Math.round(x * 1000000) / 1000000

  let scaledPoints = points.map(point => {
    let pRound = [
      rounded(point.x * scaleFactor),
      rounded(point.y * scaleFactor),
    ]

    return {
      x: pRound[0],
      y: pRound[1],
    }
  })
  return scaledPoints
}

const unScalePointList = (points, scaleFactor, rotationAngle) => {
  const rounded = x => Math.round(x)
  const rotate = p => pointRotate(p, 360 - rotationAngle)

  let unScaledPoints = points.map(point => {
    let newPoint = point

    if (rotationAngle != null) {
      newPoint = rotate([newPoint.x, newPoint.y])
    }

    newPoint = [
      rounded(newPoint[0] / scaleFactor),
      rounded(newPoint[1] / scaleFactor),
    ]

    return {
      x: newPoint[0],
      y: newPoint[1],
    }
  })
  return unScaledPoints
}

const getPointListfromString = string => {
  var coords = string.split(' ')
  let result = coords.map(c => {
    let list = c.split(',')
    return { x: list[0], y: list[1] }
  })
  return result
}

const getStringfromPointList = list => {
  let result = list.map(c => `${c.x},${c.y}`).join(' ')
  return result
}

const toImageAction = (action, scaleFactor, rotationAngle) => {
  const round = x => Math.round(x)
  const scaleFn = value => round(value * (1 / scaleFactor))
  const rotationFn = point =>
    pointRotate([point.x, point.y], 360 - rotationAngle)
      .map(c => round(c))
      .reduce((x, y) => ({
        x,
        y,
      }))

  return scaleAction(action, scaleFn, rotationFn)
}

const toFabricAction = (action, scaleFactor) => {
  const round = x => Math.round(x * 1000000) / 1000000
  const scaleFn = value => round(value * scaleFactor)
  return scaleAction(action, scaleFn)
}

const scalePoint = (point, scaleFn, rotationFn) => {
  return rotationFn({ x: scaleFn(point.x), y: scaleFn(point.y) })
}

const scaleAction = (initAction, scaleFn, rotationFn) => {
  if (initAction.type === ACTION_TYPE.MULTI_ACTION) {
    return {
      ...initAction,
      actions: initAction.actions.map(a => scaleAction(a, scaleFn, rotationFn)),
    }
  }
  const action = cloneObj(initAction)
  if ('blueprint' in action) {
    action.blueprint = scaleBlueprint(action.blueprint, scaleFn, rotationFn)
  }
  if ('new_cells_blueprints' in action) {
    action.new_cells_blueprints = action.new_cells_blueprints.map(b =>
      scaleBlueprint(b, scaleFn, rotationFn)
    )
  }
  if ('old_cells_blueprints' in action) {
    action.old_cells_blueprints = action.old_cells_blueprints.map(b =>
      scaleBlueprint(b, scaleFn, rotationFn)
    )
  }
  if ('vector' in action) {
    action.vector = scalePoint(action.vector, scaleFn, rotationFn)
  }
  if ('vectors' in action) {
    action.vectors = action.vectors.map(v => scalePoint(v, scaleFn, rotationFn))
  }
  if ('point_coord' in action) {
    action.point_coord = scalePoint(action.point_coord, scaleFn, rotationFn)
  }
  if ('old_coords' in action) {
    action.old_coords = action.old_coords.map(v =>
      scalePoint(v, scaleFn, rotationFn)
    )
  }
  if ('new_coords' in action) {
    action.new_coords = action.new_coords.map(v =>
      scalePoint(v, scaleFn, rotationFn)
    )
  }
  return action
}

const scaleBlueprint = (initBlueprint, scaleFn, rotationFn) => {
  const blueprint = cloneObj(initBlueprint)
  if ('baselineCoords' in blueprint.elements) {
    blueprint.elements.baselineCoords = blueprint.elements.baselineCoords.map(
      p => scalePoint(p, scaleFn, rotationFn)
    )
  }
  if ('polyCoords' in blueprint.elements) {
    blueprint.elements.polyCoords = blueprint.elements.polyCoords.map(p =>
      scalePoint(p, scaleFn, rotationFn)
    )
  }
  if ('coords' in blueprint.elements) {
    blueprint.elements.coords = blueprint.elements.coords.map(p =>
      scalePoint(p, scaleFn, rotationFn)
    )
  }
  if ('cells' in blueprint.elements) {
    blueprint.elements.cells = blueprint.elements.cells.map(c =>
      scaleBlueprint(c, scaleFn, rotationFn)
    )
  }
  if ('textLines' in blueprint.elements) {
    blueprint.elements.textLines = blueprint.elements.textLines.map(l =>
      scaleBlueprint(l, scaleFn, rotationFn)
    )
  }

  return blueprint
}

export {
  toFabricAction,
  toImageAction,
  jsonToBlueprint,
  blueprintToJson,
  getUnSupportedElements,
}
