export const DEFAULT_SETTINGS = {
  showColoredBackground: true,
  showStructureTypes: true,
  showStructureColors: false,
  showReadingOrder: true,
  showRelations: false,
  showRegions: true,
  showBaselines: true,
  showLinePolygons: false,
  showWords: false,
  structureTypeSize: 4,
  splitLineAngle: 0,
  treeShowTextOnly: true,
  treeExpand: false,
  treeKeepOpen: true,
  circleSize: 4,
  lineWidth: 4,
  textAlignment: 'left',
  tagsDisplayArray: ['abbrev', 'date', 'person'],
  renderSpeed: 1,
  tagging: false,
  editLayoutHorizontal: false,
  fontSize: 1,
  fontFamily: 'Noto Sans',
  showLineRegion: true,
  centerText: false,
  autocomplete: false,
  autocorrect: false,
  autocapitalize: false,
  spellcheck: false,
  isKeyboard: false,
  autoCenterLineZoom: false,
  autoCenterLineNoZoom: false,
  showAttributes: false,
  textEditorSize: 50,
  keyboardSize: 50,
  baselineColorHighlight: true,
  highlightHeight: 4,
  highlightColor: '#ff8800',
  baselineColor: '#0000ff',
  linePolygonColor: '#00fff6',
  regionColor: '#068457',
  tableColor: '#FF9900',
  wordColor: '#ff0000',
  dragging: false,
  lastViewportCenter: null,
  lastViewportZoom: null,
  lastViewportDocId: null,
  settingsSize: 35,
  commentsSize: 30,
  versionSize: 25,
  defaultStructureTag: null,
  doubleClickTagMenu: false,
  lastSettingsPage: null,
  showPageNavigator: false,
  assignStructureLevels: false,
  danWriting: false,
}
