<template>
  <div
    class="w-[98%] flex flex-col justify-center items-center rounded mt-2"
    :class="{
      'selected-region': selected,
      draggable,
    }"
    :id="id"
    @mousedown="mouseDown(id)"
    :style="{ 'background-color': selected ? getBackgroundColor() : '' }"
  >
    <RegionHeader
      @mouseUp="$emit('mouseUpWrapper', id)"
      v-if="!isSites"
      :draggable="draggable"
      :index="index"
    />

    <span v-else class="h-[10px] w-full"></span>

    <table style="min-width: 100%; overflow: scroll" class="bg-white">
      <tr v-for="(row, rowIndex) in sortedRows" :key="rowIndex">
        <td
          v-for="(cell, colIndex) in row"
          :key="colIndex"
          :rowspan="cell?.position.rowSpan"
          :colspan="cell?.position.colSpan"
          :style="{
            'background-color': getCellBackgroundColor(
              cell?.position.row,
              cell?.position.col,
              cell?.id
            ),
          }"
          :id="cell?.id"
          @mouseup="mouseUpCell(cell?.id, $event)"
        >
          <slot :lines="cell?.children" :id="cell?.id"></slot>
          <BaseButton
            v-if="showNewLineButton() && cell?.children.length === 0"
            label="Add text"
            @click="$emit('addNewLineToEmptyParent', cell?.id)"
          />
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import TextLine from './TextLine.vue'
import RegionHeader from './RegionHeader.vue'

import {
  Singleton as ActionHandler,
  ACTION_TYPE,
  SELECTION_TYPE,
} from '../LayoutEditor/components/singletons/actionHandler.js'
import { Singleton as MyFabric } from '../LayoutEditor/components/myFabric.js'
import { Singleton as Settings } from '../LayoutEditor/components/singletons/settings.js'

export default {
  components: {
    TextLine,
    RegionHeader,
  },
  props: {
    editorName: {
      type: String,
    },
    draggable: {
      type: Boolean,
    },
    id: {
      type: String,
    },
    selected: {
      type: Boolean,
    },
    selectedIds: {
      type: Array,
    },
    index: {
      type: Number,
    },
    isSites: {
      type: Boolean,
    },
    children: {
      type: Array,
    },
  },
  data() {
    return {}
  },

  computed: {
    sortedRows() {
      let rows = this.children.reduce((acc, c) => {
        if (!acc.find(row => row.index === c.position.row)) {
          acc.push({ index: c.position.row, cells: [] })
        }
        const foundRow = acc.find(row => {
          return row.index === c.position.row
        })
        foundRow.cells.push(c)
        return acc
      }, [])
      rows.forEach(row => {
        row.cells = row.cells.toSorted(
          (a, b) => a.position.col - b.position.col
        )
      })
      rows = rows.toSorted((a, b) => a.index - b.index)
      return rows.map(c => c.cells)
    },
  },
  methods: {
    showNewLineButton() {
      return Settings.instance().get('danWriting')
    },

    mouseUpCell(id, event) {
      if (event.target.id === id) {
        ActionHandler.instance(this.editorName).select({
          type: SELECTION_TYPE.CLEAR,
        })
        ActionHandler.instance(this.editorName).select({
          type: SELECTION_TYPE.ADD,
          ids: [id],
        })
      }
    },

    getBackgroundColor() {
      return MyFabric.instance().getTableColorPrimary(0.1)
    },
    getCellBackgroundColor(row, col, cellId) {
      if (this.selectedIds.includes(cellId)) {
        return MyFabric.instance().getTableColorSelected(0.2)
      }
      /* if ((row + col) % 2 === 0 && this.selectedIds.includes(this.id)) {
        return MyFabric.instance().getTableColorPrimary(0.1)
      }
      if (this.selectedIds.includes(this.id)) {
        return MyFabric.instance().getTableColorSecondary(0.1)
      } */
    },
    mouseDown(id) {
      this.$emit('mouseDown', id)
    },
  },
}
</script>

<style>
input,
textarea,
[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}

[contenteditable]:focus {
  outline: 0px solid transparent;
}

.arrow {
  position: absolute;
  top: -20px !important;
  left: 12px;
  width: 28px;
  height: 12px;
  z-index: 2;
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  top: 5px;
  left: 5px;
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-backface-visibility: hidden;
  background-color: #fff;
  -moz-box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.2);
}

.tools {
  height: 30px;
  padding: 5px 10px;
  background: #333;
  border-radius: 3px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  transition: 0.2s all;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tools:after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -5px;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #333;
}
.line-number-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 5px; /* Adjust this value if needed */
  min-width: 30px; /* Adjust this value based on how many digits the line number has */
}
</style>

<style lang="scss" scoped>
.selected-region {
  outline: 0px solid transparent;
}

table {
  border-collapse: collapse;
  table-layout: fixed;
  // width: 100%;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  td,
  th {
    min-width: 1em;
    border: 2px solid #ced4da;
    border-radius: 4px;
    padding: 3px 5px;
    vertical-align: top;
    box-sizing: border-box;
    position: relative;

    > * {
      margin-bottom: 0;
    }
  }

  th {
    font-weight: bold;
    text-align: left;
    background-color: #f1f3f5;
  }
}
.draggable {
  @apply bg-[#F3F3F3] m-[1px] cursor-pointer p-2;
}
</style>
