<template>
  <div class="app-modal__box">
    <div class="app-modal__header">
      <h3 class="app-title">
        {{ $t('Report this page') }}
      </h3>
    </div>
    <div class="app-modal__body">
      <div>
        <label
          class="block text-gray-700 text-sm font-bold mb-2"
          for="pageToReport"
        >
          {{ $t('Page to report') }}
        </label>
        <input
          type="text"
          ref="clone"
          v-model="shareUrl"
          class="w-full py-2 pl-5 pr-4 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          :disabled="true"
        />
      </div>
      <BaseInput
        :label="$t('Your email')"
        :value="email"
        @input="email = $event.target.value"
      ></BaseInput>
      <BaseInput
        :label="$t('Message')"
        :value="message"
        @input="message = $event.target.value"
        :type="'textarea'"
      ></BaseInput>
    </div>
    <div class="app-modal__footer">
      <div class="flex justify-between" style="width: 100%">
        <BaseButton @click="closeModal()" :label="'Close'"></BaseButton>
        <BaseButton
          @click="reportPage"
          :icon="'alert-octagon'"
          :label="$t('Report')"
          :type="'danger'"
        ></BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import Confirmation from '~/components/Modals/Confirmation.vue'

export default {
  data() {
    return {
      email: '',
      message: '',
    }
  },
  props: {
    link: String,
  },
  computed: {
    shareUrl() {
      if (process.client) {
        return window.location.href
      }
    },
  },
  watch: {},

  methods: {
    copyToClipboard() {
      this.$refs.clone.focus()
      navigator.clipboard.writeText(this.link)
      this.$bus.emit('notification', {
        message: this.$t('Copied to clipboard'),
        type: 'success',
      })
    },
    removeLink() {
      this.$emit('removeLink')
    },
    reportPage() {
      const modal = {
        modalContent: {
          component: Confirmation,
          props: {
            title: {
              text: this.$t('Do you want to report this page?'),
            },
            button: {
              label: this.$t('Report'),
              icon: 'alert-octagon',
            },
            type: 'danger',
          },
          events: {
            confirm: () => {
              this.sendMail()
            },
          },
        },
      }
      this.$bus.emit('open-modal', { modal: modal })
    },
    closeModal() {
      this.$bus.emit('close-modal')
    },
    async sendMail() {
      let to = 'report@readcoop.eu'
      let subject = 'Page report ' + encodeURIComponent(this.shareUrl)
      let body =
        'Report for page : ' +
        encodeURIComponent(this.shareUrl) +
        ' - Reported mail : ' +
        encodeURIComponent(this.email) +
        ' - Additional message: ' +
        encodeURIComponent(this.message)

      let mailtoLink = `mailto:${to}?subject=${subject}&body=${body}`

      // To open the mail client
      window.location.href = mailtoLink

      this.$emit('closeModal')
    },
  },
}
</script>
