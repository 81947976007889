import Editor from '../editor'

var Singleton = (function () {
  var instance

  function createInstance() {
    var object = new StateHandler()
    return object
  }

  return {
    instance: function () {
      if (!instance) {
        instance = createInstance()
      }
      return instance
    },
  }
})()

function StateHandler() {
  this.propertyWrappers = []
}

StateHandler.prototype.reset = function (state) {
  const wrapper = this.getWrapper(state)
  const defaultProperties = this.createWrapper(state).properties
  Object.entries(defaultProperties).map(([key, value]) => {
    wrapper.properties[key] = value
  })
}

StateHandler.prototype.get = function (state) {
  return this.getWrapper(state).properties
}

StateHandler.prototype.getWrapper = function (state) {
  const found = this.propertyWrappers.find(w => w.state === state)
  if (found) {
    return found
  }
  const newWrapper = this.createWrapper(state)
  this.propertyWrappers.push(newWrapper)
  return newWrapper
}

StateHandler.prototype.set = function (state, properties) {
  const defaultProperties = this.createWrapper(state).properties
  if (
    Object.keys(properties).some(
      k => !Object.keys(defaultProperties).includes(k)
    )
  ) {
    console.warn('Not allowed to assign given properties to state')
    return
  }
  const wrapper = this.getWrapper(state).properties
  Object.entries(properties).map(([key, value]) => {
    wrapper[key] = value
  })
}

StateHandler.prototype.createWrapper = function (state) {
  switch (state) {
    case Editor.STATE.ADD_RELATION:
      return {
        state,
        properties: {
          draftArrows: [],
          activeArrow: null,
          activeInit: [],
          ids: [],
          name: null,
          type: null,
          markedShape: null,
        },
      }
    case Editor.STATE.EDIT_RELATION:
      return {
        state,
        properties: {
          arrow: null,
          initPosition: null,
          hasMoved: false,
          markedShape: null,
        },
      }
  }
}

export { Singleton }
