import Baseline from './shapes/Baseline.js'
import Region from './shapes/Region.js'
import Shape from './shapes/Shape.js'
import Table from './shapes/Table.js'
import { isEmpty } from 'lodash'

import {
  Singleton as ActionHandler,
  ACTION_TYPE,
  SELECTION_TYPE,
} from './singletons/actionHandler.js'
import { Singleton as IdHandler } from './singletons/IdHandler.js'
import { Singleton as MyFabric } from './myFabric.js'
import { Singleton as ModalHandler } from './singletons/ModalHandler.js'
import { Singleton as Settings } from './singletons/settings.js'
import { Singleton as CollectionConfig } from './singletons/collectionConfig.js'
import { getScaleFactor, getRotationAngle } from '../globals.js'
import StateFreeFormSplit from './states/StateFreeFormSplit.ts'
import { Singleton as StatePropertiesHandler } from './singletons/StatePropertiesHandler.js'

import { xml2js, js2xml } from 'xml-js'
import { ctxmenu } from 'ctxmenu'
import {
  pointInPolygon,
  polygonHull,
  lineIntersectsLine,
  lineMidpoint,
  polygonCentroid,
  polygonIntersectsPolygon,
  polygonInPolygon,
  polygonBounds,
  polygonMean,
  lineLength,
  pointRotate,
} from 'geometric'
import {
  jsonToBlueprint,
  blueprintToJson,
  getUnSupportedElements,
} from './jsonParser.js'
import {
  getRectCoords,
  getClosestLine,
  getSplitCoords,
  getSplitCoordsRegion,
  whatSideOfLine,
  getMergePartners,
  array_move,
  createSplitline,
  isLineInConvexRegion,
  closedPoint,
  pointRelativeLocation,
  aFollowedByB,
} from './geometryFunctions.js'
import Cell from './shapes/Cell.js'
import Word from './shapes/Word.js'
import Relation from './shapes/Relation.js'
import { createAction } from './actions.js'
import { isEqual } from 'lodash'
import Split from '../../Actions/Split.ts'
import model from '../../Model/model.js'
import Merge from '../../Actions/Merge.ts'
import Move from '../../Actions/Move.ts'

Editor.BUTTON = Object.freeze({
  POINTER: 1,
  ADD_BASELINES: 2,
  ADD_REGIONS: 3,
  ADD_TABLES: 4,
  UNDO: 5,
  REDO: 6,
  NONE: 7,
  ADD_RELATION: 8,
  DRAG: 9,
})

Editor.STATE = Object.freeze({
  REMOVE_SHAPE: 1,
  EDIT: 2,
  ADD_BASELINES: 3,
  ADD_REGIONS: 4,
  ADD_TABLES: 5,
  VERTICAL_SPLIT: 6,
  HORIZONTAL_SPLIT: 7,
  CUSTOM_SPLIT: 8,
  ASSIGN_NEW_PARENT: 9,
  GROUP_SELECTION: 10,
  MULTI_SELECT: 11,
  DUPLICATE: 12,
  CREATE_RELATION: 13,
  ADD_RELATION: 14,
  EDIT_RELATION: 15,
  DRAG: 16,
  SELECTION_ONLY: 17,
  SITES_OR_SEARCH_RESULTS: 18,
  FREE_FORM_SPLIT: 19,
})

Editor.CURSOR = Object.freeze({
  SELECTION: 1,
  TEXTLINE: 2,
  TEXTREGION: 3,
  TABLE: 4,
  DRAG: 5,
})

Editor.SHAPE_TYPE = Object.freeze({
  REGION: 'region',
  TABLE: 'table',
  BASELINE: 'baseline',
  CELL: 'cell',
  WORD: 'word',
})

// Editor.RELATION_TYPE = Object.freeze({
//   RELATED_TO: 1,
//   SAME_AS: 2,
// })

function Editor(canvas, blueprints, vueFunctions, editorName) {
  this.splitLineFactor = 1

  this.vueFunctions = vueFunctions
  this.scaleFactor = 1
  this.blueprints = blueprints
  this.rotationAngle = 0
  this.metadata = null
  this.prevBaselineElements = []
  this.prevBaselineLine = null

  this.throttleCounter = 0
  this.moveCounter = 0
  this.missingTypesChecked = false
  this.editorName = editorName
  this.searchRects = []

  this.canvas = canvas

  this.stateObject = null

  this.baselines = function () {
    return this.regions.reduce(
      (baselines, shape) => [...baselines, ...shape.getBaselines()],
      []
    )
  }

  //use allShapes instead
  this.shapes = function () {
    let list = []
    this.regions.map(r => {
      list = list.concat(r).concat(r.baselines)
    })
    return list
  }

  this.regions = []

  const relations = []

  relations.filterByShapeId = shapeId => {
    return relations.filter(r => r.getIds().some(id => id === shapeId))
  }

  this.relations = relations
  this.relationArrow = null
  this.allRelationArrows = []
  this.allRelationNodes = []
  this.selectedNode = null

  this.activeShapeIds = []

  this.draft = null
  this.readingOrder = {}
  this.mouseEventHandlers = {}
  this.containerEventHandlers = {}
  this.keyEventHandlers = {}
  this.deletedShapes = []

  this.state = null
  this.shifted = false
  this.controlPressed = false
  this.rPressed = false
  this.lastHovered = null
  this.lastMousePosition = { x: 0, y: 0 }
  this.canvasDiagonal = Math.sqrt(
    Math.pow(this.vueFunctions.getCanvasHeight(), 2) +
      Math.pow(this.canvas.width, 2)
  )
  this.prevLine = null
  this.markedParent = null

  this.settingsHandler = settingsHandler.bind(this)

  this.mouseEventHandlers.mouseDown = mouseDown.bind(this)
  this.mouseEventHandlers.dblClick = dblClick.bind(this)
  this.mouseEventHandlers.mouseMove = mouseMove.bind(this)
  this.mouseEventHandlers.mouseOut = mouseOut.bind(this)

  this.containerEventHandlers.mouseUp = containerMouseUp.bind(this)

  this.keyEventHandlers.keyDown = keyDown.bind(this)
  this.keyEventHandlers.keyUp = keyUp.bind(this)
  this.keyEventHandlers.paste = paste.bind(this)

  this.addCanvasListeners()

  //workaroud missing mouseUp
  document
    .getElementById(`${editorName}-osd`)
    .addEventListener('click', this.containerEventHandlers.mouseUp)

  this.addKeyListerns()

  this.showOnlySelectedLine()

  this.setState(Editor.STATE.EDIT)

  this.actionHandler = ActionHandler.instance(editorName)
  //this.actionHandler.setEditor(this)
  this.actionHandler.on('action', 'editor', action =>
    this.performAction(action)
  )
  this.actionHandler.registerAs('editor', this)
  this.actionHandler.on('selection', 'editor', selection =>
    this.performSelection(selection)
  )

  this.idHandler = IdHandler.instance(this.editorName)
  this.statePH = StatePropertiesHandler.instance()

  this.settings = Settings.instance()
  this.settings.on('update', this.settingsHandler)
  this.canvas.renderSpeed = this.settings.get('renderSpeed')

  this.structureTags = CollectionConfig.instance().getStructureTags()
  this.relationTags = CollectionConfig.instance().getRelationTags()

  CollectionConfig.instance().on('update', ({ structureTags }) => {
    this.structureTags = structureTags
    this.relationTags = CollectionConfig.instance().getRelationTags()
    //this.updateStructureTypeLabels()
  })

  this.$t = this.vueFunctions.$t

  this.myFabric = MyFabric.instance(this.editorName)
  this.myFabric.init(this.scaleFactor, this.vueFunctions.getTileSourceWidth())

  ModalHandler.instance().setOpenFn(this.vueFunctions.openModal)

  //this.canvas.add(this.myFabric.getCanvasRect(this.canvas))
}

Editor.prototype.activeShapes = function () {
  const activeShapes = this.activeShapeIds
    .map(id => this.getShape(id))
    .filter(Boolean)

  activeShapes.isEmpty = () => {
    return activeShapes.length === 0
  }
  activeShapes.isSingle = () => {
    return activeShapes.length === 1
  }
  activeShapes.isWord = () => {
    return activeShapes.isSingle() && activeShapes[0] instanceof Word
  }
  activeShapes.isBaseline = () => {
    return activeShapes.isSingle() && activeShapes[0] instanceof Baseline
  }
  activeShapes.isRegion = () => {
    return activeShapes.isTextRegion() || activeShapes.isTable()
  }
  activeShapes.isTextRegion = () => {
    return activeShapes.isSingle() && activeShapes[0] instanceof Region
  }
  activeShapes.isTable = () => {
    return activeShapes.isSingle() && activeShapes[0] instanceof Table
  }
  activeShapes.areMultiple = () => {
    return !activeShapes.isEmpty() && !activeShapes.isSingle()
  }
  activeShapes.haveState = state => {
    if (activeShapes.isEmpty()) return false
    return activeShapes.every(s => s.state === state)
  }
  activeShapes.areBaselines = () => {
    if (activeShapes.isEmpty()) return false
    return activeShapes.every(s => s instanceof Baseline)
  }
  activeShapes.areWords = () => {
    if (activeShapes.isEmpty()) return false
    return activeShapes.every(s => s instanceof Word)
  }
  activeShapes.areTextRegions = () => {
    if (activeShapes.isEmpty()) return false
    return activeShapes.every(s => s instanceof Region)
  }
  activeShapes.areRegionsOrLines = () => {
    if (activeShapes.isEmpty()) return false
    return activeShapes.every(s => s instanceof Region || s instanceof Baseline)
  }
  activeShapes.areTables = () => {
    if (activeShapes.isEmpty()) return false
    return activeShapes.every(s => s instanceof Table)
  }
  activeShapes.areRegions = () => {
    return activeShapes.areTextRegions() || activeShapes.areTables()
  }

  activeShapes.areTableAndCell = () => {
    const tables = activeShapes.filter(s => s instanceof Table)
    if (tables.length != 1) return false
    const cells = activeShapes.filter(s => s instanceof Cell)
    if (cells.length != 1) return false
    return true
  }
  activeShapes.areTableAndCells = () => {
    const tables = activeShapes.filter(s => s instanceof Table)
    if (tables.length != 1) return false
    const cells = activeShapes.filter(s => s instanceof Cell)
    if (cells.length < 1) return false
    return true
  }

  activeShapes.areSiblings = () => {
    if (activeShapes.isEmpty() || activeShapes.isSingle()) {
      return false
    }
    if (activeShapes.areBaselines()) {
      const first = activeShapes[0]
      const parentId = this.getBaselineParent(first.id).id
      return activeShapes.every(
        s => parentId === this.getBaselineParent(s.id).id
      )
    }
    if (activeShapes.areTextRegions()) {
      return true
    }
    return false
  }

  activeShapes.add = (...shapes) => {
    shapes.map(s => {
      if (activeShapes.includes(s) === false) {
        if (s) {
          s.select()
          activeShapes.push(s)
        }
      }
    })
    return activeShapes
  }
  activeShapes.remove = (...shapes) => {
    shapes.map(s => {
      if (activeShapes.includes(s)) {
        s.unSelect()
        activeShapes.splice(activeShapes.indexOf(s), 1)
      }
    })
    return activeShapes
  }
  activeShapes.removeAll = () => {
    activeShapes.map(s => {
      if (this.getShape(s.id)) {
        s.unSelect()
      }
    })
    activeShapes.length = 0
    return activeShapes
  }
  activeShapes.setState = state => {
    activeShapes.map(s => s.setState(state))
  }
  activeShapes.withoutCells = () => {
    return activeShapes.filter(s => !(s instanceof Cell))
  }
  activeShapes.withoutCellsAndWords = () => {
    return activeShapes.filter(s => !(s instanceof Cell || s instanceof Word))
  }
  activeShapes.withoutTables = () => {
    return activeShapes.filter(s => !(s instanceof Table))
  }
  activeShapes.getTable = () => {
    return activeShapes.find(s => s instanceof Table)
  }
  activeShapes.getCell = () => {
    return activeShapes.find(s => s instanceof Cell)
  }

  return activeShapes
}

Editor.prototype.removeAllShapes = function () {
  this.canvas.clear()
}

Editor.prototype.updateTextEditor = function () {
  this.vueFunctions.updateTextEditor()
}

Editor.prototype.getSelectedRegionGeometrics = function () {
  return this.activeShapes()
    .filter(s => s instanceof Region)
    .map(r => {
      return {
        id: r.id,
        regionCoords: r.getScaledCoords(),
      }
    })
}

Editor.prototype.getRegionGeometrics = function () {
  return this.regions.map(r => {
    return {
      id: r.id,
      regionCoords: r.getScaledCoords(),
    }
  })
}

Editor.prototype.getActiveSplitIds = function () {
  return Array.from(
    new Set([
      ...this.activeShapes()
        .filter(s => s instanceof Region)
        .map(r => [r.id, ...r.baselines.map(l => l.id)])
        .flat(),
      ...this.activeShapes()
        .filter(s => s instanceof Baseline)
        .map(l => l.id),
    ])
  )
}

Editor.prototype.getStateObject = function (state) {
  if (state === Editor.STATE.FREE_FORM_SPLIT) {
    return new StateFreeFormSplit(this.canvas, () => this.getActiveSplitIds())
  }
}

Editor.prototype.setState = function (newState) {
  const endState = state => this.state === state && state != newState
  const startState = state => this.state != state && state === newState

  if (newState !== this.state && this.stateObject != null) {
    this.stateObject.reset()
  }
  //never allow change of Selection only
  if (newState === Editor.STATE.SELECTION_ONLY) {
    this.allShapesAndCellsAndWords().forEach(s =>
      s.setState(Shape.STATE.SELECTION_ONLY)
    )
    this.removeKeyListeners()
  }
  if (newState === Editor.STATE.SITES_OR_SEARCH_RESULTS) {
    this.allShapesAndCellsAndWords().forEach(s =>
      s.setState(Shape.STATE.SEARCH_RESULTS)
    )
    this.removeKeyListeners()
  }

  if (this.state === Editor.STATE.SELECTION_ONLY) return
  if (this.state === Editor.STATE.SEARCH_RESULTS) return

  if (endState(Editor.STATE.ADD_RELATION)) {
    const { activeArrow, draftArrows, markedShape } = this.getSP(
      Editor.STATE.ADD_RELATION
    )
    markedShape?.unMark()
    this.canvas.remove(activeArrow, draftArrows)
    this.resetSP(Editor.STATE.ADD_RELATION)
  }
  if (endState(Editor.STATE.EDIT_RELATION)) {
    const { arrow, markedShape } = this.getSP(Editor.STATE.EDIT_RELATION)
    markedShape?.unMark()
    this.selectedNode?.unSelect()
    this.selectedNode = null
    this.canvas.remove(arrow)
    this.resetSP(Editor.STATE.EDIT_RELATION)
  }
  if (
    endState(Editor.STATE.ADD_BASELINES) ||
    endState(Editor.STATE.ADD_REGIONS) ||
    endState(Editor.STATE.ADD_TABLES)
  ) {
    if (this.draft) {
      this.draft?.removeFromCanvas?.()
      this.canvas.remove(...this.prevBaselineElements)
      this.canvas.remove(this.prevBaselineLine)
      this.draft = null
    }
    this.lastAddedRegionId = null
  }
  if (endState(Editor.STATE.CUSTOM_SPLIT)) {
    if (this.prevLine != null) {
      this.settings.set({ splitLineAngle: this.prevLine.angle }, 'noUpdate')
    }
  }
  if (
    endState(Editor.STATE.VERTICAL_SPLIT) ||
    endState(Editor.STATE.HORIZONTAL_SPLIT) ||
    endState(Editor.STATE.CUSTOM_SPLIT) ||
    endState(Editor.STATE.ASSIGN_NEW_PARENT)
  ) {
    this.canvas.remove(this.prevLine)
    this.prevLine = null
  }
  if (endState(Editor.STATE.GROUP_SELECTION)) {
    this.unGroupSelect()
  }
  if (endState(Editor.STATE.MULTI_SELECT)) {
    this.canvas.remove(this.selectionRect)
    this.selectionRect = null
  }
  if (endState(Editor.STATE.DUPLICATE)) {
    this.canvas.remove(this.prevDuplicate)
    this.prevDuplicate = null
  }

  if (startState(Editor.STATE.EDIT)) {
    this.canvas.defaultCursor = this.getCursorUrl(Editor.CURSOR.SELECTION)
    this.canvas.hoverCursor = this.getCursorUrl(Editor.CURSOR.SELECTION)
    if (this.lastHovered) {
      this.lastHovered.showNormal()
      this.lastHovered = null
    }
    this.newVertexState = false
  }
  if (startState(Editor.STATE.DRAG)) {
    this.canvas.defaultCursor = this.getCursorUrl(Editor.CURSOR.DRAG)
    this.canvas.hoverCursor = this.getCursorUrl(Editor.CURSOR.DRAG)
    if (this.lastHovered) {
      this.lastHovered.showNormal()
      this.lastHovered = null
    }
  }

  if (startState(Editor.STATE.ADD_RELATION)) {
    this.settings.set({ showRelations: true })
    this.updateRelationArrows()
    //this.showDocks()
    //this.dockPositions = this.getDockPositions()
  }

  let cursorUrl
  if (startState(Editor.STATE.ADD_BASELINES)) {
    cursorUrl = this.getCursorUrl(Editor.CURSOR.TEXTLINE)
    this.settings.set({ showBaselines: true })
  }
  if (startState(Editor.STATE.ADD_REGIONS)) {
    cursorUrl = this.getCursorUrl(Editor.CURSOR.TEXTREGION)
    this.settings.set({ showRegions: true })
  }
  if (startState(Editor.STATE.ADD_TABLES)) {
    cursorUrl = this.getCursorUrl(Editor.CURSOR.TABLE)
    this.settings.set({ showRegions: true })
  }
  if (
    startState(Editor.STATE.ADD_BASELINES) ||
    startState(Editor.STATE.ADD_REGIONS) ||
    startState(Editor.STATE.ADD_TABLES)
  ) {
    this.canvas.defaultCursor = cursorUrl
    this.canvas.hoverCursor = cursorUrl
  }
  let angle
  if (startState(Editor.STATE.VERTICAL_SPLIT)) {
    angle = 90
  }
  if (startState(Editor.STATE.HORIZONTAL_SPLIT)) {
    angle = 0
  }
  if (startState(Editor.STATE.CUSTOM_SPLIT)) {
    angle = this.settings.get('splitLineAngle')
  }
  if (
    startState(Editor.STATE.VERTICAL_SPLIT) ||
    startState(Editor.STATE.HORIZONTAL_SPLIT) ||
    startState(Editor.STATE.CUSTOM_SPLIT)
  ) {
    this.prevLine = this.myFabric.getSplitLine(
      this.canvasDiagonal,
      this.lastMousePosition,
      angle
    )
    this.canvas.add(this.prevLine)
  }
  if (startState(Editor.STATE.ASSIGN_NEW_PARENT)) {
    this.newAssignStartPos = [
      this.lastMousePosition.x,
      this.lastMousePosition.y,
    ]
    this.previewNewAssign(this.lastMousePosition)
  }
  if (startState(Editor.STATE.GROUP_SELECTION)) {
    this.groupSelect()
  }
  if (startState(Editor.STATE.DUPLICATE)) {
    this.previewDuplicate(this.lastMousePosition)
  }

  this.vueFunctions.setEditorState(newState)
  if (newState !== this.state) {
    this.stateObject = this.getStateObject(newState)
    if (this.stateObject) {
      this.stateObject.whenFinished(() => this.setState(Editor.STATE.EDIT))
    }
  }

  this.state = newState
}

Editor.prototype.performSelection = function (selection) {
  const oldShapeIds = [...this.activeShapeIds]
  switch (selection.type) {
    case SELECTION_TYPE.ADD:
      this.activeShapeIds.push(...selection.ids)
      break
    case SELECTION_TYPE.REMOVE:
      this.activeShapeIds = this.activeShapeIds.filter(
        id => !selection.ids.includes(id)
      )
      break
    case SELECTION_TYPE.CLEAR:
      this.activeShapeIds = []
      break
    case SELECTION_TYPE.CENTER: {
      const shape = this.getShape(selection.id)
      if (shape) {
        const polygon = shape.getCoordsForFocus()
        const midPoint = shape.getMidPointForFocus()
        if (midPoint) {
          this.vueFunctions.focusPolygon(polygon, midPoint, { ...selection })
        }
      }
    }
  }
  const newShapeIds = [...this.activeShapeIds]
  this.updateShapes([...oldShapeIds, ...newShapeIds])
}

Editor.prototype.performAction = function (action) {
  if (action == null) return
  switch (action.type) {
    case ACTION_TYPE.ADD_RELATION:
      this.addRelation(action.relation)
      this.updateRelationArrows()
      break
    case ACTION_TYPE.REMOVE_RELATION:
      this.removeRelation(action.relation)
      this.updateRelationArrows()
      break
    case ACTION_TYPE.UPDATE_RELATION_IDS:
      const relation = this.relations.find(r => r.id === action.relation_id)
      if (relation == null) {
        warn('relation could not be found')
        break
      }
      relation.setIds([...action.new_ids])
      this.updateRelationArrows()
      break
    /*  case ACTION_TYPE.REMOVE_SHAPE:
      this.removeShape(action.shape.id)
      this.updateReadingOrder()
      break */
    /*  case ACTION_TYPE.ADD_BASELINE:
      this.addBaselineNew(action)
      this.updateReadingOrder()
      break */
    /* case ACTION_TYPE.ADD_REGION:
      this.addRegionNew(action)
      this.updateReadingOrder()
      break */
    case ACTION_TYPE.ADD_TABLE:
      this.addTableNew(action)
      this.updateReadingOrder()
      break
    /*  case ACTION_TYPE.REMOVE_BASELINE:
      this.removeShape(action.blueprint.attributes.id)
      this.updateReadingOrder()
      break */
    /* case ACTION_TYPE.REMOVE_REGION:
      this.removeShape(action.blueprint.attributes.id)
      this.updateReadingOrder()
      break */
    /*  case ACTION_TYPE.REMOVE_TABLE:
      this.removeShape(action.blueprint.attributes.id)
      this.updateReadingOrder()
      break */
    /* case ACTION_TYPE.ADD_SHAPE:
      if (action.shape instanceof Word) {
        this.addWord(action.shape, action.index, action.parent_id)
      }
      if (action.shape instanceof Baseline) {
        this.addBaseline(action.shape, action.index, action.parent_id)
      }
      if (action.shape instanceof Region) {
        this.addRegion(action.shape, action.index)
      }
      if (action.shape instanceof Table) {
        this.addTable(action.shape, action.index)
      }
      this.updateReadingOrder()
      break */

    /* case ACTION_TYPE.POSITION_MOVE: {
      const shape = this.getShape(action.shape_id)
      if (shape instanceof Region || shape instanceof Table) {
        const index = this.regions.indexOf(shape)
        array_move(this.regions, index, index + action.distance)
      }
      if (shape instanceof Baseline) {
        const parent = this.getBaselineParent(shape.id)
        const index = parent.baselines.indexOf(shape)
        array_move(parent.baselines, index, index + action.distance)
      }
      this.updateReadingOrder()
      break
    } */
    case ACTION_TYPE.CHANGE_PARENT:
      const oldParent = this.getShape(action.old_parent_id)
      const newParent = this.getShape(action.new_parent_id)
      const movedShapes = oldParent.baselines.splice(
        action.old_index,
        action.shape_count
      )
      newParent.baselines.splice(action.new_index, 0, ...movedShapes)
      this.updateReadingOrder()
      break
      /* case ACTION_TYPE.SET_ROTATION:
      let rotateAngle = action.new_angle - this.rotationAngle
      if (rotateAngle < 0) {
        rotateAngle = 360 + rotateAngle
      }
      this.allShapesAndWords().forEach(
        s => s.rotateBy && s.rotateBy(rotateAngle)
      )
      this.rotationAngle = action.new_angle
      break */
      /* case ACTION_TYPE.RESIZE:
      const scaleBy = action.factor_new / action.factor_old
      this.allShapesAndWords().forEach(s => s.scaleBy && s.scaleBy(scaleBy))

      this.scaleFactor = action.factor_new
      break */
      // case ACTION_TYPE.CENTER_LINE:
      //   {

      //     const shape = this.getShape(action.ids[0])
      //     const midPoint = shape.getMidPoint()
      //     this.vueFunctions.focusPoint(midPoint)

      //   }

      // let objects = canvas.getObjects()
      // let objectsWithId = objects.filter(function(object) {
      //   return object.id === action.ids[0]
      // })

      // let polygon = objectsWithId[0]

      // let points = []
      // for (let i = 0; i < polygon.points.length; i++) {
      //   let point = this.fabricToOpenSeadragon(
      //     viewer,
      //     canvas,
      //     polygon.points[i]
      //   )
      //   points.push(point)
      // }
      // this.centerPolygon(viewer, points)
      break
    case ACTION_TYPE.SET_TEXT:
    case ACTION_TYPE.SET_TAGS:
    /* case ACTION_TYPE.ADD_TAG:
    case ACTION_TYPE.REMOVE_TAG: */
    /* case ACTION_TYPE.ADD_POINT: */
    /* case ACTION_TYPE.REMOVE_POINT: */
    /* case ACTION_TYPE.MOVE_POINT: */
    /* case ACTION_TYPE.MOVE_POINTS: */
    /*     case ACTION_TYPE.MOVE_SHAPE:
    case ACTION_TYPE.MOVE_SHAPE_WITH_VECTOR: */
    /* case ACTION_TYPE.ADD_TABLE_COLUM:
    case ACTION_TYPE.REMOVE_TABLE_COLUM: */
    /*  case ACTION_TYPE.ADD_TABLE_ROW:
    case ACTION_TYPE.REMOVE_TABLE_ROW: */
    /* case ACTION_TYPE.ADD_CELL:
    case ACTION_TYPE.REMOVE_CELL: */
    case ACTION_TYPE.CHANGE_CELL_SPAN:
    case ACTION_TYPE.SPLIT_TABLE_COL:
    case ACTION_TYPE.SPLIT_TABLE_ROW:
    case ACTION_TYPE.MERGE_TABLE_COLS:
    case ACTION_TYPE.MERGE_TABLE_ROWS:
    case ACTION_TYPE.SET_STRUCTUR_TYPE: {
      const shape = this.getShape(action.shape_id)
      if (shape != null) {
        shape.performAction(action)
        this.updateRelationArrows()
      } else {
        warn('action shape id not found')
      }
      break
    }
    case ACTION_TYPE.MULTI_ACTION:
      action.actions.map(action => {
        this.performAction(action)
      })
      action.shape_id && this.getShape(action.shape_id).update()
      break
  }
}

Editor.prototype.clearActions = function () {
  this.actionHandler.reset()
}

Editor.prototype.addActions = function (actions) {
  this.actionHandler.addActions(actions)
}

Editor.prototype.updateShape = function (blueprint) {
  const shape = this.getShape(blueprint.attributes.id)
  if (shape) {
    shape.update(blueprint)
  }
}

Editor.prototype.updateAllShapes = function () {
  this.allShapesAndCellsAndWords().forEach(s => s.update())
  if (this.imageSearchRects) {
    this.renderSearchRects(this.imageSearchRects)
  }
  this.updateRelationArrows()
}

Editor.prototype.updateShapes = function (ids) {
  this.allShapesAndCellsAndWords().forEach(s => {
    if (ids.includes(s.id)) {
      s.update()
    }
  })
}

Editor.prototype.handleReadingOrderChange = function (action) {
  const shape = this.getShape(action.shape_id)

  if (shape instanceof Region || shape instanceof Table) {
    array_move(this.regions, action.old_index, action.new_index)
  }
  if (shape instanceof Baseline) {
    const oldParent = this.getShape(action.old_parent_id)
    const newParent = this.getShape(action.new_parent_id)
    if (oldParent.id === newParent.id) {
      array_move(oldParent.baselines, action.old_index, action.new_index)
    } else {
      const [movedShape] = oldParent.baselines.splice(action.old_index, 1)
      newParent.baselines.splice(action.new_index, 0, movedShape)
    }
  }
  this.updateReadingOrder()
}

Editor.prototype.selectLinesOfRegion = function () {
  const textRegions = this.activeShapes().filter(s => s instanceof Region)
  const tables = this.activeShapes().filter(s => s instanceof Table)
  const lines = [
    ...textRegions.reduce((acc, r) => [...acc, ...r.baselines], []),
    ...tables.reduce((acc, t) => [...acc, ...t.getBaselines()], []),
  ]
  //log(lines)
  this.actionHandler.select({
    type: SELECTION_TYPE.ADD,
    ids: lines.map(l => l.id),
  })
}

Editor.prototype.groupSelect = function () {
  const getRelations = function (group, vertices) {
    return vertices.map(v => {
      let vertexMatrix = v.calcTransformMatrix()
      let inverseLineMatrix = fabric.util.invertTransform(
        group.calcTransformMatrix()
      )
      return fabric.util.multiplyTransformMatrices(
        inverseLineMatrix,
        vertexMatrix
      )
    })
  }
  this.activeShapes().setState(Shape.STATE.GROUP_SELECTION)

  const shapeGroups = this.activeShapes()
    .withoutCellsAndWords()
    .map(shape => {
      if (shape instanceof Table) {
        const cells = shape.allCells().filter(cell => !cell.isSpanChild())
        return cells.map(cell => {
          cell.removeFromCanvas()
          return cell.getGroup().set({ opacity: 0.8 })
        })
      }
      shape.removeFromCanvas()
      if (shape instanceof Baseline) {
        shape.setAllVertices()
      }
      return shape.getGroup().set({ opacity: 0.8 })
    })
    .flat()

  if (shapeGroups.length === 0) return

  this.group = new fabric.Group(shapeGroups, {
    id: -1,
    cornerStyle: 'circle',
    padding: 0,
    cornerSize: 12,
    cornerColor: 'white',
    transparentCorners: true,
    cornerStrokeColor: 'black',
  })

  this.group.childRelations = this.activeShapes()
    .withoutCellsAndWords()
    .reduce((acc, shape) => {
      if (shape instanceof Region) {
        return [
          ...acc,
          {
            id: shape.id,
            coordsRelations:
              shape.vertices && getRelations(this.group, shape.vertices),
          },
        ]
      }
      if (shape instanceof Baseline) {
        return [
          ...acc,
          {
            id: shape.id,
            baselineRelations:
              shape.vertices && getRelations(this.group, shape.vertices),
            coordsRelations:
              shape.polygonVertices &&
              getRelations(this.group, shape.polygonVertices),
          },
        ]
      }
      if (shape instanceof Table) {
        const cells = shape.allCells().filter(cell => !cell.isSpanChild())
        const cellRelations = cells.map(cell => {
          return {
            id: cell.id,
            coordsRelations: getRelations(this.group, cell.vertices),
          }
        })
        return [...acc, ...cellRelations]
      }
    }, [])

  this.group.oldMatrix = this.group.calcTransformMatrix()

  this.canvas.add(this.group)
  this.canvas.setActiveObject(this.group)
}

Editor.prototype.unGroupSelect = function () {
  if (this.group == null) return
  this.activeShapes().setState(Shape.STATE.EDIT)

  const applyTransformation = relations => {
    return relations.map(relation => {
      const vertexTransform = fabric.util.multiplyTransformMatrices(
        this.group.calcTransformMatrix(),
        relation
      )
      const decomposed = fabric.util.qrDecompose(vertexTransform)
      return { x: decomposed.translateX, y: decomposed.translateY }
    })
  }

  const positions = this.group.childRelations.map(
    ({ id, coordsRelations, baselineRelations }) => {
      const positions = { id }
      if (coordsRelations && coordsRelations.length > 0) {
        positions.coords = this.scaleCoords(
          applyTransformation(coordsRelations)
        ).map(({ x, y }, index) => ({ index, coordinates: { x, y } }))
      }
      if (baselineRelations && baselineRelations.length > 0) {
        positions.baseline = this.scaleCoords(
          applyTransformation(baselineRelations)
        ).map(({ x, y }, index) => ({ index, coordinates: { x, y } }))
      }
      return positions
    }
  )
  const oldMatrix = JSON.stringify(this.group.oldMatrix)
  const newMatrix = JSON.stringify(this.group.calcTransformMatrix())
  if (oldMatrix !== newMatrix) {
    const mover = new Move()
    mover.apply({ positions })
    mover.newAction()
  }

  this.canvas.remove(this.group)
  this.activeShapes()
    .withoutCells()
    .map(s => {
      if (s instanceof Table) {
        s.allCells().map(cell => {
          cell.update()
        })
      } else {
        s.update()
      }
    })
  this.canvas.discardActiveObject()
  this.group = null
}

Editor.prototype.allCells = function () {
  return this.tableRegions().reduce(
    (cells, table) => [...cells, ...table.allCells()],
    []
  )
}

Editor.prototype.getCell = function (id) {
  return this.allCells().find(cell => cell.id === id)
}

Editor.prototype.getTableOfCell = function (id) {
  return this.tableRegions().find(
    table => table.allCells().some(c => c.id === id),
    []
  )
}

Editor.prototype.isCell = function (id) {
  return this.allCells().some(c => c.id === id)
}

Editor.prototype.isTable = function (id) {
  return this.getShape(id) instanceof Table
}

Editor.prototype.findParentByPolygon = function (polygonCoords) {
  let parentShape = this.getTextRegionsAndCells().find(shape => {
    let area = shape.getAreaForced()
    if (area == null) return false
    let found = polygonCoords.some(c => pointInPolygon([c.x, c.y], area))
    return found
  })
  return parentShape
}

Editor.prototype.findBaselinesInArea = function (coordList) {
  const touchingLines = this.baselines().filter(line => {
    const area = line.getArea()
    if (area == null) return false
    return isLineInConvexRegion(
      area.map(([x, y]) => ({ x, y })),
      coordList
    )
  })
  return touchingLines
}

Editor.prototype.findParentByPolygonExact = function (polygonCoords) {
  return this.getTextRegionsAndCellsWithoutSpanChilds().find(shape => {
    return isLineInConvexRegion(polygonCoords, shape, 50)
  })
}

// Editor.prototype.findROIndex = function (line, futureParent) {
//   let newLineY = line.vertices[0].top
//   let yValues = futureParent.baselines
//     .map((parentLine) => parentLine.vertices[0].top)
//     .sort(function (a, b) {
//       return a - b
//     })
//   let newIndex = yValues.findIndex((value) => value > newLineY)
//   newIndex = newIndex === -1 ? yValues.length : newIndex
//   return newIndex
// }

Editor.prototype.findBaseline = function (e) {
  // let pointer = this.canvas.getPointer(e)
  // let foundBaseline = this.baselines().find((baseline) => {
  //   let polyCoords = baseline.polyCoords
  //   let point = [pointer.x, pointer.y]
  //   return pointInPolygon(point, polyCoords)
  // })
  // return foundBaseline
}

Editor.prototype.findShape = function (pointer) {
  if (this.state === Editor.STATE.SITES_OR_SEARCH_RESULTS) {
    return this.baselines().find(s => {
      const area = s.getAreaForced()
      if (area == null) return false
      const point = [pointer.x, pointer.y]
      return pointInPolygon(point, area)
    })
  }
  const findByArea = (shapes, pointer) => {
    return shapes.find(s => {
      const area = s.getArea()
      if (area == null) return false
      const point = [pointer.x, pointer.y]
      return pointInPolygon(point, area)
    })
  }
  const foundWord = findByArea(this.words(), pointer)
  if (foundWord) return foundWord
  const foundLine = findByArea(this.baselines(), pointer)
  if (foundLine) return foundLine
  const foundRegion = findByArea(this.getTextRegionsAndCells(), pointer)
  if (foundRegion) return foundRegion
}

Editor.prototype.getSelectionParter = function (shape, pointer) {
  // const foundLine = this.baselines().find((baseline) =>
  //   pointInPolygon([pointer.x, pointer.y], baseline.polyCoords)
  // )
  // const foundRegion = this.regions.find((region) =>
  //   pointInPolygon([pointer.x, pointer.y], region.getArea())
  // )
  // const areLineSiblings = (line1, line2) => {
  //   if (line1 == null || line2 == null) return false
  //   const siblings = this.getBaselineParent(line1.id)?.baselines
  //   if (siblings == null) return false
  //   return siblings.find((s) => line2.id === s.id) && true
  // }
  // if (areLineSiblings(shape, foundLine)) {
  //   return foundLine
  // }
  // if (shape instanceof Region || (shape instanceof Table && foundRegion)) {
  //   return foundRegion
  // }
}

Editor.prototype.settingsUpdate = function () {
  this.allShapesAndCellsAndWords().map(s => s.update())
  this.updateRelationArrows()
}

Editor.prototype.buttonClick = function (button) {
  if (button === Editor.BUTTON.UNDO) {
    this.setState(Editor.STATE.EDIT)
    this.actionHandler.undo()
  }
  if (button === Editor.BUTTON.REDO) {
    this.setState(Editor.STATE.EDIT)
    this.actionHandler.redo()
  }

  if (button === Editor.BUTTON.ADD_BASELINES) {
    this.actionHandler.select({
      type: SELECTION_TYPE.CLEAR,
    })
    this.setState(Editor.STATE.ADD_BASELINES)
  }

  if (button === Editor.BUTTON.ADD_REGIONS) {
    this.actionHandler.select({
      type: SELECTION_TYPE.CLEAR,
    })
    this.setState(Editor.STATE.ADD_REGIONS)
  }
  if (button === Editor.BUTTON.ADD_TABLES) {
    this.actionHandler.select({
      type: SELECTION_TYPE.CLEAR,
    })
    this.setState(Editor.STATE.ADD_TABLES)
  }

  if (button === Editor.BUTTON.ADD_RELATION) {
    this.actionHandler.select({
      type: SELECTION_TYPE.CLEAR,
    })
    this.setState(Editor.STATE.ADD_RELATION)
  }

  if (button === Editor.BUTTON.POINTER) {
    this.activeShapes().setState(Shape.STATE.EDIT)
    this.setState(Editor.STATE.EDIT)
  }

  if (button === Editor.BUTTON.DRAG) {
    this.activeShapes().setState(Shape.STATE.EDIT)
    this.actionHandler.select({
      type: SELECTION_TYPE.CLEAR,
    })
    this.setState(Editor.STATE.DRAG)
  }
}

Editor.prototype.renderSearchRects = function (rects) {
  this.searchRects.map(r => this.canvas.remove(r))
  const scaledRects = rects.map(r => {
    return {
      x: r.x * getScaleFactor(),
      y: r.y * getScaleFactor(),
      width: r.width * getScaleFactor(),
      height: r.height * getScaleFactor(),
    }
  })
  this.searchRects = this.myFabric.getSearchRects(scaledRects)
  this.searchRects.map(r => this.canvas.add(r))
}

Editor.prototype.setNewBlueprints = function (
  blueprints,
  isSearchOrSites,
  searchRects
) {
  this.blueprints = blueprints
  this.render(isSearchOrSites, searchRects)
}
Editor.prototype.reset = function () {
  //this.setState(Editor.STATE.EDIT)
  this.regions = []
  this.relations.length = 0
  this.metadata = null
  this.lastMousePosition = { x: 0, y: 0 }
  this.canvas.clear()
  this.lastHovered = null
  this.actionHandler.reset()
  this.idHandler.reset()
}
Editor.prototype.setActionHandlerXmlKey = function (xmlKey) {
  this.actionHandler.setXmlKey(xmlKey)
}

Editor.prototype.render = function (isSearchOrSites, searchRects) {
  this.canvas.clear()

  this.lastMousePosition = { x: 0, y: 0 }

  let { regionBlueprints, relationsBlueprint, metadataBlueprint } =
    this.blueprints

  this.metadata = metadataBlueprint

  if (relationsBlueprint != null) {
    this.relations = relationsBlueprint.elements.relations.map(
      blueprint => new Relation(blueprint, this.editorName)
    )
  }

  this.regions = regionBlueprints.map((blueprint, index) => {
    if (blueprint.elements.textLines) {
      return new Region(
        this.canvas,
        blueprint,
        this.editorName,
        null,
        index,
        () => [...this.activeShapeIds]
      )
    }
    return new Table(
      this.canvas,
      blueprint,
      this.editorName,
      null,
      index,
      () => [...this.activeShapeIds]
    )
  })
  /*   const missingTypes = this.getMissingStructureTypes()
  if (missingTypes != null) {
    this.saveMissingTypes(missingTypes)
  } */
  //this.updateReadingOrder(true)
  this.updateRelationArrows()
  if (this.newVertexState && this.activeShapes().isBaseline()) {
    this.activeShapes()[0].setState(Shape.STATE.ADD_VERTEX)
  }

  if (isSearchOrSites) {
    Settings.instance().setDefaults()
    this.setState(Editor.STATE.SITES_OR_SEARCH_RESULTS)
    this.canvas.clear()

    if (searchRects) {
      this.imageSearchRects = searchRects
      this.renderSearchRects(searchRects)
    }
  }
}

Editor.prototype.saveMissingTypes = function (missingTypes) {
  if (missingTypes.length > 0) {
    const colors = [
      '#e6194b',
      '#3cb44b',
      '#ffe119',
      '#4363d8',
      '#f58231',
      '#911eb4',
      '#46f0f0',
      '#f032e6',
      '#bcf60c',
      '#fabebe',
      '#008080',
      '#e6beff',
      '#9a6324',
      '#fffac8',
      '#800000',
      '#aaffc3',
      '#808000',
      '#ffd8b1',
      '#000075',
      '#808080',
    ]

    const missingTypesObjects = missingTypes.map(name => ({
      name,
      color: colors[Math.floor(Math.random() * colors.length)],
      hidden: false,
    }))

    CollectionConfig.instance().setStructureTags([
      ...this.structureTags,
      ...missingTypesObjects,
    ])
  }
}

Editor.prototype.getMissingStructureTypes = function () {
  const types = this.getAllStructureTypesFromShapes()

  const configTypes = [...this.structureTags.map(t => t.name)]

  if (configTypes.length === 0) return

  if (this.missingTypesChecked) return

  this.missingTypesChecked = true

  const missingTypes = types.filter(t => !configTypes.includes(t))

  if (missingTypes.length === 0) return

  return missingTypes
}

Editor.prototype.getAllStructureTypesFromShapes = function () {
  return Array.from(
    this.allShapesAndCellsAndWords().reduce(
      (acc, s) => new Set([...acc, s.getStructureType()]),
      new Set()
    )
  ).filter(t => t != null)
}

/* Editor.prototype.createPageXML = function () {
  this.actionHandler.select({
    type: SELECTION_TYPE.CLEAR,
  })
  this.updateReadingOrder()
  let regionBlueprints = this.regions.map((region, index) =>
    region.getBlueprint(index)
  )
  const relationsBlueprint = {
    attributes: {},
    elements: { relations: this.relations.map(r => r.getBlueprint()) },
  }

  let json = blueprintToJson(
    this.pageJSON,
    regionBlueprints,
    relationsBlueprint,
    this.metadata,
    this.scaleFactor,
    this.rotationAngle
  )
  let isValid = true
  let xml = js2xml(json, {
    compact: false,
    ignoreComment: true,
    spaces: 4,
    attributesFn: value => {
      if (value.imgUrl) {
        value.imgUrl = value.imgUrl.replaceAll('&', '&amp;')
      }
      return value
    },
  })
  // if (!isValid) {
  //   console.error('parsing error: null, NaN or undefined was found')

  //   return
  // }
  return xml
} */

Editor.prototype.getLayout = function () {
  return {
    regions: this.regions.map(r => r.getLayout()),
    metadata: {
      attributes: this.metadata.attributes,
      properties: this.metadata.elements.properties
        .map(p => p.attributes)
        .filter(p => p.key != 'mdSetName'),
    },
  }
}

Editor.prototype.setMetadataProperties = function (properties) {
  const setName = this.metadata.elements.properties.find(
    p => p.attributes.key === 'mdSetName'
  )
  this.metadata.elements.properties = properties.map(p => ({ attributes: p }))
  this.metadata.elements.properties.push(setName)
}

Editor.prototype.getRemoveAction = function (shapes) {
  const words = shapes.filter(s => s instanceof Word)
  const lines = shapes.filter(s => s instanceof Baseline)
  const regions = shapes.filter(s => s instanceof Region)
  const tables = shapes.filter(s => s instanceof Table)
  const cells = shapes.filter(s => s instanceof Cell)
  cells.forEach(cell => {
    const table = this.getTableOfCell(cell.id)
    if (!tables.includes(table)) {
      tables.push(table)
    }
  })

  const getRelationsFromShapeList = shapeList => {
    return Array.from(
      shapeList.reduce((acc, s) => {
        return new Set([
          ...acc,
          ...this.relations.filter(r => r.getIds().some(id => id === s.id)),
        ])
      }, new Set())
    )
  }

  const shapesWithChildren = shapes.reduce(
    (acc, s) => [
      ...acc,
      s,
      ...(s.getBaselines?.() || []),
      ...(s.getWords?.() || []),
      ...(s.allCells?.() || []),
    ],
    []
  )

  const shapeRelations = getRelationsFromShapeList(shapesWithChildren)

  const actions = [
    ...shapeRelations.map(r => {
      return createAction(ACTION_TYPE.REMOVE_RELATION, { relation: r })
    }),
    ...words.map(word => {
      const parent = this.getWordParent(word.id)
      //TODO
      //return createAction(ACTION_TYPE.REMOVE_SHAPE, { shape: word, parent })
    }),
    ...lines.map(line => {
      const parent = this.getBaselineParent(line.id)
      const index = parent.baselines.findIndex(l => l.id === line.id)
      return createAction(ACTION_TYPE.REMOVE_BASELINE, {
        blueprint: line.getBlueprint(),
        parent_id: parent.id,
        index,
      })
    }),
    ...regions.map(region =>
      createAction(ACTION_TYPE.REMOVE_REGION, {
        blueprint: region.getBlueprint(),
        index: this.regions.findIndex(r => r.id === region.id),
      })
    ),
    ...tables.map(table =>
      createAction(ACTION_TYPE.REMOVE_TABLE, {
        blueprint: table.getBlueprint(),
        index: this.regions.findIndex(r => r.id === table.id),
      })
    ),
  ]

  return {
    type: ACTION_TYPE.MULTI_ACTION,
    actions,
  }
}

// Editor.prototype.removeShapeWithAction = function (shape) {
//   this.actionHandler.select({
//     type: SELECTION_TYPE.CLEAR,
//   })
//   if (
//     this.state === Editor.STATE.ADD_BASELINES ||
//     this.state === Editor.STATE.ADD_REGIONS
//   ) {
//     shape.removeFromCanvas()
//     return
//   }

//   let action
//   if (shape instanceof Baseline) {
//     const parent = this.getBaselineParent(shape.id)
//     action = {
//       type: ACTION_TYPE.REMOVE_SHAPE,
//       shape: shape,
//       parent_id: parent.id,
//       index: parent.baselines.findIndex((l) => l.id === shape.id),
//     }
//   } else {
//     action = {
//       type: ACTION_TYPE.REMOVE_SHAPE,
//       shape: shape,
//       index: this.regions.findIndex((l) => l.id === shape.id),
//     }
//   }

//   this.actionHandler.doAction(action)
//   this.actionHandler.addAction(action)
//   this.updateReadingOrder()
// }

Editor.prototype.pointRemoveAllowed = function (id) {
  const baseline = this.allShapes().find(s =>
    s.polygonVertices?.some(v => v.id === id)
  )
  if (baseline != null && baseline.polygonVertices.length > 3) return true

  const shape = this.allShapes().find(s => s.vertices?.some(v => v.id === id))
  return (
    (shape instanceof Region && shape.vertices.length > 3) ||
    (shape instanceof Baseline && shape.vertices.length > 2)
  )
}

Editor.prototype.removePointWithAction = function (id) {
  const baseline = this.allShapes().find(s =>
    s.polygonVertices?.some(v => v.id === id)
  )
  if (baseline) {
    const vertex = baseline.polygonVertices.find(v => v.id === id)
    const index = baseline.polygonVertices.indexOf(vertex)
    this.actionHandler.newAction({
      type: ACTION_TYPE.REMOVE_POLYGON_POINT,
      shape_id: baseline.id,
      point_coord: {
        x: vertex.left,
        y: vertex.top,
      },
      point_index: index,
    })
    return
  }
  const shape = this.allShapes().find(s => s.vertices?.some(v => v.id === id))
  const index = shape.vertices.findIndex(v => v.id === id)
  const vertex = shape.vertices.find(v => v.id === id)

  this.actionHandler.newAction({
    type: ACTION_TYPE.REMOVE_POINT,
    shape_id: shape.id,
    point_coord: {
      x: vertex.left,
      y: vertex.top,
    },
    point_index: index,
  })
}

// Editor.prototype.selectNewShapes = function (shapes) {
//   if (this.isCell(shape?.id)) {
//     const table = this.getTableOfCell(shape.id)
//     this.selectNewShape(table)
//     table.selectNewCell(shape)
//     return
//   }
//   if (shape && this.activeShape?.id === shape.id) {
//     if (this.isTable(shape.id)) {
//       shape.selectNewCell(null)
//     }
//     return
//   }
//   this.activeShapes.unSelectAll()
//   this.activeShapes.clear()

//   if (shape) {
//     shape.select()
//     const parent = this.getBaselineParent(shape.id)
//     // this.markNewParent(parent)
//     this.activeShapes.push(shape)
//   }
//   // this.vueFunctions.setActiveShape(shape)
// }

Editor.prototype.markNewShapeNew = function (parent) {
  if (this.markedParent?.id === parent?.id) return
  if (this.markedParent) {
    this.markedParent.unMark()
    this.markedParent = null
  }
  if (parent) {
    parent.mark()
    this.markedParent = parent
  }
}

Editor.prototype.markNewShape = function (oldShape, newShape) {
  if (oldShape?.id === newShape?.id) return
  if (oldShape) {
    oldShape.unMark()
  }
  if (newShape) {
    newShape.mark()
  }
}

Editor.prototype.addCanvasListeners = function () {
  this.canvas.on('mouse:down', this.mouseEventHandlers.mouseDown)
  this.canvas.on('mouse:dblclick', this.mouseEventHandlers.dblClick)
  this.canvas.on('mouse:move', this.mouseEventHandlers.mouseMove)
  this.canvas.on('mouse:out', this.mouseEventHandlers.mouseOut)
}

Editor.prototype.removeCanvasListeners = function () {
  this.canvas.off('mouse:down', this.mouseEventHandlers.mouseDown)
  this.canvas.off('mouse:dblclick', this.mouseEventHandlers.dblClick)
  this.canvas.off('mouse:move', this.mouseEventHandlers.mouseMove)
  this.canvas.off('mouse:out', this.mouseEventHandlers.mouseOut)
}

Editor.prototype.addKeyListerns = function () {
  document.addEventListener('keydown', this.keyEventHandlers.keyDown)
  document.addEventListener('keyup', this.keyEventHandlers.keyUp)
  document.addEventListener('paste', this.keyEventHandlers.paste)
}

Editor.prototype.showOnlySelectedLine = function () {}

Editor.prototype.removeKeyListeners = function () {
  document.removeEventListener('keydown', this.keyEventHandlers.keyDown)
  document.removeEventListener('keyup', this.keyEventHandlers.keyUp)
  document.removeEventListener('paste', this.keyEventHandlers.paste)
}

Editor.prototype.removeSettingsListener = function () {
  this.settings.off('update', this.settingsHandler)
}

Editor.prototype.finishRelation = function () {
  const { ids, name, type, activeArrow, draftArrows } = this.getSP(
    Editor.STATE.ADD_RELATION
  )

  if (ids.length <= 1) return
  const newRelation = new Relation(null, this.editorName)
  newRelation.setIds(ids)
  newRelation.setName(name)
  newRelation.setType(type)
  const action = {
    type: ACTION_TYPE.ADD_RELATION,
    relation: newRelation,
    index: this.relations.length,
  }
  this.actionHandler.newAction(action)
  this.canvas.remove(activeArrow, ...draftArrows)
  this.resetSP(Editor.STATE.ADD_RELATION)
}

Editor.prototype.finishBaseline = function (coords) {
  const { polyCoords } = this.myFabric.getPolygon(coords.map(c => [c.x, c.y]))
  const blueprint = {
    attributes: {
      id: this.idHandler.requestId('l'),
    },
    elements: {
      polyCoords: polyCoords.map(c => ({
        x: c[0],
        y: c[1],
      })),
      baselineCoords: coords,
      words: [],
      text: '',
    },
  }
  let parent = this.findParentByPolygon(coords)
  if (parent) {
    const index = parent.expectedIndex(coords)
    const action = {
      type: ACTION_TYPE.ADD_BASELINE,
      index: index,
      blueprint: blueprint,
      parent_id: parent.id,
    }
    this.actionHandler.newAction(action)
  } else {
    const bounds = polygonBounds(polyCoords)
    const coords = [
      { x: bounds[0][0], y: bounds[0][1] },
      { x: bounds[1][0], y: bounds[0][1] },
      { x: bounds[1][0], y: bounds[1][1] },
      { x: bounds[0][0], y: bounds[1][1] },
    ]
    const coordsMidPoint = polygonMean(coords.map(({ x, y }) => [x, y]))
    const index = this.expectedRegionIndex(coordsMidPoint)
    const action = {
      type: ACTION_TYPE.ADD_REGION,
      index: index,
      blueprint: {
        type: 'text',
        attributes: {
          id: this.idHandler.requestId('r'),
        },
        elements: {
          coords,
          textLines: [blueprint],
          text: '',
        },
      },
    }
    this.actionHandler.newAction(action)
  }
}

Editor.prototype.expectedRegionIndex = function (midPoint) {
  const newRegionMidpoint = midPoint
  const midpoints = this.regions.map(r => r.getMidPoint())
  const closest = closedPoint(newRegionMidpoint, midpoints)
  if (closest == null) return 0
  const follows = aFollowedByB(closest, newRegionMidpoint)
  if (follows) {
    return midpoints.indexOf(closest) + 1
  }
  return midpoints.indexOf(closest)
}

Editor.prototype.get = function (region) {
  const newRegionMidpoint = region.getMidPoint()
  const midpoints = this.regions.map(r => r.getMidPoint())

  return 0
}

Editor.prototype.splitTextLine = function (currentLine, splitLine) {
  const baselineCoords = currentLine.getScaledBaselineCoords()
  const polyCoords = currentLine.getScaledPolygonCoords()
  const vertices = baselineCoords.map(coord => {
    return currentLine.getVertex(coord.x, coord.y)
  })
  const polygonVertices = polyCoords.map(coord => {
    return currentLine.getPolygonVertex(coord.x, coord.y)
  })
  currentLine.updateGeometricLines(vertices, polygonVertices)

  let splitList = getSplitCoords(
    vertices,
    currentLine.geometricLines,
    splitLine,
    this.editorName
  )
  let splitListPolygons = getSplitCoords(
    polygonVertices,
    currentLine.geometricLinesPolygon,
    splitLine,
    this.editorName
  )
  if (splitListPolygons.length <= 1 || splitListPolygons.length > 3) return
  if (splitList.length <= 1) return

  let last = splitListPolygons.pop()

  let newLines = splitList.map((splitItem, index) => {
    const polygonSplitItem = splitListPolygons[index % splitListPolygons.length]
    let textLine = new Baseline(this.canvas, null, this.editorName)
    if (currentLine.getStructureType()) {
      textLine.setStructureType(currentLine.getStructureType())
    }
    const textualTags = currentLine.attributes.custom?.tags
    if (textualTags?.length > 0) {
      textLine.attributes.custom = {
        ...textLine.attributes.custom,
        tags: textualTags,
      }
    }
    textLine.vertices = splitItem.vertices.map(v =>
      textLine.getVertex(v.x, v.y, v.id)
    )
    textLine.polygonVertices = polygonSplitItem.vertices.map(v =>
      textLine.getPolygonVertex(v.x, v.y, v.id)
    )
    if (index === 0) {
      textLine.polygonVertices = [
        ...textLine.polygonVertices,
        ...last.vertices.map(v => textLine.getPolygonVertex(v.x, v.y, v.id)),
      ]
    }
    textLine.words = []
    textLine.text = currentLine.text
    return textLine
  })

  return newLines.map(s => s.getBlueprint())
}

Editor.prototype.splitTextRegion = function (currentRegion, splitLine) {
  /*  let splitList = getSplitCoords(
    currentRegion.vertices,
    currentRegion.geometricLines,
    splitLine,
    this.editorName
  ) */
  let splitList = getSplitCoordsRegion(
    currentRegion.getScaledCoords(),
    splitLine
  )
  if (splitList.length <= 1 || splitList.length > 3) return

  let newRegionBlueprints = splitList.map((splitItem, index) => {
    let newCoords = splitItem.vertices.map(v => ({ x: v.x, y: v.y }))
    return {
      type: 'text',
      attributes: {
        id: this.idHandler.requestId('r'),
        ...(currentRegion.getStructureType() && {
          custom: { structureType: currentRegion.getStructureType() },
        }),
      },
      elements: {
        coords: newCoords,
        textLines: [],
        text: '',
      },
    }
  })

  let splittedLineBlueprints = currentRegion.baselines.reduce((acc, line) => {
    let newLines = this.splitShape(line, splitLine)
    if (newLines) return acc.concat(newLines)
    return acc.concat([line.getBlueprint()])
  }, [])

  let sides = [
    {
      region: newRegionBlueprints[0],
      sideIndex: whatSideOfLine(
        newRegionBlueprints[0].elements.coords,
        splitLine
      ),
    },
    {
      region: newRegionBlueprints[1],
      sideIndex: whatSideOfLine(
        newRegionBlueprints[1].elements.coords,
        splitLine
      ),
    },
  ]

  splittedLineBlueprints.map(blueprint => {
    let lineSideIndex = whatSideOfLine(
      blueprint.elements.baselineCoords,
      splitLine
    )
    sides
      .find(side => side.sideIndex === lineSideIndex)
      .region.elements.textLines.push(blueprint)
  })

  return sides.map(side => side.region)
}

Editor.prototype.splitShape = function (shape, splitLine) {
  if (shape instanceof Baseline) {
    return this.splitTextLine(shape, splitLine)
  }
  if (shape instanceof Region) {
    return this.splitTextRegion(shape, splitLine)
  }
}

Editor.prototype.getMergeAction = function (oldShapes, newBlueprint) {
  if (oldShapes[0].getType() === Shape.TYPE.BASELINE) {
    const parent = this.getBaselineParent(oldShapes[0].id)
    const newIndex = parent.baselines.indexOf(oldShapes[0])
    return {
      type: ACTION_TYPE.MULTI_ACTION,
      actions: [
        ...this.getRemoveAction(oldShapes).actions,
        {
          type: ACTION_TYPE.ADD_BASELINE,
          blueprint: newBlueprint,
          index: newIndex,
          parent_id: parent.id,
        },
      ],
    }
  }
  if (oldShapes[0].getType() === Shape.TYPE.REGION) {
    const newIndex = this.regions.indexOf(oldShapes[0])
    return {
      type: ACTION_TYPE.MULTI_ACTION,
      actions: [
        ...this.getRemoveAction(oldShapes).actions,
        {
          type: ACTION_TYPE.ADD_REGION,
          blueprint: newBlueprint,
          index: newIndex,
        },
      ],
    }
  }
}

Editor.prototype.getSplitAction = function (oldShape, newBlueprints) {
  if (oldShape instanceof Baseline) {
    const parent = this.getBaselineParent(oldShape.id)
    const oldIndex = parent.baselines.indexOf(oldShape)
    return {
      type: ACTION_TYPE.MULTI_ACTION,
      actions: [
        ...this.getRemoveAction([oldShape]).actions,
        ...newBlueprints.map((blueprint, index) => {
          const newParent = this.findParentByPolygonExact(
            blueprint.elements.polyCoords
          )
          return {
            type: ACTION_TYPE.ADD_BASELINE,
            blueprint,
            index: oldIndex + index,
            parent_id: newParent ? newParent.id : parent?.id,
          }
        }),
      ],
    }
  }

  if (oldShape instanceof Region) {
    const newBlueprintsOrdered = newBlueprints.toSorted((a, b) =>
      aFollowedByB(
        this.getMidPoint(a.elements.coords),
        this.getMidPoint(b.elements.coords)
      )
        ? -1
        : 1
    )
    const oldIndex = this.regions.indexOf(oldShape)
    return {
      type: ACTION_TYPE.MULTI_ACTION,
      actions: [
        ...this.getRemoveAction([oldShape]).actions,
        ...newBlueprintsOrdered.map((b, index) => {
          return {
            type: ACTION_TYPE.ADD_REGION,
            blueprint: b,
            index: oldIndex + index,
          }
        }),
      ],
    }
  }
}

Editor.prototype.getMidPoint = function (coords) {
  return polygonMean(coords.map(({ x, y }) => [x, y]))
}

Editor.prototype.getSP = function (state) {
  return this.statePH.get(state)
}

Editor.prototype.setSP = function (state, properties) {
  return this.statePH.set(state, properties)
}

Editor.prototype.resetSP = function (state) {
  return this.statePH.reset(state)
}

Editor.prototype.getRegion = function (id) {
  let foundRegion
  this.regions.map(r => {
    if (r.id === id) {
      foundRegion = r
    }
  })
  return foundRegion
}

Editor.prototype.getBaselineParent = function (id) {
  return this.getTextRegionsAndCells().find(shape =>
    shape.baselines.find(line => line.id === id)
  )
}

Editor.prototype.getWordParent = function (id) {
  return this.baselines().find(line => line.words.find(word => word.id === id))
}

Editor.prototype.getTextRegionsAndCells = function () {
  return [...this.textRegions(), ...this.allCells()]
}

Editor.prototype.getTextRegionsAndCellsWithoutSpanChilds = function () {
  return this.getTextRegionsAndCells().filter(shape => !shape.isSpanChild?.())
}

Editor.prototype.getShape = function (id) {
  return this.allShapesAndCellsAndWords().find(s => {
    if (s == null) {
      warn('allShapes contains a null value')
    }
    return s?.id === id
  })
}

Editor.prototype.getRelation = function (id) {
  return this.relations.find(r => r.id === id)
}

Editor.prototype.words = function () {
  return this.baselines().reduce((acc, line) => [...acc, ...line.words], [])
}

Editor.prototype.allShapes = function () {
  return this.regions.reduce((acc, region) => {
    if (region.baselines) {
      return [...acc, region, ...region.baselines]
    }
    return [...acc, region, ...region.getBaselines()]
  }, [])
}

Editor.prototype.allShapesAndCells = function () {
  return [...this.allShapes(), ...this.allCells()]
}

Editor.prototype.allShapesAndCellsAndWords = function () {
  return [...this.allShapesAndCells(), ...this.words()]
}

Editor.prototype.allShapesAndWords = function () {
  return [...this.allShapes(), ...this.words()]
}

Editor.prototype.addRegion = function (region, index) {
  this.regions.splice(index, 0, region)
  region.baselines?.map(bl => {
    bl.update()
  })
  region.update()
}

Editor.prototype.addTable = function (table, index) {
  this.regions.splice(index, 0, table)
  table.update()
}

Editor.prototype.addBaselineNew = function ({ blueprint, index, parent_id }) {
  const line = new Baseline(this.canvas, blueprint, this.editorName, true)
  this.requestVertexShift(line)
  let parent = this.getRegion(parent_id) || this.getCell(parent_id)
  parent.addBaseline(line, index)
  line.words.map(w => w.update())
  line.update()
}

Editor.prototype.addRegionNew = function ({ blueprint, index }) {
  const region = new Region(this.canvas, blueprint, this.editorName, true)
  this.requestVertexShift(region)
  this.regions.splice(index, 0, region)
  region.baselines?.map(bl => {
    bl.update()
  })
  region.update()
}

Editor.prototype.addTableNew = function ({ blueprint, index }) {
  const table = new Table(this.canvas, blueprint, this.editorName)
  this.requestVertexShift(table)
  this.regions.splice(index, 0, table)
  table.update()
}

Editor.prototype.addBaseline = function (line, index, parentId) {
  let parent = this.getRegion(parentId) || this.getCell(parentId)
  parent.addBaseline(line, index)
  line.words.map(w => w.update())
  line.update()
}

Editor.prototype.addWord = function (word, index, parentId) {
  const parent = this.getShape(parentId)
  parent.addWord(word, index)
  word.update()
}

Editor.prototype.addShape = function (shape, index, parentId) {
  if (shape instanceof Baseline) {
    this.addBaseline(shape, index, parentId)
  }
  if (shape instanceof Region) {
    this.addRegion(shape, index)
  }
}

// Editor.prototype.removeRegion = function (id) {
//   this.regions = this.regions.filter((region, index) => {
//     if (region.id === id) {
//       region.unSelect()
//       region.removeFromCanvas()
//       region.baselines.map((bl) => {
//         bl.unSelect()
//         bl.removeFromCanvas()
//       })
//       this.deletedShapes.push({
//         object: region,
//         index: index,
//       })
//       return false
//     }
//     return true
//   })
// }

// Editor.prototype.restoreShapeRelations = function (id) {
//   const shapeRelations = this.relationGraveyard.filterByShapeId(id)
// }

// Editor.prototype.removeShapeRelations = function (id) {
//   const shapeRelations = this.relations.filterByShapeId(id)
//   shapeRelations.map((r) => {
//     const index = this.relations.indexOf(r)
//     const [deletedRelation] = this.relations.splice(index, 1)
//     this.relationGraveyard.push(deletedRelation)
//   })
// }

Editor.prototype.removeShape = function (id) {
  if (this.getShape(id) != null) {
    this.actionHandler.select({
      type: SELECTION_TYPE.REMOVE,
      ids: [id],
    })
  }
  let index = this.regions.findIndex(r => r.id === id)
  if (index >= 0) {
    const [region] = this.regions.splice(index, 1)
    region.unSelect()
    //log('called here')
    region.removeFromCanvas()
    region.removeChildsFromCanvas()
    return
  }
  let parent = this.getBaselineParent(id)
  if (parent != null) {
    index = parent.baselines.findIndex(l => l.id === id)
    if (index >= 0) {
      const [line] = parent.baselines.splice(index, 1)
      line.unSelect()
      line.removeFromCanvas()
      line.removeChildsFromCanvas()
      return
    }
  }
  parent = this.getWordParent(id)
  if (parent != null) {
    index = parent.words.findIndex(word => word.id === id)
    if (index >= 0) {
      const [line] = parent.words.splice(index, 1)
      line.unSelect()
      line.removeFromCanvas()
      return
    }
  }
}

Editor.prototype.mergeSmallLines = function (region) {
  return
  // let mergePartners = []
  // let candidates = [...region.baselines]

  // region.baselines.map((line) => {
  //   let otherCandidates = candidates.filter((c) => c.id != line.id)
  //   let partners = getMergePartners(line, otherCandidates, this.scaleFactor, {
  //     width: this.canvas.width,
  //     height: this.vueFunctions.getCanvasHeight(),
  //   })
  //   if (partners) {
  //     partners.map((p) => {
  //       candidates = candidates.filter((c) => c.id != p.id)
  //     })
  //     mergePartners.push(partners)
  //   }
  // })

  // if (mergePartners.length < 1) return

  // let oldLines = mergePartners.flat()
  // let newLines = mergePartners.map((p) => this.mergeTextLines(p))

  // return {
  //   type: ACTION_TYPE.MULTI_ACTION,
  //   actions: oldLines
  //     .map((line) => ({
  //       type: ACTION_TYPE.REMOVE_SHAPE,
  //       shape_id: line.id,
  //     }))
  //     .concat(
  //       newLines.map((line) => ({
  //         type: ACTION_TYPE.ADD_SHAPE,
  //         shape_id: line.id,
  //       }))
  //     ),
  // }
}

Editor.prototype.merge = function () {
  const activeCells = this.activeShapes().filter(s => s instanceof Cell)

  if (activeCells.length > 0) {
    const table = this.getTableOfCell(activeCells[0].id)
    table.merge(activeCells)
  }
  if (this.activeShapes().areSiblings() === false) return
  let newBlueprint
  if (this.activeShapes().areBaselines()) {
    //newBlueprint = this.mergeTextLines([...this.activeShapes()])
    const mergeParams = {
      selectedElements: this.activeShapeIds,
    }
    const merger = new Merge()
    merger.apply(mergeParams)
    merger.newAction()
    return
  }
  if (this.activeShapes().areTextRegions()) {
    newBlueprint = this.mergeTextRegions([...this.activeShapes()])
  }

  const action = this.getMergeAction([...this.activeShapes()], newBlueprint)
  //log(newShape)

  this.actionHandler.select({
    type: SELECTION_TYPE.CLEAR,
  })
  this.actionHandler.select({
    type: SELECTION_TYPE.ADD,
    ids: [newBlueprint.attributes.id],
  })
  this.actionHandler.newAction(action)
  /*   this.actionHandler.doAction(action)
  this.actionHandler.addAction(action) */
}

Editor.prototype.mergeTextLines = function (textLines) {
  const merge = lineBlueprints => {
    if (lineBlueprints.length === 1) {
      return lineBlueprints[0]
    }
    let [line1, line2] = getClosestLine(lineBlueprints)

    const allPolygonPoints = [
      ...line1.elements.polyCoords,
      ...line2.elements.polyCoords,
    ].map(v => [v.x, v.y])
    const hull = polygonHull(allPolygonPoints)

    const foundTypes = []
    const oldLines = [line1, line2]
    oldLines.forEach(l => {
      const type = l.attributes.custom?.structureType
      if (type != null && !foundTypes.includes(type)) {
        foundTypes.push(type)
      }
    })

    const getLineCustom = (line1, line2) => {
      const customObject = {}
      if (foundTypes.length === 1) {
        customObject.structureType = foundTypes[0]
      }
      const line1Tags = line1.attributes.custom?.tags
      const line2Tags = line2.attributes.custom?.tags?.map(t => {
        const newTag = cloneObj(t)
        const offsetRule = newTag.rules.find(r => r.directive === 'offset')
        offsetRule.value =
          Number(offsetRule.value) + Number(line1.elements.text.length) + 1
        return newTag
      })
      const allTags = [...(line1Tags || []), ...(line2Tags || [])]
      if (allTags.length > 0) {
        customObject.tags = allTags
      }
      if (isEmpty(customObject)) return
      return { custom: customObject }
    }
    const newBlueprint = {
      attributes: {
        id: this.idHandler.requestId('l'),
        ...(getLineCustom(line1, line2) || {}),
      },
      elements: {
        polyCoords: hull.map(point => ({
          x: point[0],
          y: point[1],
        })),
        baselineCoords: [
          ...line1.elements.baselineCoords,
          ...line2.elements.baselineCoords,
        ],
        words: line1.elements.words.concat(line2.elements.words),
        text: `${line1.elements.text} ${line2.elements.text}`,
      },
    }
    return merge(
      [newBlueprint].concat(
        lineBlueprints.filter(
          line =>
            line.attributes.id !== line1.attributes.id &&
            line.attributes.id !== line2.attributes.id
        )
      )
    )
  }
  let newLine = merge(textLines.map(l => l.getBlueprint()))
  return newLine
}

Editor.prototype.mergeTextRegions = function (textRegions) {
  let allRegionPoints = textRegions.reduce((allPoints, region) => {
    return allPoints.concat(region.vertices.map(v => [v.left, v.top]))
  }, [])

  let allTextLines = textRegions.reduce((allLines, region) => {
    return allLines.concat(region.baselines)
  }, [])

  let hull = polygonHull(allRegionPoints)

  const newCoords = hull.map(point => ({ x: point[0], y: point[1] }))

  const newTextlines = allTextLines.map(line => line.getBlueprint())

  const foundTypes = []
  textRegions.forEach(r => {
    const type = r.getStructureType()
    if (type != null && !foundTypes.includes(type)) {
      foundTypes.push(type)
    }
  })
  return {
    type: 'text',
    attributes: {
      id: this.idHandler.requestId('r'),
      ...(foundTypes.length === 1 && {
        custom: { structureType: foundTypes[0] },
      }),
    },
    elements: {
      coords: newCoords,
      textLines: newTextlines,
      text: '',
    },
  }
}

// Editor.prototype.handleMultiselection = function (foundShape) {

//   const areSiblings = (line1, line2) => {
//     let siblings = this.getBaselineParent(line1.id).baselines
//     if (siblings == null) return false
//     return siblings.find((s) => line2.id === s.id) && true
//   }

//   const requestSelectionAdd = (selection, shape) => {
//     if (selection.has(shape)) {
//       selection.remove(shape)
//     } else {
//       selection.add(shape)
//     }
//   }

//   const setNewMultiselection = (shape1, shape2) => {
//     let selection = new Multiselection(this.canvas)
//     selection.add(shape1)
//     selection.add(shape2)
//     this.activeShape = selection
//   }

//   if (foundShape == null) return

//   const activeType =
//     this.activeShape instanceof Baseline
//       ? 'Baseline'
//       : this.activeShape instanceof Region
//       ? 'Region'
//       : this.activeShape instanceof Table
//       ? 'Table'
//       : null

//   const foundType =
//     foundShape instanceof Baseline
//       ? 'Baseline'
//       : foundShape instanceof Region
//       ? 'Region'
//       : foundShape instanceof Table
//       ? 'Table'
//       : null

//   if (
//     this.activeShape instanceof Baseline &&
//     !(foundShape instanceof Baseline)
//   ) {
//     return
//   }
//   if (!areSiblings(this.activeShape, foundShape)) {
//     return
//   }
//   if (
//     (this.activeShape instanceof Region || this.activeShape instanceof Table) &&
//     !(
//       this.activeShape instanceof Region ||
//       this.activeShape instanceof Table ||
//       this.activeShape instanceof Baseline
//     )
//   ) {
//     return
//   }

//   // if (this.activeShape instanceof Baseline) {
//   //   if (foundShape instanceof Baseline) {
//   //     if (areSiblings(this.activeShape, foundShape)) {
//   //       setNewMultiselection(this.activeShape, foundShape)
//   //     }
//   //   }
//   // } else if (null) {
//   //   if (foundShape instanceof Region || foundShape instanceof Table) {
//   //     setNewMultiselection(this.activeShape, foundShape)
//   //   }
//   //   if (foundShape instanceof Baseline) {
//   //     let region = this.getBaselineParent(foundShape.id)
//   //     setNewMultiselection(this.activeShape, region)
//   //   }
//   // } else if (this.activeShape instanceof Multiselection) {
//   if (this.activeShape.type === 'Baseline' && foundShape instanceof Baseline) {
//     if (areSiblings(this.activeShape.shapes[0], foundShape)) {
//       requestSelectionAdd(this.activeShape, foundShape)
//     }
//   } else if (
//     (this.activeShape.type === 'Region' && foundShape instanceof Region) ||
//     foundShape instanceof Table
//   ) {
//     requestSelectionAdd(this.activeShape, foundShape)
//   } else if (
//     this.activeShape.type === 'Region' &&
//     foundShape instanceof Baseline
//   ) {
//     let region = this.getBaselineParent(foundShape.id)
//     requestSelectionAdd(this.activeShape, region)
//   }
// }
// this.vueFunctions.updateTree()

Editor.prototype.getCursorUrl = function (cursor) {
  switch (cursor) {
    case Editor.CURSOR.SELECTION:
      return `url("/images/selection_tool_cursor.png") 5 3, default`
    case Editor.CURSOR.DRAG:
      return `url("/images/drag_tool_cursor.png") 5 3, default`
    case Editor.CURSOR.TEXTLINE:
      return `url("/images/pen_tool_cursor.png") 2 2, default`
    case Editor.CURSOR.TEXTREGION:
      return `url("/images/region_tool_cursor.png") 13 13, default`
    case Editor.CURSOR.TABLE:
      return `url("/images/table_tool_cursor.png") 15 15, default`
  }
}

// Editor.prototype.previewSplit = function (pointer, state) {
//   if (state === Editor.STATE.VERTICAL_SPLIT) {
//     this.prevLine = this.myFabric.getVerticalSplitLine(
//       this.vueFunctions.getCanvasHeight(),
//       pointer
//     )
//   }

//   if (state === Editor.STATE.HORIZONTAL_SPLIT) {
//     this.prevLine = this.myFabric.getHorizontalSplitLine(
//       this.canvas.width,
//       pointer
//     )
//   }
//   if (state === Editor.STATE.CUSTOM_SPLIT) {
//     this.prevLine = this.myFabric.getSplitLine(
//       this.canvas.width,
//       this.canvas.height,
//       pointer,
//       90
//     )
//   }
//   this.canvas.add(this.prevLine)
// }

Editor.prototype.updateDuplicate = function (pointer) {
  if (this.activeShapes().isBaseline() && this.prevDuplicate) {
    if (!this.canvas.contains(this.prevDuplicate)) {
      this.canvas.add(this.prevDuplicate)
    }
    this.prevDuplicate.set({ left: pointer.x, top: pointer.y })
    this.canvas.requestRenderAll()
  }
}

Editor.prototype.updateRelationArrow = function (pointer) {}

Editor.prototype.previewDuplicate = function () {
  if (this.activeShapes().isBaseline()) {
    this.prevDuplicate = this.myFabric.clone(this.activeShapes()[0].line)
    this.prevDuplicate.set({
      evented: false,
      originX: 'center',
      originY: 'center',
      initPosition: { x: this.prevDuplicate.left, y: this.prevDuplicate.top },
    })
  }
}

Editor.prototype.previewNewAssign = function (pointer) {
  this.canvas.remove(this.prevLine)
  this.prevLine = this.myFabric.getNewAssignLine(this.newAssignStartPos, [
    pointer.x,
    pointer.y,
  ])

  const parent = this.findParentByPolygon([pointer])
  this.markNewShapeNew(parent)

  this.canvas.add(this.prevLine)
}

Editor.prototype.createSelectionRect = function (pointer) {
  this.selectionRect = this.myFabric.getSelectionRect(pointer.x, pointer.y)
  this.canvas.add(this.selectionRect)
}

Editor.prototype.addRelation = function (relation) {
  this.relations.push(relation)
  //log([...this.relations])
}

Editor.prototype.removeRelation = function ({ id }) {
  const index = this.relations.findIndex(r => r.id === id)
  this.relations.splice(index, 1)
}

// Editor.prototype.showDocks = function() {
//   this.allShapesAndCellsAndWords().map(s => {
//     s.showDock(this.getType(s))
//   })
// }

// Editor.prototype.getDockPositions = function() {
//   return this.allShapesAndCells().map(s => {
//     if (s.dock == null) {
//       return
//     }
//     return { x: s.dock.left, y: s.dock.top }
//   })
// }

// Editor.prototype.removeDocks = function() {
//   this.allShapesAndCellsAndWords().map(s => {
//     s.removeDock()
//   })
// }

Editor.prototype.initRelationCreation = function (id, name, type) {
  const baseShape = this.getShape(id)
  const init = baseShape.getMidPoint()
  const newArrow = this.myFabric.getRelationArrow(init, [
    this.lastMousePosition.x,
    this.lastMousePosition.y,
  ])
  this.canvas.add(newArrow)
  this.setSP(Editor.STATE.ADD_RELATION, {
    name,
    type,
    activeArrow: newArrow,
    activeInit: init,
    ids: [baseShape.id],
  })
}

// Editor.prototype.showRelations = function () {
//   this.relationViews = this.relations.map(({ ids, type }) => {
//     const vertices = ids.reduce(
//       (acc, id) => [...acc, ...this.getShape(id).vertices],
//       []
//     )
//     return this.myFabric.getRelationBubble(vertices, type)
//   })
//   const views = this.relationViews.flat()
//   views.map((v) => {
//     if (!this.canvas.contains(v)) {
//       this.canvas.add(v)
//     }
//   })
// }

Editor.prototype.textRegions = function () {
  return this.regions.filter(r => r instanceof Region)
}

Editor.prototype.tableRegions = function () {
  return this.regions.filter(r => r instanceof Table)
}

Editor.prototype.updateRelationArrows = function () {
  //log('Relations: ', this.relations)
  this.canvas.remove(...this.allRelationArrows, ...this.allRelationNodes)
  this.allRelationArrows = []
  if (this.settings.get('showRelations') === false) return
  this.allRelationArrows = this.relations.reduce((acc, r) => {
    const arrows = []
    r.getIds().reduce((id1, id2) => {
      const s1 = this.getShape(id1)
      const s2 = this.getShape(id2)
      if (s1 == null || s2 == null) {
        warn('Relation has wrong shape ids')
        arrows.push(null)
        return id2
      }
      arrows.push(
        this.myFabric.getRelationArrow(
          s1.getMidPoint(),
          s2.getMidPoint(),
          r.getName()
        )
      )
      return id2
    })
    if (arrows.some(a => !a)) return acc
    return [...acc, ...arrows]
  }, [])
  this.allRelationNodes = this.relations.reduce((acc, r) => {
    const nodes = r.getIds().map((id, index) => {
      const shape = this.getShape(id)
      if (shape == null) {
        log('Relation has wrong shape ids')
        log(id)
        return
      }
      const newNode = this.myFabric.getRelationNode(
        shape.getMidPoint(),
        r,
        id,
        index
      )
      newNode.on('moving', () => {
        const { markedShape } = this.getSP(Editor.STATE.EDIT_RELATION)
        const newShape = this.findShape(this.lastMousePosition)
        this.markNewShape(markedShape, newShape)
        this.setSP(Editor.STATE.EDIT_RELATION, {
          hasMoved: true,
          markedShape: newShape,
        })
      })
      newNode.on('mouseup', () => {
        const { hasMoved, markedShape, arrow } = this.getSP(
          Editor.STATE.EDIT_RELATION
        )
        if (!hasMoved) {
          const newInit = [this.selectedNode.left, this.selectedNode.top]
          this.canvas.remove(arrow)
          this.setSP(Editor.STATE.EDIT_RELATION, {
            initPosition: newInit,
            arrow: this.myFabric.getRelationArrow(newInit, [
              this.lastMousePosition.x,
              this.lastMousePosition.y,
            ]),
          })
          return
        }
        if (markedShape == null || markedShape.id === newNode.shapeId) {
          newNode.resetPosition()
          this.canvas.requestRenderAll()
          this.setState(Editor.STATE.EDIT)
          return
        }
        const oldIds = r.getIds()
        const newIds = [...oldIds]
        const currentIndex = oldIds.findIndex(id => id === newNode.shapeId)
        newIds.splice(currentIndex, 1, markedShape.id)
        const action = {
          type: ACTION_TYPE.UPDATE_RELATION_IDS,
          index: this.relations.indexOf(r),
          old_ids: oldIds,
          new_ids: newIds,
        }
        this.setState(Editor.STATE.EDIT)
        this.actionHandler.newAction(action)
      })
      return newNode
    })
    if (nodes.some(n => !n)) return acc
    return [...acc, ...nodes]
  }, [])

  this.canvas.add(...this.allRelationArrows, ...this.allRelationNodes)
}

/* Editor.prototype.updateStructureTypeLabels = function () {
  const missingTypes = this.getMissingStructureTypes()
  if (missingTypes != null) {
    this.saveMissingTypes(missingTypes)
  }
  this.allShapesAndCellsAndWords().map(s => {
    s.updateStructureColor()
    s.updateStructureText()
  })
} */

Editor.prototype.updateRelationTypes = function () {
  this.updateRelationArrows()
}

Editor.prototype.updateReadingOrder = function (noUpdate) {
  this.regions.map((r, rIndex) => {
    r.setReadingOrder(rIndex)
    if (r.baselines) {
      r.baselines.map((l, lIndex) => {
        l.setReadingOrder(lIndex)
      })
    }
    if (r.allCells) {
      r.allCells().map(c => {
        c.baselines.map((l, index) => {
          l.setReadingOrder(index)
        })
      })
    }
  })
  if (!noUpdate) {
    this.allShapesAndCells().map(s => s.update())
  }
}

Editor.prototype.resetContextMenu = function () {
  ctxmenu.update(`#${this.editorName}-box`, [])
}

Editor.prototype.getStructureTagMenuItems = function (shapes) {
  //const assignStructureLevels = this.settings.get('assignStructureLevels')

  const getStructureItem = (shapeInfo, type, color, bold, level) => {
    const getAction = (shapeInfo, type) => () => {
      let action
      if (shapeInfo.length === 1) {
        const [{ id, oldType }] = shapeInfo
        action = {
          type: ACTION_TYPE.SET_STRUCTUR_TYPE,
          shape_id: id,
          old_type: oldType,
          new_type: type,
        }
      } else {
        action = {
          type: ACTION_TYPE.MULTI_ACTION,
          actions: shapeInfo.map(({ id, oldType }) => ({
            type: ACTION_TYPE.SET_STRUCTUR_TYPE,
            shape_id: id,
            old_type: oldType,
            new_type: type,
          })),
        }
      }
      this.actionHandler.newAction(action)
    }

    const getText = (
      type,
      color,
      bold,
      level
    ) => `<div style="display: inline-block;
      background-color:${color};
      border-radius:20px;
      width: 20px;
      height: 20px;
      margin-bottom: -3px;
      margin-right: 12px;
      margin-left: -3px;"></div>${
        bold
          ? `<strong>${level != null ? `${type}-${level}` : `${type}`}</strong>`
          : `${level != null ? `${type}-${level}` : `${type}`}`
      }`

    /*    if (assignStructureLevels && type !== 'none') {
      return {
        text: getText(type, color, bold),
        subMenu: Array.from({ length: 10 }, (_, i) => i + 1).map(level => ({
          text: `level ${level}`,
          action: getAction(shapeInfo, `${type}#level#${level}`),
        })),
      }
    } */
    return {
      text: getText(type, color, bold, level),
      action: getAction(
        shapeInfo,
        `${type}${level != null ? `#level#${level}` : ''}`
      ),
    }
  }
  const structureTypeList = this.structureTags.filter(t => !t.hidden)
  structureTypeList.push({ name: 'none', color: '#FFFFFF' })

  if (shapes.areTableAndCell()) {
    const cell = shapes.find(s => s instanceof Cell)
    const cellStructureType = cell?.getStructureType() || 'none'
    const cellStructureTypeIndex = structureTypeList.findIndex(
      el => el.name === cellStructureType
    )
    return structureTypeList.map(({ name, color, level }, index) =>
      getStructureItem(
        [
          {
            id: cell.id,
            oldType: cellStructureType,
          },
        ],
        name,
        color,
        cellStructureTypeIndex === index,
        level
      )
    )
  }
  if (shapes.areMultiple()) {
    const shapeInfo = shapes
      .filter(s => !(s instanceof Word))
      .map(s => ({
        id: s.id,
        oldType: s.getStructureType() || 'none',
      }))
    const indices = shapeInfo.map(({ oldType }) =>
      structureTypeList.findIndex(el => el.name === oldType)
    )
    return structureTypeList.map(({ name, color, level }, index) =>
      getStructureItem(shapeInfo, name, color, indices.includes(index), level)
    )
  }
  const currentStructureType = shapes[0].getStructureType() || 'none'
  const structureTypeIndex = structureTypeList.findIndex(
    el => el.name === currentStructureType
  )
  return structureTypeList.map(({ name, color, level }, index) =>
    getStructureItem(
      [
        {
          id: shapes[0].id,
          oldType: currentStructureType,
        },
      ],
      name,
      color,
      structureTypeIndex === index,
      level
    )
  )
}

Editor.prototype.tagMenuOpen = function () {
  const liWithSpans = document.querySelectorAll('li span')
  for (let span of liWithSpans) {
    if (span.textContent.trim() === 'none') {
      return true
    }
  }
  return false
}

Editor.prototype.openStructureTagMenu = function (shapes) {
  this.resetContextMenu()
  const itemList = this.getStructureTagMenuItems(shapes)

  ctxmenu.show(itemList, this.lastContainerMouseUpEvent)
}

Editor.prototype.getContextMenuItems = function (element, position) {
  this.resetContextMenu()

  const getIconDiv = (iconId, text) => {
    return `<div class='ctx-menu-icon ' id='${iconId}'></div>${text}`
  }

  const getAssignStructure = shapes => {
    return {
      text: this.$t(getIconDiv('ctx-structure-tags', 'Assign structure type')),
      subMenu: this.getStructureTagMenuItems(shapes),
    }
  }

  const getDelete = shapes => {
    return {
      text: this.$t(getIconDiv('ctx-delete', 'Delete')),

      action: () => {
        const action = this.getRemoveAction(shapes)
        this.actionHandler.newAction(action)
      },
    }
  }

  const getTableItems = (shapes, position) => {
    const table = shapes.find(s => s instanceof Table)
    return [
      {
        text: this.$t(getIconDiv('ctx-dissolve', 'Dissolve cells')),
        action: () => {
          table.dissolveCells(position)
        },
      },
      {
        text: this.$t(getIconDiv('ctx-merge-row', 'Merge cells')),
        action: () => {
          const activeCells = this.activeShapes().filter(s => s instanceof Cell)
          if (activeCells.length > 0) {
            const table = this.getTableOfCell(activeCells[0].id)
            table.merge(activeCells)
          }
        },
      },

      /*   {
        text: this.$t(getIconDiv('ctx-merge-row', 'Merge row')),
        action: () => {
          table.mergeRow(position)
        },
      },
      {
        text: this.$t(getIconDiv('ctx-merge-col', 'Merge column')),
        action: () => {
          table.mergeColumn(position)
        },
      },
      {
        text: this.$t(getIconDiv('arrowRight-icon', 'Merge right')),
        action: () => {
          table.mergeRightCell(position)
        },
      },
      {
        text: this.$t(getIconDiv('arrowLeft-icon', 'Merge left')),
        action: () => {
          table.mergeLeftCell(position)
        },
      },
      {
        text: this.$t(getIconDiv('arrowUp-icon', 'Merge above')),
        action: () => {
          table.mergeTopCell(position)
        },
      },
      {
        text: this.$t(getIconDiv('arrowDown-icon', 'Merge below')),
        action: () => {
          table.mergeBottomCell(position)
        },
      }, */
    ]
  }

  const getMerge = () => {
    return {
      text: this.$t(getIconDiv('ctx-merge-row', 'Merge Shapes')),
      action: () => {
        this.merge()
      },
    }
  }

  const getVerticalSplit = () => {
    return {
      text: this.$t(getIconDiv('ctx-split-vertical', 'Vertical split')),
      action: () => {
        this.setState(Editor.STATE.VERTICAL_SPLIT)
      },
    }
  }

  const getHorizontalSplit = () => {
    return {
      text: this.$t(getIconDiv('ctx-split-horizontal', 'Horizontal split')),
      action: () => {
        this.setState(Editor.STATE.HORIZONTAL_SPLIT)
      },
    }
  }

  const getCustomSplit = () => {
    return {
      text: this.$t(getIconDiv('ctx-custom-split', 'Custom split')),
      action: () => {
        this.setState(Editor.STATE.CUSTOM_SPLIT)
      },
    }
  }

  const getFreeFormSplit = () => {
    return {
      text: this.$t(getIconDiv('ctx-custom-split', 'Free form split')),
      action: () => {
        this.setState(Editor.STATE.FREE_FORM_SPLIT)
      },
    }
  }

  const getDeletePoint = element => {
    return {
      text: this.$t(getIconDiv('ctx-delete', 'Delete Point')),
      action: () => {
        if (this.pointRemoveAllowed(element.id)) {
          this.removePointWithAction(element.id)
        }
      },
    }
  }

  const getRemoveDivider = (foundShape, element) => {
    return {
      text: this.$t(getIconDiv('ctx-delete', 'Remove Divider')),
      action: () => {
        foundShape.removeLine(element.id)
      },
    }
  }

  const getAssignNewParent = () => {
    return {
      text: this.$t(getIconDiv('ctx-assign-to-region', 'Assign to new region')),
      action: () => {
        this.settings.set({ showRegions: true })
        this.setState(Editor.STATE.ASSIGN_NEW_PARENT)
      },
    }
  }

  const getSelectAll = () => {
    return {
      text: this.$t(getIconDiv('ctx-select-all', 'Select All')),
      action: () => {
        if (this.regions.length > 0) {
          this.actionHandler.select({
            type: SELECTION_TYPE.CLEAR,
          })
          this.actionHandler.select({
            type: SELECTION_TYPE.ADD,
            ids: this.allShapesAndCells().map(r => r.id),
          })
        }
      },
    }
  }

  const getDuplicate = shape => {
    return {
      text: this.$t(getIconDiv('ctx-duplicate', 'Duplicate')),
      action: () => {
        this.setState(Editor.STATE.DUPLICATE)
      },
    }
  }

  const getAddRelationItem = (name, type, color, shapeId) => ({
    text: `<div style="display: inline-block;
      background-color:${color};
      border-radius:20px;
      width: 20px;
      height: 20px;
      margin-bottom: -3px;
      margin-right: 12px;
      margin-left: -3px;"></div>${name} `,
    action: () => {
      this.setState(Editor.STATE.ADD_RELATION)
      this.initRelationCreation(shapeId, name, type)
    },
  })

  const getChangeRelationItem = (name, type, color, relationId) => ({
    text: `<div style="display: inline-block;
      background-color:${color};
      border-radius:20px;
      width: 20px;
      height: 20px;
      margin-bottom: -3px;
      margin-right: 12px;
      margin-left: -3px;"></div>${name} `,
    action: () => {
      const relation = this.getRelation(relationId)

      const newRelation = new Relation(null, this.editorName)
      newRelation.setIds(relation.getIds())
      newRelation.setName(name)
      newRelation.setType(type)

      if (relation) {
        const removeAction = {
          type: ACTION_TYPE.REMOVE_RELATION,
          relation,
        }
        const addAction = {
          type: ACTION_TYPE.ADD_RELATION,
          relation: newRelation,
        }

        const action = {
          type: ACTION_TYPE.MULTI_ACTION,
          actions: [removeAction, addAction],
        }

        this.actionHandler.newAction(action)
      }
    },
  })

  const getCreateRelation = shape => {
    const relationsList = [...this.relationTags.filter(t => !t.hidden)]

    return {
      text: this.$t(getIconDiv('ctx-relation', 'Add Relation')),
      subMenu: relationsList.map(({ name, type, color }) =>
        getAddRelationItem(name, type, color, shape.id)
      ),
    }
  }

  const getDeleteRelation = relationId => {
    return {
      text: this.$t(getIconDiv('ctx-delete', 'Remove Relation')),
      action: () => {
        const relation = this.getRelation(relationId)
        if (relation) {
          const action = {
            type: ACTION_TYPE.REMOVE_RELATION,
            relation,
          }

          this.actionHandler.newAction(action)
        }
      },
    }
  }
  const getChangeRelationName = relationId => {
    const relationsList = [...this.relationTags.filter(t => !t.hidden)]

    return {
      text: this.$t('Change Relation'),
      subMenu: relationsList.map(({ name, type, color }) =>
        getChangeRelationItem(name, type, color, relationId)
      ),
    }
  }

  let itemList = []

  if (this.activeShapes().isEmpty()) {
    itemList = [getSelectAll()]
  }

  if (this.activeShapes().areMultiple()) {
    itemList = [
      ...(!this.activeShapes().areWords()
        ? [getAssignStructure(this.activeShapes())]
        : []),
      getDelete(this.activeShapes()),
      getVerticalSplit(),
      getHorizontalSplit(),
      getCustomSplit(),
    ]
    if (this.activeShapes().areSiblings()) {
      itemList.push(getMerge())
      if (this.activeShapes().areBaselines()) {
        itemList.push(getAssignNewParent())
      }
    }
  }

  if (
    this.activeShapes().areTableAndCells() ||
    this.activeShapes().isSingle()
  ) {
    itemList = [
      ...(!this.activeShapes().isWord()
        ? [getAssignStructure(this.activeShapes())]
        : []),
      getDelete(this.activeShapes()),
      getVerticalSplit(),
      getHorizontalSplit(),
      getCustomSplit(),
    ]
    if (this.activeShapes().areTableAndCells()) {
      itemList.push(getCreateRelation(this.activeShapes().getCell()))
      //itemList.push(getDeleteRelation(this.activeShapes.getCell()))
      itemList.push(...getTableItems(this.activeShapes(), position))
    } else {
      itemList.push(getCreateRelation(this.activeShapes()[0]))
      //itemList.push(getDeleteRelation(this.activeShapes[0]))
    }

    if (this.activeShapes().isBaseline()) {
      itemList.push(getAssignNewParent())
      itemList.push(getDuplicate())
    }
    if (this.activeShapes().isWord()) {
      itemList = [
        /*  getAssignStructure(this.activeshapes()), */
        getCreateRelation(this.activeShapes()[0]),
        //getDeleteRelation(this.activeShapes[0]),
        getDelete(this.activeshapes()),
      ]
    }
  }
  if (this.activeShapes().areRegionsOrLines()) {
    itemList.push(getFreeFormSplit())
  }

  if (element instanceof fabric.Polyline) {
    if (this.activeShapes().find(s => s instanceof Table)) {
      const table = this.activeShapes().find(s => s instanceof Table)
      itemList = [getRemoveDivider(table, element)]
    }
  }

  if (element instanceof fabric.Circle) {
    itemList = [getDeletePoint(element)]
  }

  if ('relationId' in (element || {})) {
    itemList = [
      getDeleteRelation(element.relationId),
      getChangeRelationName(element.relationId),
    ]
  }

  if (this.activeShapes()[0]?.invalid) {
    this.resetContextMenu()
  }

  return itemList
}

Editor.prototype.getType = function (shape) {
  if (shape instanceof Region) {
    return Editor.SHAPE_TYPE.REGION
  }
  if (shape instanceof Table) {
    return Editor.SHAPE_TYPE.TABLE
  }
  if (shape instanceof Baseline) {
    return Editor.SHAPE_TYPE.BASELINE
  }
  if (shape instanceof Cell) {
    return Editor.SHAPE_TYPE.CELL
  }
  if (shape instanceof Word) {
    return Editor.SHAPE_TYPE.WORD
  }
}

Editor.prototype.getIndex = function (shape) {
  if (shape instanceof Region || shape instanceof Table) {
    return this.regions.indexOf(shape)
  }
  if (shape instanceof Baseline) {
    const parent = this.getBaselineParent(shape.id)
    return parent.baselines.indexOf(shape)
  }
}

Editor.prototype.writeToClipboard = function (shapes) {
  const filterTextlines = blueprint => {
    if (blueprint.elements?.textLines != null) {
      blueprint.elements.textLines = blueprint.elements.textLines.filter(tl => {
        if (lineIds.includes(tl.attributes.id)) {
          lines = lines.filter(l => l.id != tl.attributes.id)
          return true
        }
        return false
      })
    }
    if (blueprint.elements?.cells != null) {
      blueprint.elements.cells.map(c => filterTextlines(c))
    }
  }

  let lines = shapes.filter(s => s instanceof Baseline)
  const lineIds = lines.map(l => l.id)

  const regions = shapes.filter(s => s instanceof Region || s instanceof Table)

  const regionInfos = regions.map(s => {
    //log(s)
    const index = this.getIndex(s)
    const bluePrint = s.getBlueprint(index, true)
    filterTextlines(bluePrint)
    return {
      type: this.getType(s),
      blueprint: bluePrint,
    }
  })
  const lineInfos = lines.map(s => {
    const index = this.getIndex(s)
    const parentId = this.getBaselineParent(s.id).id
    return {
      type: this.getType(s),
      blueprint: s.getBlueprint(index, null, true),
      parentId,
    }
  })
  const infos = [...regionInfos, ...lineInfos]
  log(infos)
  return navigator.clipboard.writeText(JSON.stringify(infos))
}

Editor.prototype.requestVertexShift = function (shape) {
  const dimensionAlreadyExists = shape =>
    this.allShapes().some(s =>
      shape.vertices.every(v =>
        s.vertices.find(
          vert =>
            Math.abs(vert.left - v.left) <= 1 && Math.abs(vert.top - v.top) <= 1
        )
      )
    )
  if (dimensionAlreadyExists(shape)) {
    shape.vertices.forEach(v => {
      v.left = v.left + 10
      v.top = v.top + 10
    })
    if (shape instanceof Baseline) {
      shape.polygonVertices.forEach(v => {
        v.left = v.left + 10
        v.top = v.top + 10
      })
    }
    if (shape.getBaselines) {
      shape.getBaselines().map(l => {
        this.requestVertexShift(l)
      })
    }
    this.requestVertexShift(shape)
  }
}

Editor.prototype.parsePastedString = function (string) {
  const isValid = string => {
    try {
      const json = JSON.parse(string)
      if (!('type' in json[0]) || !('blueprint' in json[0])) {
        return false
      }
    } catch (e) {
      return false
    }
    return true
  }

  if (!isValid(string)) return
  const infos = JSON.parse(string)
  const actions = []
  const shapeIds = []
  infos.forEach(info => {
    info.blueprint.attributes.id = this.idHandler.requestId('s')

    shapeIds.push(info.blueprint.attributes.id)

    switch (info.type) {
      case Editor.SHAPE_TYPE.REGION:
        /*   const region = new Region(
            this.canvas,
            info.blueprint,
            this.editorName
          ) */
        // requestVertexShift(region)
        info.blueprint.elements.textLines.forEach(line => {
          line.attributes.id = this.idHandler.requestId('s')
          line.elements.words = []
          shapeIds.push(line.attributes.id)
        })
        actions.push({
          type: ACTION_TYPE.ADD_REGION,
          blueprint: info.blueprint,
          index: info.blueprint.attributes.custom?.readingOrder || 0,
        })
        break
      case Editor.SHAPE_TYPE.TABLE:
        /*           const table = new Table(this.canvas, info.blueprint, this.editorName)
          // requestVertexShift(table)
          actions.push({
            type: ACTION_TYPE.ADD_SHAPE,
            index: info.blueprint.attributes.custom.readingOrder,
            shape: table,
          }) */
        info.blueprint.elements.cells.forEach(cell => {
          cell.attributes.id = this.idHandler.requestId('s')
          shapeIds.push(cell.attributes.id)
          cell.elements.textLines.forEach(line => {
            line.attributes.id = this.idHandler.requestId('s')
            line.elements.words = []
            shapeIds.push(line.attributes.id)
          })
        })
        actions.push({
          type: ACTION_TYPE.ADD_TABLE,
          blueprint: info.blueprint,
          index: info.blueprint.attributes.custom?.readingOrder || 0,
        })
        break
      case Editor.SHAPE_TYPE.BASELINE:
        // requestVertexShift(line)
        const parent = this.getShape(info.parentId)
        if (parent instanceof Region || parent instanceof Cell) {
          /*            actions.push({
              type: ACTION_TYPE.ADD_SHAPE,
              index: info.blueprint.attributes.custom.readingOrder,
              shape: line,
              parent_id: parent.id,
            }) */
          info.blueprint.elements.words = []
          actions.push({
            type: ACTION_TYPE.ADD_BASELINE,
            blueprint: info.blueprint,
            index: info.blueprint.attributes.custom?.readingOrder || 0,
            parent_id: parent.id,
          })
          break
        }
        const polyParent = this.findParentByPolygon(
          info.blueprint.elements.baselineCoords
        )
        if (polyParent != null) {
          /*             actions.push({
              type: ACTION_TYPE.ADD_SHAPE,
              index: info.blueprint.attributes.custom.readingOrder,
              shape: line,
              parent_id: polyParent.id,
            })
            return line */
          actions.push({
            type: ACTION_TYPE.ADD_BASELINE,
            blueprint: info.blueprint,
            index: info.blueprint.attributes.custom?.readingOrder || 0,
            parent_id: polyParent.id,
          })
          break
        }
        const bounds = polygonBounds(
          info.blueprint.elements.polyCoords.map(c => [c.x, c.y])
        )
        const coords = [
          { x: bounds[0][0], y: bounds[0][1] },
          { x: bounds[1][0], y: bounds[0][1] },
          { x: bounds[1][0], y: bounds[1][1] },
          { x: bounds[0][0], y: bounds[1][1] },
        ]
        const coordsMidPoint = polygonMean(coords.map(({ x, y }) => [x, y]))
        const index = this.expectedRegionIndex(coordsMidPoint)
        const action = {
          type: ACTION_TYPE.ADD_REGION,
          index: index,
          blueprint: {
            type: 'text',
            attributes: {
              id: this.idHandler.requestId('r'),
            },
            elements: {
              coords,
              textLines: [info.blueprint],
              text: '',
            },
          },
        }
        actions.push(action)
        break
    }
  })
  const action = {
    type: ACTION_TYPE.MULTI_ACTION,
    actions,
  }

  this.actionHandler.newAction(action)
  this.actionHandler.select({
    type: SELECTION_TYPE.CLEAR,
  })
  /* const ids = shapes.reduce(
      (acc, s) => [
        ...acc,
        s.id,
        ...(s.getBaselines ? s.getBaselines().map(l => l.id) : []),
      ],
      []
    ) */
  //log(ids)
  this.actionHandler.select({
    type: SELECTION_TYPE.ADD,
    ids: shapeIds,
  })
}

Editor.prototype.selectSingleShape = function (event, position) {
  const foundShape = this.findShape(position)
  if (
    this.activeShapes().isEmpty() ||
    ((this.activeShapes().haveState(Shape.STATE.EDIT) ||
      this.activeShapes().haveState(Shape.STATE.SELECTION_ONLY) ||
      this.activeShapes().haveState(Shape.STATE.SEARCH_RESULTS)) &&
      !event?.target)
  ) {
    this.actionHandler.select({
      type: SELECTION_TYPE.CLEAR,
    })
    if (foundShape != null) {
      this.actionHandler.select({
        type: SELECTION_TYPE.ADD,
        ids: [foundShape.id],
      })
      if (foundShape instanceof Cell) {
        const table = this.getTableOfCell(foundShape.id)
        if (!this.activeShapes().includes(table)) {
          this.actionHandler.select({
            type: SELECTION_TYPE.ADD,
            ids: [table.id],
          })
        }
      }
    }
  }
}

Editor.prototype.updateVertexSizes = function (target) {
  const unSelect = () => {
    if (this.hoveredVertex == null) return
    this.hoveredVertex.set({ radius: this.hoveredVertex.defaultRadius })
    this.hoveredVertex = null
  }

  const select = vertex => {
    if (target == null) return
    vertex.set({ radius: vertex.defaultRadius * 1.5 })
    this.hoveredVertex = vertex
  }

  if (target == null || target.type != 'circle') {
    unSelect()
    this.canvas.requestRenderAll()
    return
  }

  if (this.hoveredVertex == null) {
    select(target)
    this.canvas.requestRenderAll()
    return
  }

  if (this.hoveredVertex.id != target.id) {
    unSelect()
    select(target)
    this.canvas.requestRenderAll()
    return
  }
}

Editor.prototype.selectPrevious = function () {
  let newIds
  if (this.activeShapes().areTableAndCell()) {
    const cell = this.activeShapes().getCell()
    const parent = this.activeShapes().getTable()
    const index = parent.allCells().indexOf(cell)
    const newIndex = index - 1 < 0 ? parent.allCells().length - 1 : index - 1
    newIds = [parent.id, parent.allCells()[newIndex].id]
  }
  if (this.activeShapes().isBaseline()) {
    const line = this.activeShapes()[0]
    const parent = this.getBaselineParent(line.id)
    const index = parent.baselines.indexOf(line)
    const newIndex = index - 1 < 0 ? parent.baselines.length - 1 : index - 1
    newIds = [parent.baselines[newIndex].id]
  }
  if (this.activeShapes().isRegion()) {
    const region = this.activeShapes()[0]
    const index = this.regions.indexOf(region)
    const newIndex = index - 1 < 0 ? this.regions.length - 1 : index - 1
    newIds = [this.regions[newIndex].id]
  }
  if (newIds == null) return
  this.actionHandler.select({
    type: SELECTION_TYPE.CLEAR,
  })
  this.actionHandler.select({
    type: SELECTION_TYPE.ADD,
    ids: newIds,
  })
}

Editor.prototype.selectNext = function () {
  let newIds
  if (this.activeShapes().areTableAndCell()) {
    const cell = this.activeShapes().getCell()
    const parent = this.activeShapes().getTable()
    const index = parent.allCells().indexOf(cell)
    const newIndex = (index + 1) % parent.allCells().length
    newIds = [parent.id, parent.allCells()[newIndex].id]
  }
  if (this.activeShapes().isBaseline()) {
    const line = this.activeShapes()[0]
    const parent = this.getBaselineParent(line.id)
    const index = parent.baselines.indexOf(line)
    const newIndex = (index + 1) % parent.baselines.length
    newIds = [parent.baselines[newIndex].id]
  }
  if (this.activeShapes().isRegion()) {
    const region = this.activeShapes()[0]
    const index = this.regions.indexOf(region)
    const newIndex = (index + 1) % this.regions.length
    newIds = [this.regions[newIndex].id]
  }
  if (newIds == null) return
  this.actionHandler.select({
    type: SELECTION_TYPE.CLEAR,
  })
  this.actionHandler.select({
    type: SELECTION_TYPE.ADD,
    ids: newIds,
  })
}

Editor.prototype.canApplyStructureTagShortcut = function (key) {
  return this.tagMenuOpen() && /^[a-zA-Z]$/.test(key)
}

Editor.prototype.handleStructureTagShortcut = function (key) {
  const tags = this.structureTags.filter(t => !t.hidden)
  const matchingTag = tags.find(t =>
    t.name.toLowerCase().startsWith(key.toLowerCase())
  )
  if (matchingTag == null) return
  if (this.lastAddedRegionId != null && this.activeShapes().isEmpty()) {
    this.actionHandler.select({
      type: SELECTION_TYPE.CLEAR,
    })
    this.actionHandler.select({
      type: SELECTION_TYPE.ADD,
      ids: [this.lastAddedRegionId],
    })
  }

  if (this.activeShapes().areTableAndCell()) {
    const cell = this.activeShapes().getCell()
    const action = {
      type: ACTION_TYPE.SET_STRUCTUR_TYPE,
      shape_id: cell.id,
      old_type: cell.getStructureType(),
      new_type: matchingTag.name,
    }
    this.actionHandler.newAction(action)
    ctxmenu.hide()
    this.actionHandler.select({
      type: SELECTION_TYPE.CLEAR,
    })
    return
  }
  if (this.activeShapes().isTextRegion()) {
    const region = this.activeShapes()[0]
    const action = {
      type: ACTION_TYPE.SET_STRUCTUR_TYPE,
      shape_id: region.id,
      old_type: region.getStructureType(),
      new_type: matchingTag.name,
    }
    this.actionHandler.newAction(action)
    ctxmenu.hide()
    this.actionHandler.select({
      type: SELECTION_TYPE.CLEAR,
    })
    return
  }
}

Editor.prototype.resetSplitLineFactor = function () {
  this.splitLineFactor = 1
}

Editor.prototype.increaseSplitLineFactor = function () {
  if (this.splitLineFactor < 10) {
    this.splitLineFactor += 0.02
  }
}

Editor.prototype.moveSplitLine = function (
  { left, right, top, bottom },
  delta
) {
  if (this.prevLine == null) return
  if (left) {
    let left = this.prevLine.left - delta
    this.prevLine.set({ left })
  }
  if (right) {
    let left = this.prevLine.left + delta
    this.prevLine.set({ left })
  }
  if (top) {
    let top = this.prevLine.top - delta
    this.prevLine.set({ top })
  }
  if (bottom) {
    let top = this.prevLine.top + delta
    this.prevLine.set({ top })
  }
  this.canvas.requestRenderAll()
}

Editor.prototype.scaleCoords = function (coords) {
  const round = x => Math.round(x)
  const scaleFn = value => round(value * (1 / getScaleFactor()))
  const rotationFn = point => {
    const [x, y] = pointRotate(
      [point.x, point.y],
      360 - getRotationAngle()
    ).map(c => round(c))
    return { x, y }
  }
  const scalePoint = (point, scaleFn, rotationFn) => {
    return rotationFn({ x: scaleFn(point.x), y: scaleFn(point.y) })
  }
  return coords.map(c => scalePoint(c, scaleFn, rotationFn))
}

Editor.prototype.finishSplit = function () {
  let selectionIds = []

  let splitLine = createSplitline(
    [this.prevLine.left, this.prevLine.top],
    this.prevLine.angle,
    this.canvasDiagonal * 4
  )
  if (this.activeShapes().areRegionsOrLines()) {
    const splitParams = {
      selectedElements: this.getActiveSplitIds(),
      splitLine: this.scaleCoords(splitLine.map(c => ({ x: c[0], y: c[1] }))),
    }
    const splitter = new Split()
    splitter.apply(splitParams)
    /* splitter.logXml() */
    splitter.newAction()
  }

  if (this.activeShapes().find(s => s instanceof Table)) {
    if (this.state === Editor.STATE.VERTICAL_SPLIT) {
      this.activeShapes().getTable().addVerticalLine(splitLine)
      return
    }
    if (this.state === Editor.STATE.HORIZONTAL_SPLIT) {
      this.activeShapes().getTable().addHorizontalLine(splitLine)
      return
    }
    if (this.state === Editor.STATE.CUSTOM_SPLIT) {
      this.activeShapes().getTable().addVerticalLine(splitLine) ||
        this.activeShapes().getTable().addHorizontalLine(splitLine)
      return
    }
  }
}

function dblClick() {
  if (this.stateObject) {
    this.stateObject.onDoubleClick({ pointer: this.lastMousePosition })
  }
  if (
    this.settings.get('doubleClickTagMenu') &&
    this.state === Editor.STATE.ADD_REGIONS &&
    this.draft != null &&
    this.lastAddedRegionId != null &&
    this.activeShapes().isEmpty()
  ) {
    this.actionHandler.select({
      type: SELECTION_TYPE.CLEAR,
    })
    this.actionHandler.select({
      type: SELECTION_TYPE.ADD,
      ids: [this.lastAddedRegionId],
    })
    this.openStructureTagMenu(this.activeShapes())
    this.actionHandler.select({
      type: SELECTION_TYPE.CLEAR,
    })
  }
  if (
    this.settings.get('doubleClickTagMenu') &&
    (this.activeShapes().areTableAndCell() ||
      this.activeShapes().isTextRegion())
  ) {
    this.openStructureTagMenu(this.activeShapes())
  }
  if (
    this.activeShapes().haveState(Shape.STATE.ADD_VERTEX) &&
    this.activeShapes().isSingle()
  ) {
    this.newVertexState = false

    //this.activeShapes()[0].removeLastAddedVertex()
    this.activeShapes().setState(Shape.STATE.EDIT)
  }

  if (this.state === Editor.STATE.ADD_RELATION) {
    this.finishRelation()
    this.setState(Editor.STATE.EDIT)
  }

  if (this.state === Editor.STATE.ADD_BASELINES && this.draft != null) {
    this.draft.pop()
    if (this.draft.length > 1) {
      this.finishBaseline(this.draft)
    }
  }
  this.draft = null
}

function mouseMove(event) {
  this.moveCounter = (this.moveCounter + 1) % 10
  // const throttle = (fn, intensity) => {
  //   if (this.throttleCounter % intensity === 0) fn()
  //   this.throttleCounter++
  // }
  this.lastMousePosition = this.canvas.getPointer(event.e)
  if (this.stateObject) {
    this.stateObject.onMouseMove({ pointer: this.lastMousePosition })
  }
  if (
    this.state === Editor.STATE.VERTICAL_SPLIT ||
    this.state === Editor.STATE.HORIZONTAL_SPLIT ||
    this.state === Editor.STATE.CUSTOM_SPLIT
  ) {
    if (!this.canvas.contains(this.prevLine)) {
      this.canvas.add(this.prevLine)
    }
    this.prevLine.set({
      left: this.lastMousePosition.x,
      top: this.lastMousePosition.y,
    })

    this.canvas.requestRenderAll()
    return
  }
  if (this.state === Editor.STATE.SITES_OR_SEARCH_RESULTS) {
    const pointer = this.canvas.getPointer(event.e)
    const foundShape = this.findShape(pointer)
    if (foundShape instanceof Baseline) {
      this.markNewShapeNew(foundShape)
    } else {
      this.markNewShapeNew(null)
    }
    return
  }
  if (this.state === Editor.STATE.ASSIGN_NEW_PARENT) {
    this.previewNewAssign(this.canvas.getPointer(event.e))
    return
  }
  if (this.state === Editor.STATE.DUPLICATE) {
    this.updateDuplicate(this.lastMousePosition)
    return
  }
  if (this.state === Editor.STATE.ADD_RELATION) {
    const pointer = this.lastMousePosition
    const { activeArrow, activeInit, markedShape } = this.getSP(
      Editor.STATE.ADD_RELATION
    )
    this.canvas.remove(activeArrow)
    const newArrow = this.myFabric.getRelationArrow(activeInit, [
      pointer.x,
      pointer.y,
    ])
    this.canvas.add(newArrow)
    const newShape = this.findShape(this.lastMousePosition)
    this.markNewShape(markedShape, newShape)
    this.updateVertexSizes(event.target)
    this.setSP(Editor.STATE.ADD_RELATION, {
      activeArrow: newArrow,
      markedShape: newShape,
    })
    return
  }
  if (this.state === Editor.STATE.EDIT_RELATION) {
    const pointer = this.lastMousePosition
    const { arrow, initPosition, markedShape } = this.getSP(
      Editor.STATE.EDIT_RELATION
    )
    if (initPosition == null) return
    this.canvas.remove(arrow)
    const newArrow = this.myFabric.getRelationArrow(initPosition, [
      pointer.x,
      pointer.y,
    ])
    this.canvas.add(newArrow)
    const newShape = this.findShape(this.lastMousePosition)
    this.markNewShape(markedShape, newShape)
    this.updateVertexSizes(event.target)
    this.setSP(Editor.STATE.EDIT_RELATION, {
      arrow: newArrow,
      markedShape: newShape,
    })
    return
  }
  if (this.state === Editor.STATE.MULTI_SELECT) {
    if (this.selectionRect != null) {
      this.selectionRect.update(
        this.lastMousePosition.x,
        this.lastMousePosition.y
      )
      this.canvas.requestRenderAll()

      if (this.moveCounter === 0) {
        const rectArea = this.selectionRect.getArea()
        const foundShapes = this.allShapesAndCellsAndWords().reduce(
          (acc, shape) => {
            const area = shape.getArea()
            if (area == null || area.length === 0) return [...acc]
            return [
              ...acc,
              ...(shape.isVisible()
                ? polygonIntersectsPolygon(rectArea, area) ||
                  polygonInPolygon(shape.getArea(), rectArea)
                  ? [shape]
                  : []
                : []),
            ]
          },
          []
        )
        const currentIds = this.activeShapes().map(s => s.id)
        const foundIds = foundShapes.map(s => s.id)
        const newIds = foundIds.filter(id => !currentIds.includes(id))
        const removeIds = currentIds.filter(id => !foundIds.includes(id))
        if (newIds.length > 0) {
          this.actionHandler.select({
            type: SELECTION_TYPE.ADD,
            ids: newIds,
          })
        }
        if (removeIds.length > 0) {
          this.actionHandler.select({
            type: SELECTION_TYPE.REMOVE,
            ids: removeIds,
          })
        }
      }
    }
  }
  if (this.state === Editor.STATE.ADD_TABLES) {
    if (this.draft) {
      let mousePosition = this.canvas.getPointer(event.e)
      this.showPreviewRect(
        this.draft.x,
        this.draft.y,
        mousePosition.x,
        mousePosition.y
      )
    }
  }
  if (this.state === Editor.STATE.ADD_BASELINES) {
    if (this.draft) {
      this.canvas.remove(this.prevBaselineLine)
      const { line } = this.myFabric.getLineElements([
        [this.draft.at(-1).x, this.draft.at(-1).y],
        [this.lastMousePosition.x, this.lastMousePosition.y],
      ])
      this.prevBaselineLine = line
      this.canvas.add(this.prevBaselineLine)
    }
  }

  this.updateVertexSizes(event.target)

  this.activeShapes().map(s => s.handleMouseMoveEvent?.(event))
  this.draft?.handleMouseMoveEvent?.(event)
}

Editor.prototype.showPreviewRect = function (x1, y1, x2, y2) {
  this.canvas.remove(this.previewRect)
  this.previewRect = this.myFabric.getPreviewRectTable(x1, y1, x2, y2)
  this.canvas.add(this.previewRect)
}

function mouseOut(o) {
  this.activeShapes().map(s => s.handleMouseOut(o))
}

function settingsHandler({ renderSpeed }) {
  this.allShapesAndCellsAndWords().map(s => s.update())
  this.updateRelationArrows()
  this.canvas.renderSpeed = renderSpeed
}

function mouseDown(event) {
  this.vueFunctions.mouseDown()
  this.canvasDrag = false
  this.lastDownEvent = event

  const position = this.canvas.getPointer(event.e)
  if (this.stateObject) {
    this.stateObject.onMouseDown({ pointer: position })
  }
  switch (this.state) {
    case Editor.STATE.MULTI_SELECT:
      this.createSelectionRect(position)
      this.vueFunctions.setDrag(false)
      break
    case Editor.STATE.EDIT:
      this.selectedNode?.unSelect()
      if (event.target?.relationId == null) {
        this.canvas.requestRenderAll()
        this.setState(Editor.STATE.EDIT)
        break
      }
      event.target.select()
      this.canvas.requestRenderAll()
      this.selectedNode = event.target
      if (event.button !== 3) {
        this.setState(Editor.STATE.EDIT_RELATION)
      }

      break

    case Editor.STATE.ASSIGN_NEW_PARENT:
      if (this.markedParent == null) break
      const oldParent = this.getBaselineParent(this.activeShapes()[0].id)
      const newParent = this.markedParent
      const oldParentLinesCopy = [...oldParent.baselines]
      const newParentLinesCopy = [...newParent.baselines]

      const getNewAssignAction = (oldIndex, newIndex) => ({
        type: ACTION_TYPE.CHANGE_READING_ORDER,
        old_parent_id: oldParent.id,
        new_parent_id: newParent.id,
        old_index: oldIndex,
        new_index: newIndex,
      })
      const expectedIndex = (list, line) => {
        let newLineY = line.getScaledBaselineCoords()?.[0]?.y
        let yValues = list
          .map(parentLine => parentLine.getScaledBaselineCoords()?.[0]?.y)
          .sort((a, b) => a - b)
        let newIndex = yValues.findIndex(value => value > newLineY)
        return newIndex === -1 ? yValues.length : newIndex
      }
      const actions = this.activeShapes().map(line => {
        const oldIndex = oldParentLinesCopy.indexOf(line)
        oldParentLinesCopy.splice(oldIndex, 1)
        const newIndex = expectedIndex(newParentLinesCopy, line)
        newParentLinesCopy.splice(newIndex, 0, line)
        return getNewAssignAction(oldIndex, newIndex)
      })

      const finalAction = { type: ACTION_TYPE.MULTI_ACTION, actions }
      this.actionHandler.newAction(finalAction)

      const circle = this.myFabric.getNewAssignCircle(
        this.newAssignStartPos[0],
        this.newAssignStartPos[1]
      )
      this.canvas.add(circle)
      circle.animate(
        { left: position.x, top: position.y },
        {
          onChange: this.canvas.renderAll.bind(this.canvas),
          duration: 100,
          onComplete: () => this.canvas.remove(circle),
        }
      )
      this.canvas.remove(this.prevLine)
      this.setState(Editor.STATE.EDIT)
      break
  }

  if (event.button === 3) {
    if (this.state === Editor.STATE.EDIT) {
      const foundShape = this.findShape(position)

      if (
        !this.activeShapes()
          .map(s => s.id)
          .includes(foundShape?.id)
      ) {
        this.selectSingleShape(event, position)
      }
      //workaround for layouteditor disabling
      if (this.vueFunctions.isEditable() === false) {
        this.resetContextMenu()
      } else {
        const itemList = this.getContextMenuItems(
          event.target?.id ? event.target : null,
          position
        )
        ctxmenu.update(`#${this.editorName}-box`, itemList)
      }
    } else {
      this.resetContextMenu()
    }
  }

  this.activeShapes().map(s => s.handleClickEvent(event, this.controlPressed))
}

function containerMouseUp(e) {
  this.lastContainerMouseUpEvent = e
  protocolLog(`%cclicked: ${e.x},${e.y}`, 'color: pink')

  const event = this.lastDownEvent

  this.vueFunctions.setDrag(true)

  if (this.canvasDrag) return
  const position = this.lastMousePosition
  const foundShape = this.findShape(position)

  if (this.stateObject) {
    this.stateObject.onMouseUp({ pointer: position })
  }

  switch (this.state) {
    case Editor.STATE.ADD_RELATION: {
      const { ids, activeInit, activeArrow, draftArrows, markedShape } =
        this.getSP(Editor.STATE.ADD_RELATION)
      if (markedShape == null) break
      if (ids.includes(markedShape.id)) break

      this.canvas.remove(activeArrow)
      const newMidPoint = markedShape.getMidPoint()
      const newArrow = this.myFabric.getRelationArrow(activeInit, newMidPoint)
      const newDraftArrows = [...draftArrows, newArrow]
      newDraftArrows.map(a => {
        if (!this.canvas.contains(a)) {
          this.canvas.add(a)
        }
      })

      this.setSP(Editor.STATE.ADD_RELATION, {
        draftArrows: newDraftArrows,
        activeInit: newMidPoint,
        activeArrow: this.myFabric.getRelationArrow(activeInit, [
          this.lastMousePosition.x,
          this.lastMousePosition.y,
        ]),
        ids: [...ids, markedShape.id],
      })

      break
    }
    case Editor.STATE.EDIT_RELATION: {
      const { markedShape, arrow } = this.getSP(Editor.STATE.EDIT_RELATION)
      if (markedShape == null || arrow == null) break
      if (this.selectedNode.shapeId === markedShape.id) break

      const relation = this.getRelation(this.selectedNode.relationId)
      const oldIds = relation.getIds()
      const currentIndex = oldIds.findIndex(
        id => id === this.selectedNode.shapeId
      )
      const newIds = [...oldIds]
      newIds.splice(currentIndex + 1, 0, markedShape.id)
      const action = {
        type: ACTION_TYPE.UPDATE_RELATION_IDS,
        index: this.relations.indexOf(relation),
        old_ids: oldIds,
        new_ids: newIds,
      }
      this.actionHandler.newAction(action)

      this.setState(Editor.STATE.EDIT)

      break
    }
    case Editor.STATE.MULTI_SELECT:
      const updated = this.selectionRect.updated
      if (updated) break

      if (foundShape == null) break
      if (this.activeShapes().includes(foundShape)) {
        this.actionHandler.select({
          type: SELECTION_TYPE.REMOVE,
          ids: [foundShape.id],
        })
        if (foundShape instanceof Cell) {
          const table = this.getTableOfCell(foundShape.id)
          if (table.allCells().every(c => !this.activeShapes().includes(c))) {
            this.actionHandler.select({
              type: SELECTION_TYPE.REMOVE,
              ids: [table.id],
            })
          }
        }
      } else {
        this.actionHandler.select({
          type: SELECTION_TYPE.ADD,
          ids: [foundShape.id],
        })
        if (foundShape instanceof Cell) {
          const table = this.getTableOfCell(foundShape.id)
          if (!this.activeShapes().includes(table)) {
            this.actionHandler.select({
              type: SELECTION_TYPE.ADD,
              ids: [table.id],
            })
          }
        }
      }
      break
    case Editor.STATE.EDIT:
    case Editor.STATE.SELECTION_ONLY:
    case Editor.STATE.SITES_OR_SEARCH_RESULTS:
      this.selectSingleShape(event, position)
      if (event?.target?.relationId) {
        this.actionHandler.select({
          type: SELECTION_TYPE.CLEAR,
        })
      }
      break
    case Editor.STATE.ADD_REGIONS:
      if (this.draft == null) {
        let newRegion = new Region(this.canvas, null, this.editorName)
        this.draft = newRegion
        newRegion.setState(Shape.STATE.FIRST_DRAW)
        newRegion.initCoord = { x: position.x, y: position.y }
      } else {
        if (
          isEqual(JSON.parse(JSON.stringify(position)), this.draft.initCoord)
        ) {
          this.draft = null
          break
        }
        const coordList = getRectCoords(position, this.draft.initCoord)
        coordList.map(c => {
          const newVertex = this.myFabric.getRegionCircle(
            c.x,
            c.y,
            this.draft.id
          )
          this.draft.addPoint(newVertex, this.draft.vertices.length, true)
        })
        const defaultStructureTag = this.settings.get('defaultStructureTag')

        if (defaultStructureTag != null) {
          this.draft.setStructureType(defaultStructureTag)
        }
        this.draft.setState(Shape.STATE.EDIT)
        const baselines = this.findBaselinesInArea(
          coordList.map(({ x, y }) => [x, y])
        )
        let parentGroups = []
        baselines.forEach(l => {
          const parent = this.getBaselineParent(l.id)
          if (parentGroups.find(l => l.parent === parent) == null) {
            parentGroups.push({
              parent: parent,
              lines: [],
            })
          }
          const group = parentGroups.find(l => l.parent === parent)
          group.lines.push(l)
        })

        //baselines must be sorted to make sure they are on the right index when undo action
        const sortedParentGroups = parentGroups.map(g => {
          return {
            ...g,
            lines: g.lines.toSorted(
              (a, b) =>
                g.parent.baselines.indexOf(b) - g.parent.baselines.indexOf(a)
            ),
          }
        })

        const index = this.expectedRegionIndex(this.draft.getMidPoint())

        const actions = [
          {
            type: ACTION_TYPE.ADD_REGION,
            blueprint: this.draft.getBlueprint(index),
            index: index,
          },
        ]

        sortedParentGroups.forEach(g => {
          g.lines.forEach(l => {
            actions.push({
              type: ACTION_TYPE.CHANGE_READING_ORDER,
              old_parent_id: g.parent.id,
              new_parent_id: this.draft.id,
              old_index: g.parent.baselines.indexOf(l),
              new_index: 0,
            })
          })
        })

        this.actionHandler.newAction({
          type: ACTION_TYPE.MULTI_ACTION,
          actions,
        })
        this.draft.removeFromCanvas()
        this.lastAddedRegionId = this.draft.id
        this.draft = null
      }
      break
    case Editor.STATE.ADD_TABLES:
      if (this.draft == null) {
        this.draft = { x: position.x, y: position.y }
      } else {
        let coordList = getRectCoords(position, this.draft)
        coordList.reverse()
        const last = coordList.pop()
        coordList = [last, ...coordList]
        const action = {
          type: ACTION_TYPE.ADD_TABLE,
          blueprint: {
            type: 'table',
            attributes: {
              id: this.idHandler.requestId('t'),
              custom: {
                readingOrder: '1',
              },
            },
            elements: {
              coords: coordList,
              cells: [
                {
                  attributes: {
                    row: 0,
                    col: 0,
                    rowSpan: 1,
                    colSpan: 1,
                    id: this.idHandler.requestId('c'),
                  },
                  elements: {
                    coords: coordList,
                    textLines: [],
                    cornerPoints: [0, 1, 2, 3],
                  },
                },
              ],
              text: '',
            },
          },
          index: this.regions.length,
        }
        this.actionHandler.newAction(action)
        this.draft = null
      }
      break
    case Editor.STATE.ADD_BASELINES:
      if (this.draft == null) {
        this.draft = [{ x: position.x, y: position.y }]
      } else {
        this.draft.push({ x: position.x, y: position.y })
      }
      this.canvas.remove(...this.prevBaselineElements)
      this.prevBaselineElements = this.draft.map(point =>
        this.myFabric.getBaselineCircle(point.x, point.y)
      )
      const polyVertices = this.draft.map(point => {
        return [point.x, point.y]
      })
      const { line: lineElement } = this.myFabric.getLineElements(polyVertices)
      this.prevBaselineElements.push(lineElement)
      this.canvas.add(...this.prevBaselineElements)
      break
    case Editor.STATE.VERTICAL_SPLIT:
    case Editor.STATE.HORIZONTAL_SPLIT:
    case Editor.STATE.CUSTOM_SPLIT:
      this.finishSplit()
      break
    case Editor.STATE.DUPLICATE:
      const xShift =
        this.lastMousePosition.x -
        this.prevDuplicate.initPosition.x -
        this.prevDuplicate.width / 2
      const yShift =
        this.lastMousePosition.y -
        this.prevDuplicate.initPosition.y -
        this.prevDuplicate.height / 2
      const index = this.getIndex()
      const blueprint = JSON.parse(
        JSON.stringify(this.activeShapes()[0].getBlueprint(index))
      )

      blueprint.attributes.id = this.idHandler.requestId('l')

      blueprint.elements.baselineCoords.forEach(c => {
        c.x = c.x + xShift
        c.y = c.y + yShift
      })
      blueprint.elements.polyCoords.forEach(c => {
        c.x = c.x + xShift
        c.y = c.y + yShift
      })

      blueprint.elements.words = []

      const parent = this.findParentByPolygon(blueprint.elements.baselineCoords)
      if (parent != null) {
        //log(parent)
        const index = parent.expectedIndex(blueprint.elements.baselineCoords)
        const action = {
          type: ACTION_TYPE.ADD_BASELINE,
          index: index,
          blueprint: blueprint,
          parent_id: parent.id,
        }
        this.actionHandler.newAction(action)
      }
      break
  }
  this.canvas.remove(this.selectionRect)
  this.selectionRect = null

  this.draft?.handleMouseUpEvent?.(this.lastMousePosition, this.controlPressed)
}

function keyDown(e) {
  protocolLog(`%cKey down (Layout): ${e.key}`, 'color: green')
  if (this.stateObject) {
    this.stateObject.onKeyDown(e)
  }
  if (this.canApplyStructureTagShortcut(e.key)) {
    this.handleStructureTagShortcut(e.key)
    return
  }
  switch (e.key) {
    case 'Delete':
    case 'Backspace': {
      if (this.selectedNode != null) {
        const relation = this.getRelation(this.selectedNode.relationId)
        const oldIds = relation.getIds()
        const newIds = [...oldIds]
        const currentIndex = oldIds.findIndex(
          id => id === this.selectedNode.shapeId
        )
        newIds.splice(currentIndex, 1)
        let action
        if (newIds.length > 0) {
          action = {
            type: ACTION_TYPE.UPDATE_RELATION_IDS,
            index: this.relations.indexOf(relation),
            old_ids: oldIds,
            new_ids: newIds,
          }
        } else {
          action = createAction(ACTION_TYPE.REMOVE_RELATION, { relation })
        }

        this.setState(Editor.STATE.EDIT)
        this.actionHandler.newAction(action)
        break
      }
      const action = this.getRemoveAction(this.activeShapes())
      this.actionHandler.select({
        type: SELECTION_TYPE.CLEAR,
      })
      if (action.actions.length > 0) {
        this.actionHandler.newAction(action)
      }

      break
    }
    case 'Escape':
      this.activeShapes().setState(Shape.STATE.EDIT)
      this.setState(Editor.STATE.EDIT)
      break
    case 'Enter':
      //e.preventDefault()
      switch (this.state) {
        case Editor.STATE.CUSTOM_SPLIT:
        case Editor.STATE.VERTICAL_SPLIT:
        case Editor.STATE.HORIZONTAL_SPLIT: {
          this.finishSplit()
          break
        }
      }
      if (this.state === Editor.STATE.ADD_RELATION) {
        this.finishRelation()
        this.setState(Editor.STATE.EDIT)
      }
      if (this.draft == null) break
      if (this.draft.length > 1) {
        this.finishBaseline(this.draft)
      }
      this.draft = null
      break
    case 'z':
      if (e.ctrlKey || e.metaKey) {
        this.setState(Editor.STATE.EDIT)
        if (e.shiftKey) {
          this.actionHandler.redo()
        } else {
          this.actionHandler.undo()
        }
      }
      break
    case 'y':
      if (this.controlPressed) {
        e.preventDefault()
        this.setState(Editor.STATE.EDIT)
        this.actionHandler.redo()
      }
      break

    case 't':
      this.actionHandler.select({
        type: SELECTION_TYPE.CLEAR,
      })
      this.setState(Editor.STATE.ADD_TABLES)
      break
    case 'b':
      if (e.ctrlKey || e.metaKey) {
        e.preventDefault()
        this.selectLinesOfRegion()
        break
      }
      this.actionHandler.select({
        type: SELECTION_TYPE.CLEAR,
      })
      this.setState(Editor.STATE.ADD_BASELINES)
      break
    case 'r':
      this.actionHandler.select({
        type: SELECTION_TYPE.CLEAR,
      })
      this.setState(Editor.STATE.ADD_REGIONS)
      break
    case 'o':
      this.activeShapes().setState(Shape.STATE.EDIT)
      this.setState(Editor.STATE.EDIT)
      break
    case 'p':
      this.activeShapes().setState(Shape.STATE.EDIT)
      this.actionHandler.select({
        type: SELECTION_TYPE.CLEAR,
      })
      this.setState(Editor.STATE.DRAG)
      break

    case 'q':
      this.vueFunctions.buttonClick('zoomIn')
      break

    case 'v':
      if (this.controlPressed) {
        //this.readFromClipboard()
        break
      }
      if (this.state != Editor.STATE.VERTICAL_SPLIT) {
        this.setState(Editor.STATE.VERTICAL_SPLIT)
      }
      break
    case 'h':
      if (this.state != Editor.STATE.HORIZONTAL_SPLIT) {
        this.setState(Editor.STATE.HORIZONTAL_SPLIT)
      }
      break

    case 'a':
      if (this.controlPressed) {
        e.preventDefault()
        if (this.regions.length > 0) {
          this.actionHandler.select({
            type: SELECTION_TYPE.CLEAR,
          })
          this.actionHandler.select({
            type: SELECTION_TYPE.ADD,
            ids: this.allShapesAndCells().map(r => r.id),
          })
        }
        break
      }
      if (this.activeShapes().haveState(Shape.STATE.EDIT)) {
        this.activeShapes().map(s => {
          s.lastMousePosition = this.lastMousePosition
        })
        this.activeShapes().setState(Shape.STATE.ADD_VERTEX)
        this.newVertexState = true
        break
      }
      switch (this.state) {
        case Editor.STATE.CUSTOM_SPLIT:
        case Editor.STATE.VERTICAL_SPLIT:
        case Editor.STATE.HORIZONTAL_SPLIT: {
          if (this.controlPressed) {
            e.preventDefault()
            this.moveSplitLine({ left: true }, 0.02 * this.splitLineFactor)
          } else {
            this.moveSplitLine({ left: true }, 2 * this.splitLineFactor)
            this.increaseSplitLineFactor()
          }
          break
        }
      }
      break
    case 'c':
      if (this.controlPressed) {
        this.writeToClipboard(this.activeShapes())
        break
      }
      if (this.state != Editor.STATE.CUSTOM_SPLIT) {
        this.setState(Editor.STATE.CUSTOM_SPLIT)
      }
      break
    case 'x':
      if (this.controlPressed) {
        this.writeToClipboard(this.activeShapes()).then(() => {
          const action = this.getRemoveAction(this.activeShapes())
          this.actionHandler.select({
            type: SELECTION_TYPE.CLEAR,
          })
          this.actionHandler.addAction(action)
          this.actionHandler.doAction(action)
        })

        break
      }
      break

    case 'Shift':
      if (!this.shifted) {
        this.shifted = true
        this.setState(Editor.STATE.GROUP_SELECTION)
      }
      break
    case 'Meta':
    case 'Control':
      if (!this.controlPressed) {
        this.controlPressed = true
        if (this.state === Editor.STATE.EDIT) {
          this.setState(Editor.STATE.MULTI_SELECT)
        }
        //this.vueFunctions.setDrag(false)
      }
      break
    // case 'd':
    //   if (this.activeShape && this.activeShape.state === Shape.STATE.EDIT) {
    //     this.activeShape.setState(Shape.STATE.REMOVE_VERTEX)
    //   }
    //   break
    case 'l':
      let actions = []
      this.regions.map(region => {
        let action = this.mergeSmallLines(region)
        if (action) actions.push(action)
      })
      if (actions.length > 0) {
        this.actionHandler.addAction({
          type: ACTION_TYPE.MULTI_ACTION,
          actions: actions,
        })
      }
      break
    case 'm':
      this.merge()
      break
    case 'Tab':
      e.preventDefault()
      if (e.shiftKey) {
        this.setState(Editor.STATE.EDIT)
        this.selectPrevious()
        break
      }
      this.selectNext()
      break
    case 'ArrowUp':
      this.selectPrevious()
      break
    case 'ArrowDown':
      this.selectNext()
      break
    case 'ArrowLeft':
      switch (this.state) {
        case Editor.STATE.CUSTOM_SPLIT:
        case Editor.STATE.VERTICAL_SPLIT:
        case Editor.STATE.HORIZONTAL_SPLIT: {
          if (this.prevLine == null) break

          let angle = this.prevLine.angle
          if (this.controlPressed) {
            e.preventDefault()
            angle -= 0.02 * this.splitLineFactor
          } else {
            angle -= 1 * this.splitLineFactor
          }

          if (angle < 0) {
            angle = 359
          }
          this.increaseSplitLineFactor()
          this.prevLine.set({ angle })
          this.canvas.requestRenderAll()
        }
      }
      break
    case 'ArrowRight':
      switch (this.state) {
        case Editor.STATE.CUSTOM_SPLIT:
        case Editor.STATE.VERTICAL_SPLIT:
        case Editor.STATE.HORIZONTAL_SPLIT: {
          if (this.prevLine == null) break

          let angle = this.prevLine.angle

          if (this.controlPressed) {
            e.preventDefault()
            angle = (angle + 0.02 * this.splitLineFactor) % 360
          } else {
            angle = (angle + 1 * this.splitLineFactor) % 360
          }

          this.increaseSplitLineFactor()
          this.prevLine.set({ angle })
          this.canvas.requestRenderAll()
        }
      }
      break
    case 'w':
      switch (this.state) {
        case Editor.STATE.CUSTOM_SPLIT:
        case Editor.STATE.VERTICAL_SPLIT:
        case Editor.STATE.HORIZONTAL_SPLIT: {
          if (this.controlPressed) {
            e.preventDefault()
            this.moveSplitLine({ top: true }, 0.02 * this.splitLineFactor)
          } else {
            this.moveSplitLine({ top: true }, 2 * this.splitLineFactor)
            this.increaseSplitLineFactor()
          }
          break
        }
        default:
          this.vueFunctions.buttonClick('zoomOut')
      }
      break
    case 'e': {
      this.vueFunctions.buttonClick('center')
      break
    }
    case 'f': {
      this.vueFunctions.buttonClick('fitToWidth')
      break
    }
    case 'g': {
      this.vueFunctions.buttonClick('rotate')
      break
    }
    case 's':
      if (this.controlPressed) {
        e.preventDefault()
        this.vueFunctions.save()
        break
      }
      switch (this.state) {
        case Editor.STATE.CUSTOM_SPLIT:
        case Editor.STATE.VERTICAL_SPLIT:
        case Editor.STATE.HORIZONTAL_SPLIT: {
          if (this.controlPressed) {
            e.preventDefault()
            this.moveSplitLine({ bottom: true }, 0.02 * this.splitLineFactor)
          } else {
            this.moveSplitLine({ bottom: true }, 2 * this.splitLineFactor)
            this.increaseSplitLineFactor()
          }
          break
        }
      }
      break
    case 'd':
      switch (this.state) {
        case Editor.STATE.CUSTOM_SPLIT:
        case Editor.STATE.VERTICAL_SPLIT:
        case Editor.STATE.HORIZONTAL_SPLIT: {
          if (this.controlPressed) {
            e.preventDefault()
            this.moveSplitLine({ right: true }, 0.02 * this.splitLineFactor)
          } else {
            this.moveSplitLine({ right: true }, 2 * this.splitLineFactor)
            this.increaseSplitLineFactor()
          }
          break
        }
      }
      if (
        this.activeShapes().isSingle() &&
        this.activeShapes()[0].activeVertex != null
      ) {
        const vertexId = this.activeShapes()[0].activeVertex.id
        if (this.pointRemoveAllowed(vertexId)) {
          this.removePointWithAction(vertexId)
        }
      }
      break
  }
}

function paste(e) {
  e.stopPropagation()
  e.preventDefault()

  const clipboardData = e.clipboardData || window.clipboardData
  const pastedData = clipboardData.getData('Text')
  this.parsePastedString(pastedData)
}

function keyUp(e) {
  protocolLog(`%cKey up: ${e.key}`, 'color: green')
  e.preventDefault()
  switch (e.key) {
    case 'ArrowLeft':
    case 'ArrowRight':
      this.resetSplitLineFactor()
      /*       if (this.state === Editor.STATE.CUSTOM_SPLIT) {
        if (this.prevLine == null) break
        this.settings.set({ splitLineAngle: this.prevLine.angle }, 'noUpdate')
      } */
      break

    case 'Shift':
      this.shifted = false
      this.setState(Editor.STATE.EDIT)
      break
    case 'Meta':
    case 'Control':
      this.controlPressed = false
      if (this.state === Editor.STATE.MULTI_SELECT) {
        this.setState(Editor.STATE.EDIT)
      }
      //this.vueFunctions.setDrag(true)
      break
    case 'w':
    case 'a':
    case 's':
    case 'd':
      if (this.activeShapes().haveState(Shape.STATE.ADD_VERTEX)) {
        this.newVertexState = false
        this.activeShapes().setState(Shape.STATE.EDIT)
      }
      switch (this.state) {
        case Editor.STATE.CUSTOM_SPLIT:
        case Editor.STATE.VERTICAL_SPLIT:
        case Editor.STATE.HORIZONTAL_SPLIT: {
          this.resetSplitLineFactor()
        }
      }
      break
    case 'v':
      if (this.state === Editor.STATE.VERTICAL_SPLIT) {
        this.setState(Editor.STATE.EDIT)
      }
      break
    case 'c':
      if (this.state === Editor.STATE.CUSTOM_SPLIT) {
        this.setState(Editor.STATE.EDIT)
      }
      break
    case 'h':
      if (this.state === Editor.STATE.HORIZONTAL_SPLIT) {
        this.setState(Editor.STATE.EDIT)
      }
      break
    // case 'd':
    //   if (
    //     this.activeShape &&
    //     this.activeShape.state === Shape.STATE.REMOVE_VERTEX
    //   ) {
    //     this.activeShape.setState(Shape.STATE.EDIT)
    //   }
    //   break
  }
}

function correctAttrVal(value, parent) {
  if (value.imgUrl) {
    value.imgUrl = value.imgUrl.replaceAll('&', '&amp;')
  }
  return value
}

export default Editor
