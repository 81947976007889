<template>
  <div
    class="w-[98%] flex flex-col justify-center items-center rounded mt-2"
    :class="{
      'selected-region': selected,
      draggable,
    }"
    :id="id"
    :style="{ 'background-color': selected ? getBackgroundColor() : '' }"
  >
    <RegionHeader
      @mouseUp="$emit('mouseUpWrapper', id)"
      v-if="!isSites"
      :draggable="draggable"
      :structureType="structureType"
      :structureLevel="structureLevel"
      :index="index"
    />
    <span v-else class="h-[10px] w-full"></span>
    <div
      class="w-full"
      :class="{
        'p-2 bg-white': draggable,
      }"
    >
      <slot :lines="children" :id="id"></slot>
      <BaseButton
        v-if="showNewLineButton() && children.length === 0"
        label="Add text"
        @click="$emit('addNewLineToEmptyParent', id)"
      />
    </div>
  </div>
</template>

<script>
import TextLine from './TextLine.vue'
import RegionHeader from './RegionHeader.vue'
import {
  Singleton as ActionHandler,
  ACTION_TYPE,
  SELECTION_TYPE,
} from '../LayoutEditor/components/singletons/actionHandler.js'
import { Singleton as MyFabric } from '../LayoutEditor/components/myFabric.js'
import { Singleton as Settings } from '../LayoutEditor/components/singletons/settings.js'

export default {
  components: {
    TextLine,
    RegionHeader,
  },
  props: {
    editorName: {
      type: String,
    },
    draggable: {
      type: Boolean,
    },
    id: {
      type: String,
    },
    selected: {
      type: Boolean,
    },
    index: {
      type: Number,
    },
    isSites: {
      type: Boolean,
    },
    children: {
      type: Array,
    },
    structureType: {
      type: String,
    },
    structureLevel: {
      type: String,
    },
  },
  data() {
    return {}
  },

  methods: {
    showNewLineButton() {
      return Settings.instance().get('danWriting')
    },
    getBackgroundColor() {
      return MyFabric.instance().getRegionColor(0.1)
    },
    /*  mouseUp() {
      ActionHandler.instance(this.editorName).select({
        type: SELECTION_TYPE.CLEAR,
      })
      ActionHandler.instance(this.editorName).select({
        type: SELECTION_TYPE.ADD,
        ids: [this.id],
      })
    }, */
  },
}
</script>

<style scoped lang="scss">
.selected {
  outline: 0px solid transparent;
}

.selected-region {
  outline: 0px solid transparent;
}

.draggable {
  @apply bg-[#F3F3F3] m-[1px] cursor-pointer p-2;
}
</style>
