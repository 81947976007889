import {
  lineInterpolate,
  pointInPolygon,
  polygonMean,
  pointRotate,
} from 'geometric'
import Offset from 'polygon-offset'
import { segment, Point, Polygon } from '@flatten-js/core'
import { Singleton as IdHandler } from './singletons/IdHandler.js'
import { Singleton as MyFabric } from './myFabric.js'

//From https://stackoverflow.com/questions/5306680/move-an-array-element-from-one-array-position-to-another
function array_move(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1
    while (k--) {
      arr.push(undefined)
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
}

function array_move_block(arr, startIndex, endIndex, newIndex) {
  if (startIndex < 0 || endIndex >= arr.length || startIndex > endIndex) {
    throw new Error('Invalid indices')
  }

  // Extract the block of elements
  const block = arr.splice(startIndex, endIndex - startIndex + 1)

  // Adjust newIndex if it's after removal
  if (newIndex > startIndex) {
    newIndex -= block.length - 1
  }

  // Insert block at the new position
  arr.splice(newIndex, 0, ...block)
}

function projectPointer(lines, pointer) {
  const min = MyFabric.instance().getCircleSize() * 2

  let point = null
  let foundLine = lines.find(line => {
    let enoughDistance = false
    let distToFirst = distanceBetween(line.coords[0], [pointer.x, pointer.y])
    let distToSecond = distanceBetween(line.coords[1], [pointer.x, pointer.y])

    if (distToFirst > min && distToSecond > min) {
      enoughDistance = true
    }

    if (
      pointInPolygon([pointer.x, pointer.y], line.polygon) &&
      enoughDistance
    ) {
      let interpolator = lineInterpolate(line.coords)
      let sum = distToFirst + distToSecond
      let ratio = distToFirst / sum
      point = interpolator(ratio)

      return true
    }
    return false
  })
  return { line: foundLine, point: point }
}

function getOffset() {
  return MyFabric.instance().getAddPointOffset()
}

function calcLines(vertices) {
  const areDifferent = coords =>
    coords[0][0] != coords[1][0] || coords[0][1] != coords[1][1]

  if (vertices.length < 2) {
    return []
  }
  let offset = new Offset()
  let lines = []
  vertices.reduce((first, second) => {
    let coords = [
      [first.left, first.top],
      [second.left, second.top],
    ]
    if (areDifferent(coords)) {
      let polygon = offset.data(coords).margin(getOffset())[0]
      lines.push({
        coords: coords,
        polygon: polygon,
        parentIds: [first.id, second.id],
      })
    }
    return second
  })
  let coords = [
    [vertices[vertices.length - 1].left, vertices[vertices.length - 1].top],
    [vertices[0].left, vertices[0].top],
  ]
  if (areDifferent(coords)) {
    let polygon = offset.data(coords).margin(getOffset())[0]
    lines.push({
      coords: coords,
      polygon: polygon,
      parentIds: [vertices[vertices.length - 1].id, vertices[0].id],
    })
  }
  return lines
}

function calcLinesTable(points) {
  const areDifferent = coords =>
    coords[0][0] != coords[1][0] || coords[0][1] != coords[1][1]

  if (points.length < 2) {
    return []
  }
  let offset = new Offset()
  let lines = []
  points.reduce((first, second) => {
    let coords = [
      [first.x, first.y],
      [second.x, second.y],
    ]
    if (areDifferent(coords)) {
      let polygon = offset.data(coords).margin(getOffset())[0]
      lines.push({
        coords: coords,
        polygon: polygon,
        parentIds: [first.id, second.id],
      })
    }
    return second
  })
  let coords = [
    [points[points.length - 1].x, points[points.length - 1].y],
    [points[0].x, points[0].y],
  ]
  if (areDifferent(coords)) {
    let polygon = offset.data(coords).margin(getOffset())[0]
    lines.push({
      coords: coords,
      polygon: polygon,
      parentIds: [points[points.length - 1].id, points[0].id],
    })
  }
  return lines
}

function isLineInConvexRegion(polygonCoords, region, minOverlapPercent = 90) {
  const linePolygon = new Polygon(
    polygonCoords.map(({ x, y }) => new Point(x, y))
  )

  let clipArea
  if (Array.isArray(region)) {
    clipArea = region
  } else if ('row' in region) {
    //reverse the coordinates if the region is a cell
    clipArea = [...region.getArea()].reverse()
  } else {
    clipArea = region.getArea()
  }

  const clippedLine = sutherlandHodgmanClip(
    polygonCoords.map(({ x, y }) => [x, y]),
    clipArea
  )

  const clippedPolygon = new Polygon(
    clippedLine.map(([x, y]) => new Point(x, y))
  )

  const initArea = linePolygon.area()
  const clippedArea = clippedPolygon.area()

  if ((clippedArea / initArea) * 100 >= minOverlapPercent) return true
  return false

  //const points = regionPolygon.intersect(linePolygon)

  //instead of shape.vertices split the polygon of the baseline

  //recursevly cut the baseline polgyon (with the different intersection points) over and over until the inner part of the baseline remains. Compare this area to the total area
}

//source: https://rosettacode.org/wiki/Sutherland-Hodgman_polygon_clipping#JavaScript
function sutherlandHodgmanClip(subjectPolygon, clipPolygon) {
  var cp1, cp2, s, e
  var inside = function (p) {
    return (
      (cp2[0] - cp1[0]) * (p[1] - cp1[1]) > (cp2[1] - cp1[1]) * (p[0] - cp1[0])
    )
  }
  var intersection = function () {
    var dc = [cp1[0] - cp2[0], cp1[1] - cp2[1]],
      dp = [s[0] - e[0], s[1] - e[1]],
      n1 = cp1[0] * cp2[1] - cp1[1] * cp2[0],
      n2 = s[0] * e[1] - s[1] * e[0],
      n3 = 1.0 / (dc[0] * dp[1] - dc[1] * dp[0])
    return [(n1 * dp[0] - n2 * dc[0]) * n3, (n1 * dp[1] - n2 * dc[1]) * n3]
  }
  var outputList = subjectPolygon
  cp1 = clipPolygon[clipPolygon.length - 1]
  for (var j in clipPolygon) {
    cp2 = clipPolygon[j]
    var inputList = outputList
    outputList = []
    s = inputList[inputList.length - 1] //last on the input list
    for (var i in inputList) {
      e = inputList[i]
      if (inside(e)) {
        if (!inside(s)) {
          outputList.push(intersection())
        }
        outputList.push(e)
      } else if (inside(s)) {
        outputList.push(intersection())
      }
      s = e
    }
    cp1 = cp2
  }
  return outputList
}

function getSplitCoords(
  initVertices,
  geometricLines,
  splitLine,
  editorName = 'editor1'
) {
  //TODO:refactor - input parameters should be vertices and lines
  let vertices = initVertices.map(v => ({
    id: v.id,
    x: v.left,
    y: v.top,
  }))
  let splitList = []
  let splitSegment = segment(
    splitLine[0][0],
    splitLine[0][1],
    splitLine[1][0],
    splitLine[1][1]
  )

  geometricLines.map(line => {
    let lineSegment = segment(...line.coords.flat())
    let intersectionPoint = lineSegment
      .intersect(splitSegment)
      .map(({ x, y }) => ({
        x,
        y,
        id: IdHandler.instance(editorName).requestId('line_segment'),
      }))[0]

    if (intersectionPoint) {
      let vertexAfterId = line.parentIds[1]
      let afterIntersection = false
      let newSplit = []
      vertices = vertices.filter(v => {
        if (v.id === vertexAfterId) {
          afterIntersection = true
        }
        if (!afterIntersection) {
          newSplit.push(v)
        }
        return afterIntersection
      })
      vertices = [intersectionPoint].concat(vertices)
      newSplit = newSplit.concat([intersectionPoint])
      splitList.push({ vertices: newSplit })
    }
  })
  splitList.push({ vertices: vertices })
  return splitList
}

function getSplitCoordsRegion(regionCoords, splitLine) {
  const clipArea1 = [[0, 0], ...splitLine, [0, 10000]]
  const clipArea2 = [[10000, 0], [10000, 1000], ...splitLine.toReversed()]

  const clippedRegion1 = sutherlandHodgmanClip(
    regionCoords.map(({ x, y }) => [x, y]),
    clipArea1
  )

  const clippedRegion2 = sutherlandHodgmanClip(
    regionCoords.map(({ x, y }) => [x, y]),
    clipArea2
  )

  // Initialize the object with the "vertices" key
  let formatted1 = {
    vertices: [],
  }
  let formatted2 = {
    vertices: [],
  }

  // Loop through the array and convert each pair into the desired format
  clippedRegion1.forEach(pair => {
    formatted1.vertices.push({
      x: pair[0],
      y: pair[1],
    })
  })
  clippedRegion2.forEach(pair => {
    formatted2.vertices.push({
      x: pair[0],
      y: pair[1],
    })
  })
  return [formatted1, formatted2]
}

function whatSideOfLine(coords, line) {
  let polygon = coords.map(v => [v.x, v.y])
  let point = polygonMean(polygon)

  return Math.sign(
    (line[1][0] - line[0][0]) * (point[1] - line[0][1]) -
      (line[1][1] - line[0][1]) * (point[0] - line[0][0])
  )
}

function sortCellVertices(cell) {
  let topLeft = {}
  let smalestX = Infinity
  let smalestY = Infinity
  cell.vertices.map(vertex => {
    if (vertex.left < smalestX) {
      smalestX = vertex.left
      topLeft = vertex
    }
  }, [])
  return
}

function getRectCoords(position, initCoord) {
  /*   let coordsList = []
  coordsList.push({ x: position.x, y: position.y })

  if (
    (position.x > initCoord.x && position.y > initCoord.y) ||
    (position.x < initCoord.x && position.y < initCoord.y)
  ) {
    coordsList.push({ x: initCoord.x, y: position.y })
  } else {
    coordsList.push({ x: position.x, y: initCoord.y })
  }

  coordsList.push({ x: initCoord.x, y: initCoord.y })

  if (
    (position.x > initCoord.x && position.y > initCoord.y) ||
    (position.x < initCoord.x && position.y < initCoord.y)
  ) {
    coordsList.push({ x: position.x, y: initCoord.y })
  } else {
    coordsList.push({ x: initCoord.x, y: position.y })
  }

  return coordsList */
  const minX = Math.min(position.x, initCoord.x)
  const minY = Math.min(position.y, initCoord.y)
  const maxX = Math.max(position.x, initCoord.x)
  const maxY = Math.max(position.y, initCoord.y)

  const topLeft = { x: minX, y: minY }
  const topRight = { x: maxX, y: minY }
  const bottomLeft = { x: minX, y: maxY }
  const bottomRight = { x: maxX, y: maxY }

  return [topLeft, topRight, bottomRight, bottomLeft]
}

// function sortMergeOrder(shape1, shape2) {
//   let shape1First = shape1.vertices[0]
//   let shape1Last = shape1.vertices[shape1.vertices.length - 1]
//   let shape2First = shape2.vertices[0]
//   let shape2Last = shape2.vertices[shape2.vertices.length - 1]
//   let dist1 = distanceBetween(
//     shape1First.left,
//     shape1First.top,
//     shape2Last.left,
//     shape2Last.top
//   )
//   let dist2 = distanceBetween(
//     shape1Last.left,
//     shape1Last.top,
//     shape2First.left,
//     shape2First.top
//   )

//   if (dist1 < dist2) {
//     return [shape2, shape1]
//   } else {
//     return [shape1, shape2]
//   }
// }

function getMergePartners(line, candidates, scaleFactor, canvasDimensions) {
  const inPixels = number => number / scaleFactor

  let pixelsMinYDistance = inPixels(canvasDimensions.height) * 0.005
  let pixelsMinXDistance = inPixels(canvasDimensions.width) * 0.04
  let candidateMaxLineLength = inPixels(canvasDimensions.width) * 0.05
  let lineMinLineLength = inPixels(canvasDimensions.width) * 0.05

  const getLineLength = line => {
    return line.vertices.reduce(
      (acc, vertex) => {
        if (acc.last === null) {
          return { last: vertex, length: acc.length }
        }
        return {
          last: vertex,
          length:
            acc.length +
            distanceBetween(
              acc.last.left,
              acc.last.top,
              vertex.left,
              vertex.top
            ),
        }
      },
      { last: null, length: 0 }
    ).length
  }

  const areClose = (lineA, lineB) => {
    let firstA = lineA.vertices[0]
    let lastA = lineA.vertices[lineA.vertices.length - 1]

    let firstB = lineB.vertices[0]
    let lastB = lineB.vertices[lineB.vertices.length - 1]

    let distances = [
      {
        left: Math.abs(firstA.left - lastB.left),
        top: Math.abs(firstA.top - lastB.top),
      },
      {
        left: Math.abs(lastA.left - firstB.left),
        top: Math.abs(lastA.top - firstB.top),
      },
    ]
    let distInPixels = distances.map(d => ({
      left: inPixels(d.left),
      top: inPixels(d.top),
    }))

    return distInPixels.find(
      d => d.left < pixelsMinXDistance && d.top < pixelsMinYDistance
    )
      ? true
      : false
  }

  const shortCandiate = candidate => {
    return inPixels(getLineLength(candidate)) < candidateMaxLineLength
  }

  const longLine = line => {
    return inPixels(getLineLength(line)) > lineMinLineLength
  }

  if (longLine(line)) {
    let partners = candidates.filter(candidate => {
      return areClose(line, candidate) && shortCandiate(candidate)
    })

    if (partners.length > 0) {
      return [line, ...partners]
    }
  }
}

function getClosestLinePair(lineBlueprints) {
  //found here https://stackoverflow.com/questions/22566379/how-to-get-all-pairs-of-array-javascript
  const pairsOfArray = array =>
    array.reduce(
      (acc, val, i1) => [
        ...acc,
        ...new Array(array.length - 1 - i1)
          .fill(0)
          .map((v, i2) => [array[i1], array[i1 + 1 + i2]]),
      ],
      []
    )

  let lineObjects = lineBlueprints.map(line => ({
    line: line,
    first: line.elements.baselineCoords?.[0],
    last: line.elements.baselineCoords?.[
      line.elements.baselineCoords.length - 1
    ],
  }))

  let pairs = pairsOfArray(lineObjects)

  let closest = pairs.reduce(
    (closest, pair) => {
      let dist1 = distanceBetween(
        pair[0].last.y,
        pair[0].last.x,
        pair[1].first.y,
        pair[1].first.x
      )
      let dist2 = distanceBetween(
        pair[1].last.y,
        pair[1].last.x,
        pair[0].first.y,
        pair[0].first.x
      )
      let newDist = dist1 < dist2 ? dist1 : dist2
      let newPair = dist1 < dist2 ? [pair[0], pair[1]] : [pair[1], pair[0]]
      return newDist < closest.dist ? { pair: newPair, dist: newDist } : closest
    },
    { dist: Infinity }
  )

  let newLines = closest.pair.map(pair => pair.line)
  return newLines
}

function distanceBetween(c, d, e, f) {
  let x1 = c
  let y1 = d
  let x2 = e
  let y2 = f
  if (e == null && f == null) {
    x1 = c[0]
    y1 = c[1]
    x2 = d[0]
    y2 = d[1]
  }
  let a = x1 - x2
  let b = y1 - y2

  return Math.sqrt(a * a + b * b)
}

function createSplitline(origin, angle, length) {
  const initPoint = [origin[0] + length / 2, origin[1]]
  const newEndPoint = pointRotate(initPoint, angle, origin)
  const newStartPoint = pointRotate(newEndPoint, 180, origin)
  // const newTest = pointRotate([1, 1], 180, [0, 0])
  return [newStartPoint, newEndPoint]
}

function closedPoint(point, points) {
  if (points.length === 0) {
    return null // Return -1 if the array is empty
  }

  let minDistance = Number.MAX_VALUE
  let closestPoint = null

  for (let i = 0; i < points.length; i++) {
    const currentPoint = points[i]
    const distance = calculateDistance(point, currentPoint)

    if (distance < minDistance) {
      minDistance = distance
      closestPoint = currentPoint
    }
  }

  return closestPoint
}

function calculateDistance(point1, point2) {
  const xDiff = point1[0] - point2[0]
  const yDiff = point1[1] - point2[1]

  return Math.sqrt(xDiff * xDiff + yDiff * yDiff)
}

function pointRelativeLocation(point1, point2) {
  const [x1, y1] = point1
  const [x2, y2] = point2

  const xDistance = Math.abs(x2 - x1)
  const yDistance = Math.abs(y2 - y1)

  if (yDistance > xDistance) {
    if (y2 < y1) {
      return 'top'
    }
    return 'bottom'
  }
  if (x2 < x1) {
    return 'left'
  }
  return 'right'
}

function aFollowedByB(point1, point2) {
  const location = pointRelativeLocation(point1, point2)
  return location === 'bottom' || location === 'right'
}

export {
  aFollowedByB,
  pointRelativeLocation,
  closedPoint,
  createSplitline,
  projectPointer,
  calcLines,
  distanceBetween,
  getRectCoords,
  getClosestLinePair as getClosestLine,
  getSplitCoords,
  whatSideOfLine,
  getMergePartners,
  array_move,
  array_move_block,
  isLineInConvexRegion,
  calcLinesTable,
  getSplitCoordsRegion,
}
