import {
  projectPointer,
  calcLines,
  distanceBetween,
  getRectCoords,
} from '../geometryFunctions.js'
import Shape from './Shape.js'
import {
  polygonBounds,
  pointRotate,
  lineAngle,
  lineLength,
  pointTranslate,
  polygonMean,
} from 'geometric'
import { ACTION_TYPE } from '../singletons/actionHandler.js'
import { Singleton as IdHandler } from '../singletons/IdHandler.js'

import Baseline from './Baseline.js'
import { isEqual } from 'lodash'
import { getRotationAngle, getScaleFactor } from '../../globals.js'
import * as Sentry from '@sentry/vue'
import { getCoordsFromElement } from '../../../Model/modelUtils.ts'

function Region(
  canvas,
  blueprint,
  editorName,
  noUpdate,
  index,
  getActiveShapeIds
) {
  Shape.call(this, canvas, editorName)

  this.baselines = []
  this.line = {}
  this.background = {}
  this.group = {}
  this.area = null
  this.coords = []

  this.pointerOnLine = false
  this.invisibleVertex = null
  this.tempCoords = {}

  this.initCoord = null
  this.index = index
  this.getActiveShapeIds = getActiveShapeIds

  if (blueprint) {
    this.attributes = blueprint.attributes
    this.elements = blueprint.elements
    this.id = blueprint.attributes.id
    IdHandler.instance(this.editorName).addId(this.id)

    this.text = blueprint.elements.text
    this.baselines = blueprint.elements.textLines.map(
      (blueprint, index) =>
        new Baseline(
          this.canvas,
          blueprint,
          editorName,
          noUpdate,
          index,
          getActiveShapeIds
        )
    )

    if (!noUpdate) {
      this.update()
    }
  } else {
    this.id = IdHandler.instance(this.editorName).requestId('r')
  }
}
Region.prototype = Object.create(Shape.prototype)
Region.prototype.constructor = Region

Region.prototype.getBlueprint = function (myIndex) {
  if (!this.attributes || !this.elements) {
    this.elements = {
      coords: {},
      textLines: [],
      text: '',
    }
  }
  //this.attributes.id = keepId ? this.id : `r${myIndex + 1}`
  this.attributes.id = this.id
  this.setReadingOrder(myIndex)

  this.elements.textLines = this.baselines.map((line, index) =>
    line.getBlueprint(index)
  )
  this.elements.text = this.text

  let newCoords
  if (this.vertices.length > 0) {
    newCoords = this.vertices.map(v => {
      return { x: v.left, y: v.top }
    })
  } else {
    newCoords = this.getScaledCoords()
  }

  return {
    type: 'text',
    attributes: this.attributes,
    elements: {
      ...this.elements,
      coords: newCoords,
    },
  }
}

Region.prototype.getStructureType = function () {
  return this.attributes.custom?.structureType
}

Region.prototype.getType = function () {
  return Shape.TYPE.REGION
}

Region.prototype.getLayout = function () {
  return {
    type: 'Region',
    id: this.id,
    children: this.baselines.map(l => l.getLayout()),
    structureType: this.getStructureType(),
    custom: this.attributes.custom,
  }
}

Region.prototype.setState = function (state) {
  //never allow change of Selection only
  if (this.state === Shape.STATE.SELECTION_ONLY) return
  if (this.state === Shape.STATE.SEARCH_RESULTS) return

  // if (state === Shape.STATE.ADD_VERTEX) {
  //   this.lines = calcLines(this.vertices)
  // }
  // if (
  //   this.state === Shape.STATE.GROUP_SELECTION &&
  //   state != Shape.STATE.GROUP_SELECTION
  // ) {
  //   this.unGroupSelect()
  // }
  // if (
  //   this.state === Shape.STATE.LARGE_GROUP_SELECTION &&
  //   state != Shape.STATE.LARGE_GROUP_SELECTION
  // ) {
  //   this.unLargeGroupSelect()
  // }
  // if (
  //   state === Shape.STATE.GROUP_SELECTION &&
  //   this.state != Shape.STATE.GROUP_SELECTION
  // ) {
  //   this.groupSelect()
  // }
  // if (
  //   state === Shape.STATE.LARGE_GROUP_SELECTION &&
  //   this.state != Shape.STATE.LARGE_GROUP_SELECTION
  // ) {
  //   this.largeGroupSelect()
  // }
  if (this.activeVertex) {
    this.activeVertex.unSelect()
  }
  // if (this.vertices.length > 1) {
  //   this.lines = calcLines(this.vertices)
  // }
  this.state = state
}

Region.prototype.adjustToBaseline = function (polygonCoords) {
  let coordsArrays = polygonCoords.map(coord => {
    return [coord.x, coord.y]
  })

  let bounds = polygonBounds(coordsArrays)

  let coords = [
    { x: bounds[0][0], y: bounds[0][1] },
    { x: bounds[1][0], y: bounds[0][1] },
    { x: bounds[1][0], y: bounds[1][1] },
    { x: bounds[0][0], y: bounds[1][1] },
  ]
  coords.map(coord => {
    let newVertex = this.getVertex(coord.x, coord.y)
    this.addPoint(newVertex, this.vertices.length)
  })
}

Region.prototype.removeFromCanvas = function () {
  this.canvas.remove(...this.vertices)
  this.canvas.remove(this.background)
  this.canvas.remove(this.line)
  this.canvas.remove(this.previewRect)
  this.canvas.remove(this.runningVertex)
  this.canvas.remove(this.structureText)
  this.canvas.remove(this.structureColorFrame)
  this.canvas.remove(this.readingOrderText)
  this.canvas.discardActiveObject()
}

Region.prototype.removeChildsFromCanvas = function () {
  this.baselines.map(bl => {
    bl.unSelect()
    bl.removeFromCanvas()
    bl.removeChildsFromCanvas()
  })
}

Region.prototype.mark = function () {
  this.background.set('opacity', 0.1)
  this.canvas.contains(this.background) || this.canvas.add(this.background)
}

Region.prototype.unMark = function () {
  this.background.set('opacity', 0.2)
  this.canvas.remove(this.background)
}

Region.prototype.select = function () {
  this.selected = true
  this.update()
}

Region.prototype.unSelect = function () {
  this.selected = false
  this.canvas.remove(...this.vertices)
  this.canvas.remove(this.background)
  this.canvas.remove(this.previewRect, this.runningVertex)
  this.updateStructureText()
}

// Region.prototype.groupSelect = function () {
//   this.removeFromCanvas()
//   this.group = this.getGroup()

//   this.tempCoords = this.myFabric.getGroupData(this.group)

//   this.canvas.add(this.group)
//   this.canvas.setActiveObject(this.group)
// }

// Region.prototype.unGroupSelect = function () {
//   this.canvas.remove(this.group)
//   this.canvas.discardActiveObject()

//   let coords = this.myFabric.getGroupData(this.group)

//   if (!isEqual(this.tempCoords, coords)) {

//     this.modified = true
//     this.actionHandler.addAction({
//       type: ACTION_TYPE.MOVE_SHAPE,
//       shape_id: this.id,
//       old_coords: this.tempCoords.vertices,
//       new_coords: coords.vertices,
//     })
//     this.tempCoords = null
//   }
//   this.moveAllPoints(coords.vertices)
// }

// Region.prototype.largeGroupSelect = function () {
//   this.removeFromCanvas()
//   this.baselines.map((line) => line.removeFromCanvas())
//   this.group = this.getLargeGroup()

//   this.tempCoords = this.myFabric.getGroupData(this.group)

//   this.canvas.add(this.group)
//   this.canvas.setActiveObject(this.group)
// }

// Region.prototype.unLargeGroupSelect = function () {
//   this.canvas.remove(this.group)
//   this.canvas.discardActiveObject()

//   let coords = this.myFabric.getGroupData(this.group)

//   if (!isEqual(this.tempCoords, coords)) {

//     this.actionHandler.addAction({
//       type: ACTION_TYPE.MULTI_ACTION,
//       actions: this.baselines
//         .map((line, index) => ({
//           type: ACTION_TYPE.MOVE_SHAPE,
//           shape_id: line.id,
//           old_coords: this.tempCoords.groups[index].vertices,
//           new_coords: coords.groups[index].vertices,
//         }))
//         .concat({
//           type: ACTION_TYPE.MOVE_SHAPE,
//           shape_id: this.id,
//           old_coords: this.tempCoords.vertices,
//           new_coords: coords.vertices,
//         }),
//     })
//   }
//   this.modified = true
//   this.moveAllPoints(coords.vertices)
//   this.baselines.map((line, index) => {
//     line.moveAllPoints(coords.groups[index].vertices)
//   })
//   this.tempCoords = null
// }

Region.prototype.getGroup = function () {
  let vertices = this.vertices.map(v =>
    this.myFabric.clone(v).set({ radius: 0 })
  )
  let background = this.myFabric.clone(this.background)
  let members = vertices.concat(background)

  return new fabric.Group(members, {
    id: this.id,
    hasControls: true,
    subTargetCheck: true,
    cornerStyle: 'circle',
    padding: 0,
    cornerSize: 8,
    cornerColor: 'transparent',
    transparentCorners: true,
    cornerStrokeColor: 'green',
    lockSkewingX: true,
    lockSkewingY: true,
  }).setControlsVisibility({
    mtr: false,
  })
}

Region.prototype.getLargeGroup = function () {
  // let vertices = this.vertices.map((v) => this.myFabric.clone(v))
  // let background = this.myFabric.clone(this.background)
  // let lines = this.baselines.map((line) => line.getGroup())
  // let members = vertices.concat(background).concat(lines)
  // return new fabric.Group(members, {
  //   id: this.id,
  //   hasControls: false,
  // })
}

Region.prototype.addBaseline = function (line, index) {
  if (index == null) {
    index = this.expectedIndex(line)
  }
  this.baselines.splice(index, 0, line)
}

Region.prototype.removeBaseline = function (id) {
  this.baselines = this.baselines.filter(l => l.id != id)
}

Region.prototype.expectedIndex = function (coords) {
  let newLineY = coords[0].y
  let yValues = this.baselines
    .map(parentLine => parentLine.baselineCoords?.[0]?.y)
    .sort(function (a, b) {
      return a - b
    })
  let newIndex = yValues.findIndex(value => value > newLineY)
  return newIndex === -1 ? yValues.length : newIndex
}

Region.prototype.getBaselines = function () {
  return [...this.baselines]
}

Region.prototype.showRed = function () {
  if (this.background.fill === this.background.defaultColor) {
    this.background.set('fill', this.background.deleteColor)
    this.canvas.add(this.background)
  }
}

Region.prototype.showNormal = function () {
  if (this.background.fill === this.background.deleteColor) {
    this.background.set('fill', this.background.defaultColor)
    this.canvas.remove(this.background)
  }
}

Region.prototype.updateVertices = function (coords) {
  this.canvas.remove(...this.vertices)
  if (coords == null) return
  if (!(this.selected && this.myFabric.isFocused)) return
  this.vertices = coords.map(coord => this.getVertex(coord.x, coord.y)) || []

  this.canvas.add(...this.vertices)
  /*  this.vertices.forEach(vertex => {
    if (vertex.radius != circleSize) {
      vertex.set({
        radius: circleSize,
        defaultRadius: circleSize,
      })
    }
    if (vertex.stroke != vertexColor || vertex.selectedColor != vertexColor) {
      vertex.set({
        stroke: vertexColor,
        selectedColor: vertexColor,
      })
      if (vertex === this.activeVertex) {
        vertex.set('fill', vertexColor)
      }
    }
    this.canvas.add(vertex)
  }) */
}

Region.prototype.getScaledCoords = function () {
  const factor = getScaleFactor()
  const rotationAngle = getRotationAngle()
  const coords = getCoordsFromElement(this.elements.json)
  if (coords == null) return
  return coords.map(c => {
    const scaled = pointRotate([c.x * factor, c.y * factor], rotationAngle)
    return { x: scaled[0], y: scaled[1] }
  })
}

Region.prototype.getMidPoint = function () {
  let coords
  if (this.vertices.length > 0) {
    coords = this.vertices.map(v => [v.left, v.top])
  } else {
    coords = this.getScaledCoords().map(c => [c.x, c.y])
  }
  return polygonMean(coords)
}

Region.prototype.updateLineAndBackground = function () {
  const coords = this.vertices.map(v => {
    return { x: v.left, y: v.top }
  })
  this.updateLine(coords)
  this.updateBackground(coords)
}

Region.prototype.update = function () {
  const coords = this.isVisible() ? this.getScaledCoords() : null
  this.coords = coords

  this.selected = this.getActiveShapeIds?.().includes(this.id)
  if (!this.isVisible()) {
    this.removeFromCanvas()
    return
  }
  this.updateVertices(coords)
  /* this.updateVertexSize() */
  this.updateStructureColor(coords)
  this.updateStructureText(coords)
  this.updateReadingOrderText(coords)
  this.updateLine(coords)
  this.updateBackground(coords)

  if (this.selected) {
    this.updateGeometricLines(this.vertices)
  }
  if (this.state === Shape.STATE.SELECTION_ONLY) {
    this.canvas.remove(...this.vertices)
  }
  if (this.state === Shape.STATE.SEARCH_RESULTS) {
    this.removeFromCanvas()
  }
}

Region.prototype.updateLine = function (coords) {
  this.canvas.remove(this.line)
  if (coords == null) return
  this.line = this.myFabric.getRegionLine(coords, this.id)
  if (this.line != null) {
    this.canvas.add(this.line)
    this.canvas.sendToBack(this.line)
  }
}

Region.prototype.updateBackground = function (coords) {
  this.canvas.remove(this.background)
  if (coords == null) return
  this.background = this.myFabric.getRegionBackground(coords, this.id)
  if (this.selected) {
    this.canvas.add(this.background)
    this.canvas.sendToBack(this.background)
  }
}

// Region.prototype.smallUpdate = function () {
//   this.canvas.remove(this.line)
//   this.canvas.remove(this.background)

//   let polyVertices = this.vertices.map((vertex) => {
//     return { x: vertex.left, y: vertex.top }
//   })
//   this.line = this.myFabric.getRegionLine(polyVertices, this.id)
//   this.background = this.myFabric.getRegionBackground(polyVertices, this.id)

//   //this.canvas.add(this.line)
//   this.canvas.add(this.background)
//   this.canvas.sendToBack(this.line)
//   this.canvas.sendToBack(this.background)
// }

Region.prototype.getArea = function () {
  if (this.coords) {
    return this.coords.map(({ x, y }) => [x, y])
  }
}

Region.prototype.getAreaForced = function () {
  const coords = this.getScaledCoords()
  return coords.map(({ x, y }) => [x, y])
}

Region.prototype.getCoords = function () {
  return this.coords
}

Region.prototype.showPreviewRect = function (x, y) {
  this.canvas.remove(this.previewRect)
  this.previewRect = this.myFabric.getPreviewRectRegion(
    this.initCoord.x,
    this.initCoord.y,
    x,
    y
  )

  this.canvas.add(this.previewRect)
}

// Region.prototype.handleClickEvent = function (event) {
//   if (this.state === Shape.STATE.GROUP_SELECTION) {
//     this.canvas.setActiveObject(this.group)
//   }
// }

Region.prototype.handleDBLClickEvent = function (event, setButtonState) {}

Region.prototype.handleMouseMoveEvent = function (event) {
  if (this.state === Shape.STATE.FIRST_DRAW) {
    if (this.initCoord) {
      let mousePosition = this.canvas.getPointer(event.e)
      this.showPreviewRect(mousePosition.x, mousePosition.y)
    }
  } else if (this.state === Shape.STATE.EDIT) {
    if (this.preview) {
      let pointer = this.canvas.getPointer(event.e)
      let minDistanceToCreate = 0.01
      this.canvas.remove(this.runningVertex)
      let { line, point } = projectPointer(this.geometricLines, pointer)
      if (point && line) {
        this.runningVertex = this.myFabric.getRunningCircleRegion(
          point[0],
          point[1]
        )
        let self = this
        this.runningVertex.on('mousedown', function (o) {
          self.preview = false
        })
        this.runningVertex.on('moving', function (o) {
          let parent1 = self.getVertexById(line.parentIds[0])
          let parent2 = self.getVertexById(line.parentIds[1])

          if (!parent1 || !parent2) {
            Sentry.captureMessage(
              `parent1 or parent2 is null in Region {parent1: ${parent1}, parent2: ${parent2}}, region id: ${self.id}`
            )
            return
          }

          let parentDistance = distanceBetween(
            parent1.left,
            parent1.top,
            parent2.left,
            parent2.top
          )
          let distanceOverVertex =
            distanceBetween(this.left, this.top, parent1.left, parent1.top) +
            distanceBetween(this.left, this.top, parent2.left, parent2.top)

          let opacity =
            (distanceOverVertex - parentDistance) / minDistanceToCreate + 0.2

          self.canvas.remove(...self.prevLines)
          self.prevLines = [
            self.myFabric.getPreviewLineRegion(
              [this.left, this.top, parent1.left, parent1.top],
              opacity
            ),
            self.myFabric.getPreviewLineRegion(
              [this.left, this.top, parent2.left, parent2.top],
              opacity
            ),
          ]
          this.set('opacity', opacity)
          self.canvas.add(...self.prevLines)
        })
        this.runningVertex.on('mouseup', o => {
          let parent1 = self.getVertexById(line.parentIds[0])
          let parent2 = self.getVertexById(line.parentIds[1])
          let parentDistance = distanceBetween(
            parent1.left,
            parent1.top,
            parent2.left,
            parent2.top
          )
          let distanceOverVertex =
            distanceBetween(
              this.runningVertex.left,
              this.runningVertex.top,
              parent1.left,
              parent1.top
            ) +
            distanceBetween(
              this.runningVertex.left,
              this.runningVertex.top,
              parent2.left,
              parent2.top
            )

          if (distanceOverVertex - parentDistance >= minDistanceToCreate) {
            this.canvas.remove(this.runningVertex)
            this.actionHandler.newAction({
              type: ACTION_TYPE.ADD_POINT,
              shape_id: this.id,
              point_coord: {
                x: this.runningVertex.left,
                y: this.runningVertex.top,
              },
              point_index: this.getVertexIndex(line.parentIds[1]),
            })
          }
          self.preview = true
          self.canvas.remove(...self.prevLines)
          self.canvas.remove(this.runningVertex)
        })
        this.canvas.add(this.runningVertex)
        this.runningVertex.sendToBack()
        this.canvas.requestRenderAll()
      }
    }
  } else if (this.previewRect || this.runningVertex) {
    this.canvas.remove(this.previewRect, this.runningVertex)
    this.previewRect = null
    this.runningVertex = null
  }

  // if (this.state === Shape.STATE.RESIZE) {
  //   //Here a helper vertex is defined, that can move a line together with its parent Vertices
  //   let pointer = this.canvas.getPointer(event.e)
  //   this.canvas.remove(this.invisibleVertex)
  //   let { line, point } = projectPointer(this.lines, pointer)
  //   if (point && line) {
  //     this.invisibleVertex = this.myFabric.getInvisibleVertex(
  //       point[0],
  //       point[1],
  //       getRandomId()
  //     )
  //     let self = this
  //     this.invisibleVertex.on('mousedown', function (o) {
  //       self.startDragPosition = [this.left, this.top]
  //       self.parents = {
  //         first: [
  //           self.getVertexById(line.parentIds[0]).left,
  //           self.getVertexById(line.parentIds[0]).top,
  //         ],
  //         second: [
  //           self.getVertexById(line.parentIds[1]).left,
  //           self.getVertexById(line.parentIds[1]).top,
  //         ],
  //       }
  //     })
  //     this.invisibleVertex.on('moving', function (o) {
  //       let stopDragPosition = [this.left, this.top]
  //       let length = lineLength([self.startDragPosition, stopDragPosition])
  //       let angle = lineAngle([self.startDragPosition, stopDragPosition])
  //       let point = pointTranslate(self.parents.first, angle, length)
  //       self.moveVertexById(line.parentIds[0], point)
  //       point = pointTranslate(self.parents.second, angle, length)
  //       self.moveVertexById(line.parentIds[1], point)
  //       self.update()
  //     })
  //     this.canvas.add(this.invisibleVertex)
  //   }
  // }

  // // if (this.state === Shape.STATE.ADD_VERTEX) {
  // //   let pointer = this.canvas.getPointer(event.e)
  // //   this.canvas.remove(this.prevVertex)
  // //   let { point } = projectPointer(this.lines, pointer)
  // //   if (point) {
  // //     this.prevVertex = getRegionVertex(point[0], point[1], getRandomId())
  // //     this.canvas.add(this.prevVertex)
  // //   }
  // // }
}

Region.prototype.handleMouseOut = function (event) {
  // if (event.target && event.target.type === 'circle') {
  //   if (this.hoveredVertex) {
  //     //this.setState(Shape.STATE.GROUP_SELECTION)
  //     this.hoveredVertex.set({ radius: this.hoveredVertex.smallSize })
  //     if (this.hoveredVertex.fill === this.hoveredVertex.deleteColor) {
  //       this.hoveredVertex.set({ fill: this.hoveredVertex.defaultColor })
  //     }
  //     this.canvas.discardActiveObject()
  //     this.canvas.requestRenderAll()
  //     this.hoveredVertex = null
  //   }
  // }
}

// Region.prototype.drawVertex = function (x, y) {
//   let vertex = getRegionVertex(x, y, getRandomId())
//   this.canvas.add(vertex)
//   let me = this
//   vertex.on('moving', function (o) {
//     me.update()
//   })
//   vertex.on('mouseover', function (o) {
//     if (this.radius != 5) {
//       this.set({ radius: 5 })
//       this.bringToFront()
//       me.canvas.requestRenderAll()
//       me.hoveredVertex = this
//     }
//   })
//   this.vertices.push(vertex)
// }

Region.prototype.getVertex = function (x, y, id) {
  let vertex = this.myFabric.getRegionCircle(x, y, id)

  this.lastAddedVertex = vertex
  let me = this

  vertex.select = function () {
    if (me.activeVertex) {
      me.activeVertex.set('fill', this.defaultColor)
    }
    me.activeVertex = this
    me.activeVertex.set('fill', this.selectedColor)
  }

  vertex.unSelect = function () {
    if (me.activeVertex) {
      me.activeVertex.set('fill', this.defaultColor)
      me.activeVertex = null
    }
  }

  vertex.on('mousedown', o => {
    me.tempCoords = { left: vertex.left, top: vertex.top }
    vertex.select()
    if (me.state === Shape.STATE.REMOVE_VERTEX) {
      if (me.vertices.length > 3) {
        let index = me.vertices.findIndex(v => v.id === o.target.id)
        me.removePoint(o.target.id)
        me.actionHandler.addAction({
          type: ACTION_TYPE.REMOVE_POINT,
          shape_id: me.id,
          point_id: o.target.id,
          point_index: index,
        })
      }
    }
  })
  vertex.on('moving', o => {
    //me.update({ geometricLines: false })
    this.updateLineAndBackground()
  })
  vertex.on('mouseup', o => {
    if (
      this.tempCoords &&
      this.state != Shape.STATE.GROUP_SELECTION &&
      (this.tempCoords.left != vertex.left || this.tempCoords.top != vertex.top)
    ) {
      const vector = {
        x: vertex.left - this.tempCoords.left,
        y: vertex.top - this.tempCoords.top,
      }

      vertex.set({ left: this.tempCoords.left, top: this.tempCoords.top })
      vertex.setCoords()

      this.actionHandler.newAction({
        type: ACTION_TYPE.MOVE_POINT,
        shape_id: this.id,
        point_index: this.vertices.indexOf(vertex),
        vector,
      })
      this.tempCoords = null
    }
  })
  // vertex.on('mouseover', function (o) {
  //   if (
  //     this.radius != this.largeSize &&
  //     (me.state === Shape.STATE.EDIT || me.state === Shape.STATE.REMOVE_VERTEX)
  //   ) {
  //     this.set({ radius: this.largeSize })
  //     this.bringToFront()
  //     me.canvas.requestRenderAll()
  //     me.hoveredVertex = this
  //     if (me.state === Shape.STATE.REMOVE_VERTEX) {
  //       this.set({ fill: this.deleteColor })
  //     }
  //   }
  // })
  return vertex
}

/* Region.prototype.moveVertexById = function (id, position) {
  let foundVertex = this.vertices.find(v => {
    return v.id === id
  })
  foundVertex.set({ left: position[0], top: position[1] })
  foundVertex.setCoords()
  this.canvas.requestRenderAll()
} */

export default Region
