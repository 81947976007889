import { polygonHull } from 'geometric'
import {
  cloneObj,
  getLineGeometrics,
  getLineJson,
  getShapeAttributes,
  getShapeById,
  getShapeText,
  getTextLines,
  setTextlinesForRegion,
  expectedLlineIndex,
} from '../Model/modelUtils.ts'
import { compare } from 'fast-json-patch'
import IdGenerator from './IdGenerator.js'
import { distanceBetween } from '../LayoutEditor/components/geometryFunctions.js'
import BaseAction from './BaseAction.js'

export default class Merge extends BaseAction {
  constructor(pageJson?: any) {
    super(pageJson)
  }

  getClosestLines(lineGeometrics) {
    const defaultPoint = { x: 0, y: 0 }
    //found here https://stackoverflow.com/questions/22566379/how-to-get-all-pairs-of-array-javascript
    const pairsOfArray = array =>
      array.reduce(
        (acc, val, i1) => [
          ...acc,
          ...new Array(array.length - 1 - i1)
            .fill(0)
            .map((v, i2) => [array[i1], array[i1 + 1 + i2]]),
        ],
        []
      )

    let lineObjects = lineGeometrics.map(line => ({
      line: line,
      first:
        line.baselineCoords?.[0] || line.polygonCoords?.[0] || defaultPoint,
      last:
        line.baselineCoords?.[line.baselineCoords.length - 1] ||
        line.polygonCoords?.[line.polygonCoords.length - 1] ||
        defaultPoint,
    }))

    let pairs = pairsOfArray(lineObjects)

    let closest = pairs.reduce(
      (closest, pair) => {
        let dist1 = distanceBetween(
          pair[0].last.y,
          pair[0].last.x,
          pair[1].first.y,
          pair[1].first.x
        )
        let dist2 = distanceBetween(
          pair[1].last.y,
          pair[1].last.x,
          pair[0].first.y,
          pair[0].first.x
        )
        let newDist = dist1 < dist2 ? dist1 : dist2
        let newPair = dist1 < dist2 ? [pair[0], pair[1]] : [pair[1], pair[0]]
        return newDist < closest.dist
          ? { pair: newPair, dist: newDist }
          : closest
      },
      { dist: Infinity }
    )

    let newLines = closest.pair.map(pair => pair.line)
    return newLines
  }

  apply(params: any) {
    const activeMergeIds = params.selectedElements // Array of active shapes, must be changed to real active shapes later

    const recursiveMerge = mergeIds => {
      if (mergeIds.length === 1) return

      const lineGeometrics = getLineGeometrics(this.pageJson).filter(line =>
        mergeIds.includes(line.id)
      )
      let [lineGemoetric1, lineGemoetric2] =
        this.getClosestLines(lineGeometrics)
      let [line1, line2] = [
        getShapeById(lineGemoetric1.id, this.pageJson),
        getShapeById(lineGemoetric2.id, this.pageJson),
      ]

      const allPolygonPoints = [
        ...(lineGemoetric1.polygonCoords ||
          lineGemoetric1.baselineCoords ||
          []),
        ...(lineGemoetric2.polygonCoords ||
          lineGemoetric2.baselineCoords ||
          []),
      ].map(v => [v.x, v.y]) as any
      const hull = polygonHull(allPolygonPoints)

      const foundTypes: string[] = []
      const oldLines = [line1, line2]
      oldLines.forEach(line => {
        const attributes = getShapeAttributes(line)
        const type = attributes.structureType
        if (type != null && !foundTypes.includes(type)) {
          foundTypes.push(type)
        }
      })

      const newId = this.idGenerator.requestId('l')
      const line1Text = getShapeText(line1) || ''
      const line2Text = getShapeText(line2) || ''
      const newText = `${line1Text} ${line2Text}`
      const baselineCoords = [
        ...(lineGemoetric1.baselineCoords || []),
        ...(lineGemoetric2.baselineCoords || []),
      ].map(c => ({
        x: Math.round(c.x),
        y: Math.round(c.y),
      }))
      const polygonCoords = hull.map(point => ({
        x: Math.round(point[0]),
        y: Math.round(point[1]),
      }))

      const line1Tags = getShapeAttributes(line1).tags
      const line2Tags = getShapeAttributes(line2).tags?.map(t => {
        const newTag = cloneObj(t)
        const offsetRule = newTag.rules.find(r => r.directive === 'offset')
        offsetRule.value =
          Number(offsetRule.value) + Number(line1Text.length) + 1
        return newTag
      })
      const tags = [...(line1Tags || []), ...(line2Tags || [])]
      const structureType = foundTypes.length === 1 ? foundTypes[0] : null
      const newLine = getLineJson(
        newId,
        baselineCoords,
        polygonCoords,
        newText,
        tags,
        structureType
      )
      const region = getShapeById(lineGemoetric1.parentId, this.pageJson)
      const textLines = [...getTextLines(region)].filter(
        line =>
          line.attributes.id !== line1.attributes.id &&
          line.attributes.id !== line2.attributes.id
      )
      const index = expectedLlineIndex(newLine, textLines)
      textLines.splice(index, 0, newLine)
      setTextlinesForRegion(region, textLines)
      return recursiveMerge(
        mergeIds
          .filter(
            id => id !== line1.attributes.id && id !== line2.attributes.id
          )
          .concat(newId)
      )
    }
    recursiveMerge(activeMergeIds)
  }
}
