const parseCustom = string => {
  const customObject = {}
  const rules = string
    .split('}')
    .filter(Boolean)
    .map(rule => {
      const [selector, styles] = rule.split('{').map(str => str.trim())
      const styleRules = styles
        .split(';')
        .filter(Boolean)
        .map(style => {
          const [directive, value] = style.split(':').map(str => str.trim())
          return { directive, value }
        })
      return { selector, rules: styleRules }
    })

  rules.forEach(({ selector, rules }) => {
    switch (selector) {
      case 'readingOrder':
        customObject.readingOrder = rules[0].value
        break
      case 'structur':
      case 'structure':
        customObject.structureType =
          rules.find(r => r.directive === 'type')?.value || ''
        customObject.structureObject = { selector, rules }
        break
      case 'relationType':
        customObject.relationType = rules[0]?.value || ''
        break
      case 'relationName':
        customObject.relationName = rules[0]?.value || ''
        break
      case 'id':
        customObject.id = rules[0]?.value || ''
        break
      default: {
        if (!('tags' in customObject)) {
          customObject.tags = []
        }
        customObject.tags.push({ selector, rules })
      }
    }
  })

  return customObject
}

const stringifyCustom = custom => {
  //log(custom)
  let string = ''
  if ('readingOrder' in custom) {
    string += ` readingOrder {index:${custom.readingOrder};}`
  }
  if ('structureType' in custom) {
    let asString = ''
    if (custom.structureObject != null) {
      const otherProperties = custom.structureObject.rules.filter(
        o => o.directive != 'type'
      )
      asString = otherProperties.reduce(
        (acc, p) => acc + `${p.directive}:${p.value};`,
        ''
      )
    }
    string += ` structure {type:${custom.structureType};${asString}}`
  }
  if ('relationType' in custom) {
    string += ` relationType {value:${custom.relationType};}`
  }
  if ('relationName' in custom) {
    string += ` relationName {value:${custom.relationName};}`
  }
  if ('id' in custom) {
    string += ` id {value:${custom.id};}`
  }
  if ('tags' in custom) {
    custom.tags.map(tag => {
      const ruleString = tag.rules.reduce(
        (acc, { directive, value }) => acc + `${directive}:${value};`,
        ''
      )
      string += ` ${tag.selector} {${ruleString}}`
    })
  }

  string = string.substring(1)
  return string
}

const sortByReadingOrder = regions => {
  regions = regions.sort(
    (a, b) =>
      a.attributes.custom?.readingOrder - b.attributes.custom?.readingOrder
  )
  const sortLines = lines => {
    return lines.sort(
      (a, b) =>
        a.attributes.custom?.readingOrder - b.attributes.custom?.readingOrder
    )
  }
  regions.map(region => {
    if (region.elements.textLines) {
      region.elements.textLines = sortLines(region.elements.textLines)
      return
    }
    region.elements.cells.map(cell => {
      cell.elements.textLines = sortLines(cell.elements.textLines)
    })
  })
  return regions
}

const estimateBaselineCoords = polyCoords => {
  if (polyCoords == null || polyCoords.length === 0)
    return [
      { x: 0, y: 0 },
      { x: 0, y: 0 },
    ]
  const isClockwise = coords => {
    //source: https://stackoverflow.com/questions/14505565/detect-if-a-
    //set-of-points-in-an-array-that-are-the-vertices-of-a-complex-polygon
    let area = 0
    coords.map((_, i) => {
      const j = (i + 1) % coords.length
      area += coords[i].x * coords[j].y
      area -= coords[j].x * coords[i].y
    })
    return area > 0
  }

  const getNextCoord = (coord, coords) => {
    const index = coords.findIndex(c => c === coord)
    return coords[(index + 1) % coords.length]
  }
  const collectCoords = (collection, coords, coord, endCoord) => {
    if (coord === endCoord) {
      return [...collection, coord]
    }
    return collectCoords(
      [...collection, coord],
      coords,
      getNextCoord(coord, coords),
      endCoord
    )
  }
  const getMostLeft = coords => {
    const [left1, left2] = [...coords].sort((a, b) => a.x - b.x)
    if (left1.x === left2.x) {
      if (left1.y < left2.y) return left2
      return left1
    }
    return left1
  }
  const getMostRight = coords => {
    const [right1, right2] = [...coords].sort((a, b) => b.x - a.x)
    if (right1.x === right2.x) {
      if (right1.y < right2.y) return right2
      return right1
    }
    return right1
  }

  const clockwise = isClockwise(polyCoords)
  let coords = clockwise ? [...polyCoords].reverse() : [...polyCoords]
  const tolerance = 2

  let result = collectCoords(
    [],
    coords,
    getMostLeft(coords),
    getMostRight(coords)
  )
  result = simplify(result, tolerance)
  return result
}

const getMetadataBlueprint = metadata => {
  //attributes
  //log(relations)
  let attributes = getAttributesBlueprint(metadata?.attributes)

  //elements
  let elements = {
    properties: [],
  }
  metadata?.elements?.forEach(e => {
    switch (e.name) {
      case 'Property':
        elements.properties.push(getPropertyBlueprint(e))
        break
    }
  })
  return { attributes: attributes, elements: elements }
}

const getPropertyBlueprint = property => {
  let attributes = getAttributesBlueprint(property.attributes)
  return { attributes: attributes, elements: [] }
}

const getRelationsBlueprint = relations => {
  //attributes
  //log(relations)
  let attributes = getAttributesBlueprint(relations.attributes)

  //elements
  let elements = {
    relations: [],
  }
  relations.elements.map(e => {
    switch (e.name) {
      case 'Relation':
        elements.relations.push(getRelationBlueprint(e))
        break
    }
  })
  return { attributes: attributes, elements: elements }
}

const getRelationBlueprint = relation => {
  //attributes
  let attributes = getAttributesBlueprint(relation.attributes)

  //elements
  let elements = {
    ids: [],
  }
  relation.elements.map(e => {
    switch (e.name) {
      case 'RegionRef':
        elements.ids.push(getIdFromRegionRef(e))
        break
    }
  })
  return { attributes: attributes, elements: elements }
}

const getIdFromRegionRef = regionRef => {
  return regionRef.attributes?.regionRef
}

export const getRegionBlueprint = textRegion => {
  //attributes
  let attributes = getAttributesBlueprint(textRegion.attributes)

  //elements
  let elements = {
    coords: [],
    textLines: [],
    text: '',
    json: textRegion,
  }
  textRegion.elements.map(e => {
    switch (e.name) {
      case 'Coords':
        elements.coords = getCoordsBlueprint(e)
        if (elements.coords.length === 0) {
          elements.coords = [
            { x: 0, y: 0 },
            { x: 0, y: 0 },
            { x: 0, y: 0 },
            { x: 0, y: 0 },
          ]
        }
        break
      case 'TextLine':
        elements.textLines.push(getTextLineBlueprint(e))
        break
      case 'TextEquiv':
        elements.text = getTextEquivBlueprint(e)

        break
    }
  })
  return { attributes: attributes, elements: elements }
}

const getTableBlueprint = tableRegion => {
  //attributes
  let attributes = getAttributesBlueprint(tableRegion.attributes)

  //elements
  let elements = {
    coords: [],
    cells: [],
    text: '',
  }
  const cells = tableRegion.elements.filter(e => e.name === 'TableCell')

  tableRegion.elements.map(e => {
    switch (e.name) {
      case 'Coords':
        elements.coords = getCoordsBlueprint(e)
        break
      case 'TableCell':
        elements.cells.push(getCellBlueprint(e))
        break
      case 'TextEquiv':
        elements.text = getTextEquivBlueprint(e)
        break
    }
  })
  return { attributes: attributes, elements: elements }
}

const getCellBlueprint = cell => {
  //attributes
  let attributes = getAttributesBlueprint(cell.attributes)

  //elements
  let elements = {
    coords: [],
    textLines: [],
    cornerPoints: [],
  }
  cell.elements.map(e => {
    switch (e.name) {
      case 'Coords':
        elements.coords = getCoordsBlueprint(e)
        break
      case 'TextLine':
        elements.textLines.push(getTextLineBlueprint(e))
        break
      case 'CornerPts':
        elements.cornerPoints = getCornerPtsBlueprint(e)
        break
    }
  })
  return { attributes: attributes, elements: elements }
}

const getCornerPtsBlueprint = points => {
  return points.elements[0].text.split(' ').map(string => parseInt(string))
}

export const getTextLineBlueprint = textLine => {
  //attributes
  let attributes = getAttributesBlueprint(textLine.attributes)

  //elements
  let elements = {
    polyCoords: [],
    baselineCoords: [],
    words: [],
    text: '',
    json: textLine,
  }
  textLine.elements.map(e => {
    switch (e.name) {
      case 'Coords':
        elements.polyCoords = getCoordsBlueprint(e)
        break
      case 'Baseline':
        elements.baselineCoords = getBaselineBlueprint(e)
        break
      case 'Word':
        elements.words.push(getWordBlueprint(e))
        break
      case 'TextEquiv':
        elements.text = getTextEquivBlueprint(e)
        break
    }
  })
  if (elements.baselineCoords.length === 0) {
    elements.baselineCoords = estimateBaselineCoords(elements.polyCoords)
  }
  return { attributes: attributes, elements: elements }
}

const getWordBlueprint = word => {
  //attributes
  let attributes = getAttributesBlueprint(word.attributes)

  //elements
  let elements = {
    coords: [],
    text: '',
  }
  word.elements.map(e => {
    switch (e.name) {
      case 'Coords':
        elements.coords = getCoordsBlueprint(e)
        break
      case 'TextEquiv':
        elements.text = getTextEquivBlueprint(e)
        break
    }
  })
  return { attributes: attributes, elements: elements }
}

const getAttributesBlueprint = attributes => {
  return {
    ...attributes,
    ...(attributes?.custom ? { custom: parseCustom(attributes.custom) } : {}),
  }
}

const getCoordsBlueprint = coords => {
  return getPointListfromString(coords.attributes.points)
}

const getBaselineBlueprint = baseline => {
  return getPointListfromString(baseline.attributes.points)
}

const getTextEquivBlueprint = textEquiv => {
  if (
    textEquiv.elements.length > 0 &&
    textEquiv.elements[0].elements.length > 0
  ) {
    /* const escapeSymbols = unsafe => {
      return unsafe.replace(/</g, '&lt;').replace(/>/g, '&gt;')
    } */
    return textEquiv.elements[0].elements[0].text
  }
  return ''
}

export const jsonToBlueprint = globalJson => {
  // log(globalJson)
  let rootElements = getPage(globalJson).elements

  const metaData = getMetadata(globalJson)
  let metadataBlueprint = getMetadataBlueprint(metaData)
  //log('root')
  //log(rootElements)

  let regions = rootElements.filter(
    e => e.name === 'TextRegion' || e.name === 'TableRegion'
  )

  //log(regions)

  let relationsBlueprint
  const relations = rootElements.find(e => e.name === 'Relations')
  if (relations != null) {
    relationsBlueprint = getRelationsBlueprint(relations)
  }

  let unSortedRegions = regions.map(region => {
    switch (region.name) {
      case 'TextRegion':
        return getRegionBlueprint(region)
      case 'TableRegion':
        return getTableBlueprint(region)
    }
  })

  //log(unSortedRegions)

  let regionBlueprints = unSortedRegions
  return { regionBlueprints, relationsBlueprint, metadataBlueprint }
}

const getPage = globalJson => {
  const pcgts = globalJson.elements.find(e => e.name === 'PcGts')
  if (pcgts == null) {
    warn('Error: not PcGts tag in pageXml')
    return
  }
  const page = pcgts.elements.find(e => e.name === 'Page')
  if (page == null) {
    warn('Error: not Page tag in pageXml')
    return
  }
  return page
}

const getMetadata = globalJson => {
  const pcgts = globalJson.elements.find(e => e.name === 'PcGts')
  if (pcgts == null) {
    warn('Error: not PcGts tag in pageXml')
    return
  }
  const metadata = pcgts.elements.find(e => e.name === 'Metadata')
  if (metadata == null) {
    warn('Error: no Metadata tag in pageXml')
    return
  }
  const transkribusMetadata = metadata.elements.find(
    e => e.name === 'TranskribusMetadata'
  )
  if (transkribusMetadata == null) {
    return
  }
  return transkribusMetadata
}

const getPointListfromString = string => {
  if (string == null || string === '') return []
  var coords = string.split(' ')
  let result = coords.map(c => {
    let list = c.split(',')
    return { x: Number(list[0]), y: Number(list[1]) }
  })
  return result
}

const getRelationsJson = blueprint => {
  //attributes
  let attributes = getAttributesJson(blueprint.attributes)

  //elements
  let elements = []
  blueprint.elements.relations.map(relation => {
    elements.push(getRelationJson(relation))
  })
  return {
    attributes: attributes,
    elements: elements,
    name: 'Relations',
    type: 'element',
  }
}

export const getRelationJson = blueprint => {
  //attributes
  let attributes = getAttributesJson(blueprint.attributes)

  //elements
  let elements = []
  blueprint.elements.ids.map(id => {
    elements.push(getRegionRefFromId(id))
  })
  return {
    attributes: attributes,
    elements: elements,
    name: 'Relation',
    type: 'element',
  }
}

const getMetadataJson = blueprint => {
  //attributes
  let attributes = getAttributesJson(blueprint.attributes)

  //elements
  let elements = []
  blueprint.elements.properties.map(p => {
    elements.push(getPropertyJson(p))
  })
  return {
    attributes: attributes,
    elements: elements,
    name: 'TranskribusMetadata',
    type: 'element',
  }
}

const getPropertyJson = blueprint => {
  return {
    attributes: blueprint.attributes,
    elements: [],
    name: 'Property',
    type: 'element',
  }
}

export const getRegionRefFromId = id => {
  return {
    attributes: { regionRef: id },
    elements: [],
    name: 'RegionRef',
    type: 'element',
  }
}

export const getRegionJson = blueprint => {
  //attributes
  let attributes = getAttributesJson(blueprint.attributes)

  //elements
  let elements = []
  elements.push(getCoordsJson(blueprint.elements.coords))
  blueprint.elements.textLines.map(line => {
    elements.push(getTextLineJson(line))
  })
  elements.push(getTextEquivJson(blueprint.elements.text))

  return {
    attributes: attributes,
    elements: elements,
    name: 'TextRegion',
    type: 'element',
  }
}

export const getRegionJsonWithParams = (id, coords) => {
  //attributes
  let attributes = getAttributesJson({ id })

  //elements
  let elements = []
  elements.push(getCoordsJson(coords))
  /*  blueprint.elements.textLines.map(line => {
    elements.push(getTextLineJson(line))
  }) */
  elements.push(getTextEquivJson(''))

  return {
    attributes: attributes,
    elements: elements,
    name: 'TextRegion',
    type: 'element',
  }
}

export const getLineJsonWithParams = (id, baselineCoords, polyCoords) => {
  //attributes
  let attributes = getAttributesJson({ id })

  //elements
  let elements = []
  elements.push(getCoordsJson(polyCoords))
  elements.push(getBaselineJson(baselineCoords))

  elements.push(getTextEquivJson(''))

  return {
    attributes: attributes,
    elements: elements,
    name: 'TextLine',
    type: 'element',
  }
}

export const getTableJson = blueprint => {
  //attributes
  let attributes = getAttributesJson(blueprint.attributes)

  //elements
  let elements = []
  elements.push(getCoordsJson(blueprint.elements.coords))
  blueprint.elements.cells.map(cell => {
    elements.push(getCellJson(cell))
  })
  elements.push(getTextEquivJson(blueprint.elements.text))
  return {
    attributes: attributes,
    elements: elements,
    name: 'TableRegion',
    type: 'element',
  }
}

export const getCellJson = blueprint => {
  //attributes
  let attributes = getAttributesJson(blueprint.attributes)

  //elements
  let elements = []
  elements.push(getCoordsJson(blueprint.elements.coords))
  blueprint.elements.textLines.map(line => {
    elements.push(getTextLineJson(line))
  })
  elements.push(getCornerPtsJson(blueprint.elements.cornerPoints))
  return {
    attributes: attributes,
    elements: elements,
    name: 'TableCell',
    type: 'element',
  }
}

export const getTextLineJson = blueprint => {
  //attributes
  let attributes = getAttributesJson(blueprint.attributes)

  //elements
  let elements = []
  elements.push(getCoordsJson(blueprint.elements.polyCoords))
  elements.push(getBaselineJson(blueprint.elements.baselineCoords))
  blueprint.elements.words.map(word => {
    elements.push(getWordJson(word))
  })
  elements.push(getTextEquivJson(blueprint.elements.text))

  //removes invalid Baseline elements
  // elements = elements.filter((e) => {
  //   const isBaseline = e.name === 'Baseline'
  //   const pointsInvalid =
  //     e.points === null || e.points === undefined || e.points.length === 0
  //   if (isBaseline && pointsInvalid) return false
  //   return true
  // })

  return {
    attributes: attributes,
    elements: elements,
    name: 'TextLine',
    type: 'element',
  }
}

const getWordJson = blueprint => {
  //attributes
  let attributes = getAttributesJson(blueprint.attributes)

  //elements
  let elements = []
  elements.push(getCoordsJson(blueprint.elements.coords))
  elements.push(getTextEquivJson(blueprint.elements.text))

  return {
    attributes: attributes,
    elements: elements,
    name: 'Word',
    type: 'element',
  }
}

const getAttributesJson = blueprintAttributes => {
  //log(blueprintAttributes)
  const attributes = { ...blueprintAttributes }
  if ('custom' in blueprintAttributes) {
    attributes.custom = stringifyCustom(blueprintAttributes.custom)
  }
  return attributes
}
const getStringfromPointList = list => {
  let result = list.map(c => `${c.x},${c.y}`).join(' ')
  return result
}

const getCoordsJson = coords => {
  return {
    attributes: {
      points: getStringfromPointList(coords),
    },
    elements: [],
    name: 'Coords',
    type: 'element',
  }
}

const getBaselineJson = coords => {
  return {
    attributes: {
      points: getStringfromPointList(coords),
    },
    elements: [],
    name: 'Baseline',
    type: 'element',
  }
}

const getCornerPtsJson = points => {
  return {
    elements: [
      {
        type: 'text',
        text: points.join(' '),
      },
    ],
    name: 'CornerPts',
    type: 'element',
  }
}

export const getTextEquivJson = text => {
  return {
    elements: [
      {
        elements: text.length <= 0 ? [] : [{ type: 'text', text: text }],
        name: 'Unicode',
        type: 'element',
      },
    ],
    name: 'TextEquiv',
    type: 'element',
  }
}

// to suit your point format, run search/replace for '.x' and '.y';
// for 3D version, see 3d branch (configurability would draw significant performance overhead)

// square distance between 2 points
function getSqDist(p1, p2) {
  var dx = p1.x - p2.x,
    dy = p1.y - p2.y

  return dx * dx + dy * dy
}

// square distance from a point to a segment
function getSqSegDist(p, p1, p2) {
  var x = p1.x,
    y = p1.y,
    dx = p2.x - x,
    dy = p2.y - y

  if (dx !== 0 || dy !== 0) {
    var t = ((p.x - x) * dx + (p.y - y) * dy) / (dx * dx + dy * dy)

    if (t > 1) {
      x = p2.x
      y = p2.y
    } else if (t > 0) {
      x += dx * t
      y += dy * t
    }
  }

  dx = p.x - x
  dy = p.y - y

  return dx * dx + dy * dy
}
// rest of the code doesn't care about point format

// basic distance-based simplification
function simplifyRadialDist(points, sqTolerance) {
  var prevPoint = points[0],
    newPoints = [prevPoint],
    point

  for (var i = 1, len = points.length; i < len; i++) {
    point = points[i]

    if (getSqDist(point, prevPoint) > sqTolerance) {
      newPoints.push(point)
      prevPoint = point
    }
  }

  if (prevPoint !== point) newPoints.push(point)

  return newPoints
}

function simplifyDPStep(points, first, last, sqTolerance, simplified) {
  var maxSqDist = sqTolerance,
    index

  for (var i = first + 1; i < last; i++) {
    var sqDist = getSqSegDist(points[i], points[first], points[last])

    if (sqDist > maxSqDist) {
      index = i
      maxSqDist = sqDist
    }
  }

  if (maxSqDist > sqTolerance) {
    if (index - first > 1)
      simplifyDPStep(points, first, index, sqTolerance, simplified)
    simplified.push(points[index])
    if (last - index > 1)
      simplifyDPStep(points, index, last, sqTolerance, simplified)
  }
}

// simplification using Ramer-Douglas-Peucker algorithm
function simplifyDouglasPeucker(points, sqTolerance) {
  var last = points.length - 1

  var simplified = [points[0]]
  simplifyDPStep(points, 0, last, sqTolerance, simplified)
  simplified.push(points[last])

  return simplified
}

// both algorithms combined for awesome performance
function simplify(points, tolerance, highestQuality) {
  if (points.length <= 2) return points

  var sqTolerance = tolerance !== undefined ? tolerance * tolerance : 1

  points = highestQuality ? points : simplifyRadialDist(points, sqTolerance)
  points = simplifyDouglasPeucker(points, sqTolerance)

  return points
}

export function validateCells(cells) {
  console.log('Cells for validation:', cells)
  // Create a grid to track occupied positions
  const grid = []

  // Iterate through each cell
  for (const cell of cells) {
    const row = parseInt(cell.attributes.row)
    const col = parseInt(cell.attributes.col)
    const rowSpan = parseInt(cell.attributes.rowSpan || 1)
    const colSpan = parseInt(cell.attributes.colSpan || 1)
    // Check for decimal numbers
    if (
      !Number.isInteger(row) ||
      !Number.isInteger(col) ||
      !Number.isInteger(rowSpan) ||
      !Number.isInteger(colSpan)
    ) {
      console.log(
        `Invalid cell ${cell.attributes.id}: row, col, rowSpan, and colSpan must be integers (no decimal numbers).`
      )
      return false
    }

    // Validate that positions and spans are positive
    if (row < 0 || col < 0 || rowSpan <= 0 || colSpan <= 0) {
      console.log(
        `Invalid cell position or span for cell ${cell.attributes.id}. row, col, rowSpan, and colSpan must be positive.`
      )
      return false
    }

    // Ensure grid is large enough
    for (let i = 0; i < row + rowSpan; i++) {
      grid[i] = grid[i] || []
    }

    // Iterate over the positions this cell occupies
    for (let r = row; r < row + rowSpan; r++) {
      for (let c = col; c < col + colSpan; c++) {
        if (grid[r][c]) {
          // If the position is already occupied, return an error
          console.log(
            `Overlap detected at position (${r}, ${c}) by cell ${cell.attributes.id}`
          )
          return false
        }
        // Mark the position as occupied
        grid[r][c] = true
      }
    }
  }

  // Check if all positions are filled or covered
  const rowCount = Math.max(
    ...cells.map(
      cell => parseInt(cell.attributes.row) + parseInt(cell.attributes.rowSpan)
    )
  )
  const colCount = Math.max(
    ...cells.map(
      cell => parseInt(cell.attributes.col) + parseInt(cell.attributes.colSpan)
    )
  )

  for (let r = 0; r < rowCount; r++) {
    for (let c = 0; c < colCount; c++) {
      if (!grid[r] || !grid[r][c]) {
        // If there's a missing position, return an error
        console.log(`Missing cell at position (${r}, ${c})`)
        return false
      }
    }
  }

  // If we reach here, the table is valid
  return true
}

export function combineCells(cells) {
  // Variables to store the min and max x and y coordinates
  let minX = Infinity,
    minY = Infinity,
    maxX = -Infinity,
    maxY = -Infinity

  // Array to store combined TextLines
  let combinedTextLines = []

  cells.forEach(cell => {
    // Find Coords points for each cell
    const coords = cell.elements.find(el => el.name === 'Coords')
    if (coords && coords.attributes && coords.attributes.points) {
      const points = coords.attributes.points
        .split(' ')
        .map(point => point.split(',').map(Number))

      // Update minX, minY, maxX, maxY by comparing each point
      points.forEach(([x, y]) => {
        if (x < minX) minX = x
        if (y < minY) minY = y
        if (x > maxX) maxX = x
        if (y > maxY) maxY = y
      })
    }

    // Collect TextLine elements
    const textLines = cell.elements.filter(el => el.name === 'TextLine')
    combinedTextLines.push(...textLines)
  })

  // Create the new combined cell
  const newCell = {
    type: 'element',
    name: 'TableCell',
    attributes: {
      row: '0', // Placeholder value, could be adjusted as needed
      col: '0', // Placeholder value, could be adjusted as needed
      rowSpan: '1', // Adjust as necessary
      colSpan: '1', // Adjust as necessary
      id: generateRandomId(),
    },
    elements: [
      {
        type: 'element',
        name: 'Coords',
        attributes: {
          points: `${minX},${minY} ${minX},${maxY} ${maxX},${maxY} ${maxX},${minY}`,
        },
        elements: [],
      },
      ...combinedTextLines, // Insert the combined TextLines here
      {
        type: 'element',
        name: 'CornerPts',
        elements: [
          {
            type: 'text',
            text: '0 1 2 3',
          },
        ],
      },
    ],
  }

  return newCell
}

function generateRandomId() {
  return 'c_' + Math.random().toString(36).substr(2, 9)
}

export function processTableRegions(obj) {
  // Recursive function to traverse the elements array
  function traverse(elements) {
    if (!Array.isArray(elements)) return

    elements.forEach((element, index) => {
      if (element.name === 'TableRegion') {
        // Find all TableCell elements
        const cells = element.elements.filter(el => el.name === 'TableCell')

        if (cells.length > 0) {
          // Combine the cells
          const valid = validateCells(cells)
          if (!valid) {
            window.emitEditorNotification({
              message:
                'Cells of this table were combined to one singe cell, because of invalid cell positions or overlaps.',
              type: 'error',
              indefinite: true,
            })
            const combinedCell = combineCells(cells)

            // Replace TableCell elements with one combined cell
            element.elements = element.elements.filter(
              el => el.name !== 'TableCell'
            )
            element.elements.push(combinedCell)
          }
        }
      } else if (element.elements) {
        // Recursively traverse nested elements
        traverse(element.elements)
      }
    })
  }

  // Start traversal from the root element
  traverse(obj.elements)
}

// export as AMD module / Node module / browser or worker variable
if (typeof define === 'function' && define.amd)
  define(function () {
    return simplify
  })
else if (typeof module !== 'undefined') {
  module.exports = simplify
  module.exports.default = simplify
} else if (typeof self !== 'undefined') self.simplify = simplify
else window.simplify = simplify
