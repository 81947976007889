import Dexie from 'dexie'

const db: any = new Dexie('LocalEditorChanges')

let hasCheckedForExpired = false

db.version(1).stores({
  recoveries: 'xmlKey, createdAt',
})

export const useEditorStorage = () => {
  const setActions = async (actions: any[], xmlKey: string) => {
    if (actions.length === 0) {
      clearStorage(xmlKey)
      return
    }
    const recovery = await db.recoveries.get(xmlKey)
    const date = new Date().toISOString()
    if (recovery == null) {
      await db.recoveries.add({ xmlKey, actions, createdAt: date })
    } else {
      await db.recoveries.put({
        ...recovery,
        actions,
        createdAt: date,
      })
    }
  }

  const getStorage = async (xmlKey: string) => {
    const recovery = await db.recoveries.get(xmlKey)
    return recovery
  }

  const clearStorage = async (xmlKey: string) => {
    if (!hasCheckedForExpired) {
      const numberOfDaysForDelete = 7
      const tenDaysAgo =
        Date.now() - numberOfDaysForDelete * 24 * 60 * 60 * 1000
      await db.recoveries
        .where('createdAt')
        .below(tenDaysAgo) // Use timestamp for comparison
        .delete()
      hasCheckedForExpired = true
    }
    await db.recoveries.delete(xmlKey)
  }
  return {
    setActions,
    getStorage,
    clearStorage,
  }
}
