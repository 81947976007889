import { ACTION_TYPE } from './singletons/actionHandler'
import Shape from './shapes/Shape'

const createAction = (type, parameters) => {
  switch (type) {
    case ACTION_TYPE.ADD_RELATION: {
      const { relation } = parameters
      return {
        type,
        relation,
      }
    }
    case ACTION_TYPE.REMOVE_RELATION: {
      const { relation } = parameters
      return {
        type,
        relation,
      }
    }
    case ACTION_TYPE.ADD_SHAPE: {
      const { relation } = parameters
      return {
        type,
        relation,
      }
    }
    case ACTION_TYPE.REMOVE_SHAPE: {
      const { shape, parent } = parameters
      return {
        type,
        shape,
        ...(parent && { parent_id: parent.id }),
        index: getIndex(shape, parent),
      }
    }
    case ACTION_TYPE.REMOVE_BASELINE: {
      const { blueprint, parent_id, index } = parameters
      return {
        type,
        blueprint,
        parent_id,
        index,
      }
    }
    case ACTION_TYPE.REMOVE_REGION: {
      const { blueprint, index } = parameters
      return {
        type,
        blueprint,
        index,
      }
    }
    case ACTION_TYPE.REMOVE_TABLE: {
      const { blueprint, index } = parameters
      return {
        type,
        blueprint,
        index,
      }
    }
  }
}

const getIndex = (shape, parent) => {
  switch (shape.getType()) {
    case Shape.TYPE.WORD: {
      return parent.words.findIndex(w => w.id === shape.id)
    }
    case Shape.TYPE.BASELINE: {
      return parent.baselines.findIndex(l => l.id === shape.id)
    }
    case Shape.TYPE.TABLE:
    case Shape.TYPE.REGION: {
      return parent.regions.findIndex(l => l.id === shape.id)
    }
  }
}

export { createAction }
