<template>
  <div class="relative">
    <BaseInput
      :value="term"
      @input="getAsyncData($event.target.value)"
      @focus="openOnFocus = true"
      :placeholder="'e.g. ' + placeholder"
    ></BaseInput>
    <div
      v-if="openOnFocus && loading"
      class="z-10 w-full bg-white border border-gray-300 rounded mt-2"
    >
      Loading...
    </div>

    <div
      v-if="openOnFocus && !loading"
      class="z-10 w-full bg-white border border-gray-300 rounded mt-2"
    >
      <div
        v-for="option in data"
        :key="option.id"
        @click="selectOption(option)"
        class="p-2 hover:bg-gray-200 cursor-pointer"
      >
        <slot v-bind:option="option"> </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    initTerm: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      term: '',
      openOnFocus: false,
      selected: null,
    }
  },
  mounted() {
    this.term = this.initTerm
  },
  methods: {
    getAsyncData(term) {
      this.term = term
      this.$emit('typing', this.term)
    },
    selectOption(option) {
      this.selected = option
      this.openOnFocus = false
      this.$emit('selectOption', option)
    },
  },
}
</script>
