//https://stackoverflow.com/questions/4811822/get-a-ranges-start-and-end-offsets-relative-to-its-parent-container
export function getSelectionCharacterOffsetWithin(element, range) {
  if (!element) {
    return { start: 0, end: 0 }
  }

  var start = 0
  var end = 0
  var doc = element.ownerDocument || element.document
  var win = doc.defaultView || doc.parentWindow
  var sel
  if (typeof win.getSelection != 'undefined') {
    sel = win.getSelection()
    if (sel.rangeCount > 0) {
      var preCaretRange = range.cloneRange()
      preCaretRange.selectNodeContents(element)
      preCaretRange.setEnd(range.startContainer, range.startOffset)
      start = preCaretRange.toString().length
      preCaretRange.setEnd(range.endContainer, range.endOffset)
      end = preCaretRange.toString().length
    }
  } else if ((sel = doc.selection) && sel.type != 'Control') {
    var textRange = sel.createRange()
    var preCaretTextRange = doc.body.createTextRange()
    preCaretTextRange.moveToElementText(element)
    preCaretTextRange.setEndPoint('EndToStart', textRange)
    start = preCaretTextRange.text.length
    preCaretTextRange.setEndPoint('EndToEnd', textRange)
    end = preCaretTextRange.text.length
  }
  return { start: start, end: end }
}

function getParentDiv(node) {
  if (node?.tagName?.toUpperCase() !== 'DIV') {
    return getParentDiv(node.parentElement)
  }
  return node
}

function getParentSpan(node) {
  if (node.tagName?.toUpperCase() !== 'SPAN') {
    return getParentSpan(node.parentElement)
  }
  return node
}

function getStart(selection) {
  const range = selection.getRangeAt(0).cloneRange()
  range.collapse(true)

  const startDiv = getParentDiv(range.startContainer)

  const offset = getSelectionCharacterOffsetWithin(startDiv, range)

  return { startId: startDiv.id, startIndex: offset.start }
}

function getEnd(selection) {
  const range = selection.getRangeAt(selection.rangeCount - 1).cloneRange()
  range.collapse()

  const endDiv = getParentDiv(range.endContainer)

  const offset = getSelectionCharacterOffsetWithin(endDiv, range)
  return { endId: endDiv.id, endIndex: offset.end }
}

function calcLastRangeRect(selection) {
  const range = selection.getRangeAt(selection.rangeCount - 1)
  let rects = Array.from(range.getClientRects())

  if (rects.length === 0) return

  rects = [rects.at(-1)]

  const minX = Math.min(...rects.map(r => r.left))
  const maxX = Math.max(...rects.map(r => r.right))
  const minY = Math.min(...rects.map(r => r.top))
  const maxY = Math.max(...rects.map(r => r.bottom))
  return { x: minX, width: maxX - minX, y: minY, height: maxY - minY }
}

function getSelection() {
  const selection = window.getSelection()
  if (selection.rangeCount === 0) return null
  const start = getStart(selection)
  const end = getEnd(selection)
  const popupRect = calcLastRangeRect(selection)
  if (start.startId == null || start.startId === '') return
  if (end.endId == null || end.endId === '') return
  if (popupRect == null) return
  return { ...start, ...end, popupRect }
}

function getRangeRects(divId, startIndex, endIndex) {
  const div = document.querySelector('#' + divId)

  const result1 = getRelativeIndex(div, startIndex)
  const result2 = getRelativeIndex(div, endIndex)

  // Check if result1.node and result2.node are Node objects
  if (!(result1.node instanceof Node) || !(result2.node instanceof Node)) {
    console.error('result1.node or result2.node is not a Node object')
    return []
  }

  const range = document.createRange()
  range.setStart(result1.node, result1.pos)
  range.setEnd(result2.node, result2.pos)
  const rects = Array.from(range.getClientRects())

  // Check if any rectangles were returned
  if (rects.length === 0) {
    console.error('No rectangles were returned')
    return []
  }

  return rects.map(r => ({
    x: r.left,
    width: r.right - r.left,
    y: r.top,
    height: r.bottom - r.top,
  }))
}

function setSelection({ startId, endId, startIndex, endIndex }, options) {
  const findFirstTextNodeInNestedDivOrSpan = element => {
    // Check if the current element is a text node
    if (element.nodeType === 3) {
      return element // Found a text node
    }

    // Check if the current element is a div or span
    if (
      element.nodeName.toLowerCase() === 'div' ||
      element.nodeName.toLowerCase() === 'span'
    ) {
      // Iterate over its child nodes
      for (let i = 0; i < element.childNodes.length; i++) {
        // Recursively search for text node within child nodes
        const textNode = findFirstTextNodeInNestedDivOrSpan(
          element.childNodes[i]
        )

        // If found, return the text node
        if (textNode) {
          return textNode
        }
      }
    }

    // No text node found in this subtree
    return null
  }
  const lastTextNodeInNestedDivOrSpan = element => {
    // Check if the current element is a text node
    if (element.nodeType === 3) {
      return element // Found a text node
    }

    // Check if the current element is a div or span
    if (
      element.nodeName.toLowerCase() === 'div' ||
      element.nodeName.toLowerCase() === 'span'
    ) {
      // Iterate over its child nodes in reverse order
      for (let i = element.childNodes.length - 1; i >= 0; i--) {
        // Recursively search for text node within child nodes
        const textNode = lastTextNodeInNestedDivOrSpan(element.childNodes[i])

        // If found, return the text node
        if (textNode) {
          return textNode
        }
      }
    }

    // No text node found in this subtree
    return null
  }

  const addRangeOrEnd = (startId, endId, startIndex, endIndex, ranges) => {
    if (startId == null) return ranges
    const div = document.querySelector('#' + startId)
    const divText = document.querySelector('#text-' + startId)

    //to make sure the cursor can be set to an empty div
    if (!divText.hasChildNodes() || divText.textContent.trim() === '') {
      // Add an empty text node
      const emptyTextNode = document.createTextNode('')
      divText.appendChild(emptyTextNode)
    }

    const { node, pos } = getRelativeIndex(divText, startIndex)
    const newRange = { startNode: node, startIndex: pos }

    if (startId === endId) {
      const { node, pos } = getRelativeIndex(divText, endIndex)
      newRange.endNode = node
      newRange.endIndex = pos
      ranges.push(newRange)
      return ranges
    } else {
      const lastTextNode = lastTextNodeInNestedDivOrSpan(divText)

      newRange.endNode = lastTextNode
      newRange.endIndex = lastTextNode.length
      ranges.push(newRange)
      return addRangeOrEnd(div.nextSibling?.id, endId, 0, endIndex, ranges)
    }
  }

  const isValidNode = value => value !== null && typeof value === 'object'

  setTimeout(() => {
    const rangesData = addRangeOrEnd(startId, endId, startIndex, endIndex, [])

    const sel = window.getSelection()
    sel.removeAllRanges()
    if (isFirefox()) {
      rangesData.forEach(({ startNode, startIndex, endNode, endIndex }) => {
        if (isValidNode(startNode) && isValidNode(endNode)) {
          const range = document.createRange()
          range.setStart(startNode, startIndex)
          range.setEnd(endNode, endIndex)
          sel.addRange(range)
        } else {
          console.error('Invalid startNode or endNode for range in Firefox')
        }
      })
    } else {
      if (rangesData.length > 0) {
        const range = document.createRange()
        range.setStart(rangesData.at(0).startNode, rangesData[0].startIndex)
        range.setEnd(rangesData.at(-1).endNode, rangesData.at(-1).endIndex)
        sel.addRange(range)
      }
    }
  }, options?.delay || 0)

  /* const ranges = []
  const startRange = document.createRange()
  const { node, pos } = getRelativeIndex(divStart, startIndex)
  startRange.setStart(node, pos)
  ranges.push(startRange)
 */
  /*   const ranges = addRangeOrEnd(divStart, divEnd, startIndex, endIndex, [])


  /*   let savetyCounter = 0
  let iteratingDiv = divStart
  while (iteratingDiv !== divEnd && savetyCounter < 1000) {
    savetyCounter++
  } 

  range.setEnd(result2.node, result2.pos)
  const textNodeFirst = findFirstTextNodeInNestedDivOrSpan(this.textDiv)
  const textNodeLast = lastTextNodeInNestedDivOrSpan(this.textDiv)
  range.setStart(textNodeFirst, 0)
  range.setEnd(textNodeLast, textNodeLast.length)
  const sel = window.getSelection()
  sel.removeAllRanges()
  sel.addRange(range)
  /*   if (startId !== endId) {
    console.warn('currently not supported')
    return
  } 

  const result2 = getRelativeIndex(divEnd, endIndex)

  const range = document.createRange()
  range.setStart(result1.node, result1.pos)
  range.setEnd(result2.node, result2.pos)
  const sel = window.getSelection()
  sel.removeAllRanges()
  sel.addRange(range)
  return */
}

//https://stackoverflow.com/questions/36869503/set-caret-position-in-contenteditable-div-that-has-children
// Move caret to a specific point in a DOM element
function getRelativeIndex(el, pos) {
  // Loop through all child nodes
  for (var node of el.childNodes) {
    if (node.nodeType == 3) {
      // we have a text node
      if (node.length >= pos) {
        // finally add our range
        return { node, pos } // we are done
      } else {
        pos -= node.length
      }
    } else {
      pos = getRelativeIndex(node, pos)
      if (pos.node) {
        return pos // no need to finish the for loop
      }
    }
  }
  return pos // needed because of recursion stuff
}

function isCollapsed() {
  const selection = window.getSelection()
  if (selection.rangeCount === 0) return true
  const range = selection.getRangeAt(0)
  return range?.collapsed
}

function isFirefox() {
  return typeof InstallTrigger !== 'undefined'
}

function getSelectionRanges() {
  var selection = window.getSelection()
  var ranges = []
  for (var i = 0; i < selection.rangeCount; i++) {
    ranges.push(selection.getRangeAt(i).cloneRange())
  }
  return ranges
}

function clearSelection() {
  window.getSelection().removeAllRanges()
}

function setSelectionRanges(ranges) {
  const selection = window.getSelection()
  selection.removeAllRanges()
  ranges.forEach(r => {
    selection.addRange(r)
  })
  return ranges
}

function reApplySelection({ milliSeconds }) {
  const ranges = getSelectionRanges()
  setTimeout(() => {
    setSelectionRanges(ranges)
  }, milliSeconds)
}

function reApplyCursor({ milliSeconds }) {
  const selection = window.getSelection()
  if (selection.rangeCount === 0) return
  const range = selection.getRangeAt(0)
  range.collapse()
  setTimeout(() => {
    setSelectionRanges([range])
  }, milliSeconds)
}

export default {
  getSelection,
  setSelection,
  getRangeRects,
  reApplySelection,
  isCollapsed,
  clearSelection,
  reApplyCursor,
}
