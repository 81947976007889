import { removeShapesByIds } from '../Model/modelUtils.ts'
import BaseAction from './BaseAction.js'

export default class DeleteShapes extends BaseAction {
  constructor(pageJson: any) {
    super(pageJson)
  }

  apply(params: any) {
    const { shapeIds } = params
    removeShapesByIds(shapeIds, this.pageJson)
  }
}
