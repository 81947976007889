import {
  getShapeById,
  setCoordsForElement,
  getCoordsFromElement,
  getBaselineFromElement,
  setBaselineForElement,
} from '../Model/modelUtils.ts'
import BaseAction from './BaseAction.js'

export default class Move extends BaseAction {
  constructor(pageJson?: any) {
    super(pageJson)
  }

  apply(params: any) {
    const positions = params.positions
    positions.forEach(({ id, coords, baseline }) => {
      const shape = getShapeById(id, this.pageJson)
      if (coords) {
        const elementCoords = getCoordsFromElement(shape)
        coords.forEach(({ index, coordinates }) => {
          elementCoords[index] = coordinates
        })
        setCoordsForElement(shape, elementCoords)
      }
      if (baseline) {
        const elementBaseline = getBaselineFromElement(shape)
        baseline.forEach(({ index, coordinates }) => {
          elementBaseline[index] = coordinates
        })
        setBaselineForElement(shape, elementBaseline)
      }
    })
  }
}
