import { ACTION_TYPE } from '../singletons/actionHandler.js'
import { Singleton as IdHandler } from '../singletons/IdHandler.js'

import Shape from './Shape.js'
import { polygonMean, pointRotate } from 'geometric'
import Editor from '../editor.js'
import Word from './Word.js'
import { isEqual } from 'lodash'
import {
  projectPointer,
  calcLines,
  distanceBetween,
} from '../geometryFunctions.js'
import { getRotationAngle, getScaleFactor } from '../../globals.js'
import * as Sentry from '@sentry/vue'
import {
  getBaselineFromElement,
  getCoordsFromElement,
} from '../../../Model/modelUtils.ts'

function Baseline(
  canvas,
  blueprint,
  editorName,
  noUpdate,
  index,
  getActiveShapeIds
) {
  Shape.call(this, canvas, editorName)

  this.activeVertex = null
  this.tempCoords = null
  this.line = null
  this.polygonLine = null
  this.polygonBackground = null
  this.group = null
  this.prevLine = null
  this.lastAddedVertex = {}
  this.alive = true
  this.modified = true
  this.insertionPosition = null
  this.polygonVertices = []
  this.previewCircle = {} //not used
  this.polyCoords = []
  this.highlightBox = null
  this.words = []
  this.geometricLinesPolygon = []
  this.index = index
  this.getActiveShapeIds = getActiveShapeIds
  this.baselineCoords = null
  this.polygonCoords = null

  if (blueprint) {
    this.modified = false
    this.attributes = blueprint.attributes
    this.elements = blueprint.elements
    this.id = blueprint.attributes.id
    IdHandler.instance(this.editorName).addId(this.id)

    this.text = blueprint.elements.text
    this.words = blueprint.elements.words.map(
      blueprint => new Word(canvas, blueprint, editorName, getActiveShapeIds)
    )
    /*     blueprint.elements.baselineCoords.map(coord => {
      let newVertex = this.getVertex(coord.x, coord.y)
      this.addPoint(newVertex, this.vertices.length, noUpdate)
    }) */
    /*     if (blueprint.elements.polyCoords.length > 30) {
      const { $features } = useNuxtApp()
       if ($features.polygonSimplification) {
        blueprint.elements.polyCoords = simplify(
          blueprint.elements.polyCoords,
          5
        )
      } 
    } */
    /* blueprint.elements.polyCoords.map(coord => {
      let newVertex = this.getPolygonVertex(coord.x, coord.y)
      this.addPolygonVertex(newVertex, this.polygonVertices.length, noUpdate)
    }) */
    this.update()
  } else {
    this.id = IdHandler.instance(this.editorName).requestId('l')
  }
}

Baseline.prototype = Object.create(Shape.prototype)
Baseline.prototype.constructor = Baseline

Baseline.prototype.getBlueprint = function (myIndex) {
  /* this.elements.polyCoords =
    this.getScaledPolygonCoords() ||
    this.polygonVertices.map(v => ({ x: v.left, y: v.top })) */

  //this.attributes.id = keepId ? this.id : `${parentId}l${myIndex + 1}`
  this.attributes.id = this.id

  this.setReadingOrder(myIndex)

  /* this.elements.baselineCoords =
    this.getScaledBaselineCoords() ||
    this.vertices.map(v => ({ x: v.left, y: v.top })) */
  this.elements.words = this.words.map((word, index) =>
    word.getBlueprint(index)
  )
  this.elements.text = this.text
  return {
    attributes: this.attributes,
    elements: {
      ...this.elements,
      polyCoords:
        this.getScaledPolygonCoords() ||
        this.polygonVertices.map(v => ({ x: v.left, y: v.top })),
      baselineCoords:
        this.getScaledBaselineCoords() ||
        this.vertices.map(v => ({ x: v.left, y: v.top })),
    },
  }
}

Baseline.prototype.getType = function () {
  return Shape.TYPE.BASELINE
}

Baseline.prototype.getWords = function () {
  return this.words
}

Baseline.prototype.getLayout = function () {
  return {
    type: 'Baseline',
    text: this.text,
    id: this.id,
    structureType: this.getStructureType(),
    custom: this.getCustomWithTagIds(),
  }
}

Baseline.prototype.addPolygonVertex = function (vertex, index, noUpdate) {
  this.canvas.add(vertex)
  this.polygonVertices.splice(index, 0, vertex)
  if (this.polygonVertices.length > 1) {
    if (!noUpdate) {
      this.update()
    }
  }
}

Baseline.prototype.mark = function () {
  this.canvas.remove(this.polygonBackground)

  this.polygonCoords = this.getScaledPolygonCoords()
  this.polygonBackground = this.myFabric.getPolygonBackground(
    this.polygonCoords,
    this.id
  )
  this.canvas.add(this.polygonBackground)
}

Baseline.prototype.unMark = function () {
  this.canvas.remove(this.polygonBackground)
  this.canvas.requestRenderAll()
}

Baseline.prototype.getVertex = function (x, y, id) {
  let vertex = this.myFabric.getBaselineCircle(x, y, id)
  let me = this
  this.lastAddedVertex = vertex

  vertex.select = () => {
    if (this.activeVertex) {
      this.activeVertex.set('fill', vertex.defaultColor)
    }
    this.activeVertex = vertex
    this.activeVertex.set('fill', vertex.selectedColor)
    this.canvas.requestRenderAll()
  }

  vertex.unSelect = () => {
    if (this.activeVertex) {
      this.activeVertex.set('fill', vertex.defaultColor)
      this.activeVertex = null
    }
    this.canvas.requestRenderAll()
  }

  vertex.on('mousedown', function (o) {
    me.tempCoords = { left: this.left, top: this.top }

    this.select()

    if (me.state === Shape.STATE.REMOVE_VERTEX) {
      if (me.vertices.length > 2) {
        let index = me.vertices.findIndex(v => v.id === o.target.id)
        me.removePoint(o.target.id)
        me.modified = true
        me.actionHandler.addAction({
          type: ACTION_TYPE.REMOVE_POINT,
          shape_id: me.id,
          point_id: o.target.id,
          point_index: index,
        })
      }
    }
  })
  vertex.on('moving', o => {
    //me.update({ geometricLines: false })
    const coords = this.vertices.map(v => {
      return { x: v.left, y: v.top }
    })
    this.updateLineAndArrows(coords)
    this.updateHighlightBox(coords)
  })
  vertex.on('mousedblclick', function (o) {
    // me.groupSelect()
  })
  vertex.on('mouseup', o => {
    if (
      this.tempCoords &&
      this.state != Shape.STATE.GROUP_SELECTION &&
      (this.tempCoords.left != vertex.left || this.tempCoords.top != vertex.top)
    ) {
      this.modified = true

      const vector = {
        x: vertex.left - this.tempCoords.left,
        y: vertex.top - this.tempCoords.top,
      }

      vertex.set({ left: this.tempCoords.left, top: this.tempCoords.top })
      vertex.setCoords()

      this.actionHandler.newAction({
        type: ACTION_TYPE.MOVE_POINT,
        shape_id: this.id,
        point_index: this.vertices.indexOf(vertex),
        vector,
      })
      this.tempCoords = null
    }
  })
  // vertex.on('mouseover', () => {
  //   if (this.state === Shape.STATE.EDIT) {
  //     vertex.set({ radius: vertex.defaultRadius * 1.5 })
  //     vertex.bringToFront()
  //     this.canvas.requestRenderAll()
  //     this.hoveredVertex = vertex
  //   }
  // })

  return vertex
}

Baseline.prototype.movePolygonPoint = function (action) {
  const vertex = this.polygonVertices[action.point_index]
  const { x, y } = action.vector
  if (vertex) {
    vertex.set({ left: vertex.left + x, top: vertex.top + y })
    vertex.setCoords()
    this.update()
  }
}

Baseline.prototype.movePolygonPoints = function (action) {
  const vectors = action.vectors
  this.polygonVertices.forEach((vertex, index) => {
    vertex.set({
      left: vertex.left + vectors[index].x,
      top: vertex.top + vectors[index].y,
    })
    vertex.setCoords()
  })
  this.update()
}

Baseline.prototype.getPolygonVertex = function (x, y, id) {
  let vertex = this.myFabric.getPolygonCircle(x, y, id)
  this.lastAddedVertex = vertex

  vertex.select = () => {
    if (this.activeVertex) {
      this.activeVertex.set('fill', vertex.defaultColor)
    }
    this.activeVertex = vertex
    this.activeVertex.set('fill', vertex.selectedColor)
    this.canvas.requestRenderAll()
  }

  vertex.unSelect = () => {
    if (this.activeVertex) {
      this.activeVertex.set('fill', vertex.defaultColor)
      this.activeVertex = null
    }
    this.canvas.requestRenderAll()
  }

  vertex.on('mousedown', o => {
    this.tempCoords = { left: vertex.left, top: vertex.top }
    vertex.select()
  })
  vertex.on('moving', o => {
    //this.update({ geometricLines: false })
    const polygonCoords = this.polygonVertices.map(v => {
      return { x: v.left, y: v.top }
    })
    this.updatePolygonLine(polygonCoords)
    this.updatePolygonBackground(polygonCoords)
  })
  vertex.on('mouseup', o => {
    if (
      this.tempCoords &&
      this.state != Shape.STATE.GROUP_SELECTION &&
      (this.tempCoords.left != vertex.left || this.tempCoords.top != vertex.top)
    ) {
      this.modified = true

      const vector = {
        x: vertex.left - this.tempCoords.left,
        y: vertex.top - this.tempCoords.top,
      }

      this.actionHandler.newAction({
        type: ACTION_TYPE.MOVE_POLYGON_POINT,
        shape_id: this.id,
        point_index: this.polygonVertices.indexOf(vertex),
        vector,
      })
      this.tempCoords = null
    }
  })
  return vertex
}

Baseline.prototype.setState = function (state) {
  //never allow change of Selection only
  if (this.state === Shape.STATE.SELECTION_ONLY) return
  if (this.state === Shape.STATE.SEARCH_RESULTS) return

  if (this.activeVertex) {
    this.activeVertex.unSelect()
  }

  if (this.prevLine || this.previewCircle) {
    this.canvas.remove(this.prevLine, this.previewCircle)
    this.prevLine = null
    this.previewCircle = null
  }

  // if (
  //   this.state === Shape.STATE.GROUP_SELECTION &&
  //   state != Shape.STATE.GROUP_SELECTION
  // ) {
  //   this.unGroupSelect()
  // }
  if (
    this.state === Shape.STATE.REMOVE_VERTEX &&
    state != Shape.STATE.REMOVE_VERTEX
  ) {
    this.vertices.map(v => {
      v.set({ hoverCursor: 'default' })
    })
  }
  if (state === Shape.STATE.ADD_VERTEX) {
    this.previewVertex(this.lastMousePosition.x, this.lastMousePosition.y)
  }
  if (state === Shape.STATE.REMOVE_VERTEX) {
    this.vertices.map(v => {
      v.set({ hoverCursor: 'pointer' })
    })
  }
  // if (
  //   state === Shape.STATE.GROUP_SELECTION &&
  //   this.state != Shape.STATE.GROUP_SELECTION
  // ) {
  //   this.groupSelect()
  // }
  if (state === Shape.STATE.EDIT && this.state != Shape.STATE.EDIT) {
    this.canvas.discardActiveObject()
    this.canvas.requestRenderAll()
  }
  //log(state)
  this.state = state
}

Baseline.prototype.handleClickEvent = function (event) {
  if (this.state === Shape.STATE.ADD_VERTEX) {
    if (this.insertionPosition) {
      this.insertVertex(this.insertionPosition.x, this.insertionPosition.y)
      //this.lines = calcLines(this.vertices)
    }
  }

  if (this.state === Shape.STATE.GROUP_SELECTION) {
    // if (event.target) {
    // }
    // let pointer = this.canvas.getPointer(event)
    // let point = [pointer.x, pointer.y]
  }
  if (this.state === Shape.STATE.EDIT) {
    //   let pointer = this.canvas.getPointer(event)
    //   let point = [pointer.x, pointer.y]
    //   if (
    //     pointInPolygon(point, this.polyCoords) &&
    //     !event.newSelection &&
    //     !event.target
    //   ) {
    //     this.setState(Shape.STATE.GROUP_SELECTION)
    //   }
  }
}

Baseline.prototype.handleMouseUpEvent = function (position) {
  if (this.state === Shape.STATE.FIRST_DRAW) {
    let newVertex = this.myFabric.getBaselineCircle(position.x, position.y)
    this.addPoint(newVertex, this.vertices.length)
  }
}

Baseline.prototype.handleDBLClickEvent = function (event, setButtonState) {
  if (this.state === Shape.STATE.FIRST_DRAW) {
    // this.removeLastAddedVertex()
    // this.setState(Shape.STATE.EDIT)
    // this.unSelect()
  }
  if (this.state === Shape.STATE.ADD_VERTEX) {
    //move this to editor
    this.removeLastAddedVertex()
    this.state = Shape.STATE.EDIT
    setButtonState(Editor.BUTTON.POINTER)
  }
}

Baseline.prototype.handleMouseMoveEvent = function (event) {
  if (this.state === Shape.STATE.ADD_VERTEX) {
    let mousePosition = this.canvas.getPointer(event.e)
    this.previewVertex(mousePosition.x, mousePosition.y)
  } else {
    if (this.state === Shape.STATE.FIRST_DRAW) {
      let mousePosition = this.canvas.getPointer(event.e)
      this.previewFirstDraw(mousePosition.x, mousePosition.y)
    } else {
      if (this.prevLine || this.previewCircle) {
        this.canvas.remove(this.prevLine, this.previewCircle)
        this.prevLine = null
        this.previewCircle = null
      }
    }
  }
  if (this.state === Shape.STATE.EDIT) {
    if (this.preview) {
      let pointer = this.canvas.getPointer(event.e)
      let minDistanceToCreate = 0.01
      this.canvas.remove(this.runningVertex)
      let { line, point } = projectPointer(this.geometricLines, pointer)
      if (point && line && this.settings.get('showBaselines')) {
        this.runningVertex = this.myFabric.getRunningCircleBaseline(
          point[0],
          point[1]
        )
        let self = this
        this.runningVertex.on('mousedown', function (o) {
          self.preview = false
        })
        this.runningVertex.on('moving', function (o) {
          let parent1 = self.getVertexById(line.parentIds[0])
          let parent2 = self.getVertexById(line.parentIds[1])

          if (!parent1 || !parent2) {
            Sentry.captureMessage(
              `parent1 or parent2 is null in Baseline {parent1: ${parent1}, parent2: ${parent2}}, line id: ${self.id}`
            )
            return
          }

          let parentDistance = distanceBetween(
            parent1.left,
            parent1.top,
            parent2.left,
            parent2.top
          )
          let distanceOverVertex =
            distanceBetween(this.left, this.top, parent1.left, parent1.top) +
            distanceBetween(this.left, this.top, parent2.left, parent2.top)

          let opacity =
            (distanceOverVertex - parentDistance) / minDistanceToCreate + 0.2

          self.canvas.remove(...self.prevLines)
          self.prevLines = [
            self.myFabric.getPreviewLineBaseline(
              [this.left, this.top, parent1.left, parent1.top],
              opacity
            ),
            self.myFabric.getPreviewLineBaseline(
              [this.left, this.top, parent2.left, parent2.top],
              opacity
            ),
          ]
          this.set('opacity', opacity)
          self.canvas.add(...self.prevLines)
        })
        this.runningVertex.on('mouseup', function (o) {
          let parent1 = self.getVertexById(line.parentIds[0])
          let parent2 = self.getVertexById(line.parentIds[1])
          let parentDistance = distanceBetween(
            parent1.left,
            parent1.top,
            parent2.left,
            parent2.top
          )
          let distanceOverVertex =
            distanceBetween(this.left, this.top, parent1.left, parent1.top) +
            distanceBetween(this.left, this.top, parent2.left, parent2.top)

          if (distanceOverVertex - parentDistance > minDistanceToCreate) {
            self.canvas.remove(this)
            self.actionHandler.newAction({
              type: ACTION_TYPE.ADD_POINT,
              shape_id: self.id,
              point_coord: {
                x: self.runningVertex.left,
                y: self.runningVertex.top,
              },
              point_index: self.getVertexIndex(line.parentIds[1]),
            })
          }
          self.preview = true
          self.canvas.remove(...self.prevLines)
          self.canvas.remove(this)
        })
        this.canvas.add(this.runningVertex)
        this.runningVertex.sendToBack()
        this.canvas.requestRenderAll()
      } else {
        let { line: lineP, point: pointP } = projectPointer(
          this.geometricLinesPolygon,
          pointer
        )
        if (pointP && lineP && this.settings.get('showLinePolygons')) {
          this.runningVertex = this.myFabric.getRunningCircleLinePolygon(
            pointP[0],
            pointP[1]
          )
          let self = this
          this.runningVertex.on('mousedown', function (o) {
            self.preview = false
          })
          this.runningVertex.on('moving', function (o) {
            let parent1 = self.getVertexById(lineP.parentIds[0])
            let parent2 = self.getVertexById(lineP.parentIds[1])
            let parentDistance = distanceBetween(
              parent1.left,
              parent1.top,
              parent2.left,
              parent2.top
            )
            let distanceOverVertex =
              distanceBetween(this.left, this.top, parent1.left, parent1.top) +
              distanceBetween(this.left, this.top, parent2.left, parent2.top)

            let opacity =
              (distanceOverVertex - parentDistance) / minDistanceToCreate + 0.2

            self.canvas.remove(...self.prevLines)
            self.prevLines = [
              self.myFabric.getPreviewLineLinePolygon(
                [this.left, this.top, parent1.left, parent1.top],
                opacity
              ),
              self.myFabric.getPreviewLineLinePolygon(
                [this.left, this.top, parent2.left, parent2.top],
                opacity
              ),
            ]
            this.set('opacity', opacity)
            self.canvas.add(...self.prevLines)
          })
          this.runningVertex.on('mouseup', o => {
            let parent1 = this.getVertexById(lineP.parentIds[0])
            let parent2 = this.getVertexById(lineP.parentIds[1])
            let parentDistance = distanceBetween(
              parent1.left,
              parent1.top,
              parent2.left,
              parent2.top
            )
            let distanceOverVertex =
              distanceBetween(
                this.runningVertex.left,
                this.runningVertex.top,
                parent1.left,
                parent1.top
              ) +
              distanceBetween(
                this.runningVertex.left,
                this.runningVertex.top,
                parent2.left,
                parent2.top
              )

            if (distanceOverVertex - parentDistance > minDistanceToCreate) {
              this.canvas.remove(this.runningVertex)
              this.actionHandler.newAction({
                type: ACTION_TYPE.ADD_POLYGON_POINT,
                shape_id: this.id,
                point_coord: {
                  x: this.runningVertex.left,
                  y: this.runningVertex.top,
                },
                point_index: this.getVertexIndex(lineP.parentIds[1]),
              })
            }
            this.preview = true
            this.canvas.remove(...this.prevLines)
            this.canvas.remove(this.runningVertex)
          })
          this.canvas.add(this.runningVertex)
          this.runningVertex.sendToBack()
          this.canvas.requestRenderAll()
        }
      }
    }
  }
}

// Baseline.prototype.handleMouseOver = function (event) {
//   if (event.target && event.target.id === this.id) {
//   }
// }

Baseline.prototype.handleMouseOut = function (event) {
  // if (event.target?.type === 'circle') {
  //   if (this.hoveredVertex) {
  //     this.hoveredVertex.set({ radius: this.hoveredVertex.defaultRadius })
  //     this.canvas.discardActiveObject()
  //     this.canvas.requestRenderAll()
  //     this.hoveredVertex = null
  //   }
  // }
}

Baseline.prototype.addWord = function (word, index) {
  this.words.splice(index, 0, word)
}

Baseline.prototype.select = function () {
  this.selected = true
  //this.canvas.remove(this.polygon)
  //this.canvas.remove(...this.vertices)
  //this.canvas.add(...this.vertices)
  this.update()
}

Baseline.prototype.unSelect = function () {
  this.selected = false
  this.setState(Shape.STATE.EDIT)
  /* this.canvas.remove(...this.vertices)
  this.canvas.remove(this.prevLine, this.previewCircle, this.runningVertex) */
  this.prevLine = null
  this.previewCircle = null

  // this.polygon.set('opacity', 0.15)//test
  //this.polygon.set('opacity', 0)
  //this.line.set('opacity', this.line.opacityValue)
  //this.updateStructureText()
  this.update()
}

Baseline.prototype.removeLastAddedVertex = function () {
  const index = this.vertices.indexOf(this.lastAddedVertex)
  if (index > -1) {
    this.vertices.splice(index, 1)
    this.canvas.remove(this.lastAddedVertex)
    this.update()
  }
}

Baseline.prototype.insertVertex = function (x, y) {
  this.actionHandler.newAction({
    type: ACTION_TYPE.ADD_POINT,
    shape_id: this.id,
    point_index: this.insertionPosition.index,
    point_coord: {
      x,
      y,
    },
  })
}

Baseline.prototype.previewVertex = function (x, y) {
  if (x === 0 || y === 0) return
  this.canvas.remove(this.prevLine)
  let first = this.baselineCoords[0]
  let last = this.baselineCoords[this.baselineCoords.length - 1]
  let current
  let index
  if (
    distanceBetween(first.x, first.y, x, y) <
    distanceBetween(last.x, last.y, x, y)
  ) {
    current = first
    index = 0
  } else {
    current = last
    index = this.baselineCoords.length
  }

  this.prevLine = this.myFabric.getPreviewLineBaseline([
    x,
    y,
    current.x,
    current.y,
  ])
  this.insertionPosition = { x: x, y: y, index: index }
  this.canvas.add(this.prevLine)

  // this.canvas.remove(this.previewCircle, this.prevLine)
  // this.previewCircle = null

  // //this needs claenUp
  // this.insertionPosition = null
  // let first = this.vertices[0]
  // let last = this.vertices[this.vertices.length - 1]
  // let current
  // let index
  // let x1 = x
  // let y1 = y
  // let x3
  // let y3
  // let x2
  // let y2
  // if (
  //   distanceBetween(first.left, first.top, x, y) <
  //   distanceBetween(last.left, last.top, x, y)
  // ) {
  //   current = first
  //   x3 = current.left
  //   y3 = current.top
  //   x2 = this.vertices[1].left
  //   y2 = this.vertices[1].top
  //   index = 0
  // } else {
  //   current = last
  //   x3 = current.left
  //   y3 = current.top
  //   x2 = this.vertices[this.vertices.length - 2].left
  //   y2 = this.vertices[this.vertices.length - 2].top
  //   index = this.vertices.length
  // }

  // this.lines.map((line) => {
  //   if (pointOnLine([x, y], line, 40)) {
  //     this.previewCircle = getPreviewCircle(x, y)
  //     this.insertionPosition = { x: x, y: y, index: line[2].index }
  //   }
  // })
  // if (this.previewCircle) {
  //   this.canvas.add(this.previewCircle)
  // }

  // const getAngle = ({ x: x1, y: y1 }, { x: x2, y: y2 }) => {
  //   const dot = x1 * x2 + y1 * y2
  //   const det = x1 * y2 - y1 * x2
  //   const angle = (Math.atan2(det, dot) / Math.PI) * 180
  //   return (angle + 360) % 360
  // }
  // let angle = getAngle(
  //   {
  //     x: x1 - x3,
  //     y: y1 - y3,
  //   },
  //   {
  //     x: x2 - x3,
  //     y: y2 - y3,
  //   }
  // )

  // if (angle < 270 && angle > 90) {
  //   this.prevLine = getPreviewLine([x, y, current.left, current.top])
  //   this.insertionPosition = { x: x, y: y, index: index }
  //   this.canvas.add(this.prevLine)
  // }
}
Baseline.prototype.previewFirstDraw = function (x, y) {
  let newestVertex = this.vertices[this.vertices.length - 1]
  if (newestVertex == null) return
  this.canvas.remove(this.prevLine)
  this.prevLine = this.myFabric.getPreviewLineBaseline([
    x,
    y,
    newestVertex.left,
    newestVertex.top,
  ])

  this.canvas.add(this.prevLine)
}

Baseline.prototype.getCenter = function () {
  const coords = this.vertices.map(({ left, top }) => [left, top])
  return polygonMean(coords)
}

Baseline.prototype.getMidPointForFocus = function () {
  const currentCoords =
    this.getScaledPolygonCoords() || this.getScaledBaselineCoords()

  if (currentCoords == null) return

  const coords = currentCoords.map(({ x, y }) => [x, y])

  if (coords.length === 1) return [coords[0][0], coords[0][1]]
  return polygonMean(coords)
}

Baseline.prototype.getMidPoint = function () {
  const currentCoords = this.polygonCoords || this.baselineCoords

  if (currentCoords == null) return

  const coords = currentCoords.map(({ x, y }) => [x, y])

  if (coords.length === 1) return [coords[0][0], coords[0][1]]
  return polygonMean(coords)
}

// Baseline.prototype.groupSelect = function () {
//   this.removeFromCanvas()
//   this.group = this.getGroup()

//   this.tempCoords = this.myFabric.getGroupData(this.group)

//   this.canvas.add(this.group)
//   this.canvas.setActiveObject(this.group)
// }

// Baseline.prototype.unGroupSelect = function () {
//   this.canvas.remove(this.group)
//   this.canvas.discardActiveObject()

//   let coords = this.myFabric.getGroupData(this.group)

//   if (!isEqual(this.tempCoords, coords)) {
//     this.modified = true
//     this.actionHandler.addAction({
//       type: ACTION_TYPE.MOVE_SHAPE,
//       shape_id: this.id,
//       old_coords: this.tempCoords.vertices,
//       new_coords: coords.vertices,
//     })
//     this.tempCoords = null
//   }
//   this.moveAllPoints(coords.vertices)
// }

Baseline.prototype.getGroup = function () {
  const members = []

  if (this.line) {
    members.push(this.myFabric.clone(this.line).set('opacity', 0.5))
  }

  if (this.vertices && this.vertices.length > 0) {
    const verticesClones = this.vertices.map(v =>
      this.myFabric.clone(v).set('opacity', 0.5)
    )
    members.push(...verticesClones)
  }

  if (this.polygonBackground) {
    members.push(this.myFabric.clone(this.polygonBackground))
  }

  if (this.polygonVertices && this.polygonVertices.length > 0) {
    const polygonVerticesClones = this.polygonVertices.map(v =>
      this.myFabric.clone(v).set('opacity', 0.5)
    )
    members.push(...polygonVerticesClones)
  }

  return new fabric.Group(members, {
    id: this.id,
    hasControls: true,
    cornerStyle: 'circle',
    padding: 0,
    cornerSize: 8,
    cornerColor: 'transparent',
    transparentCorners: true,
    cornerStrokeColor: 'blue',
    lockSkewingX: true,
    lockSkewingY: true,
  }).setControlsVisibility({
    mtr: false,
  })
}

/* Baseline.prototype.showRed = function() {
  if (this.polygon.fill === this.polygon.defaultColor) {
    this.polygon?.set('fill', this.polygon.deleteColor)
    this.canvas.requestRenderAll()
  }
} */

/* Baseline.prototype.showNormal = function() {
  if (this.polygon.fill === this.polygon.deleteColor) {
    this.polygon?.set('fill', this.polygon.defaultColor)
    this.canvas.requestRenderAll()
  }
} */

Baseline.prototype.updatePolygonLine = function (polygonCoords) {
  this.canvas.remove(this.polygonLine)
  if (polygonCoords == null || polygonCoords.length === 0) return

  this.polygonLine = this.myFabric.getPolygonLine(polygonCoords, this.id)
  this.canvas.add(this.polygonLine)
  this.canvas.sendToBack(this.polygonLine)
}

Baseline.prototype.updatePolygonBackground = function (polygonCoords) {
  this.canvas.remove(this.polygonBackground)

  if (polygonCoords == null || polygonCoords.length === 0 || !this.selected) {
    return
  }

  this.polygonBackground = this.myFabric.getPolygonBackground(
    polygonCoords,
    this.id
  )
  this.canvas.add(this.polygonBackground)
  this.canvas.sendToBack(this.polygonBackground)
}

Baseline.prototype.setAllVertices = function () {
  const baselineCoords = this.getScaledBaselineCoords()
  if (baselineCoords) {
    this.vertices = baselineCoords.map(coord => {
      return this.getVertex(coord.x, coord.y)
    })
  }
  const polygonCoords = this.getScaledPolygonCoords()
  if (polygonCoords) {
    this.polygonVertices = polygonCoords.map(coord => {
      return this.getPolygonVertex(coord.x, coord.y)
    })
  }
}

Baseline.prototype.updateVertices = function (baselineCoords, polyCoords) {
  this.canvas.remove(...this.vertices)
  this.canvas.remove(...this.polygonVertices)

  if (!this.selected || !this.myFabric.isFocused) return

  if (baselineCoords) {
    this.vertices = baselineCoords.map(coord => {
      return this.getVertex(coord.x, coord.y)
    })
    this.updateGeometricLines(this.vertices)
    this.canvas.add(...this.vertices)
  }
  if (polyCoords) {
    this.polygonVertices = polyCoords.map(coord => {
      return this.getPolygonVertex(coord.x, coord.y)
    })
    this.updateGeometricLines(null, this.polygonVertices)
    this.canvas.add(...this.polygonVertices)
  }

  /*   const circleSize = this.myFabric.getCircleSize()
  const baselineColor = this.myFabric.getBaselineColor()
  const polygonColor = this.myFabric.getLinePolygonColor() */
  /*   this.vertices.forEach(vertex => {
    if (vertex.radius != circleSize) {
      vertex.set({
        radius: circleSize,
        defaultRadius: circleSize,
      })
    }
    if (
      vertex.stroke != baselineColor ||
      vertex.selectedColor != baselineColor
    ) {
      vertex.set({
        stroke: baselineColor,
        selectedColor: baselineColor,
      })
      if (vertex === this.activeVertex) {
        vertex.set('fill', baselineColor)
      }
    }
    this.canvas.remove(vertex)
    this.canvas.add(vertex)
  })
  this.polygonVertices.forEach(vertex => {
    if (vertex.radius != circleSize) {
      vertex.set({
        radius: circleSize,
        defaultRadius: circleSize,
      })
    }
    if (vertex.stroke != polygonColor || vertex.selectedColor != polygonColor) {
      vertex.set({
        stroke: polygonColor,
        selectedColor: polygonColor,
      })
      if (vertex === this.activeVertex) {
        vertex.set('fill', polygonColor)
      }
    }
    this.canvas.remove(vertex)

    this.canvas.add(vertex)
  }) */
}

Baseline.prototype.updateHighlightBox = function (baselineCoords) {
  this.canvas.remove(this.highlightBox)
  if (!this.selected) return
  if (baselineCoords == null) {
    baselineCoords = this.getScaledBaselineCoords()
  }
  if (baselineCoords == null) return
  if (baselineCoords && baselineCoords.length === 0) return

  const maxPolygonY = Math.max(...this.polygonVertices.map(v => v.top))
  const minPolygonY = Math.min(...this.polygonVertices.map(v => v.top))
  const { polygon, polyCoords } = this.myFabric.getPolygon(
    baselineCoords.map(({ x, y }) => [x, y]),
    this.id,
    maxPolygonY,
    minPolygonY
  )
  this.highlightBox = polygon
  this.polyCoords = polyCoords
  this.canvas.add(this.highlightBox)
  this.canvas.sendToBack(this.highlightBox)
}

Baseline.prototype.getScaledPolygonCoords = function () {
  const factor = getScaleFactor()
  const rotationAngle = getRotationAngle()
  const polygonCoords = getCoordsFromElement(this.elements.json)
  if (polygonCoords == null) return
  return polygonCoords.map(c => {
    const scaled = pointRotate([c.x * factor, c.y * factor], rotationAngle)
    return { x: scaled[0], y: scaled[1] }
  })
}

Baseline.prototype.getScaledBaselineCoords = function () {
  const factor = getScaleFactor()
  const rotationAngle = getRotationAngle()
  const baselineCoords = getBaselineFromElement(this.elements.json)
  if (baselineCoords == null) return
  return baselineCoords.map(c => {
    const scaled = pointRotate([c.x * factor, c.y * factor], rotationAngle)
    return { x: scaled[0], y: scaled[1] }
  })
}

Baseline.prototype.update = function () {
  if (this.state === Shape.STATE.FIRST_DRAW) return

  this.selected = this.getActiveShapeIds?.().includes(this.id)

  const baselineCoords = this.settings.get('showBaselines')
    ? this.getScaledBaselineCoords()
    : null

  const polygonCoords = this.settings.get('showLinePolygons')
    ? this.getScaledPolygonCoords()
    : null

  this.baselineCoords = baselineCoords
  this.polygonCoords = polygonCoords

  this.updateVertices(baselineCoords, polygonCoords)

  this.updateLineAndArrows(baselineCoords)
  this.updateHighlightBox(baselineCoords)
  this.updatePolygonLine(polygonCoords)
  this.updatePolygonBackground(polygonCoords)

  this.updateStructureText(polygonCoords || baselineCoords)
  this.updateReadingOrderText(polygonCoords || baselineCoords)

  if (this.state === Shape.STATE.SELECTION_ONLY) {
    this.canvas.remove(...this.vertices)
    this.canvas.remove(...this.polygonVertices)
  }
  if (this.state === Shape.STATE.SEARCH_RESULTS) {
    this.removeFromCanvas()
    if (this.selected) {
      const newCoords = this.getScaledPolygonCoords()
      this.updatePolygonBackground(newCoords)
      this.updatePolygonLine(newCoords)
      return
    }
  }
  if (this.state === Shape.STATE.FIRST_DRAW) {
    this.canvas.remove(this.polygonBackground)
    this.canvas.add(...this.vertices)
  }
  this.baselineCoords = baselineCoords
  this.polygonCoords = polygonCoords
}

Baseline.prototype.updateLineAndArrows = function (baselineCoords) {
  this.canvas.remove(this.line)
  if (baselineCoords == null || baselineCoords.length === 0) return

  const polyVertices = baselineCoords.map(vertex => {
    return [vertex.x, vertex.y]
  })
  const { line } = this.myFabric.getLineElements(
    polyVertices,
    this.id,
    this.selected
  )
  this.line = line
  if (!this.selected) {
    this.line?.set?.('opacity', this.line?.opacityValue)
  }
  this.canvas.add(this.line)
  this.canvas.sendToBack(this.line)
}

Baseline.prototype.calculatePolygon = function () {
  const polyVertices = this.vertices.map(vertex => [vertex.left, vertex.top])
  const { polyCoords } = this.myFabric.getPolygon(polyVertices, this.id)
  this.polygonVertices = polyCoords.map(([x, y]) =>
    this.myFabric.getPolygonCircle(x, y)
  )
  /*  this.polygon = polygon
  this.polyCoords = polyCoords
  if (this.selected) {
    this.polygon?.set('opacity', this.polygon.opacityValue)
  } else {
    this.polygon?.set('opacity', 0)
  }
  this.canvas.add(this.polygon)
  this.canvas.sendToBack(this.polygon) */
}

Baseline.prototype.getCoordsForFocus = function () {
  return this.getScaledPolygonCoords() || this.getScaledBaselineCoords()
}

Baseline.prototype.getArea = function () {
  if (this.polygonCoords) {
    return this.polygonCoords.map(({ x, y }) => [x, y])
  }
  if (this.baselineCoords) {
    const padding = Math.min(this.myFabric.getLineWidth() * 5, 10)
    const shiftedUp = this.baselineCoords.map(({ x, y }) => ({
      x,
      y: y - padding,
    }))
    const allCoords = [
      ...shiftedUp,
      ...shiftedUp.map(({ x, y }) => ({ x, y: y + 2 * padding })).toReversed(),
    ]
    return allCoords.map(({ x, y }) => [x, y])
  }
}

Baseline.prototype.getAreaForced = function () {
  this.polygonCoords = this.getScaledPolygonCoords()
  this.baselineCoords = this.getScaledBaselineCoords()
  if (this.polygonCoords) {
    return this.polygonCoords.map(({ x, y }) => [x, y])
  }
  if (this.baselineCoords) {
    const shiftedUp = this.baselineCoords.map(({ x, y }) => ({ x, y: y - 10 }))
    const allCoords = [
      ...shiftedUp,
      ...shiftedUp.map(({ x, y }) => ({ x, y: y + 20 })).toReversed(),
    ]
    return allCoords.map(({ x, y }) => [x, y])
  }
}

Baseline.prototype.removeFromCanvas = function () {
  this.canvas.remove(...this.vertices)
  this.canvas.remove(...this.polygonVertices)
  this.canvas.remove(this.line)
  this.canvas.remove(this.polygonBackground)
  this.canvas.remove(this.polygonLine)
  this.canvas.remove(this.prevLine)
  this.canvas.remove(this.runningVertex)
  this.canvas.remove(this.structureText)
  this.canvas.remove(this.structureColorFrame)
  this.canvas.remove(this.readingOrderText)
  this.canvas.remove(this.highlightBox)
  this.canvas.discardActiveObject()
}

Baseline.prototype.removeChildsFromCanvas = function () {
  this.words.map(w => {
    w.unSelect()
    w.removeFromCanvas()
  })
}

export default Baseline
