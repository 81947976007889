import _ from 'lodash'
import { toImageAction, toFabricAction } from '../jsonParser.js'
import {
  actionMethods,
  ACTION_TYPE,
  afterEditorAction,
} from './actionMethods.js'
import model from '../../../Model/model.js'
import { getRotationAngle, getScaleFactor } from '../../globals.js'
import { useEditorStorage } from '../../../../../composables/functional/useEditorStorage.ts'

const Singleton = (function () {
  const instances = []

  return {
    instance: function (editorName) {
      if (editorName == null) {
        editorName = 'editor1'
      }
      if (instances.find(i => i.editorName === editorName) == null) {
        instances.push(new ActionHandler(editorName))
      }
      return instances.find(i => i.editorName === editorName)
    },
  }
})()

function ActionHandler(editorName) {
  this.editorName = editorName
  this.actionStack = [{}]

  this.xmlKey = null
  this.actionIndex = 0
  this.enabled = true

  this.registeredComponents = {
    editor: null,
    layout: null,
    tree: null,
    text: null,
  }

  this.eventHandlers = {
    'action-index-update': {
      layout: null,
    },
    action: {
      editor: null,
      layout: null,
      tree: null,
      text: null,
    },
    selection: {
      editor: null,
      layout: null,
      tree: null,
      text: null,
    },
  }
}

const SELECTION_TYPE = Object.freeze({
  ADD: 1,
  REMOVE: 2,
  CLEAR: 3,
  CENTER: 4,
})

ActionHandler.prototype.enable = function () {
  this.enabled = true
}
ActionHandler.prototype.disable = function () {
  this.enabled = false
}

ActionHandler.prototype.registerAs = function (name, component) {
  if (name in this.registeredComponents) {
    this.registeredComponents[name] = component
  }
}

ActionHandler.prototype.on = function (key, handlerType, handler) {
  if (!key in this.eventHandlers) {
    console.error('invalid key name')
    return
  }
  if (!handlerType in this.eventHandlers[key]) {
    console.error('invalid handler type')
    return
  }
  this.eventHandlers[key][handlerType] = handler
}

ActionHandler.prototype.emit = function (key, event) {
  if (!key in this.eventHandlers) {
    console.error('invalid event name')
    return
  }
  if (key === 'action') {
    if (event.type === ACTION_TYPE.MULTI_ACTION) {
      event.actions.forEach(action => this.emit('action', action))
      return
    }
    const methods = this.getActionMethods(event)
    if (methods && methods.handleEditor) {
      methods.handleEditor?.(this.registeredComponents.editor, event)
      afterEditorAction(this.registeredComponents.editor, event)
      return
    }
  }

  Object.values(this.eventHandlers[key]).forEach(
    handler => handler && handler(event)
  )
}

ActionHandler.prototype.getActionMethods = function (action) {
  return actionMethods.find(a => a.type === action.type)
}

ActionHandler.prototype.getActionType = function (action) {
  if (action == null || _.isEmpty(action)) {
    warn('Action is invalid!')
    return
  }
  return Object.entries(ACTION_TYPE).find(
    ([_, number]) => number === action.type
  )[0]
}

ActionHandler.prototype.getSelectionType = function (selection) {
  if (selection == null) {
    warn('Selection is invalid!')
    return
  }
  return Object.entries(SELECTION_TYPE).find(
    ([_, number]) => number === selection.type
  )[0]
}

ActionHandler.prototype.logAction = function (action) {
  const transformActionType = action => {
    const newAction = { ...action }
    //log(newAction)
    newAction.type = this.getActionType(newAction)
    if ('actions' in newAction) {
      newAction.actions = newAction.actions.map(a => transformActionType(a))
    }
    return newAction
  }
  protocolLog(
    `%caction performed: %o`,
    'color: yellow',
    transformActionType(action)
  )
}

ActionHandler.prototype.getChangesCount = function () {
  return this.actionIndex
}

ActionHandler.prototype.redo = function () {
  if (this.actionIndex < this.actionStack.length - 1) {
    this.actionIndex++
    let nextAction = this.actionStack[this.actionIndex]

    /*  const fabricAction = toFabricAction(nextAction, getScaleFactor())
    this.doAction(fabricAction) */
    this.doActionModel(nextAction)
  }
  this.storeActions()
  this.emit('action-index-update', this.getChangesCount())
}

ActionHandler.prototype.undo = function () {
  if (this.actionIndex > 0) {
    let lastAction = this.actionStack[this.actionIndex]
    this.actionIndex--
    const reversedAction = this.reverse(lastAction)
    /* const fabricAction = toFabricAction(reversedAction, getScaleFactor())
    this.doAction(fabricAction) */
    this.doActionModel(reversedAction)
  }
  this.storeActions()
  this.emit('action-index-update', this.getChangesCount())
}

ActionHandler.prototype.doAction = function (action) {
  if (this.enabled === false) return
  this.logAction(action)
  this.emit('action', action)
  //this.editor.performAction(action)
  //this.editor.vueFunctions.performAction(action)
}

ActionHandler.prototype.doActionModel = function (action) {
  if (this.enabled === false) return
  this.logAction(action)
  model.handleAction(action, true, {
    popLastAction: () => {
      this.popLastAction()
    },
  })
}

ActionHandler.prototype.addAction = function (action) {
  if (this.actionIndex + 1 < this.actionStack.length) {
    this.actionStack = this.actionStack.slice(0, this.actionIndex + 1)
  }
  this.actionStack.push(action)
  this.actionIndex = this.actionStack.length - 1

  this.storeActions()
  this.emit('action-index-update', this.getChangesCount())
}

ActionHandler.prototype.storeActions = function () {
  const editorStorage = useEditorStorage()
  const actionStackSliced = this.actionStack.slice(0, this.actionIndex + 1)
  const [_, ...rest] = actionStackSliced
  editorStorage.setActions(rest, this.xmlKey)
}

ActionHandler.prototype.popLastAction = function () {
  this.actionStack.pop()
  this.actionIndex = this.actionStack.length - 1
  this.storeActions()
  this.emit('action-index-update', this.getChangesCount())
}

ActionHandler.prototype.do = function (action) {
  this.doAction(action)
  this.addAction(action)
}

ActionHandler.prototype.newAction = function (action) {
  const imageAction = toImageAction(
    action,
    getScaleFactor(),
    getRotationAngle()
  )

  this.addAction(imageAction)

  //const fabricAction = toFabricAction(imageAction, getScaleFactor())
  //this.doAction(fabricAction)
  this.doActionModel(imageAction)
}

ActionHandler.prototype.select = function (selection) {
  protocolLog(
    `%cselection performed: ${this.getSelectionType(selection)} %o`,
    'color: orange',
    selection
  )
  this.emit('selection', selection)
  //this.editor.performSelection(selection)
  //this.editor.vueFunctions.performSelection(selection)
}

ActionHandler.prototype.reverse = function (action) {
  const methods = this.getActionMethods(action)
  if (methods && methods.reverse) {
    return methods.reverse(action)
  }

  switch (action.type) {
    case ACTION_TYPE.UPDATE_CELL_COORDS:
      return {
        type: ACTION_TYPE.UPDATE_CELL_COORDS,
        shape_id: action.shape_id,
        position: action.position,
        vector: { x: action.vector.x * -1, y: action.vector.y * -1 },
      }
    case ACTION_TYPE.ADD_SHAPE:
      return {
        type: ACTION_TYPE.REMOVE_SHAPE,
        index: action.index,
        shape: action.shape,
        parent_id: action.parent_id,
      }
    case ACTION_TYPE.REMOVE_SHAPE:
      return {
        type: ACTION_TYPE.ADD_SHAPE,
        index: action.index,
        shape: action.shape,
        parent_id: action.parent_id,
      }
    case ACTION_TYPE.ADD_BASELINE:
      return {
        type: ACTION_TYPE.REMOVE_BASELINE,
        index: action.index,
        blueprint: action.blueprint,
        parent_id: action.parent_id,
      }
    case ACTION_TYPE.ADD_REGION:
      return {
        type: ACTION_TYPE.REMOVE_REGION,
        index: action.index,
        blueprint: action.blueprint,
      }
    case ACTION_TYPE.ADD_TABLE:
      return {
        type: ACTION_TYPE.REMOVE_TABLE,
        index: action.index,
        blueprint: action.blueprint,
      }
    case ACTION_TYPE.REMOVE_BASELINE:
      return {
        type: ACTION_TYPE.ADD_BASELINE,
        index: action.index,
        blueprint: action.blueprint,
        parent_id: action.parent_id,
      }
    case ACTION_TYPE.REMOVE_REGION:
      return {
        type: ACTION_TYPE.ADD_REGION,
        index: action.index,
        blueprint: action.blueprint,
      }
    case ACTION_TYPE.REMOVE_TABLE:
      return {
        type: ACTION_TYPE.ADD_TABLE,
        index: action.index,
        blueprint: action.blueprint,
      }
    case ACTION_TYPE.ADD_POINT:
      return {
        type: ACTION_TYPE.REMOVE_POINT,
        shape_id: action.shape_id,
        point_coord: action.point_coord,
        point_index: action.point_index,
      }
    case ACTION_TYPE.REMOVE_POINT:
      return {
        type: ACTION_TYPE.ADD_POINT,
        shape_id: action.shape_id,
        point_coord: action.point_coord,
        point_index: action.point_index,
      }
    case ACTION_TYPE.MOVE_POINT:
      return {
        type: ACTION_TYPE.MOVE_POINT,
        shape_id: action.shape_id,
        point_index: action.point_index,
        vector: { x: action.vector.x * -1, y: action.vector.y * -1 },
      }
    case ACTION_TYPE.MOVE_POINT_TABLE:
      return {
        type: ACTION_TYPE.MOVE_POINT_TABLE,
        shape_id: action.shape_id,
        cell_ids: action.cell_ids,
        point_indices: action.point_indices,
        vector: { x: action.vector.x * -1, y: action.vector.y * -1 },
      }
    case ACTION_TYPE.MOVE_POINTS:
      return {
        type: ACTION_TYPE.MOVE_POINTS,
        shape_id: action.shape_id,
        vectors: action.vectors.map(v => ({ x: v.x * -1, y: v.y * -1 })),
      }
    /* case ACTION_TYPE.MOVE_SHAPE:
      return {
        type: ACTION_TYPE.MOVE_SHAPE,
        shape_id: action.shape_id,
        old_coords: action.new_coords,
        new_coords: action.old_coords,
      } */
    case ACTION_TYPE.MULTI_ACTION:
      return {
        type: ACTION_TYPE.MULTI_ACTION,
        shape_id: action.shape_id,
        actions: action.actions.map(action => this.reverse(action)).reverse(),
      }
    case ACTION_TYPE.ADD_TABLE_COLUM:
      return {
        type: ACTION_TYPE.REMOVE_TABLE_COLUM,
        shape_id: action.shape_id,
        col_index: action.col_index,
      }
    case ACTION_TYPE.REMOVE_TABLE_COLUM:
      return {
        type: ACTION_TYPE.ADD_TABLE_COLUM,
        shape_id: action.shape_id,
        col_index: action.col_index,
      }
    case ACTION_TYPE.ADD_TABLE_ROW:
      return {
        type: ACTION_TYPE.REMOVE_TABLE_ROW,
        shape_id: action.shape_id,
        row_index: action.row_index,
      }
    case ACTION_TYPE.REMOVE_TABLE_ROW:
      return {
        type: ACTION_TYPE.ADD_TABLE_ROW,
        shape_id: action.shape_id,
        row_index: action.row_index,
      }
    case ACTION_TYPE.ADD_CELL:
      return {
        type: ACTION_TYPE.REMOVE_CELL,
        shape_id: action.shape_id,
        blueprint: action.blueprint,
        row: action.row,
        col: action.col,
      }
    case ACTION_TYPE.REMOVE_CELL:
      return {
        type: ACTION_TYPE.ADD_CELL,
        shape_id: action.shape_id,
        blueprint: action.blueprint,
        row: action.row,
        col: action.col,
      }
    case ACTION_TYPE.CHANGE_CELL_SPAN:
      return {
        type: ACTION_TYPE.CHANGE_CELL_SPAN,
        shape_id: action.shape_id,
        cell_id: action.cell_id,
        old_row_span: action.new_row_span,
        old_col_span: action.new_col_span,
        new_row_span: action.old_row_span,
        new_col_span: action.old_col_span,
      }
    case ACTION_TYPE.CHANGE_PARENT:
      return {
        type: ACTION_TYPE.CHANGE_PARENT,
        shape_count: action.shape_count,
        old_parent_id: action.new_parent_id,
        new_parent_id: action.old_parent_id,
        old_index: action.new_index,
        new_index: action.old_index,
      }
    /* case ACTION_TYPE.MOVE_SHAPE_WITH_VECTOR:
      return {
        type: ACTION_TYPE.MOVE_SHAPE_WITH_VECTOR,
        shape_id: action.shape_id,
        vector: { x: action.vector.x * -1, y: action.vector.y * -1 },
      } */
    case ACTION_TYPE.SET_STRUCTUR_TYPE:
      return {
        type: ACTION_TYPE.SET_STRUCTUR_TYPE,
        shape_id: action.shape_id,
        old_type: action.new_type,
        new_type: action.old_type,
      }
    /* case ACTION_TYPE.CHANGE_READING_ORDER:
      return {
        type: ACTION_TYPE.CHANGE_READING_ORDER,
        shape_id: action.shape_id,
        old_index: action.new_index,
        new_index: action.old_index,
      } */
    case ACTION_TYPE.POSITION_MOVE:
      return {
        type: ACTION_TYPE.POSITION_MOVE,
        shape_id: action.shape_id,
        distance: -action.distance,
      }
    case ACTION_TYPE.ADD_RELATION:
      return {
        type: ACTION_TYPE.REMOVE_RELATION,
        relation: action.relation,
      }
    case ACTION_TYPE.REMOVE_RELATION:
      return {
        type: ACTION_TYPE.ADD_RELATION,
        relation: action.relation,
      }
    case ACTION_TYPE.UPDATE_RELATION_IDS:
      return {
        type: ACTION_TYPE.UPDATE_RELATION_IDS,
        index: action.index,
        old_ids: action.new_ids,
        new_ids: action.old_ids,
      }
    case ACTION_TYPE.SET_TEXT:
      return {
        type: ACTION_TYPE.SET_TEXT,
        shape_id: action.shape_id,
        old_text: action.new_text,
        new_text: action.old_text,
      }
    case ACTION_TYPE.ADD_TAG:
      return {
        type: ACTION_TYPE.REMOVE_TAG,
        shape_id: action.shape_id,
        tag: action.tag,
      }
    case ACTION_TYPE.REMOVE_TAG:
      return {
        type: ACTION_TYPE.ADD_TAG,
        shape_id: action.shape_id,
        tag: action.tag,
      }
    case ACTION_TYPE.SET_TAGS:
      return {
        type: ACTION_TYPE.SET_TAGS,
        shape_id: action.shape_id,
        old_tags: action.new_tags,
        new_tags: action.old_tags,
      }
    case ACTION_TYPE.SET_ROTATION:
      return {
        type: ACTION_TYPE.SET_ROTATION,
        old_angle: action.new_angle,
        new_angle: action.old_angle,
      }
    case ACTION_TYPE.RESIZE:
      return {
        type: ACTION_TYPE.RESIZE,
        old_width: action.new_width,
        new_width: action.old_width,
        factor_old: action.factor_new,
        factor_new: action.factor_old,
      }
    case ACTION_TYPE.SPLIT_TABLE_COL:
      return {
        type: ACTION_TYPE.MERGE_TABLE_COLS,
        shape_id: action.shape_id,
        old_cells_blueprints: action.new_cells_blueprints,
        new_cells_blueprints: action.old_cells_blueprints,
      }
    case ACTION_TYPE.UPDATE_CELLS:
      return {
        type: ACTION_TYPE.UPDATE_CELLS,
        shape_id: action.shape_id,
        old_cells_blueprints: action.new_cells_blueprints,
        new_cells_blueprints: action.old_cells_blueprints,
      }
    case ACTION_TYPE.SPLIT_TABLE_ROW:
      return {
        type: ACTION_TYPE.MERGE_TABLE_ROWS,
        shape_id: action.shape_id,
        old_cells_blueprints: action.new_cells_blueprints,
        new_cells_blueprints: action.old_cells_blueprints,
      }
    case ACTION_TYPE.MERGE_TABLE_COLS:
      return {
        type: ACTION_TYPE.SPLIT_TABLE_COL,
        shape_id: action.shape_id,
        old_cells_blueprints: action.new_cells_blueprints,
        new_cells_blueprints: action.old_cells_blueprints,
      }
    case ACTION_TYPE.MERGE_TABLE_ROWS:
      return {
        type: ACTION_TYPE.SPLIT_TABLE_ROW,
        shape_id: action.shape_id,
        old_cells_blueprints: action.new_cells_blueprints,
        new_cells_blueprints: action.old_cells_blueprints,
      }
    case ACTION_TYPE.CHANGE_STATUS:
      return {
        type: ACTION_TYPE.CHANGE_STATUS,
        old_status: action.new_status,
        new_status: action.old_status,
      }
  }
}

ActionHandler.prototype.addActions = function (actions) {
  this.actionStack.push(...actions)
  this.actionIndex = this.actionStack.length - 1
  this.emit('action-index-update', this.getChangesCount())
}

ActionHandler.prototype.reset = function () {
  this.actionStack = [{}]
  this.actionIndex = 0
  this.emit('action-index-update', this.getChangesCount())
}

ActionHandler.prototype.setXmlKey = function (xmlKey) {
  this.xmlKey = xmlKey
}
// ActionHandler.prototype.setEditor = function(editor) {
//   this.editor = editor
// }

export { Singleton, ACTION_TYPE, SELECTION_TYPE }
