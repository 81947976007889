export default class IdGenerator {
  private ids: string[] = []
  private idCounter: number = 0

  constructor(pageJson: any) {
    this.ids = this.extractIds(pageJson)
  }

  extractIds(pageJson) {
    let ids: any = []

    function findIds(node) {
      if (node && typeof node === 'object') {
        if (node.attributes && node.attributes.id) {
          ids.push(node.attributes.id)
        }
        // Recursively check child elements, if they exist
        if (node.elements && Array.isArray(node.elements)) {
          node.elements.forEach(child => findIds(child))
        }
      }
    }
    findIds(pageJson)
    return ids
  }

  modifyId(id) {
    this.idCounter++
    const newId = `${id}_${this.idCounter}`
    if (this.ids.includes(newId)) {
      return this.modifyId(id)
    }
    this.ids.push(newId)
    return newId
  }

  requestId(id) {
    this.idCounter = 0
    if (!this.ids.includes(id)) {
      this.ids.push(id)
      return id
    }
    const newId = this.modifyId(id)
    return newId
  }
}
