import {
  lineAngle,
  lineMidpoint,
  pointTranslate,
  lineLength,
  polygonMean,
  polygonBounds,
  polygonHull,
  polygonScale,
  lineInterpolate,
} from 'geometric'
import { Singleton as IdHandler } from './singletons/IdHandler.js'
import { Singleton as Settings } from './singletons/settings.js'
import { Singleton as CollectionConfig } from './singletons/collectionConfig.js'
import { getScaleFactor } from '../globals.js'

var Singleton = (function () {
  const instances = []

  return {
    instance: function (editorName) {
      if (editorName == null) {
        editorName = 'editor'
      }
      if (instances.find(i => i.editorName === editorName) == null) {
        instances.push(new MyFabric(editorName))
      }
      return instances.find(i => i.editorName === editorName)
    },
  }
})()

function getRGBA(r, g, b, a) {
  return 'rgba(' + r + ',' + g + ',' + b + ',' + (a / 255).toFixed(1) + ')'
}

function RGBAToHexA(r, g, b, a) {
  r = r.toString(16)
  g = g.toString(16)
  b = b.toString(16)
  a = Math.round(a * 255).toString(16)

  if (r.length == 1) r = '0' + r
  if (g.length == 1) g = '0' + g
  if (b.length == 1) b = '0' + b
  if (a.length == 1) a = '0' + a

  return '#' + r + g + b
}

function MyFabric(editorName) {
  this.editorName = editorName
  this.idHandler = IdHandler.instance(this.editorName)
  this.settings = Settings.instance()
  this.isFocused = true
}

MyFabric.prototype.setFocused = function (bool) {
  this.isFocused = bool
}

MyFabric.prototype.init = function (scale, tileSourceWidth) {
  this.circleRadius = 4
  this.largeCircleRadius = 6
  this.strokeWidth = 1
  this.wordBackground = 'rgba(255,105,180,0.2)'
  this.wordLineColor = 'rgb(255,105,180)'
  this.baselineColor = 'blue'
  this.baselinePolygonColor = '#00FFFF'
  this.tableColor = 'orange'
  this.regionColor = 'green'
  this.defaultVertexColor = '#FFFFFF'
  this.selectedVertexColor = 'yellow'
  this.deleteColor = 'red'
  this.lineWidth = 2
  this.lineColor = 'blue'
}

MyFabric.prototype.getBaselineColor = function () {
  return Settings.instance().get('baselineColor')
}

MyFabric.prototype.getBaselineHighlightColor = function (opacity) {
  const color = Settings.instance().get('highlightColor')
  if (!opacity) return color

  return hexToRgbaString(color, opacity)
}

MyFabric.prototype.isShowColoredBackground = function () {
  return Settings.instance().get('showColoredBackground')
}

MyFabric.prototype.getLinePolygonColor = function () {
  return Settings.instance().get('linePolygonColor')
}

MyFabric.prototype.getRegionColor = function (opacity) {
  const color = Settings.instance().get('regionColor')
  if (!opacity) return color
  return interpolateColor('#ffffff', color, opacity)
}

MyFabric.prototype.getTableColorPrimary = function (opacity) {
  const percentage = 0.5 // 50% interpolation
  const interpolatedColor = interpolateColor(
    this.getTableColorSecondary(),
    '#FFFF00',
    percentage
  )
  if (!opacity) return interpolatedColor
  return interpolateColor('#ffffff', interpolatedColor, opacity)
}

MyFabric.prototype.getTableColorSecondary = function (opacity) {
  const color = Settings.instance().get('tableColor')
  if (!opacity) return color
  return hexToRgbaString(color, opacity)
}

MyFabric.prototype.getTableColorSelected = function (opacity) {
  const color1 = this.getTableColorPrimary()
  const color2 = this.getTableColorSecondary()
  const percentage = 0.5 // 50% interpolation

  const interpolatedColor = interpolateColor(color1, color2, percentage)
  if (!opacity) return interpolatedColor
  return hexToRgbaString(interpolatedColor, opacity)
}

MyFabric.prototype.getWordColor = function () {
  return Settings.instance().get('wordColor')
}

MyFabric.prototype.getCircleSize = function () {
  return Math.max(this.settings.get('circleSize'), 1)
}

MyFabric.prototype.getStrokeWidth = function () {
  return this.getCircleSize() / 4
}

MyFabric.prototype.getLabelSize = function () {
  return Math.max(this.settings.get('structureTypeSize'), 1) * 3
}

MyFabric.prototype.getAddPointOffset = function () {
  return Math.max(
    this.settings.get('lineWidth') != null ? this.settings.get('lineWidth') : 1,
    4
  )
}

MyFabric.prototype.getLineWidth = function () {
  const lineWidth = Math.max(
    this.settings.get('lineWidth') != null ? this.settings.get('lineWidth') : 2,
    1
  )
  const upScaled = lineWidth * 1.5 - 1
  return upScaled / 4
}

MyFabric.prototype.getBaselineHighlightHeight = function () {
  return Math.max(
    this.settings.get('highlightHeight') == null
      ? 2
      : this.settings.get('highlightHeight'),
    1
  )
}

MyFabric.prototype.getRelationBubble = function (vertices, type) {
  const points = vertices.map(({ left, top }) => [left, top])
  const hull = polygonScale(polygonHull(points), 1.5)
  const coords = hull.map(([x, y]) => ({ x, y }))
  coords.push(coords[0])

  const size = this.getLabelSize()

  const topLeft = this.getTopLeft(
    coords.map(({ x, y }) => ({ left: x, top: y }))
  )
  const left = topLeft.left + Math.sqrt(size) * getScaleFactor()
  const top = topLeft.top + Math.sqrt(size) * getScaleFactor()

  return [
    new fabric.Polyline(coords, {
      strokeWidth: 1,
      originX: 'center',
      originY: 'center',
      stroke: 'white',
      fill: 'transparent',
      selectable: false,
      evented: false,
    }),
    new fabric.Text(type, {
      originX: 'left',
      originY: 'top',
      fontSize: size,
      fill: 'black',
      backgroundColor: 'rgba(255,255,255,0.8)',
      evented: false,
      left,
      top,
    }),
  ]
}

MyFabric.prototype.getSearchRects = function (rects) {
  return rects.map(rect => {
    const { x, y, width, height } = rect
    return new fabric.Rect({
      left: x,
      top: y,
      width,
      height,
      fill: 'rgba(255, 0, 0, 0.2)',
      stroke: 'red',
      strokeWidth: 0.2,
      evented: false,
    })
  })
}

MyFabric.prototype.getWordCircle = function (
  x,
  y,
  id = this.idHandler.requestId('circle')
) {
  return new fabric.Circle({
    radius: this.getCircleSize(),
    fill: this.defaultVertexColor,
    strokeWidth: this.getStrokeWidth(),
    stroke: this.getWordColor(),
    left: x,
    top: y,
    originX: 'center',
    originY: 'center',
    hasControls: false,
    hasBorders: false,
    id: id,
    defaultRadius: this.getCircleSize(),
    defaultColor: this.defaultVertexColor,
    selectedColor: this.getWordColor(),
  })
}

MyFabric.prototype.getWordPolyLine = function (coords, id) {
  // const coordsAsArray = coords.map(({ x, y }) => [x, y])

  if (coords.length > 0) {
    coords.push(coords[0])
  }
  return new fabric.Polyline(coords, {
    strokeWidth: 1,
    originX: 'center',
    originY: 'center',
    stroke: this.getWordColor(),
    selectable: false,
    evented: false,
    id,
    color: hexToRgbaString(this.getWordColor(), 0.2),
  })
}

MyFabric.prototype.getBaselineCircle = function (
  x,
  y,
  id = this.idHandler.requestId('circle')
) {
  return new fabric.Circle({
    radius: this.getCircleSize(),
    fill: this.defaultVertexColor,
    strokeWidth: this.getStrokeWidth(),
    stroke: this.getBaselineColor(),
    left: x,
    top: y,
    originX: 'center',
    originY: 'center',
    hasControls: false,
    hasBorders: false,
    id: id,
    defaultRadius: this.getCircleSize(),
    defaultColor: this.defaultVertexColor,
    selectedColor: this.getBaselineColor(),
  })
}

MyFabric.prototype.getPolygon = function (
  points,
  initId,
  maxPolygonY,
  minPolygonY
) {
  //const height = maxPolygonY - minPolygonY || 30 * getScaleFactor()
  const height = 30 * getScaleFactor()
  let polyline = this.getPolyline(points, height)
  polyline.reverse()
  polyline = points.concat(polyline)
  let highlightColor =
    this.settings.get('highlightColor') == undefined
      ? 'blue'
      : this.settings.get('highlightColor')

  if (highlightColor?.red) {
    highlightColor = RGBAToHexA(
      highlightColor.red,
      highlightColor.green,
      highlightColor.blue,
      highlightColor.alpha
    )
  }

  let isShowColoredBackground = this.settings.get('showColoredBackground')

  //console.log('highlightColor', this.settings.get('highlightHeight'))
  let fpointsOffset = polyline.map(p => ({ x: p[0], y: p[1] }))
  let opacity =
    this.settings?.get('highlightColor')?.alpha == undefined
      ? 0.2
      : this.settings.get('highlightColor').alpha / 255

  return {
    polygon: new fabric.Polyline(fpointsOffset, {
      strokeWidth: this.getBaselineHighlightHeight(),
      originX: 'center',
      originY: 'center',
      stroke: isShowColoredBackground ? highlightColor : '',
      fill: isShowColoredBackground ? highlightColor : '',
      opacity: opacity,
      opacityValue: opacity,
      selectable: false,
      evented: false,
      id: initId,
      defaultColor: this.baselineColor,
      deleteColor: this.deleteColor,
    }),
    polyCoords: polyline,
  }
}

MyFabric.prototype.getLineElements = function (points, initId, isSelected) {
  let line = {}
  let arrows = []
  let newLineColor
  let newHighlightColor
  const baselineColorHighlight = this.settings.get('baselineColorHighlight')

  const lineColor = this.settings.get('baselineColor')
  if (lineColor?.red != null) {
    newLineColor = getRGBA(
      lineColor.red,
      lineColor.green,
      lineColor.blue,
      lineColor.alpha
    )
  } else {
    newLineColor = lineColor
  }
  const highlightColor = this.settings.get('highlightColor')
  //const showHighlightColor = this.settings.get('showHighlightColor')

  if (highlightColor?.red != null) {
    newHighlightColor = getRGBA(
      highlightColor.red,
      highlightColor.green,
      highlightColor.blue,
      highlightColor.alpha
    )
  } else {
    newHighlightColor = highlightColor
  }

  if (isSelected) {
    if (baselineColorHighlight) {
      newLineColor = newHighlightColor
    }
  }

  let pointObjects = points.map(p => ({ x: p[0], y: p[1] }))

  line = new fabric.Polyline(pointObjects, {
    strokeWidth: this.getLineWidth(),
    stroke: newLineColor,
    fill: 'transparent',
    selectable: false,
    evented: false,
    opacity: 1,
    opacityValue: 1,
    id: initId,
  })

  if (points.length > 0) {
    points.reduce((first, second) => {
      let line = [first, second]

      let angle = lineAngle(line)
      let midPoint = lineMidpoint(line)
      let arrow = this.getArrow(midPoint[0], midPoint[1], angle, initId)
      arrows.push(arrow)
      return second
    })
  }
  return { line: line, arrows: arrows }
}

MyFabric.prototype.getPolyline = function (points, height) {
  // const areEqual = (points) => {
  //   let equal = true
  //   points.reduce((first, second) => {
  //     if (JSON.stringify(first) != JSON.stringify(second)) {
  //       equal = false
  //     }
  //     return second
  //   })
  //   return equal
  // }
  if (points.length <= 1) {
    return []
  }
  let lines = []
  let polyLine = []
  let initDistance = height

  const degreeMinusdegree = (first, second) => {
    let result = first - second
    if (result < 0) {
      result = 360 + result
    }
    return result
  }
  const drawTranslatedMidPoints = (line, degree) => {
    let distance = initDistance

    //this makes corners smoother
    if (degree < 135) {
      let distFactor = Math.pow(-(degree / 135 - 1), 1)
      distance = distance + distance * distFactor
    }
    if (degree > 225) {
      let distFactor = Math.pow(-(225 / degree - 1), 0.5)
      distance = distance + distance * distFactor
    }

    degree = degree / 2

    let angle = lineAngle(line)
    let pointAngle = degreeMinusdegree(angle, degree)
    let startPoint = line[0]
    let translatedPoint = pointTranslate(startPoint, pointAngle, distance)
    polyLine.push(translatedPoint)
  }

  const drawTranslatedStartPoint = line => {
    let angle = lineAngle(line)
    let pointAngle = degreeMinusdegree(angle, 90)
    let startPoint = line[0]
    let translatedPoint = pointTranslate(startPoint, pointAngle, initDistance)
    polyLine.push(translatedPoint)
  }

  const drawTranslatedEndPoint = line => {
    let angle = lineAngle(line)
    let pointAngle = degreeMinusdegree(angle, 90)
    let startPoint = line[1]
    let translatedPoint = pointTranslate(startPoint, pointAngle, initDistance)
    polyLine.push(translatedPoint)
  }

  points.reduce((first, second) => {
    lines.push([first, second])
    return second
  })

  //draw first
  drawTranslatedStartPoint(lines[0])

  //drawMidpoints starting with the second line (the degree to the previous line is passed)
  lines.reduce((first, second) => {
    let degreeFirstToSecond = degreeMinusdegree(
      degreeMinusdegree(lineAngle(second), lineAngle(first)),
      180
    )
    drawTranslatedMidPoints(second, degreeFirstToSecond)

    return second
  })

  //draw last
  drawTranslatedEndPoint(lines[lines.length - 1])

  return polyLine
}

MyFabric.prototype.getRegionCircle = function (
  x,
  y,
  id = this.idHandler.requestId('circle')
) {
  return new fabric.Circle({
    radius: this.getCircleSize(),
    fill: this.defaultVertexColor,
    strokeWidth: this.getStrokeWidth(),
    stroke: this.getRegionColor(),
    left: x,
    top: y,
    originX: 'center',
    originY: 'center',
    hasControls: false,
    hasBorders: false,
    id: id,
    defaultRadius: this.getCircleSize(),
    defaultColor: this.defaultVertexColor,
    selectedColor: this.getRegionColor(),
  })
}

MyFabric.prototype.getPolygonCircle = function (
  x,
  y,
  id = this.idHandler.requestId('circle')
) {
  return new fabric.Circle({
    radius: this.getCircleSize(),
    fill: this.defaultVertexColor,
    strokeWidth: this.getStrokeWidth(),
    stroke: this.getLinePolygonColor(),
    left: x,
    top: y,
    originX: 'center',
    originY: 'center',
    hasControls: false,
    hasBorders: false,
    id: id,
    defaultRadius: this.getCircleSize(),
    defaultColor: this.defaultVertexColor,
    selectedColor: this.getLinePolygonColor(),
  })
}

MyFabric.prototype.getRegionBackground = function (points, initId) {
  if (points == null || points.length === 0) return
  points.push(points[0])
  let line = new fabric.Polyline(points, {
    strokeWidth: 0,
    fill: this.getRegionColor(),
    opacity: 0.2,
    selectable: false,
    evented: false,
    id: initId,
    defaultColor: this.getRegionColor(),
    deleteColor: this.deleteColor,
  })
  return line
}

MyFabric.prototype.getPolygonBackground = function (points, initId) {
  points.push(points[0])

  let line = new fabric.Polyline(points, {
    strokeWidth: 0,
    fill: this.getLinePolygonColor(),
    opacity: 0.1,
    selectable: false,
    evented: false,
    id: initId,
    defaultColor: this.getLinePolygonColor(),
    deleteColor: this.deleteColor,
  })
  return line
}

MyFabric.prototype.getRegionLine = function (points, initId) {
  if (points == null || points.length === 0) return
  points.push(points[0])
  let line = new fabric.Polyline(points, {
    strokeWidth: this.getLineWidth(),
    stroke: this.getRegionColor(),
    fill: 'transparent',
    opacity: 1,
    selectable: false,
    evented: false,
    id: initId,
  })
  return line
}

MyFabric.prototype.getPolygonLine = function (points, initId) {
  const newPoints = [...points, points[0]]

  let line = new fabric.Polyline(newPoints, {
    strokeWidth: this.getLineWidth(),
    stroke: this.getLinePolygonColor(),
    fill: 'transparent',
    opacity: 1,
    selectable: false,
    evented: false,
    id: initId,
  })
  return line
}

MyFabric.prototype.getRunningCircle = function (x, y, color) {
  return new fabric.Circle({
    radius: this.getCircleSize(),
    fill: color,
    strokeWidth: this.getStrokeWidth(),
    stroke: color,
    opacity: 0.2,
    left: x,
    top: y,
    originX: 'center',
    originY: 'center',
    hasControls: false,
    hasBorders: false,
    hoverCursor: 'pointer',
    moveCursor: 'grabbing',
  })
}
MyFabric.prototype.getRunningCircleBaseline = function (x, y) {
  return this.getRunningCircle(x, y, this.getBaselineColor())
}
MyFabric.prototype.getRunningCircleLinePolygon = function (x, y) {
  return this.getRunningCircle(x, y, this.getLinePolygonColor())
}
MyFabric.prototype.getRunningCircleRegion = function (x, y) {
  return this.getRunningCircle(x, y, this.getRegionColor())
}

MyFabric.prototype.getTableCircle = function (
  x,
  y,
  id = this.idHandler.requestId('circle')
) {
  return new fabric.Circle({
    radius: this.getCircleSize(),
    fill: this.defaultVertexColor,
    strokeWidth: this.getStrokeWidth(),
    stroke: this.getTableColorSecondary(),
    left: x,
    top: y,
    originX: 'center',
    originY: 'center',
    hasControls: false,
    hasBorders: false,
    id: id,
    padding: 5,
    defaultRadius: this.getCircleSize(),
    defaultColor: this.defaultVertexColor,
    selectedColor: this.getTableColorSecondary(),
  })
}

MyFabric.prototype.getHoverLine = function (points, id) {
  return new fabric.Polyline(points, {
    strokeWidth: 3,
    originX: 'center',
    originY: 'center',
    stroke: 'black',
    padding: 20,
    fill: 'transparent',
    opacity: 0.6,
    hasControls: false,
    hasBorders: false,
    evented: true,
    hoverCursor: 'move',
    id,
  })
}

MyFabric.prototype.getCellBackground = function (points, state, opacity) {
  const interpolatedColor = this.getTableColorSelected()

  let cellColor
  switch (state) {
    case 'selected':
      cellColor = hexToRgbaString(interpolatedColor, opacity)
      break
    case 'spanning':
      cellColor = hexToRgbaString('#a020f0', opacity)
      break
    case 'even':
      cellColor = hexToRgbaString(this.getTableColorPrimary(), opacity)
      break
    case 'odd':
      cellColor = hexToRgbaString(this.getTableColorSecondary(), opacity)
      break
  }

  points.push(points[0])
  return new fabric.Polyline(points, {
    strokeWidth: 0,
    fill: cellColor,
    stroke: this.getTableColorSecondary(),
    selectable: false,
    evented: false,
  })
}

MyFabric.prototype.getCellFrame = function (points) {
  points.push(points[0])
  return new fabric.Polyline(points, {
    strokeWidth: this.getLineWidth(),
    opacity: 0.8,
    stroke: this.getTableColorSecondary(),
    fill: 'transparent',
    selectable: false,
    evented: false,
  })
}

function getTextColor(backgroundColor) {
  if (backgroundColor == null) return '#000000'

  const hexColor = backgroundColor.replace('#', '')
  const r = parseInt(hexColor.slice(0, 2), 16)
  const g = parseInt(hexColor.slice(2, 4), 16)
  const b = parseInt(hexColor.slice(4, 6), 16)
  const brightness = (r * 299 + g * 587 + b * 114) / 1000

  // Dynamically adapt text color based on background brightness
  return brightness > 128 ? '#000000' : '#FFFFFF' // Use black or white text based on brightness
}

MyFabric.prototype.getStructureText = function (type, topLeft, level) {
  const color =
    CollectionConfig.instance()
      .getStructureTags()
      .find(t => t.name === type)?.color || 'black'

  const currentSize = this.getLabelSize()

  const size = currentSize * getScaleFactor()

  if (type == null) return

  const typeText = level ? `${type}-${level}` : type

  const paddingX = size / 2
  const paddingY = size / 4
  const correction = paddingY / 4

  const text = new fabric.Text(typeText, {
    fontFamily: 'Noto Sans Display',
    fontSize: size,
    fill: getTextColor(color),
    left: paddingX,
    top: paddingY + correction,
  })

  const textBounding = text.getBoundingRect()
  const bg = new fabric.Rect({
    fill: hexToRgbaString(color, 0.5),
    rx: 4 * getScaleFactor(),
    ry: 4 * getScaleFactor(),
    width: textBounding.width + 2 * paddingX,
    height: textBounding.height + 2 * paddingY,
  })

  return new fabric.Group([bg, text], {
    left: topLeft.x,
    top: topLeft.y - textBounding.height * 1.5,
    originX: 'left',
    originY: 'top',
    evented: false,
  })
}

MyFabric.prototype.getStructureColorFrame = function (type, coords) {
  if (type == null) return
  if (coords.length === 0) return

  coords.push(coords[0])
  const color =
    CollectionConfig.instance()
      .getStructureTags()
      .find(t => t.name === type)?.color || 'black'

  return new fabric.Polyline(coords, {
    strokeWidth: 3,
    stroke: color,
    fill: color,
    opacity: 0.2,
    selectable: false,
    evented: false,
  })
}

MyFabric.prototype.getIdLabel = function (id, topLeft) {
  const size = this.getLabelSize()
  const left = topLeft?.x - Math.sqrt(size) * getScaleFactor()
  const top = topLeft?.y + Math.sqrt(size) * getScaleFactor()

  return new fabric.Text(id, {
    originX: 'right',
    originY: 'top',
    fontSize: size,
    fill: 'black',
    backgroundColor: 'white',
    evented: false,
    left,
    top,
  })
}

MyFabric.prototype.getROText = function (roIndex, topLeft) {
  if (roIndex == null) return

  const indexInt = parseInt(roIndex) + 1

  const currentSize = this.getLabelSize()

  const size = currentSize * getScaleFactor()

  const paddingX = size / 2
  const paddingY = size / 4
  const correction = paddingY / 4

  const text = new fabric.Text(indexInt.toString(), {
    fontFamily: 'Noto Sans Display',
    fontSize: size,
    fill: 'black',
    left: paddingX,
    top: paddingY + correction,
  })

  const textBounding = text.getBoundingRect()
  const bg = new fabric.Rect({
    fill: 'white',
    rx: 4 * getScaleFactor(),
    ry: 4 * getScaleFactor(),
    width: textBounding.width + 2 * paddingX,
    height: textBounding.height + 2 * paddingY,
  })

  const left = topLeft?.x - Math.sqrt(size) * getScaleFactor()
  const top = topLeft?.y + Math.sqrt(size) * getScaleFactor()
  return new fabric.Group([bg, text], {
    left,
    top,
    originX: 'right',
    originY: 'top',
    evented: false,
  })
}

MyFabric.prototype.areObjectsIdentical = function (obj1, obj2) {
  if (obj1 == null || obj2 == null) return false
  let obj1Properties = obj1.toObject()
  let obj2Properties = obj2.toObject()

  return JSON.stringify(obj1Properties) === JSON.stringify(obj2Properties)
}

MyFabric.prototype.getTopLeft = function (vertices) {
  return vertices.reduce((acc, v) =>
    Math.abs(acc.left) + Math.abs(acc.top) > Math.abs(v.left) + Math.abs(v.top)
      ? v
      : acc
  )
}
// MyFabric.prototype.getHorizontalTableLine = function (circles) {
//   return new fabric.Polyline(
//     circles.map((c) => ({ x: c.left, y: c.top })),
//     {
//       id: this.idHandler.get(),
//       strokeWidth: 1,
//       originX: 'center',
//       originY: 'center',
//       stroke: 'red',
//       padding: 20,
//       fill: 'transparent',
//       opacity: 0.5,
//       hasControls: false,
//       lockMovementX: true,
//       evented: false,
//       hasBorders: false,
//       hoverCursor: 'row-resize',
//     }
//   )
// }

// MyFabric.prototype.getTableLine = function (circles) {
//   return new fabric.Polyline(
//     circles.map((c) => ({ x: c.left, y: c.top })),
//     {
//       strokeWidth: 1,
//       originX: 'center',
//       originY: 'center',
//       stroke: 'red',
//       fill: 'transparent',
//       opacity: 0.5,
//       hasControls: false,
//       evented: false,
//       hasBorders: false,
//     }
//   )
// }

// MyFabric.prototype.getTableBackground = function (midPoint, width, height) {
//   let rect = new fabric.Rect({
//     id: this.idHandler.get(),
//     left: midPoint[0],
//     top: midPoint[1],
//     width: width,
//     height: height,
//     originX: 'center',
//     originY: 'center',
//     strokeWidth: 0,
//     fill: 'yellow',
//     opacity: 0.1,
//     selectable: false,
//     evented: false,
//   })
//   return rect
// }

// MyFabric.prototype.getTableCornerPoints = function (frameCoords) {
//   const updateLine = (circle, neighbor) => {
//     let distLine = [
//       [circle.left, circle.top],
//       [neighbor.circle.left, neighbor.circle.top],
//     ]
//     if (neighbor.line.isVertical) {
//       neighbor.line.set({
//         height: lineLength(distLine),
//         top: lineMidpoint(distLine)[1],
//       })
//     } else {
//       neighbor.line.set({
//         width: lineLength(distLine),
//         left: lineMidpoint(distLine)[0],
//       })
//     }
//     neighbor.relation = getLineCircleRelation(circle, neighbor.line)
//   }

//   const getLineCircleRelation = (circle, line) => {
//     let circleMatrix = circle.calcTransformMatrix()
//     let inverseLineMatrix = fabric.util.invertTransform(
//       line.calcTransformMatrix()
//     )
//     return fabric.util.multiplyTransformMatrices(
//       inverseLineMatrix,
//       circleMatrix
//     )
//   }

//   let circles = frameCoords.map(
//     (coord) =>
//       new fabric.Circle({
//         id: this.idHandler.get(),
//         radius: 5,
//         fill: 'black',
//         strokeWidth: 1,
//         stroke: 'black',
//         left: coord.x,
//         top: coord.y,
//         originX: 'center',
//         originY: 'center',
//         hasControls: false,
//         hasBorders: false,
//         update: function () {
//           updateLine(this, this.ancestor)
//           updateLine(this, this.successor)
//         },
//         ancestor: {
//           circle: {},
//           line: {},
//           relation: {},
//         },
//         successor: {
//           circle: {},
//           line: {},
//           relation: {},
//         },
//       })
//   )
//   circles.map((circle, index) => {
//     circle.ancestor.circle =
//       circles[index - 1 >= 0 ? index - 1 : circles.length - 1]
//     circle.successor.circle =
//       circles[index + 1 <= circles.length - 1 ? index + 1 : 0]

//     // circle.on('moved', function () {
//     //   updateLine(this, this.ancestor)
//     //   updateLine(this, this.successor)
//     // })
//   })
//   return circles
// }

// MyFabric.prototype.getTableFrameLines = function (cornerPoints) {
//   const getLineCircleRelation = (circle, line) => {
//     let circleMatrix = circle.calcTransformMatrix()
//     let inverseLineMatrix = fabric.util.invertTransform(
//       line.calcTransformMatrix()
//     )
//     return fabric.util.multiplyTransformMatrices(
//       inverseLineMatrix,
//       circleMatrix
//     )
//   }

//   const updateLine = (circle, neighbor) => {
//     let distLine = [
//       [circle.left, circle.top],
//       [neighbor.circle.left, neighbor.circle.top],
//     ]
//     if (neighbor.line.isVertical) {
//       neighbor.line.set({
//         height: lineLength(distLine),
//         top: lineMidpoint(distLine)[1],
//       })
//     } else {
//       neighbor.line.set({
//         width: lineLength(distLine),
//         left: lineMidpoint(distLine)[0],
//       })
//     }
//     neighbor.relation = getLineCircleRelation(circle, neighbor.line)
//   }

//   const moveWithLine = (circle, line, relation) => {
//     let circleTransform = fabric.util.multiplyTransformMatrices(
//       line.calcTransformMatrix(),
//       relation
//     )
//     let decomposed = fabric.util.qrDecompose(circleTransform)
//     circle.set({
//       left: decomposed.translateX,
//       top: decomposed.translateY,
//     })
//   }

//   let myFabric = this

//   let pointsForLines = cornerPoints.concat([cornerPoints[0]])

//   let lines = []

//   pointsForLines.reduce((first, second, index) => {
//     let isVertical = index % 2 === 0
//     let line = new fabric.Line(
//       [first.left, first.top, second.left, second.top],
//       {
//         strokeWidth: 2,
//         originX: 'center',
//         originY: 'center',
//         fill: 'black',
//         stroke: 'black',
//         padding: 10,
//         hasControls: false,
//         // selectable: false,
//         lockMovementX: isVertical ? false : true,
//         lockMovementY: isVertical ? true : false,
//         id: this.idHandler.get(),
//         isVertical: isVertical,
//       }
//     )
//     lines.push(line)
//     first.successor.line = line
//     second.ancestor.line = line

//     return second
//   })

//   cornerPoints.map((p) => {
//     p.update()
//   })

//   lines.map((line) => {
//     line.on('moving', function () {
//       moveWithLine(p, this, p.ancestor.relation)
//       updateLine(p, p.successor)
//     })
//   })

//   return lines

//   return lines
// }

// MyFabric.prototype.getVerticalTableLines = function (
//   seperators,
//   [topLine, rightLine, bottomLine, leftLine]
// ) {
//   let buffer = 5

//   let lines = seperators.map((s) => {
//     return {
//       circles: [],
//       stroke: new fabric.Line(
//         [s, topLine.stroke.top, s, bottomLine.stroke.top],
//         {
//           strokeWidth: 1,
//           fill: 'blue',
//           stroke: 'blue',
//           lockMovementY: true,
//           padding: 10,
//         }
//       ),
//     }
//   })
//   lines.map((line, index) => {
//     let stroke = line.stroke
//     stroke.on('moving', function (e) {
//       let leftBefore = lines[index - 1]?.stroke.left || leftLine.stroke.left
//       let leftAfter = lines[index + 1]?.stroke.left || rightLine.stroke.left

//       if (stroke.left < leftBefore + buffer) {
//         this.set('left', leftBefore + buffer)
//       }
//       if (stroke.left > leftAfter - buffer) {
//         this.set('left', leftAfter - buffer)
//       }
//     })
//   })
//   return lines
// }

// MyFabric.prototype.getHorizontalTableLines = function (
//   seperators,
//   [topLine, rightLine, bottomLine, leftLine]
// ) {
//   let buffer = 5

//   let lines = seperators.map((s) => {
//     return {
//       circles: [],
//       stroke: new fabric.Line(
//         [leftLine.stroke.left, s, rightLine.stroke.left, s],
//         {
//           strokeWidth: 1,
//           fill: 'blue',
//           stroke: 'blue',
//           lockMovementX: true,
//           padding: 10,
//         }
//       ),
//     }
//   })
//   lines.map((line, index) => {
//     let stroke = line.stroke
//     stroke.on('moving', function (e) {
//       let topBefore = lines[index - 1]?.stroke.top || topLine.stroke.top
//       let topAfter = lines[index + 1]?.stroke.top || bottomLine.stroke.top

//       if (stroke.top < topBefore + buffer) {
//         this.set('top', topBefore + buffer)
//       }
//       if (stroke.top > topAfter - buffer) {
//         this.set('top', topAfter - buffer)
//       }
//     })
//   })
//   return lines
// }

// MyFabric.prototype.getInvisibleVertex = function (
//   x,
//   y,
//   id = this.idHandler.get()
// ) {
//   return new fabric.Circle({
//     radius: this.largeCircleRadius,
//     fill: 'red',
//     left: x,
//     top: y,
//     originX: 'center',
//     originY: 'center',
//     hasControls: false,
//     hasBorders: false,
//     id: id,
//     hoverCursor: 'pointer',
//     moveCursor: 'pointer',
//   })
// }

MyFabric.prototype.getArrow = function (
  x,
  y,
  angle,
  initId,
  size = 4,
  color = 'black',
  opacity = 0.4
) {
  return new fabric.Triangle({
    width: size,
    height: size,
    left: x,
    top: y,
    angle: angle + 90,
    fill: color,
    strokeWidth: 0.6,
    stroke: 'black',
    originX: 'center',
    originY: 'center',
    opacity,
    strokeWidth: 0.8,
    selectable: false,
    evented: false,
    id: initId,
  })
}

// MyFabric.prototype.getPreviewCircle = function (x, y) {
//   return new fabric.Circle({
//     radius: this.circleRadius,
//     fill: 'blue',
//     strokeWidth: 0.6,
//     stroke: 'black',
//     opacity: 0.3,
//     left: x,
//     top: y,
//     originX: 'center',
//     originY: 'center',
//     selectable: false,
//     evented: false,
//   })
// }

MyFabric.prototype.getSplitLine = function (
  canvasDiagonal,
  mousePosition,
  angle
) {
  return new fabric.Line(
    [
      mousePosition.x - canvasDiagonal,
      mousePosition.y,
      mousePosition.x + canvasDiagonal,
      mousePosition.y,
    ],
    {
      strokeWidth: 1,
      fill: 'blue',
      stroke: 'blue',
      selectable: false,
      evented: false,
      originX: 'center',
      originY: 'center',
      angle,
    }
  )
}

// MyFabric.prototype.getVerticalSplitLine = function (
//   canvasHeight,
//   mousePosition
// ) {
//   return new fabric.Line([mousePosition.x, 0, mousePosition.x, canvasHeight], {
//     strokeWidth: 1,
//     fill: 'blue',
//     stroke: 'blue',
//     selectable: false,
//     evented: false,
//   })
// }

// MyFabric.prototype.getHorizontalSplitLine = function (
//   canvasWidth,
//   mousePosition
// ) {
//   return new fabric.Line([0, mousePosition.y, canvasWidth, mousePosition.y], {
//     strokeWidth: 1,
//     fill: 'blue',
//     stroke: 'blue',
//     selectable: false,
//     evented: false,
//   })
// }

MyFabric.prototype.getNewAssignLine = function ([x1, y1], [x2, y2]) {
  return new fabric.Line([x1, y1, x2, y2], {
    strokeWidth: 1,
    fill: 'black',
    stroke: 'black',
    selectable: false,
    evented: false,
    startPos: [x1, y1],
  })
}

MyFabric.prototype.getNewAssignCircle = function (x, y) {
  return new fabric.Circle({
    radius: this.circleRadius,
    fill: 'black',
    strokeWidth: this.strokeWidth,
    stroke: 'black',
    left: x,
    top: y,
    originX: 'center',
    originY: 'center',
    hasControls: false,
    hasBorders: false,
    evented: false,
  })
}

// MyFabric.prototype.getTextRect = function () {
//   let rect = new fabric.Rect({
//     left: 0,
//     top: 0,
//     width: 100,
//     height: 100,
//     strokeWidth: 1,
//     stroke: 'black',
//     fill: 'green',
//     selectable: false,
//   })

//   rect.on('mouseover', function (o) {
//   })
//   rect.on('mouseout', function (o) {
//   })
//   return rect
// }

MyFabric.prototype.getPreviewLine = function (coords, opacity = 0.3, color) {
  return new fabric.Line(coords, {
    originX: 'center',
    originY: 'center',
    strokeWidth: 1,
    fill: color,
    stroke: color,
    opacity: opacity,
    selectable: false,
    evented: false,
  })
}

MyFabric.prototype.getPreviewLineBaseline = function (coords, opacity) {
  return this.getPreviewLine(coords, opacity, this.getBaselineColor())
}

MyFabric.prototype.getPreviewLineLinePolygon = function (coords, opacity) {
  return this.getPreviewLine(coords, opacity, this.getLinePolygonColor())
}

MyFabric.prototype.getPreviewLineRegion = function (coords, opacity) {
  return this.getPreviewLine(coords, opacity, this.getRegionColor())
}

MyFabric.prototype.getPreviewRect = function (x1, y1, x2, y2, color) {
  let width = Math.abs(x1 - x2)
  let height = Math.abs(y1 - y2)
  let midPoint = lineMidpoint([
    [x1, y1],
    [x2, y2],
  ])
  return new fabric.Rect({
    left: midPoint[0],
    top: midPoint[1],
    originX: 'center',
    originY: 'center',
    width: width,
    height: height,
    strokeWidth: 1,
    stroke: color,
    fill: color,
    opacity: 0.2,
    selectable: false,
    evented: false,
  })
}

MyFabric.prototype.getPreviewRectTable = function (x1, y1, x2, y2) {
  return this.getPreviewRect(x1, y1, x2, y2, this.getTableColorSecondary())
}

MyFabric.prototype.getPreviewRectRegion = function (x1, y1, x2, y2) {
  return this.getPreviewRect(x1, y1, x2, y2, this.getRegionColor())
}

MyFabric.prototype.getSelectionRect = function (x, y) {
  const rect = new fabric.Rect({
    left: x,
    top: y,
    originX: 'center',
    originY: 'center',
    width: 0,
    height: 0,
    strokeWidth: 2,
    stroke: 'black',
    fill: 'transparent',
    opacity: 0.5,
    selectable: false,
    evented: false,
    initX: x,
    initY: y,
    updated: false,
  })
  rect.update = (x, y) => {
    rect.updated = true
    const width = Math.abs(rect.initX - x)
    const height = Math.abs(rect.initY - y)
    const midPoint = lineMidpoint([
      [rect.initX, rect.initY],
      [x, y],
    ])
    rect.set({ width, height, left: midPoint[0], top: midPoint[1] })
  }
  rect.getArea = () => {
    const halfWidth = rect.width / 2
    const halfHeight = rect.height / 2
    return [
      [rect.left - halfWidth, rect.top - halfHeight],
      [rect.left - halfWidth, rect.top + halfHeight],
      [rect.left + halfWidth, rect.top + halfHeight],
      [rect.left + halfWidth, rect.top - halfHeight],
    ]
  }
  return rect
}

MyFabric.prototype.getCanvasRect = function (canvas) {
  return new fabric.Rect({
    left: 0,
    top: 0,
    originX: 'left',
    originY: 'top',
    width: canvas.width,
    height: canvas.height,
    strokeWidth: 3,
    stroke: 'red',
    fill: 'transparent',
    selectable: false,
    evented: false,
  })
}

MyFabric.prototype.getInvalidTableBackground = function (points) {
  return new fabric.Polyline(points, {
    strokeWidth: 0,
    fill: 'red',
    opacity: 0.2,
    selectable: false,
    evented: false,
  })
}

MyFabric.prototype.getInvalidTableFrame = function (points) {
  return new fabric.Polyline([...points, points[0]], {
    strokeWidth: 1,
    opacity: 0.6,
    stroke: 'black',
    fill: 'transparent',
    selectable: false,
    evented: false,
  })
}

MyFabric.prototype.getInvalidTableSign = function (points) {
  let polygon = points.map(({ x, y }) => [x, y])
  let mean = polygonMean(polygon)

  return new fabric.Text('Invalid Table', {
    originX: 'center',
    originY: 'center',
    fontSize: 50 * getScaleFactor(),
    fill: 'yellow',
    evented: false,
    left: mean[0],
    top: mean[1],
  })
}

// MyFabric.prototype.getQuickPolyline = function (points) {
//   return new fabric.Polyline([...points, points[0]], {
//     strokeWidth: 1,
//     stroke: 'black',
//     fill: 'transparent',
//     selectable: false,
//     evented: false,
//   })
// }

MyFabric.prototype.clone = function (object) {
  let objectClone
  object.clone(clone => {
    objectClone = clone
  })
  return objectClone
}

MyFabric.prototype.getGroupData = function (group) {
  const getData = (group, left, top) => {
    let circles = group.getObjects().filter(o => o.type === 'circle')
    let groups = group.getObjects().filter(o => o.type === 'group')
    // let horizontalMid =

    //(group.left + group.width / 2) + g.left + (group.left + group.width / 2) + o.left

    //This is working

    // let verticalMid = top + group.height / 2
    return {
      vertices: circles.map(o => ({
        left: left + (group.width / 2 + o.left) * group.scaleX,
        top: top + (group.height / 2 + o.top) * group.scaleY,
      })),
      groups: groups.map(g =>
        getData(
          g,
          left + (group.width / 2 + g.left) * group.scaleX,
          top + (group.height / 2 + g.top) * group.scaleY
        )
      ),
    }
  }
  return getData(group, group.left, group.top)
}

MyFabric.prototype.getDock = function ([x, y], id, type) {
  return new fabric.Circle({
    radius: this.getCircleSize() * 2,
    fill: 'transparent',
    strokeWidth: this.strokeWidth,
    stroke:
      type === 'baseline'
        ? 'blue'
        : type === 'region'
        ? 'green'
        : type === 'table'
        ? 'yellow'
        : type === 'word'
        ? this.wordLineColor
        : 'orange',
    left: x,
    top: y,
    originX: 'center',
    originY: 'center',
    hasControls: false,
    hasBorders: false,
    selectable: false,
    id: id,
    defaultRadius: this.getCircleSize() * 2,
  })
}

MyFabric.prototype.getRelationNode = function ([x, y], r, shapeId, index) {
  const color =
    CollectionConfig.instance()
      .getRelationTags()
      .find(t => t.name === r.getName())?.color || 'black'
  //log(type)
  const circle = new fabric.Circle({
    radius: this.getCircleSize() * 2,
    fill: 'transparent',
    strokeWidth: this.strokeWidth,
    stroke: color,
    left: x,
    top: y,
    originX: 'center',
    originY: 'center',
    hasControls: false,
    hasBorders: false,
    selectable: true,
    //added this to disable editing
    //evented: false,
    relationId: r.id,
    shapeId: shapeId,
    id: shapeId,
    index,
    defaultRadius: this.getCircleSize() * 2,
  })
  circle.select = () => {
    circle.set('fill', color)
  }
  circle.unSelect = () => {
    circle.set('fill', 'transparent')
  }
  circle.resetPosition = () => {
    circle.set({ left: x, top: y })
    circle.setCoords()
  }
  return circle
}

MyFabric.prototype.getRelationArrow = function (p1, p2, name) {
  const color =
    CollectionConfig.instance()
      .getRelationTags()
      .find(t => t.name === name)?.color || 'black'
  let line = [p1, p2]
  const length = lineLength(line)
  const reducedLength1 = length - 15
  const ratio1 = reducedLength1 / length
  const reducedLength2 = length - 25
  const ratio2 = reducedLength2 / length
  let angle = lineAngle(line)
  let interpolatedEndPoint = lineInterpolate(line)(ratio2)
  let interpolatedStartPoint = lineInterpolate(line)(1 - ratio1)
  let midPoint = lineMidpoint(line)

  const size = this.getLabelSize()

  const members = []
  let arrow = this.getArrow(
    interpolatedEndPoint[0],
    interpolatedEndPoint[1],
    angle,
    -1,
    size,
    color,
    1
  )
  let arrowLine = new fabric.Line(
    [...interpolatedStartPoint, ...interpolatedEndPoint],
    {
      strokeWidth: 2,
      stroke: color,
      selectable: false,
      evented: false,
    }
  )
  members.push(arrow, arrowLine)
  if (name != null) {
    const size = this.getLabelSize()
    const text = new fabric.Text(name, {
      originX: 'right',
      originY: 'top',
      fontSize: size,
      fill: 'black',
      backgroundColor: 'white',
      evented: false,
      left: midPoint[0],
      top: midPoint[1],
    })
    members.push(text)
  }

  const group = new fabric.Group(members, {
    selectable: false,
    evented: false,
  })
  return group
}

function interpolateColor(color1, color2, percentage) {
  // Convert hex colors to RGB
  const rgb1 = hexToRgb(color1)
  const rgb2 = hexToRgb(color2)

  // Interpolate each component (R, G, B)
  const interpolatedColor = {
    r: Math.round(rgb1.r + (rgb2.r - rgb1.r) * percentage),
    g: Math.round(rgb1.g + (rgb2.g - rgb1.g) * percentage),
    b: Math.round(rgb1.b + (rgb2.b - rgb1.b) * percentage),
  }

  // Convert back to hex
  const interpolatedHexColor = rgbToHex(
    interpolatedColor.r,
    interpolatedColor.g,
    interpolatedColor.b
  )

  return interpolatedHexColor
}

function hexToRgb(hex) {
  // Remove the hash if it exists
  hex = hex.replace(/^#/, '')

  // Parse the hex values to RGB
  const bigint = parseInt(hex, 16)
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255

  return { r, g, b }
}

function hexToRgbaString(hex, opacity) {
  const rgb = hexToRgb(hex)

  if (opacity < 0 || opacity > 1) {
    throw new Error('Opacity must be between 0 and 1')
  }

  return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})`
}

function rgbToHex(r, g, b) {
  // Convert RGB values to hex
  const toHex = value => {
    const hex = value.toString(16)
    return hex.length === 1 ? '0' + hex : hex
  }

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`
}

// if (this.readingOrderText) {
//   this.canvas.remove(this.readingOrderText)
// }
// if (this.vertices[0] && this.showReadingOrder) {
//   this.readingOrderText = new fabric.Text(this.index + '', {
//     left: this.vertices[0].get('left'),
//     top: this.vertices[0].get('top'),
//     fontSize: 20,
//     selectable: false,
//     evented: false,
//     fill: 'green',
//   })
//   this.canvas.add(this.readingOrderText)
// }

// this.canvas.add(
//   new fabric.Polyline(
//     [
//       { x: 0, y: 0 },
//       { x: 0, y: this.canvas.getHeight() },
//       { x: this.canvas.getWidth(), y: this.canvas.getHeight() },
//       { x: this.canvas.getWidth(), y: 0 },
//       { x: 0, y: 0 }
//       // { x: 10, y: 10 },
//       // { x: 50, y: 30 },
//       // { x: 40, y: 70 },
//     ],
//     {
//       strokeWidth: 6,
//       stroke: 'green',
//       fill: 'transparent',
//       selectable: false,
//       evented: false
//     }
//   )
// )

// export {
//   getCircle,
//   getPreviewLine,
//   getLineElements,
//   getPreviewCircle,
//   getPolygon,
//   getRegionVertex,
//   getRegionLine,
//   getRegionBackground,
//   getInvisibleVertex,
//   getRunningVertex,
//   getPreviewRect,
// }

export { Singleton }
