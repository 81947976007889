<template>
  <div
    :id="id"
    class="flex justify-center items-center rounded-sm relative m-[2px]"
    :style="{
      minHeight: computedFontSize * 1.8 + 'rem',
      'background-color': getBackgroundColor(),
    }"
    :class="{ selected, draggable }"
    @mouseup="mouseUpWrapper"
  >
    <div
      v-if="showLineRegion && !isSites"
      :class="[`w-6 flex justify-center items-center mb-0 noselect`]"
      @selectstart="handleSelectStart"
      :style="{
        'user-select': 'none !important',
      }"
    >
      <span class="text-[#7A7A7A] text-xs noselect mt-0.5">{{
        index + 1
      }}</span>
    </div>
    <span
      v-if="draggable"
      class="absolute right-0 top-1"
      v-html="$svg.dragIndicator"
    ></span>
    <div class="flex flex-col flex-grow">
      <div
        :id="`text-${id}`"
        v-bind:style="{
          'white-space': 'normal',
          fontSize: computedFontSize + 'rem',
          fontFamily: computedFontFamily,
          minHeight: computedFontSize + 'rem',
        }"
        :contenteditable="isContentEditable"
        :dir="textDirection"
        @focusout="lineFocusOut(id, $event)"
        @dblclick="onDoubleClick()"
        @mouseup="mouseUpLine"
        @mousedown="mouseDownContenteditable"
        @keydown="handleKeydown"
        @input="onInput"
        v-html="html"
        :autocomplete="'off'"
        :autocorrect="'off'"
        :autocapitalize="'off'"
        :spellcheck="false"
      ></div>
      <div
        :id="`tags-${id}`"
        :class="[`w-full relative`]"
        :style="{ height: `${tagSpace}px` }"
      >
        <span
          v-for="tag in cleanTags"
          @click="tagClicked(tag)"
          :style="{
            cursor: 'pointer',
            position: 'absolute',
            left: `${tag.x}px`,
            top: `${tag.y}px`,
            width: `${tag.width}px`,
            height: `${tag.height}px`,
            paddingTop: '1px',
            paddingBottom: '1px',
            display: 'flex',
            alignItems: 'center',
            borderLeft: tag.openLeft
              ? ''
              : `${tag.borderWidth}px solid ${tag.color}`,
            borderRight: tag.openRight
              ? ''
              : `${tag.borderWidth}px solid ${tag.color}`,
          }"
        >
          <span
            v-if="tag.showCircles"
            class="absolute rounded-circle"
            :style="{
              backgroundColor: `${tag.color}`,
              left: '-3px',
              top: '-3px',
            }"
          ></span>
          <span
            v-if="tag.showCircles"
            class="absolute rounded-circle"
            :style="{
              backgroundColor: `${tag.color}`,
              left: '-3px',
              bottom: '-3px',
            }"
          ></span>
          <span
            :style="{
              width: '100%',
              height: '2px',
              backgroundColor: `${tag.color}`,
            }"
            :data-testid="`tag-${tag.name}`"
          ></span
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
import { Singleton as Settings } from '../LayoutEditor/components/singletons/settings.js'

import {
  textToHtml,
  getSelectionCharacterOffsetWithin,
  getCaretCharacterOffsetWithin,
  insertTextAtCaret,
  saveSelection,
  restoreSelection,
  Cursor,
  isRTL,
  removeForbiddenNonePrintableChars,
} from './TextEditorUtils.js'
import { replaceCSSCharsInCustomTagValue } from '../../../utils/misc.js'
import {
  Singleton as ActionHandler,
  ACTION_TYPE,
  SELECTION_TYPE,
} from '../LayoutEditor/components/singletons/actionHandler.js'

import CursorManager from './CursorManager.js'
import { isEmpty, isEqual } from 'lodash'
import { Singleton as MyFabric } from '../LayoutEditor/components/myFabric.js'

let indexStack = []

export default {
  setup() {
    const { userProfile } = useKeycloak()
    return { userProfile }
  },
  data() {
    return {
      showLineRegion: Settings.instance().get('showLineRegion'),
      fontSize: Settings.instance().get('fontSize'),
      fontFamily: Settings.instance().get('fontFamily'),
      tagging: Settings.instance().get('tagging'),
      autoCenterLineNoZoom: Settings.instance().get('autoCenterLineNoZoom'),
      autoCenterLineZoom: Settings.instance().get('autoCenterLineZoom'),
      textAlignment: Settings.instance().get('textAlignment'),
      textDiv: null,
      lineDiv: null,
      tagsDiv: null,
      keyActions: [],
    }
  },
  props: {
    editorName: {
      type: String,
    },
    searchTerm: {
      type: String,
    },
    isMobileSize: {
      type: Boolean,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    textualTags: {
      type: Array,
      default: () => [],
    },
    showTags: {
      type: Boolean,
    },
    selected: {
      type: Boolean,
    },
    isContentEditable: {
      type: Boolean,
    },
    isEditable: {
      type: Boolean,
    },
    index: {
      type: Number,
    },
    id: {
      type: String,
    },
    isSites: {
      type: Boolean,
    },
    custom: {
      type: Object,
    },
    customBefore: {
      type: Object,
    },
    customAfter: {
      type: Object,
    },
    text: {
      type: String,
    },
    diffHtml: {
      type: String,
    },
  },
  /* watch: {
    custom(newValue) {
      setTimeout(() => {
        this.delayedTags = newValue?.tags
      }, 1)
    },
  }, */
  computed: {
    computedFontFamily() {
      return this.fontFamily === 'noto' ? 'Noto Sans' : this.fontFamily
    },
    allowTagging() {
      return !this.isSites && this.tagging && this.isEditable
    },
    tagSpace() {
      if (this.cleanTags.length <= 0) return 0
      const maxYShift = indexStack.reduce((acc, { yShift }) => {
        return Math.max(acc, yShift)
      }, 0)
      const height = maxYShift * 10
      return height > 0 ? height + 5 : 0
    },
    computedFontSize() {
      if (this.isMobileSize) {
        return 0.8
      }
      return this.fontSize
    },
    cleanTags() {
      if (!this.tagsDiv || !this.textDiv) return []
      if (!this.showTags) return []
      indexStack = []
      const indexOverlap = (interval1, interval2) => {
        const intervalContainsInterval = (interval1, interval2) => {
          if (
            interval1.start >= interval2.start &&
            interval1.start <= interval2.end
          )
            return true
          if (
            interval1.end >= interval2.start &&
            interval1.end <= interval2.end
          )
            return true
          return false
        }
        return (
          intervalContainsInterval(interval1, interval2) ||
          intervalContainsInterval(interval2, interval1)
        )
      }
      let tags = this.getTags()
      if (this.$features?.dan && this.userProfile?.IsAdmin && tags) {
        tags = this.sortByLevel(tags)
      }
      return (
        tags
          ?.reduce((acc, tag) => {
            if (tag.selector === 'line_conf') return acc
            if (tag.selector === 'word_conf') return acc

            const offset = parseInt(
              tag?.rules?.find(rule => rule.directive === 'offset')?.value,
              10
            )
            const length = parseInt(
              tag?.rules?.find(rule => rule.directive === 'length')?.value,
              10
            )
            const continued = tag?.rules?.find(
              rule => rule.directive === 'continued'
            )?.value

            let start = offset
            let end = offset + length

            const { newStart, newEnd } = this.adjustStartEndForActions(
              start,
              end
            )
            start = newStart
            end = newEnd

            if (start > end) return acc

            if (isNaN(start) || isNaN(end)) return acc

            const innerText = this.textDiv.innerText

            if (start > innerText.length || end > innerText.length) return acc

            if (innerText.length === 0) return acc

            /*             const range = document.createRange()
            range.setStart(innerText, start)
            range.setEnd(innerText, end)
            const rect = this.getRangeRect(range)
            */

            const rects = CursorManager.getRangeRects(
              'text-' + this.id,
              start,
              end
            )

            const getCleanTagFromRect = (rect, rectIndex, rectsLength) => {
              const containerRect = this.tagsDiv.getBoundingClientRect()
              const containerX = containerRect.left
              const containerY = containerRect.top

              const relativeX = rect.x - containerX
              const relativeY = rect.y - containerY

              const configTag = this.textualTags.find(
                t => t.name === tag.selector
              )

              let overlapping = indexStack.filter(i => {
                return indexOverlap(i, { start, end })
              })
              /* if (rectIndex > 0) {
                overlapping = []
              } */

              const yShifts = overlapping.map(({ yShift }) => yShift)
              const yShiftsUnique = [...new Set(yShifts)]
              const yShiftsSorted = yShiftsUnique.toSorted((a, b) => a - b)

              let expected = 0

              // Iterate through the sorted array
              for (const shift of yShiftsSorted) {
                if (shift === expected) {
                  expected++
                } else {
                  break
                }
              }

              if (rectIndex === rectsLength - 1) {
                indexStack.push({ start, end, yShift: expected })
              }

              let spanHeight = 7
              let borderWidth = 2
              let y = relativeY + rect.height + expected * spanHeight
              let showCircles = false
              if (tag.selector === 'gap') {
                spanHeight = this.remToPixels(this.computedFontSize)
                borderWidth = 1
                y = relativeY + 4
                showCircles = true
              }
              let openLeft = false
              let openRight = false
              //We check for continued tags but also for tags that have multiple rects due to text editor line breaks
              if (
                (continued &&
                  start === 0 &&
                  this.customBefore?.tags?.find(
                    t =>
                      t.selector === tag.selector &&
                      t.rules.find(r => r.directive === 'continued')
                  )) ||
                rectIndex > 0
              ) {
                openLeft = true
              }
              if (
                (continued &&
                  end === innerText.length &&
                  this.customAfter?.tags?.find(
                    t =>
                      t.selector === tag.selector &&
                      t.rules.find(r => r.directive === 'continued')
                  )) ||
                rectIndex < rectsLength - 1
              ) {
                openRight = true
              }
              return {
                name: tag.selector,
                start,
                end,
                openLeft,
                openRight,
                borderWidth: borderWidth,
                showCircles,
                id: tag.id,
                attributes: tag.rules,
                x: relativeX,
                y,
                absoluteX: rect.x,
                absoluteY: rect.y,
                width: rect.width,
                height: spanHeight,
                color: configTag?.color || 'grey',
                rawTag: tag,
              }
            }
            return [
              ...acc,
              ...rects.map((r, index) =>
                getCleanTagFromRect(r, index, rects.length)
              ),
            ]
          }, [])
          .filter(t => t != null) || []
      )
    },

    textStyles() {
      return this.getTags().filter(t => t.selector === 'textStyle')
    },
    html() {
      if (this.diffHtml) {
        return this.diffHtml
      }
      let html = textToHtml(this.text || '', this.textStyles, this.mapping)

      if (this.searchTerm) {
        const wrapSubstringWithSpan = (inputString, searchString) => {
          // Create a regular expression with case-insensitive flag
          const regex = new RegExp(`(${searchString})`, 'ig')

          // Use replace method to wrap the matched substring with <span>
          const resultString = inputString.replace(
            regex,
            '<span style="border: 2px solid red; padding: 2px; font-weight: bold;">$1</span>'
          )

          return resultString
        }
        html = wrapSubstringWithSpan(html, this.searchTerm)
      }
      /*       setTimeout(() => {
        this.renderTags()
      }, 100) */
      return html
    },
    textDirection() {
      return isRTL(this.text) ? 'rtl' : 'ltr'
    },
    mapping() {
      let mappedTags = this.textualTags.map(tag => {
        return {
          name: tag.name,
          style: `border-bottom: 3px ${tag.color} solid; padding-bottom:0.5px;cursor: pointer; position: relative;`,
          type: 'tag',
        }
      })
      // add textStyles
      mappedTags = mappedTags.concat([
        { name: 'textStyle', style: 'font-weight: bold;', type: 'bold' },
        {
          name: 'textStyle',
          style: 'text-decoration: underline;',
          type: 'underlined',
        },
        {
          name: 'textStyle',
          style: 'text-decoration: line-through;',
          type: 'strikethrough',
        },

        { name: 'textStyle', style: 'font-style: italic;', type: 'italic' },
        {
          name: 'textStyle',
          style: 'vertical-align: sub;font-size: smaller;',
          type: 'subscript',
        },
        {
          name: 'textStyle',
          style: 'vertical-align: super;font-size: smaller;',
          type: 'superscript',
        },
      ])

      return mappedTags
    },
  },
  mounted() {
    if (this.isEditable) {
      Settings.instance().on('update', this.handleSettingsChange)
    }

    this.lineDiv = document.getElementById(`${this.id}`)
    this.textDiv = document.getElementById(`text-${this.id}`)
    this.tagsDiv = document.getElementById(`tags-${this.id}`)
  },
  beforeUnmount() {
    Settings.instance().off('update', this.handleSettingsChange)
  },
  methods: {
    adjustStartEndForActions(start, end) {
      let newStart = start
      let newEnd = end
      this.keyActions.forEach(a => {
        if (a.type === 'insertText') {
          if (a.index < newStart) {
            newStart = newStart + 1
            newEnd = newEnd + 1
          } else if (a.index >= newStart && a.index < newEnd) {
            newEnd = newEnd + 1
          }
        }
        if (a.type === 'deleteContentBackward') {
          if (a.index < newStart) {
            newStart = newStart - 1
            newEnd = newEnd - 1
          } else if (a.index >= newStart && a.index < newEnd) {
            newEnd = newEnd - 1
          }
        }
      })
      return { newStart, newEnd }
    },
    sortByLevel(data) {
      return data.toSorted((a, b) => {
        const levelA = a.rules.find(rule => rule.directive === 'level')?.value
        const levelB = b.rules.find(rule => rule.directive === 'level')?.value
        return parseInt(levelB) - parseInt(levelA)
      })
    },
    getTags() {
      return this.custom?.tags || []
    },
    mouseUpLine() {
      this.$emit('mouseUp', this.id)
      CursorManager.reApplySelection({ milliSeconds: 10 })
      if (this.popUpCanOpen()) {
        this.openTagPopup()
      }
    },
    remToPixels(remValue) {
      // Get the computed font size of the root element (html)
      const fontSize = parseFloat(
        getComputedStyle(document.documentElement).fontSize
      )

      // Convert rem to pixels
      const pixelsValue = remValue * fontSize

      return pixelsValue
    },
    setCursor(offset) {
      if (offset > this.text.length) {
        offset = this.text.length
      }

      CursorManager.setSelection(
        {
          startId: this.id,
          endId: this.id,
          startIndex: offset,
          endIndex: offset,
        },
        { delay: 1 }
      )
    },

    getBackgroundColor() {
      const color = MyFabric.instance().getBaselineHighlightColor(0.1)
      const showHighlightColor = MyFabric.instance().isShowColoredBackground()
      return this.selected && showHighlightColor ? color : ''
    },
    handleSettingsChange({
      textAlignment,
      tagging,
      fontSize,
      fontFamily,
      keyboardSize,
      showLineRegion,
      autocomplete,
      autocorrect,
      autocapitalize,
      spellcheck,
      autoCenterLineNoZoom,
      autoCenterLineZoom,
    }) {
      if (
        this.textAlignment !== textAlignment ||
        this.fontSize !== fontSize ||
        this.fontFamily !== fontFamily
      ) {
        let milliSeconds = 10
        if (this.fontFamily !== fontFamily) {
          milliSeconds = 500
        }
        this.$emit('hideTags')
        setTimeout(() => {
          this.$emit('showTags')
        }, milliSeconds)
      }

      this.autoCenterLineNoZoom = autoCenterLineNoZoom
      this.autoCenterLineZoom = autoCenterLineZoom
      this.textAlignment = textAlignment
      this.tagging = tagging
      this.fontSize = fontSize
      this.fontFamily = fontFamily
      this.keyboardSize = keyboardSize
      this.showLineRegion = showLineRegion
      this.autocomplete = autocomplete
      this.autocorrect = autocorrect
      this.autocapitalize = autocapitalize
      this.spellcheck = spellcheck
    },
    /*     updateAttribute(tag, attributeName, value) {
      const withContinued = [
        { id: this.id, tag },
        /*  ...this.getContinued(line, tag),
      ]

      const actions = withContinued.map(({ id, tag }) => {
        const oldTags = this.custom.tags

        const newTags = this.custom.tags.map(t => {
          const tagCopy = JSON.parse(JSON.stringify(t))
          if (tagCopy.id === tag.id) {
            const attrObj = tagCopy.rules.find(
              r => r.directive === attributeName
            )
            // clean attributes to not conflict with CSS rules : ; { }
            let cleanValue = replaceCSSChars(value)
            if (attrObj) {
              attrObj.value = cleanValue
            } else {
              tagCopy.rules.push({
                directive: attributeName,
                value: cleanValue,
              })
            }
          }
          return tagCopy
        })
        if (oldTags[0] == null || newTags[0] == null) {
          console.warn('oldTags', oldTags)
          console.warn('newTags', newTags)
        }

        return {
          type: ACTION_TYPE.SET_TAGS,
          shape_id: this.id,
          old_tags: oldTags,
          new_tags: newTags,
        }
      })
      ActionHandler.instance(this.editorName).newAction({
        type: ACTION_TYPE.MULTI_ACTION,
        actions,
      })
    }, */
    tagClicked({ rawTag, width, height, start, end }) {
      this.$emit('updatePopupParams', this.getTagEditConfig(rawTag))

      const lastRect = CursorManager.getRangeRects(
        'text-' + this.id,
        start,
        end
      )?.at(-1)

      if (lastRect == null) return

      this.$nextTick(() => {
        this.$emit('updatePopupPosition', {
          x: lastRect.x,
          y: lastRect.y + 20,
          width,
          height,
        })
      })
    },
    getTagEditConfig(tag) {
      return {
        tagIdentifier: {
          selector: tag.selector,
          offset: Number(tag.rules.find(r => r.directive === 'offset').value),
          length: Number(tag.rules.find(r => r.directive === 'length').value),
        },
        state: 'EDIT',
        eventHandlers: {
          getTag: identifier => {
            if (identifier == null) return
            const { selector, offset, length } = identifier
            return this.getTagByTagParameters(selector, offset, length)
          },
          getTagText: identifier => {
            if (identifier == null) return
            const { offset, length } = identifier
            return this.getTextSubstring(offset, length)
          },
          deleteTag: tag => {
            this.$emit('deleteTag', this.id, tag)
          },
          updateAttribute: (tag, attributeName, value, remove) => {
            this.$emit(
              'updateAttribute',
              this.id,
              tag,
              attributeName,
              value,
              remove
            )
          },
        },
      }
    },
    getContinuedTag(selector) {
      return this.getTags().find(
        t =>
          t.selector === selector &&
          t.rules.find(r => r.directive === 'continued')
      )
    },
    getDeleteTagAction(tag) {
      const foundIndex = this.getTags().findIndex(t =>
        isEqual(cloneObj(t), cloneObj(tag))
      )
      if (foundIndex < 0) return
      return {
        type: ACTION_TYPE.SET_TAGS,
        shape_id: this.id,
        old_tags: cloneObj(this.getTags()),
        new_tags: this.getTags().toSpliced(foundIndex, 1),
      }
    },
    getUpdateTagAttributeAction(tag, attributeName, value, remove) {
      let newTags = this.getTags().map(t => {
        if (isEqual(cloneObj(t), cloneObj(tag))) {
          const tagCopy = cloneObj(t)
          const attrObj = tagCopy.rules.find(r => r.directive === attributeName)
          let cleanValueCss = replaceCSSCharsInCustomTagValue(value)
          let cleanValue = removeForbiddenNonePrintableChars(cleanValueCss)
          if (attrObj != null) {
            if (cleanValue?.length === 0) {
              tagCopy.rules = tagCopy.rules.filter(
                r => r.directive !== attributeName
              )
            } else {
              attrObj.value = cleanValue
            }
          } else {
            tagCopy.rules.push({
              directive: attributeName,
              value: cleanValue,
            })
            if (attributeName === 'superscript') {
              tagCopy.rules = tagCopy.rules.filter(
                r => r.directive !== 'subscript'
              )
            }
            if (attributeName === 'subscript') {
              tagCopy.rules = tagCopy.rules.filter(
                r => r.directive !== 'superscript'
              )
            }
          }
          if (remove) {
            tagCopy.rules = tagCopy.rules.filter(
              r => r.directive !== attributeName
            )
          }
          return tagCopy
        }
        return t
      })
      //remove textStyle tag with no styles set
      newTags = newTags.filter(
        t => !(t.selector === 'textStyle' && t.rules.length <= 2)
      )
      return {
        type: ACTION_TYPE.SET_TAGS,
        shape_id: this.id,
        old_tags: cloneObj(this.getTags()),
        new_tags: cloneObj(newTags),
      }
    },

    /*     renderTags() {
      /* const tags = this.cleanTags || []

      const tags = [
        { end: 7, id: 's_1219_1', name: 'date', start: 4 },
        { end: 16, id: 's_1219_2', name: 'person', start: 12 },
      ]
      const div = document.getElementById('text-' + this.id)

      const textNode = div.firstChild
      tags.forEach(tag => {
        const range = document.createRange()
        range.setStart(textNode, tag.start)
        range.setEnd(textNode, tag.end)
        const rect = this.getRangeRect(range)

        const span = document.createElement('span')
        span.style.position = 'absolute'
        span.style.left = 0 + 'px'
        span.style.top = 0 + 'px'
        span.style.width = 10 + 'px'
        span.style.height = 10 + 'px'
        span.style.backgroundColor = 'red'



        div.appendChild(span)
      })
    }, */
    handleSelectStart(event) {
      event.preventDefault()
    },

    lineFocusOut(id, e) {
      const actions = []

      const oldTags = this.getTags()
      const oldText = this.text

      let newTags = this.getTags().map(tag => {
        if (tag) {
          const newTag = JSON.parse(JSON.stringify(tag))
          const offsetObj = newTag?.rules?.find(r => r.directive === 'offset')
          const lengthObj = newTag?.rules?.find(r => r.directive === 'length')
          let start = Number(offsetObj.value)
          let end = start + Number(lengthObj.value)
          const { newStart, newEnd } = this.adjustStartEndForActions(start, end)
          offsetObj.value = newStart
          lengthObj.value = newEnd - newStart
          return newTag
        }
      })

      if (oldText !== e.target.innerText) {
        const textAction = {
          type: ACTION_TYPE.SET_TEXT,
          shape_id: id,
          old_text: this.text,
          new_text: e.target.innerText.replace(/\n/g, ''), //removes unwanted line breaks created when pressing enter
        }
        actions.push(textAction)

        if (oldTags[0] == null || newTags[0] == null) {
          console.warn('oldTags', oldTags)
          console.warn('newTags', newTags)
        }
        const tagAction = {
          type: ACTION_TYPE.SET_TAGS,
          shape_id: id,
          old_tags: structuredClone(toRaw(oldTags)),
          new_tags: newTags,
        }
        // Add the tag action to the array
        actions.push(tagAction)
      }

      this.keyActions = []

      // If there are any actions in the array, perform them using the MULTI_ACTION type
      if (actions.length > 0) {
        ActionHandler.instance(this.editorName).newAction({
          type: ACTION_TYPE.MULTI_ACTION,
          actions: actions,
        })
      }

      // Update the current HTML
      //this.currentHtml = e.target.innerHTML
    },
    /*  getTagEditConfig(tags) {
      return {
        tags,
        visible: true,
        state: 'EDIT',
        eventHandlers: {
          deleteTag: tag => {
            this.$emit('updatePopupParams', { visible: false })
            this.deleteTag(tag)
          },
          updateTextStyleOfTag: (tag, style, options) => {
            //this.updateTextStyleOfTag(tag, style, options)
          },
          updateAttribute: (tag, attributeName, value) => {
            this.updateAttribute(tag, attributeName, value)
          },
        },
      }
    }, */
    deleteTag(tag) {
      const withContinued = [
        { id: this.id, tag },
        /*   ...this.getContinued(line, tag), */
      ]
      const actions = withContinued.map(({ id, tag }) => ({
        type: ACTION_TYPE.REMOVE_TAG,
        shape_id: id,
        tag: tag,
      }))
      const action = {
        type: ACTION_TYPE.MULTI_ACTION,
        actions,
      }
      ActionHandler.instance(this.editorName).newAction(action)
    },
    onDoubleClick() {
      const centerSelection = {
        type: SELECTION_TYPE.CENTER,
        id: this.id,
        centerAlwaysAndZoom: true,
      }
      ActionHandler.instance(this.editorName).select(centerSelection)
    },
    mouseUpWrapper() {
      this.$emit('mouseUpWrapper', this.id)
    },
    mouseDownContenteditable() {
      this.$emit('mouseDown', this.id)
    },
    popUpCanOpen() {
      if (CursorManager.isCollapsed()) return false
      if (!this.allowTagging) return false
      const selection = CursorManager.getSelection()
      if (selection == null) return false
      return true
    },
    openTagPopup() {
      const selection = CursorManager.getSelection()

      const { x, y, width, height } = selection.popupRect

      this.$emit('updatePopupParams', {
        state: 'CREATE',
        eventHandlers: {
          createTag: (type, textStyle) => {
            if (type === 'gap') {
              selection.endIndex = selection.startIndex
              selection.endId = selection.startId
            }
            this.$emit('createTag', { type, textStyle, selection })
          },
        },
      })
      this.$nextTick(() => {
        this.$emit('updatePopupPosition', { x, y, width, height })
      })
    },
    /*     getCaretPosition(element) {
      var selection = window.getSelection()
      var range = selection.getRangeAt(0)
      var preCaretRange = range.cloneRange()
      preCaretRange.selectNodeContents(element)
      preCaretRange.setEnd(range.endContainer, range.endOffset)
      var caretPosition = preCaretRange.toString().length
      return caretPosition
    }, */
    onInput(e) {
      const caretPosition = getCaretCharacterOffsetWithin(this.textDiv)
      if (e.inputType === 'insertText') {
        this.keyActions = [
          ...this.keyActions,
          {
            type: e.inputType,
            index: caretPosition - 1,
            character: e.data,
          },
        ]
      }
      if (e.inputType === 'deleteContentBackward') {
        this.keyActions = [
          ...this.keyActions,
          {
            type: e.inputType,
            index: caretPosition,
            character: e.data,
          },
        ]
      }
    },
    getDeleteTextAction(startIndex, endIndex, { all }) {
      if (all) {
        return {
          type: ACTION_TYPE.SET_TEXT,
          shape_id: this.id,
          old_text: this.text,
          new_text: '',
        }
      }
      return {
        type: ACTION_TYPE.SET_TEXT,
        shape_id: this.id,
        old_text: this.text,
        new_text:
          this.text.slice(0, startIndex) +
          this.text.slice(endIndex === Infinity ? this.text.length : endIndex),
      }
    },
    getDeleteTagsAction(startIndex, endIndex, { all }) {
      if (all) {
        return {
          type: ACTION_TYPE.SET_TAGS,
          shape_id: this.id,
          old_tags: structuredClone(toRaw(this.getTags())),
          new_tags: [],
        }
      }
      const oldTags = structuredClone(toRaw(this.getTags()))
      const newTags = oldTags.filter(t => {
        const offset = t.rules.find(r => r.directive === 'offset').value
        const length = t.rules.find(r => r.directive === 'length').value
        if (
          (offset >= startIndex && offset <= endIndex) ||
          (offset + length >= startIndex && offset + length <= endIndex)
        ) {
          return false
        }
        return true
      })
      return {
        type: ACTION_TYPE.SET_TAGS,
        shape_id: this.id,
        old_tags: oldTags,
        new_tags: newTags,
      }
    },
    getCreateTagAction(
      type,
      textStyle,
      startIndex,
      endIndex,
      continued = false,
      updatePopup = true
    ) {
      const getRules = (length, offset, textStyle, continued) => {
        let rules = [
          { directive: 'offset', value: offset },
          {
            directive: 'length',
            value: length,
          },
        ]
        if (textStyle) {
          // check if textStyle already in line with offset and length inside
          rules.push({ directive: textStyle, value: true })
        }
        //textstyle can not have continued
        if (continued && !textStyle) {
          rules.push({
            directive: 'continued',
            value: true,
          })
        }
        return rules
      }
      const oldTags = this.getTags()

      const rules = getRules(
        endIndex - startIndex,
        startIndex,
        textStyle,
        continued
      )

      const newTag = {
        selector: type,
        rules: rules,
      }
      const newTags = [...oldTags, newTag]

      const action = {
        type: ACTION_TYPE.SET_TAGS,
        shape_id: this.id,
        old_tags: oldTags,
        new_tags: newTags,
      }

      if (updatePopup) {
        setTimeout(() => {
          const name = type.replace(/#level#.*/, '')
          const foundTag = this.getTagByTagParameters(
            name,
            startIndex,
            endIndex - startIndex
          )
          if (foundTag != null) {
            this.$emit('updatePopupParams', this.getTagEditConfig(foundTag))
            this.$nextTick(() => {
              this.$emit('updatePopupPosition')
            })
          } else {
            let getTagsJson
            try {
              getTagsJson = JSON.stringify(this.getTags())
            } catch (error) {}
            Sentry.captureMessage(
              `found tag is null. type: ${type}, name: ${name}, startIndex: ${startIndex}, endIndex: ${endIndex}, getTags: ${getTagsJson}`
            )
          }
        }, 100)
      }

      return action

      /*  let actions = []

      const createNewSelection = (node, offset) => {
        let range = new Range()
        range.setStart(node, offset)
        range.setEnd(node, offset)
        s.removeAllRanges()
        s.addRange(range)
      }

      if (startId !== endId) {
        createNewSelection(anchorNode, anchorOffset)

        const div = document.getElementById(startId)
        const offset = getCaretCharacterOffsetWithin(div)

        const rules = getRules(
          div.innerText.length - offset,
          offset,
          textStyle,
          true
        )

        const action = {
          type: ACTION_TYPE.ADD_TAG,
          shape_id: startId,
          tag: {
            selector: type,
            rules: rules,
          },
        }
        actions.push(action)

        createNewSelection(focusNode, focusOffset)

        const divEnd = document.getElementById(endId)
        const offsetEnd = getCaretCharacterOffsetWithin(divEnd)

        const rulesEnd = getRules(offsetEnd, 0, textStyle, true)

        const actionEnd = {
          type: ACTION_TYPE.ADD_TAG,
          shape_id: endId,
          tag: {
            selector: type,
            rules: rulesEnd,
          },
        }
        actions.push(actionEnd)

        const allLineIds = this.allLines.map(l => l.id)
        const indexStart = allLineIds.indexOf(startId)
        const indexEnd = allLineIds.indexOf(endId)
        const linesInBetween = allLineIds.filter(
          (_, index) => index > indexStart && index < indexEnd
        )
        linesInBetween.forEach(id => {
          const div = document.getElementById(id)
          const rules = getRules(div.innerHTML.length, 0, textStyle, true)
          const action = {
            type: ACTION_TYPE.ADD_TAG,
            shape_id: id,
            tag: {
              selector: type,
              rules: rules,
            },
          }
          actions.push(action)
        })
      }

      this.doAction({
        type: ACTION_TYPE.MULTI_ACTION,
        actions,
      }) */
    },
    getTextSubstring(initOffset, initLength) {
      let offset = Number(initOffset)
      let length = Number(initLength)
      return this.text.substring(offset, offset + length)
    },
    getTagByTagParameters(name, offset, length) {
      const tag = this.getTags().find(t => {
        if (t.selector !== name) {
          return false
        }
        if (
          !t.rules.find(
            r => r.directive === 'offset' && Number(r.value) === offset
          )
        ) {
          return false
        }
        if (
          !t.rules.find(
            r => r.directive === 'length' && Number(r.value) === length
          )
        ) {
          return false
        }
        return true
      })
      return tag
    },
    handleKeydown(e) {
      if (e.key === 'Enter' && !e.ctrlKey && !e.metaKey) {
        e.preventDefault()
        if (this.popUpCanOpen()) {
          this.openTagPopup()
        } else if (Settings.instance().get('danWriting')) {
          this.$emit('newLineOnEnter')
        } else {
          this.$emit('jumpCursorToNextLine')
        }
      }
      this.$emit('lineKeydown', e)
    },
  },
}
</script>
<style>
.diff-added {
  background-color: lightgreen;
}
.diff-removed {
  background-color: salmon;
  text-decoration: line-through;
}

.rounded-circle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
.selected {
  outline: 0px solid transparent;
}

.draggable {
  @apply bg-[#F3F3F3] my-[2px]  mr-2 cursor-pointer;
}
</style>
