var Singleton = (function () {
  const instances = []

  return {
    instance: function (editorName) {
      if (editorName == null) {
        editorName = 'editor1'
      }
      if (instances.find(i => i.editorName === editorName) == null) {
        instances.push(new IdHandler(editorName))
      }
      return instances.find(i => i.editorName === editorName)
    },
  }
})()

function IdHandler(editorName) {
  this.editorName = editorName
  this.idCounter = 1
  this.ids = []
}

IdHandler.prototype.reset = function () {
  this.idCounter = 1
  this.ids = []
  //log('reset')
}

IdHandler.prototype.get = function (id = '') {
  this.requestId(id)
}

IdHandler.prototype.extendId = function (id) {
  this.idCounter++
  const newId = `${id}_${this.idCounter}`
  if (this.ids.includes(newId)) {
    return this.extendId(id)
  }
  this.ids.push(newId)
  return newId
}

IdHandler.prototype.addId = function (id) {
  if (!this.ids.includes(id)) {
    this.ids.push(id)
  }
}

IdHandler.prototype.requestId = function (id) {
  if (!this.ids.includes(id)) {
    this.ids.push(id)
    return id
  }
  const newId = this.extendId(id)
  return newId
}

export { Singleton }
