import {
  lineMidpoint,
  polygonHull,
  pointInPolygon,
  lineIntersectsPolygon,
  pointRotate,
} from 'geometric'
import { isEqual } from 'lodash'
import { ACTION_TYPE, SELECTION_TYPE } from '../singletons/actionHandler.js'
import { Singleton as IdHandler } from '../singletons/IdHandler.js'
import { Singleton as ModalHandler } from '../singletons/ModalHandler.js'
import { segment, Point, Polygon } from '@flatten-js/core'

import {
  getRectCoords,
  getClosestLine,
  getSplitCoords,
  whatSideOfLine,
  getMergePartners,
} from '../geometryFunctions.js'
import Cell from './Cell.js'
import CellMatrix from './CellMatrix.js'
import Shape from './Shape.js'
import {
  projectPointer,
  calcLinesTable,
  distanceBetween,
  isLineInConvexRegion,
} from '../geometryFunctions.js'
import { getRotationAngle, getScaleFactor } from '../../globals.js'
import { lineIntersectsLine } from 'geometric'

function Table(
  canvas,
  blueprint,
  editorName,
  noUpdate,
  index,
  getActiveShapeIds
) {
  Shape.call(this, canvas, editorName)

  this.state = Shape.STATE.EDIT

  this.editorName = editorName

  this.prev = true
  this.hoverLine = null
  this.hoveredVertex = null
  this.mousePosition = {}
  this.index = index

  this.selected = false

  this.idHandler = IdHandler.instance(this.editorName)
  this.canvas = canvas

  this.markedCell = null
  this.background = {}
  this.vertices = []
  this.horizontalLines = []
  this.verticalLines = []
  this.lines = []

  this.getActiveShapeIds = getActiveShapeIds

  if (blueprint) {
    this.attributes = blueprint.attributes
    this.elements = blueprint.elements
    this.id = blueprint.attributes.id
    IdHandler.instance(this.editorName).addId(this.id)

    this.text = blueprint.elements.text

    const matrixCells = blueprint.elements.cells.reduce(
      (acc, cell) => [...acc, ...this.requestChop(cell)],
      []
    )
    let numberOfRows = matrixCells.reduce(
      (number, cell) =>
        cell.attributes.row >= number ? cell.attributes.row + 1 : number,
      0
    )
    let numberOfCols = matrixCells.reduce(
      (number, cell) =>
        cell.attributes.col >= number ? cell.attributes.col + 1 : number,
      0
    )
    this.cellMatrix = [...Array(numberOfRows)].map(e =>
      Array(numberOfCols).fill(null)
    )

    matrixCells.forEach(blueprint => {
      this.cellMatrix[blueprint.attributes.row][blueprint.attributes.col] =
        new Cell(
          this.canvas,
          blueprint,
          this.editorName,
          getActiveShapeIds,
          (...args) => this.pointMove(...args)
        )
    })

    /* this.cells = blueprint.elements.cells.map(
      (blueprint, i) =>
        new Cell(
          this.canvas,
          blueprint,
          this.editorName,
          noUpdate,
          i,
          getActiveShapeIds,
          (...args) => this.pointMove(...args)
        )
    ) */
    this.update()
  }
}

Table.prototype = Object.create(Shape.prototype)
Table.prototype.constructor = Table

Table.prototype.requestChop = function (cell) {
  const row = Number(cell.attributes.row)
  const col = Number(cell.attributes.col)
  const colSpan = Number(cell.attributes.colSpan) || 1
  const rowSpan = Number(cell.attributes.rowSpan) || 1
  const cornerPoints = cell.elements.cornerPoints
  const coords = cell.elements.coords
  const correctedCoords = this.getCorrectedCoords(
    coords,
    rowSpan,
    colSpan,
    cornerPoints
  )
  const tubes = this.getTubes(correctedCoords, colSpan)
  const cells = tubes.reduce((acc, tube, index) => {
    return [...acc, ...this.getCellsOfTube(tube, row + index, col)]
  }, [])
  const newMatrixCoords = cells[0].matrixCoords
  cells[0] = cloneObj(cell)
  cells[0].elements.coords = coords
  cells[0].matrixCoords = newMatrixCoords
  cells[0].attributes.row = row
  cells[0].attributes.col = col
  cells[0].attributes.rowSpan = rowSpan
  cells[0].attributes.colSpan = colSpan
  return cells
}

Table.prototype.getCellsAndIndices = function (point) {
  return this.allCells()
    .map(c => {
      const index = c.coords.findIndex(p => p.x === point.x && p.y === point.y)
      if (index >= 0) return { cell: c, index }
    })
    .filter(Boolean)
}

Table.prototype.getCellParent = function (row, col) {
  return this.allCells().find(p => {
    return (
      row >= p.row &&
      row < p.row + p.rowSpan &&
      col >= p.col &&
      col < p.col + p.colSpan
    )
  })
}

Table.prototype.selectCellIds = function (ids) {
  this.actionHandler.select({
    type: SELECTION_TYPE.CLEAR,
  })
  this.actionHandler.select({
    type: SELECTION_TYPE.ADD,
    ids: [this.id, ...ids],
  })
}

Table.prototype.getNeighborCellsWithPointIndex = function (
  row,
  rowSpan,
  col,
  colSpan,
  point
) {
  const neighbors = []

  for (let r = row - 1; r < row + rowSpan + 1; r++) {
    for (let c = col - 1; c < col + colSpan + 1; c++) {
      if (
        !(
          r < 0 ||
          c < 0 ||
          r >= this.cellMatrix.length ||
          c >= this.cellMatrix[0].length
        )
      ) {
        let cell = this.cellMatrix[r][c]
        if (cell.isSpanChild()) {
          cell = this.getCellParent(r, c)
        }
        const index = cell.indexOfPoint(point)
        if (index >= 0 && neighbors.every(n => n.id !== cell.id)) {
          neighbors.push({
            id: cell.id,
            index: cell.indexOfPoint(point),
          })
        }
      }
    }
  }
  return neighbors
}

Table.prototype.pointMove = function (
  row,
  rowSpan,
  col,
  colSpan,
  point,
  vector
) {
  const cellsAndIndices = this.getNeighborCellsWithPointIndex(
    row,
    rowSpan,
    col,
    colSpan,
    point
  )
  const action = {
    type: ACTION_TYPE.MOVE_POINT_TABLE,
    shape_id: this.id,
    cell_ids: cellsAndIndices.map(({ id }) => id),
    point_indices: cellsAndIndices.map(({ index }) => index),
    vector,
  }
  this.actionHandler.newAction(action)
}

Table.prototype.getBlueprint = function (myIndex) {
  /*   if (!this.attributes || !this.elements) {
    this.elements = {
      coords: {},
      textLines: [],
      text: '',
    }
  } */
  //this.attributes.id = keepId ? this.id : `t${myIndex + 1}`
  this.attributes.id = this.id
  //this.setReadingOrder(myIndex)
  this.elements.coords = this.getTableCoords()
  this.elements.cells = this.allCells()
    .filter(c => !c.isSpanChild())
    .map((cell, index) => cell.getBlueprint(index))
  this.elements.text = this.text

  return { type: 'table', attributes: this.attributes, elements: this.elements }
}

Table.prototype.getType = function () {
  return Shape.TYPE.TABLE
}

Table.prototype.getLayout = function () {
  return {
    type: 'Table',
    id: this.id,
    children: this.allCells()
      .filter(c => !c.isSpanChild())
      .map(c => c.getLayout()),
    structureType: this.getStructureType(),
    custom: this.attributes.custom,
  }
}

Table.prototype.handleColSplit = function (action) {
  const col = this.getColIndex(action.new_cells_blueprints)
  this.cellMatrix.createColumn(col)
  this.replaceCells(action)
  this.update()
}
Table.prototype.handleRowSplit = function (action) {
  const row = this.getRowIndex(action.new_cells_blueprints)
  this.cellMatrix.createRow(row)
  this.replaceCells(action)
  this.update()
}

Table.prototype.handleColMerge = function (action) {
  const col = this.getColIndex(action.old_cells_blueprints)
  this.replaceCells(action)
  this.cellMatrix.deleteColumn(col)
  this.update()
}

Table.prototype.handleRowMerge = function (action) {
  const row = this.getRowIndex(action.old_cells_blueprints)
  this.replaceCells(action)
  this.cellMatrix.deleteRow(row)
  //this.cellMatrix.log()
  this.update()
}

Table.prototype.getColIndex = function (blueprints) {
  return blueprints.reduce((max, b) => {
    if (b.attributes.col > max) {
      return b.attributes.col
    }
    return max
  }, 0)
}

Table.prototype.getRowIndex = function (blueprints) {
  return blueprints.reduce((max, b) => {
    if (b.attributes.row > max) {
      return b.attributes.row
    }
    return max
  }, 0)
}

Table.prototype.replaceCells = function (action) {
  action.old_cells_blueprints.forEach(b =>
    this.cellMatrix.removeCellById(b.attributes.id)
  )
  action.new_cells_blueprints.forEach(b => {
    const newCell = new Cell(
      this.canvas,
      b,
      this.editorName,
      this.vertices,
      p => this.creatVertex(p.x, p.y)
    )
    this.cellMatrix.setCell(newCell, newCell.row, newCell.col)
  })
}

Table.prototype.getWidth = function () {
  return this.cellMatrix[0].length
}

Table.prototype.getHeight = function () {
  return this.cellMatrix.length
}

Table.prototype.getTableCoords = function () {
  const maxRowIndex = this.getHeight() - 1
  const maxColIndex = this.getWidth() - 1
  const topLeft = this.cellMatrix[0][0].matrixCoords[0]
  const topRight = this.cellMatrix[0][maxColIndex].matrixCoords[3]
  const bottomRight = this.cellMatrix[maxRowIndex][maxColIndex].matrixCoords[2]

  const bottomLeft = this.cellMatrix[maxRowIndex][0].matrixCoords[1]
  return [topLeft, topRight, bottomRight, bottomLeft]
}

Table.prototype.fixCells = function (invalidCells, allCells) {}

Table.prototype.validateCells = function (cells) {
  const positions = []
  const invalidCells = []
  const missingPositions = []
  const maxRow = cells.reduce((acc, c) => (c.row > acc ? c.row : acc), 0)
  const maxCol = cells.reduce((acc, c) => (c.col > acc ? c.col : acc), 0)
  const rowSteps = Array.from({ length: maxRow + 1 }, (_, i) => i)
  const colSteps = Array.from({ length: maxCol + 1 }, (_, i) => i)
  const expectedPositions = rowSteps.reduce(
    (acc, rStep) => [...acc, ...colSteps.map(cStep => [rStep, cStep])],
    []
  )
  const allCellsValid = cells.reduce((acc, cell) => {
    const colSpan = cell.colSpan
    const rowSpan = cell.rowSpan
    const rowSteps = Array.from({ length: rowSpan }, (_, i) => i)
    const colSteps = Array.from({ length: colSpan }, (_, i) => i)
    rowSteps.map(rStep => {
      colSteps.map(cStep => {
        positions.push([cell.row + rStep, cell.col + cStep])
      })
    })
    const coords = cell.elements.coords
    const cornerPoints = cell.elements.cornerPoints
    const expectedCoordsLength = rowSpan * 2 + colSpan * 2
    const validCornerpoints =
      JSON.stringify([0, rowSpan, rowSpan + colSpan, rowSpan * 2 + colSpan]) ===
      JSON.stringify(cornerPoints)
    const validCoordsLength = expectedCoordsLength === coords.length
    if (validCoordsLength && validCornerpoints) return acc
    if (expectedCoordsLength === 4 && coords.length > 4) return acc
    invalidCells.push(cell)
    return false
  }, true)
  const validPositions = expectedPositions.every(ep => {
    if (positions.some(p => isEqual(ep, p))) return true
    missingPositions.push(`row: ${ep[0]} col: ${ep[1]}`)
    return false
  })
  if (!allCellsValid) {
    ModalHandler.instance().open(
      'Unfortunately, this table can not be displayed',
      'The following cells contain invalid coordinates: ' +
        invalidCells.map(c => c.id).join(', ')
    )
  }
  if (!validPositions) {
    ModalHandler.instance().open(
      'Unfortunately, this table can not be displayed',
      'The following cells are missing: ' + missingPositions.join(', ')
    )
  }
  return allCellsValid && validPositions
}

Table.prototype.getCorrectedCoords = function (
  coords,
  rowSpan,
  colSpan,
  cornerPoints
) {
  const notSpanning = rowSpan * 2 + colSpan * 2 === 4
  if (notSpanning && coords.length > 4) {
    return coords.filter((_, index) => cornerPoints.includes(index))
  }
  return coords
}

Table.prototype.getTubes = function (coords, colSpan) {
  let singleTube = colSpan * 2 + 2 === coords.length
  if (singleTube) return [coords]

  let coordsLength = coords.length

  let [leftFirst, leftSecond] = coords
  let lowerRest = coords.filter(
    (_, index) => index > 1 && index <= coordsLength - colSpan - 2
  )
  let [rightFirst, rightSecond] = coords.filter(
    (_, index) =>
      index > coordsLength - colSpan - 2 && index <= coordsLength - colSpan
  )
  let upperMidCoords = [
    ...coords.filter((_, index) => index > coordsLength - colSpan),
  ]

  let height = leftSecond.y - leftFirst.y
  let lowerMidCoords = upperMidCoords
    .map(({ x, y }) => ({ x: x, y: y + height }))
    .reverse()

  let tube = [
    leftFirst,
    leftSecond,
    ...lowerMidCoords,
    ...[rightFirst, rightSecond],
    ...upperMidCoords,
  ]
  let nextCoords = [
    leftSecond,
    ...lowerRest,
    rightFirst,
    ...[...lowerMidCoords].reverse(),
  ]
  return [tube, ...this.getTubes(nextCoords, colSpan)]
}

Table.prototype.getCellsOfTube = function (tube, row, col) {
  let newCells = []

  const popLast = coords => {
    let startIndex = coords.length / 2 - 1
    const newPlaceholderCell = {
      attributes: {
        row: row,
        col: col + startIndex - 1,
        rowSpan: 1,
        colSpan: 1,
        id: IdHandler.instance(this.editorName).requestId('cell'),
      },
      elements: {
        coords: [],
        textLines: [],
        cornerPoints: [0, 1, 2, 3],
      },
      matrixCoords: [
        coords[(startIndex + 3) % coords.length],
        coords[startIndex],
        coords[startIndex + 1],
        coords[startIndex + 2],
      ],
    }
    newCells = [newPlaceholderCell, ...newCells]
    let copy = [...coords]
    copy.splice(startIndex + 1, 2)
    if (copy.length > 2) {
      popLast(copy)
    }
  }
  popLast(tube)
  return newCells
}

/* Table.prototype.init = function (coords) {
  this.cellMatrix = new CellMatrix(1, 1, this.editorName)
  let cell = this.createCell()
  let vertices = coords.map(p => this.creatVertex(p.x, p.y))
  cell.setVertices(vertices)
  this.cellMatrix.setCell(cell, 0, 0)
  //this.cellMatrix.log()
  this.update()
} */

Table.prototype.updateVertices = function () {
  const circleSize = this.myFabric.getCircleSize()
  const vertexColor = this.myFabric.getTableColorSecondary()
  this.vertices.forEach(vertex => {
    if (vertex.radius != circleSize) {
      vertex.set({
        radius: circleSize,
        defaultRadius: circleSize,
      })
    }
    if (vertex.stroke != vertexColor || vertex.selectedColor != vertexColor) {
      vertex.set({
        stroke: vertexColor,
        selectedColor: vertexColor,
      })
      if (vertex === this.activeVertex) {
        vertex.set('fill', vertexColor)
      }
    }
  })
}

Table.prototype.update = function () {
  this.selected = this.getActiveShapeIds?.().includes(this.id)
  /*
  if (!this.isVisible()) {
    this.removeFromCanvas()
    return
  }
  this.updateVertices()
  this.updateStructureColor()
  this.updateStructureText()
  this.updateReadingOrderText()
  this.allCells().map(cell => {
    cell.tableSelected = this.selected
  })
  this.updateMatrix()
  this.updateLines()
  this.updateCells()
  if (!this.selected) {
    this.unSelect()
  }*/

  this.updateReadingOrderText(
    this.isVisible()
      ? [
          {
            x: this.cellMatrix[0][0].matrixCoords[0].x,
            y: this.cellMatrix[0][0].matrixCoords[0].y,
          },
        ]
      : null
  )
  if (this.selected) {
    this.updateLines()
  }
  if (this.state === Shape.STATE.SELECTION_ONLY) {
    this.canvas.remove(...this.vertices)
  }
  if (this.state === Shape.STATE.SEARCH_RESULTS) {
    this.removeFromCanvas()
  }
}

Table.prototype.allCells = function () {
  return this.cellMatrix.flat()
}

Table.prototype.updateMatrix = function () {
  this.cellMatrix.updateCellProperties()
}

// Table.prototype.removeLonelyVertices = function () {
//   let lonelyVertices = this.allVertices().filter((v) => {
//     v.set({ radius: v.smallSize, evented: true })
//     let cells = this.cellMatrix.getCellsOfVertex(v.id)
//     return (
//       cells.every((c) => c.isSpanParent() || c.isSpanChild()) &&
//       cells.length === 4
//     )
//   })
//   lonelyVertices.map((l) => {
//     l.set({ radius: 0, evented: false })
//   })
//   this.canvas.requestRenderAll()
// }

Table.prototype.getNextPointRight = function (
  currentPoint,
  currentCell,
  points
) {
  const nextPoint = currentCell.getNextPointRight(currentPoint)
  if (nextPoint) {
    points.push({
      cell: currentCell,
      point: nextPoint,
    })
    return this.getNextPointRight(nextPoint, currentCell, points)
  } else {
    const nextCell = this.cells.find(
      c => c.hasPointLeft(currentPoint) && !points.some(p => p.cell === c)
    )
    if (nextCell) {
      return this.getNextPointRight(currentPoint, nextCell, points)
    }
  }
  return points
}

Table.prototype.getNextPointDown = function (
  currentPoint,
  currentCell,
  points
) {
  const nextPoint = currentCell.getNextPointDown(currentPoint)
  if (nextPoint) {
    points.push({
      cell: currentCell,
      point: nextPoint,
    })
    return this.getNextPointDown(nextPoint, currentCell, points)
  } else {
    const nextCell = this.cells.find(
      c => c.hasPointTop(currentPoint) && !points.some(p => p.cell === c)
    )
    if (nextCell) {
      return this.getNextPointDown(currentPoint, nextCell, points)
    }
  }
  return points
}

Table.prototype.getHorizontalPoints = function (startPoint, listOfPointLists) {
  let startCell = this.cells.find(
    c => c.hasPoint(startPoint) && c.indexOfPoint(startPoint) === 0
  )
  if (!startCell) {
    startCell = this.cells.find(
      c => c.hasPoint(startPoint) && c.indexOfPoint(startPoint) === 1
    )
  }

  const newPointObjects = this.getNextPointRight(startPoint, startCell, [
    { cell: startCell, point: startPoint },
  ])
  listOfPointLists.push(newPointObjects.map(({ point }) => point))
  const nextPoint = startCell.getNextPointDown(startPoint)
  if (nextPoint) {
    return this.getHorizontalPoints(nextPoint, listOfPointLists)
  }
  return listOfPointLists
}

Table.prototype.getVerticalPoints = function (startPoint, listOfPointLists) {
  let startCell = this.cells.find(
    c => c.hasPoint(startPoint) && c.indexOfPoint(startPoint) === 0
  )
  if (!startCell) {
    startCell = this.cells.find(
      c => c.hasPoint(startPoint) && c.indexOfPoint(startPoint) === 3
    )
  }

  const newPointObjects = this.getNextPointDown(startPoint, startCell, [
    { cell: startCell, point: startPoint },
  ])
  listOfPointLists.push(newPointObjects.map(({ point }) => point))
  const nextPoint = startCell.getNextPointRight(startPoint)
  if (nextPoint) {
    return this.getVerticalPoints(nextPoint, listOfPointLists)
  }
  return listOfPointLists
}

Table.prototype.getTransposedMatrix = function () {
  return this.cellMatrix[0].map((_, colIndex) =>
    this.cellMatrix.map(row => row[colIndex])
  )
}

Table.prototype.getVerticalCoords = function () {
  let takenCoords = []

  let list = []
  this.getTransposedMatrix().map((col, index, cols) => {
    list.push(
      col.reduce((list, cell) => {
        let leftVertices = cell
          .getLeftCoords()
          .filter(v => !takenCoords.find(c => c.x === v.x && c.y === v.y))
        takenCoords.push(...leftVertices)
        return [...list, ...leftVertices]
      }, [])
    )

    if (index >= cols.length - 1) {
      list.push(
        col.reduce((list, cell) => {
          let rightVertices = cell
            .getRightCoords()
            .filter(v => !takenCoords.find(c => c.x === v.x && c.y === v.y))
          takenCoords.push(...rightVertices)
          return [...list, ...rightVertices]
        }, [])
      )
    }
  })
  return list
}

Table.prototype.getHorizontalCoords = function () {
  let takenCoords = []

  let list = []
  this.cellMatrix.map((row, index, rows) => {
    list.push(
      row.reduce((list, cell) => {
        let topVertices = cell
          .getTopCoords()
          .filter(v => !takenCoords.find(c => c.x === v.x && c.y === v.y))
        takenCoords.push(...topVertices)
        return [...list, ...topVertices]
      }, [])
    )
    if (index >= rows.length - 1) {
      list.push(
        row.reduce((list, cell) => {
          let bottomVertices = cell
            .getBottomCoords()
            .filter(v => !takenCoords.find(c => c.x === v.x && c.y === v.y))
          takenCoords.push(...bottomVertices)
          return [...list, ...bottomVertices]
        }, [])
      )
    }
  })
  return list
}

Table.prototype.updateLines = function () {
  const horizontalPoints = this.getHorizontalCoords()
  const horizontalLines = horizontalPoints.map((points, index) => {
    return {
      id: this.idHandler.requestId('table_line'),
      polygons: calcLinesTable(points)
        .map(({ polygon }) => polygon)
        .slice(0, -1),
      points,
      isVertical: false,
    }
  })
  const verticalPoints = this.getVerticalCoords()
  const verticalLines = verticalPoints.map((points, index) => {
    return {
      id: this.idHandler.requestId('table_line'),
      polygons: calcLinesTable(points)
        .map(({ polygon }) => polygon)
        .slice(0, -1),
      points,
      isVertical: true,
    }
  })
  this.lines = [...horizontalLines, ...verticalLines]
}

Table.prototype.updateCells = function () {
  //why?
  // this.cellMatrix.allCells().map((c) => c.removeFromCanvas())

  this.cellMatrix.allCells().map(c => c.update())
  /*  this.cellMatrix
      .allCells()
      .map((c, index) => (index % 2 === 0 ? c.update() : null)) */
}

// Table.prototype.updateHorizontalLines = function () {
//   let self = this
//   this.canvas.remove(...this.horizontalLines)
//   this.horizontalLines = []
//   this.cellMatrix.getHorizontalCircles().map((circleLine) => {
//     let newLine = this.myFabric.getHorizontalTableLine(circleLine)

//     newLine.hulls = calcLines(circleLine)
//       .map(({ polygon }) => polygon)
//       .slice(0, -1)
//     newLine.circles = [...circleLine]
//     newLine.relations = this.getRelations(newLine)
//     newLine.on('moving', function () {
//       self.horizontalMovement(this)
//     })
//     newLine.on('moved', () => {
//       this.updateCircles(newLine)
//       this.updateCells()
//       this.updateLines()
//     })
//     this.horizontalLines.push(newLine)
//   })
//   this.canvas.add(...this.horizontalLines)
//   this.horizontalLines.map((line) => this.canvas.sendToBack(line))
// }

// Table.prototype.updateVerticalLines = function () {
//   let self = this
//   this.canvas.remove(...this.verticalLines)
//   this.verticalLines = []
//   this.cellMatrix.getVerticalCircles().map((circleLine) => {
//     let newLine = this.myFabric.getVerticalTableLine(circleLine)

//     newLine.hulls = calcLines(circleLine)
//       .map(({ polygon }) => polygon)
//       .slice(0, -1)
//     newLine.circles = [...circleLine]
//     newLine.relations = this.getRelations(newLine)

//     newLine.on('moving', function () {
//       self.verticalMovement(this)
//     })
//     newLine.on('moved', () => {
//       this.updateCircles(newLine)
//       this.updateCells()
//       this.updateLines()
//       this.allVertices().map((v) => this.canvas.bringToFront(v))
//     })
//     this.verticalLines.push(newLine)
//   })
//   this.canvas.add(...this.verticalLines)
//   this.verticalLines.map((line) => this.canvas.sendToBack(line))
// }

Table.prototype.updateCircles = function (line) {
  line.relations.map((relation, index) => {
    let lineTransform = fabric.util.multiplyTransformMatrices(
      line.calcTransformMatrix(),
      relation
    )
    let decomposed = fabric.util.qrDecompose(lineTransform)
    let currentCircle = line.vertices[index]
    currentCircle.set({
      left: decomposed.translateX,
      top: decomposed.translateY,
    })
  })
}

Table.prototype.getPositionOfLine = function (id) {
  //
  // let foundIndex = this.verticalLines.findIndex((line) => line.id === id)
  // if (foundIndex >= 0) {
  //   return {
  //     cols: [foundIndex - 1, foundIndex].filter((index) =>
  //       this.cellMatrix.colExists(index)
  //     ),
  //   }
  // }
  // foundIndex = this.horizontalLines.findIndex((line) => line.id === id)
  // if (foundIndex >= 0) {
  //   return {
  //     rows: [foundIndex - 1, foundIndex].filter((index) =>
  //       this.cellMatrix.rowExists(index)
  //     ),
  //   }
  // }
}

Table.prototype.getRelations = function (line, vertices) {
  return vertices.map(v => {
    let vertexMatrix = v.calcTransformMatrix()
    let inverseLineMatrix = fabric.util.invertTransform(
      line.calcTransformMatrix()
    )
    return fabric.util.multiplyTransformMatrices(
      inverseLineMatrix,
      vertexMatrix
    )
  })
}

// Table.prototype.groupSelect = function () {
//   this.removeFromCanvas()
//   this.group = this.getGroup()

//   this.canvas.add(this.group)
//   this.canvas.setActiveObject(this.group)
// }

// Table.prototype.largeGroupSelect = function () {
//   this.removeFromCanvas()
//   this.removeChildsFromCanvas()
//   this.group = this.getLargeGroup()
//   this.groupPosition = { x: this.group.left, y: this.group.top }

//   this.canvas.add(this.group)
//   this.canvas.setActiveObject(this.group)
// }

/* Table.prototype.getGroup = function () {
  let frames = this.allCells()
    .map(cell => cell.frame && this.myFabric.clone(cell.frame))
    .filter(Boolean)
  let backgrounds = this.getCellBackgrounds()
    .map(background => background && this.myFabric.clone(background))
    .filter(b => b != null)

  let members = [...frames, ...backgrounds]

  let group = new fabric.Group(members, {
    id: this.id,
    hasControls: true,
    cornerStyle: 'circle',
    padding: 0,
    cornerSize: 12,
    cornerColor: 'transparent',
    transparentCorners: true,
    cornerStrokeColor: 'yellow',
    strokeUniform: true,
  }).setControlsVisibility({
    mtr: false,
  })

  let relations = this.allCells()
    .map(c => c.coords)
    .flat()
    .map(circle => {
      let circleMatrix = circle.calcTransformMatrix()
      let inverseGroupMatrix = fabric.util.invertTransform(
        group.calcTransformMatrix()
      )
      return fabric.util.multiplyTransformMatrices(
        inverseGroupMatrix,
        circleMatrix
      )
    })
  group.relations = relations
  return group
} */

/* Table.prototype.getLargeGroup = function () {
  let lines = this.allLines().map(line => this.myFabric.clone(line))
  let backgrounds = this.getCellBackgrounds().map(background =>
    this.myFabric.clone(background)
  )

  const baselines = this.getBaselines().map(line => line.getGroup())

  let members = [...lines, ...backgrounds, ...baselines]

  let group = new fabric.Group(members, {
    id: this.id,
    hasControls: true,
    cornerStyle: 'circle',
    padding: 0,
    cornerSize: 12,
    cornerColor: 'transparent',
    transparentCorners: true,
    cornerStrokeColor: 'yellow',
    strokeUniform: true,
  }).setControlsVisibility({
    mtr: false,
  })
  return group
} */

// Table.prototype.unGroupSelect = function () {
//   const oldCoords = this.allVertices().map(({ left, top }) => ({
//     left,
//     top,
//   }))
//   const newCoords = this.group.relations.map((relation) => {
//     const circleTransform = fabric.util.multiplyTransformMatrices(
//       this.group.calcTransformMatrix(),
//       relation
//     )
//     const decomposed = fabric.util.qrDecompose(circleTransform)
//     return { left: decomposed.translateX, top: decomposed.translateY }
//   })

//   if (!isEqual(oldCoords, newCoords)) {
//     this.actionHandler.addAction({
//       type: ACTION_TYPE.MOVE_SHAPE,
//       shape_id: this.id,
//       old_coords: oldCoords,
//       new_coords: newCoords,
//     })
//   }
//   this.canvas.remove(this.group)
//   this.canvas.add(...this.allVertices())
//   this.moveAllPoints(newCoords)
//   this.update()
// }

// Table.prototype.unLargeGroupSelect = function () {
//   const vector = {
//     x: this.group.left - this.groupPosition.x,
//     y: this.group.top - this.groupPosition.y,
//   }
//   const action = {
//     type: ACTION_TYPE.MULTI_ACTION,
//     actions: this.getBaselines()
//       .map((line) => ({
//         type: ACTION_TYPE.MOVE_SHAPE_WITH_VECTOR,
//         shape_id: line.id,
//         vector,
//       }))
//       .concat({
//         type: ACTION_TYPE.MOVE_SHAPE_WITH_VECTOR,
//         shape_id: this.id,
//         vector,
//       }),
//   }

//   this.actionHandler.doAction(action)
//   if (vector.x != 0 && vector.y != 0) {
//     this.actionHandler.addAction(action)
//   }

//   this.canvas.remove(this.group)
//   this.canvas.add(...this.allVertices())
//   this.update()
// }

Table.prototype.getCellBackgrounds = function () {
  return this.cellMatrix.allCells().map(cell => cell.background)
}

// Table.prototype.moveAllPoints = function (coords) {
//   this.allVertices().map((vertex, index) => {
//     vertex.set({ left: coords[index].left, top: coords[index].top })
//     vertex.setCoords()
//   })
//   this.update()
// }

Table.prototype.moveAllPointsWithVector = function ({ x, y }) {
  this.allVertices().map(vertex => {
    vertex.set({ left: vertex.left + x, top: vertex.top + y })
    vertex.setCoords()
  })
  this.update()
}

Table.prototype.removeFromCanvas = function () {
  this.canvas.remove(this.structureText)
  this.canvas.remove(this.structureColorFrame)
  this.canvas.remove(this.readingOrderText)
  this.canvas.remove(this.previewRect)
  this.canvas.remove(this.hoverLine)
}

Table.prototype.removeChildsFromCanvas = function () {
  this.allCells().map(c => c.baselines.map(l => l.removeFromCanvas()))
}

Table.prototype.select = function () {
  this.selected = true
  this.update()
}

Table.prototype.unSelect = function () {
  this.selected = false
  this.selectNewCell(null)
  this.canvas.remove(this.previewRect)
  this.canvas.remove(this.hoverLine)
  this.hoverLine = null
  this.allCells().map(cell => {
    cell.showFrameOnly()
    cell.tableSelected = false
  })
  this.updateStructureText()
}

Table.prototype.getFrame = function () {
  let left = this.verticalLines[0]
  let bottom = this.horizontalLines[this.horizontalLines.length - 1]
  let right = this.verticalLines[this.verticalLines.length - 1]
  let top = this.horizontalLines[0]
  return [left, bottom, right, top]
}
Table.prototype.allLines = function () {
  return [...this.horizontalLines, ...this.verticalLines]
}

Table.prototype.getArea = function () {
  let hull = polygonHull(this.allVertices().map(c => [c.left, c.top]))
  return hull
}

Table.prototype.getBaselines = function () {
  let result = this.allCells().reduce(
    (acc, cell) => (cell ? [...acc, ...cell.baselines] : acc),
    []
  )
  return result
}

Table.prototype.dissolve = function (cell) {
  const allPositions = []
  for (let row = cell.row; row <= cell.row + cell.rowSpan - 1; row++) {
    for (let col = cell.col; col <= cell.col + cell.colSpan - 1; col++) {
      allPositions.push({ row, col })
    }
  }
  const newBlueprints = []
  const oldBlueprint = cell.getBlueprint()
  this.allCells().forEach(c => {
    if (allPositions.some(p => p.row === c.row && p.col === c.col)) {
      const textLineBlueprints = []
      cell.baselines.forEach(l => {
        if (
          isLineInConvexRegion(
            l.getAreaForced().map(([x, y]) => ({ x, y })),
            c.matrixCoords.map(({ x, y }) => [x, y]).reverse(),
            50
          )
        ) {
          textLineBlueprints.push(l.getBlueprint())
        }
      })
      newBlueprints.push({
        attributes: {
          row: c.row,
          col: c.col,
          rowSpan: 1,
          colSpan: 1,
          id: IdHandler.instance(this.editorName).requestId('c'),
        },
        elements: {
          coords: c.matrixCoords,
          textLines: textLineBlueprints,
          cornerPoints: [0, 1, 2, 3],
        },
      })
    }
  })
  return { newBlueprints, oldBlueprint }
}

Table.prototype.dissolveCells = function (position) {
  let cell = this.getParentFromPointer(position)
  const { newBlueprints, oldBlueprint } = this.dissolve(cell)

  this.actionHandler.newAction({
    type: ACTION_TYPE.UPDATE_CELLS,
    shape_id: this.id,
    old_cells_blueprints: [oldBlueprint],
    new_cells_blueprints: newBlueprints,
  })
}

Table.prototype.getMergedCellCoords = function (cells) {
  const allPositions = []

  cells.forEach(cell => {
    for (let row = cell.row; row <= cell.row + cell.rowSpan - 1; row++) {
      for (let col = cell.col; col <= cell.col + cell.colSpan - 1; col++) {
        allPositions.push({ row, col })
      }
    }
  })

  const minRow = cells.reduce((min, c) => (c.row < min ? c.row : min), Infinity)
  const minCol = cells.reduce((min, c) => (c.col < min ? c.col : min), Infinity)
  const maxRow = cells.reduce(
    (max, c) => (c.row + c.rowSpan - 1 > max ? c.row + c.rowSpan - 1 : max),
    -1
  )
  const maxCol = cells.reduce(
    (max, c) => (c.col + c.colSpan - 1 > max ? c.col + c.colSpan - 1 : max),
    -1
  )

  let isRectangular = true
  for (let row = minRow; row <= maxRow; row++) {
    for (let col = minCol; col <= maxCol; col++) {
      if (!allPositions.find(p => p.row === row && p.col === col)) {
        isRectangular = false
      }
    }
  }
  if (!isRectangular) return
  const newCoords = []
  for (let row = minRow; row <= maxRow; row++) {
    const leftCoords = this.cellMatrix[row][minCol].getLeftCoords()
    newCoords.push(leftCoords[0])
  }
  for (let col = minCol; col <= maxCol; col++) {
    const bottomCoords = this.cellMatrix[maxRow][col].getBottomCoords()
    newCoords.push(bottomCoords[0])
  }
  for (let row = maxRow; row >= minRow; row--) {
    const rightCoords = this.cellMatrix[row][maxCol].getRightCoords()
    newCoords.push(rightCoords[1])
  }
  for (let col = maxCol; col >= minCol; col--) {
    const topCoords = this.cellMatrix[minRow][col].getTopCoords()
    newCoords.push(topCoords[1])
  }
  return { newCoords, minRow, minCol, maxRow, maxCol }
}

Table.prototype.merge = function (cells) {
  const mergeResult = this.getMergedCellCoords(cells)
  if (!mergeResult) return
  const { newCoords, minRow, minCol, maxRow, maxCol } = mergeResult

  const foundTypes = []
  cells.forEach(c => {
    const type = c.getStructureType()
    if (type != null && !foundTypes.includes(type)) {
      foundTypes.push(type)
    }
  })

  const rowSpan = maxRow - minRow + 1
  const colSpan = maxCol - minCol + 1
  const action = {
    type: ACTION_TYPE.UPDATE_CELLS,
    shape_id: this.id,
    old_cells_blueprints: cells.map(c => c.getBlueprint()),
    new_cells_blueprints: [
      {
        attributes: {
          row: minRow,
          col: minCol,
          rowSpan: rowSpan,
          colSpan: colSpan,
          id: IdHandler.instance(this.editorName).requestId('c'),
          ...(foundTypes.length === 1 && {
            custom: { structureType: foundTypes[0] },
          }),
        },
        elements: {
          coords: newCoords,
          textLines: cells
            .map(c => c.baselines)
            .flat()
            .map(b => b.getBlueprint()),
          cornerPoints: this.calcCornerPoints(rowSpan, colSpan),
        },
      },
    ],
  }
  this.actionHandler.newAction(action)

  /* const isRectangular = cells.every(c => )
  const minRow = cells.reduce((min, c) => (c.row < min ? c.row : min), Infinity)
  const minCol = cells.reduce((min, c) => (c.col < min ? c.col : min), Infinity)
  const maxRow = cells.reduce(
    (max, c) => (c.row + c.rowSpan - 1 > max ? c.row + c.rowSpan - 1 : max),
    -1
  )
  const maxCol = cells.reduce(
    (max, c) => (c.col + c.colSpan - 1 > max ? c.col + c.colSpan - 1 : max),
    -1
  )
  for (let row = minRow; row <= maxRow; row++) {
    for (let col = minCol; col <= maxCol; col++) {
      allPositions.push({ row, col })
    }
  }
  const oldBlueprints = []

  this.allCells().map(cell => {
    if (allPositions.some(p => p.row === cell.row && p.col === cell.col)) {
      oldBlueprints.push(cell.getBlueprint())
    }
  })
 */
}

Table.prototype.removeForbidden = function (lineIndex, isVertical) {
  if (lineIndex <= 0) return true
  let lastIndex = isVertical
    ? this.cellMatrix[0].length
    : this.cellMatrix.length
  return lastIndex <= lineIndex
}

Table.prototype.removeLine = function (id) {
  this.canvas.remove(this.hoverLine)
  this.hoverLine = null
  let { index, isVertical, points } = this.lines.find(line => line.id === id)
  if (this.removeForbidden(index, isVertical)) return
  let pairs = []
  let cancle = false

  this.allCells().forEach(cell => {
    const foundPoints = []
    cell.coords.forEach(c => {
      if (points.find(p => p.x === c.x && p.y === c.y)) {
        foundPoints.push(c)
      }
    })
    if (foundPoints.length > 0) {
      const foundPair = pairs.find(p =>
        p.foundPoints.every(fp =>
          foundPoints.find(p => p.x === fp.x && p.y === fp.y)
        )
      )
      if (foundPair) {
        foundPair.cell2 = cell
      } else {
        pairs.push({
          cell1: cell,
          foundPoints: foundPoints,
        })
      }
    }
  })

  const oldCellsBlueprints = []
  const newCellsBlueprints = []

  pairs.forEach(pair => {
    const { cell1, cell2 } = pair
    if (cell1 == null || cell2 == null) {
      cancle = true
      return
    }
    const mergeResult = this.getMergedCellCoords([cell1, cell2])
    if (mergeResult == null) {
      cancle = true
      return
    }
    let { newCoords } = mergeResult
    // minCol etc can not be used because of the filtering in the next line
    newCoords = newCoords.filter(
      c => !points.find(p => p.x === c.x && p.y === c.y)
    )

    if (newCoords == null) {
      cancle = true
      return
    }

    const newColSpan = this.calcSpan(newCoords.length, cell1.rowSpan)
    const newRowSpan = this.calcSpan(newCoords.length, cell1.colSpan)
    const newCell = {
      attributes: {
        row: cell1.row,
        col: cell1.col,
        rowSpan: isVertical ? cell1.rowSpan : newRowSpan,
        colSpan: isVertical ? newColSpan : cell1.colSpan,
        id: IdHandler.instance(this.editorName).requestId('c'),
      },
      elements: {
        coords: newCoords,
        textLines: [
          ...cell1.baselines.map(b => b.getBlueprint()),
          ...cell2.baselines.map(b => b.getBlueprint()),
        ],
        cornerPoints: this.calcCornerPoints(cell1.rowSpan, newColSpan),
      },
    }

    oldCellsBlueprints.push(
      pair.cell1.getBlueprint(),
      pair.cell2.getBlueprint()
    )
    newCellsBlueprints.push(newCell)
  })

  if (!cancle) {
    this.actionHandler.newAction({
      type: isVertical
        ? ACTION_TYPE.MERGE_TABLE_COLS
        : ACTION_TYPE.MERGE_TABLE_ROWS,
      shape_id: this.id,
      old_cells_blueprints: oldCellsBlueprints,
      new_cells_blueprints: newCellsBlueprints,
    })
    //this.selectCellIds(newCellsBlueprints.map(c => c.attributes.id))
  }
}

Table.prototype.creatVertex = function (x, y, id) {
  const factor = getScaleFactor()
  const rotationAngle = getRotationAngle()
  const [newX, newY] = pointRotate([x * factor, y * factor], rotationAngle)

  let newVertex = this.myFabric.getTableCircle(newX, newY, id)
  newVertex.on('mousedown', () => {
    this.saveTempCoords(newVertex)
  })
  newVertex.on('moved', () => {
    this.update()

    const vector = {
      x: newVertex.left - this.tempCoords[0].left,
      y: newVertex.top - this.tempCoords[0].top,
    }

    newVertex.set({
      left: this.tempCoords[0].left,
      top: this.tempCoords[0].top,
    })
    newVertex.setCoords()

    const actions = this.allCells()
      .map(c => {
        const vertices = c.isSpanParent() ? c.spanVertices : c.vertices
        const index = vertices.indexOf(newVertex)

        if (index < 0) return
        const oldCoords = vertices.map(({ left, top }) => ({ x: left, y: top }))
        const newCoords = vertices.map(({ left, top }, i) => {
          if (i === index) return { x: left + vector.x, y: top + vector.y }
          return { x: left, y: top }
        })
        return {
          type: ACTION_TYPE.UPDATE_CELL_COORDS,
          shape_id: c.id,
          oldCoords,
          newCoords,
        }
      })
      .filter(Boolean)

    /*  const index = vertexCells.vertices.indexOf(newVertex)
      const col = index > 1 ? vertexCells.col + 1 : vertexCells.col
      const row = index % 3 === 0 ? vertexCells.row : vertexCells.row + 1 */

    /*    this.actionHandler.newAction({
        type: ACTION_TYPE.MULTI_ACTION,
        actions: actions,
      }) */

    this.tempCoords = null
  })
  this.vertices.push(newVertex)
  return newVertex
}

Table.prototype.updateCellFrames = function () {
  this.allCells().map(cell => cell.updateFrame())
}

Table.prototype.getVertex = function (id) {
  return this.vertices.find(v => v.id === id)
}

Table.prototype.getVertexByCoords = function (x, y, tolerance = 0) {
  return this.vertices.find(c => {
    return Math.abs(c.left - x) <= tolerance && Math.abs(c.top - y) <= tolerance
  })
}

Table.prototype.saveTempCoords = function (...vertices) {
  this.tempCoords = vertices.map(({ top, left }) => ({ top, left }))
}

Table.prototype.getPosition = function (cell) {
  let position = {}
  this.cellMatrix.find((row, rowIndex) => {
    return row.find((c, colIndex) => {
      position.row = rowIndex
      position.col = colIndex
      return c === cell
    })
  })
  return position
}

Table.prototype.getCellsOfColumn = function (col) {
  let transposed = this.getTransposedMatrix()
  return transposed[col]
}

Table.prototype.noVerticalSplit = function (splitLine, cells) {
  const horizontalCellLines = cells.reduce(
    (acc, cell) => [
      ...acc,
      cell.getTopCoords().reduce((points, v) => [...points, [v.x, v.y]], []),
      cell.getBottomCoords().reduce((points, v) => [...points, [v.x, v.y]], []),
    ],
    []
  )
  //log(horizontalCellLines)
  return horizontalCellLines.some(line => {
    //log(!lineIntersectsLine(line, splitLine))
    //log(line)
    //log(splitLine)
    return !lineIntersectsLine(line, splitLine)
  })
}

Table.prototype.splitForbidden = function (line, cells) {
  let bools = cells.map(cell => {
    let coords = getSplitCoords(
      cell.vertices,
      cell.geometricLines,
      line,
      this.editorName
    ).map(intem => intem.vertices)

    if (coords.length != 3) return true
    let verticalCorrect =
      coords[0].length === 3 && coords[1].length === 4 && coords[2].length === 1
    let horizontalCorrect =
      coords[0].length === 2 && coords[1].length === 4 && coords[2].length === 2
    if (verticalCorrect || horizontalCorrect) return false
    return true
  })
  return bools.some(bool => bool)
}

Table.prototype.getFirstCutCell = function (splitLine) {
  return this.allCells().find(cell => {
    let { col } = this.getPosition(cell)
    let columnCells = this.getCellsOfColumn(col)
    return !this.noVerticalSplit(splitLine, columnCells)
  })
}

Table.prototype.calcSpan = function (coordsLength, rowSpan) {
  return coordsLength / 2 - rowSpan
}

Table.prototype.calcCornerPoints = function (rowSpan, colSpan) {
  return [0, rowSpan, rowSpan + colSpan, rowSpan + colSpan + rowSpan]
}

Table.prototype.addVerticalLine = function (splitLine) {
  const cuttedCells = this.cutCells(splitLine, 'vertical')
  if (cuttedCells.length === 0) return
  const oldCellsBlueprints = []
  const newCellsBlueprints = []
  cuttedCells.forEach(({ cell, newCoords1, newCoords2 }) => {
    oldCellsBlueprints.push(cell.getBlueprint())

    const cell1LineIds = []
    const cell2LineIds = []
    cell.baselines.forEach(l => {
      if (
        isLineInConvexRegion(
          l.getAreaForced().map(([x, y]) => ({ x, y })),
          newCoords1.map(({ x, y }) => [x, y]).reverse(),
          50
        )
      ) {
        cell1LineIds.push(l.id)
      } else {
        cell2LineIds.push(l.id)
      }
    })

    const textLineBlueprints = cell.baselines.map(l => l.getBlueprint())

    const newColSpan1 = this.calcSpan(newCoords1.length, cell.rowSpan)
    newCellsBlueprints.push({
      attributes: {
        row: cell.row,
        col: cell.col,
        rowSpan: cell.rowSpan,
        colSpan: newColSpan1,
        id: IdHandler.instance(this.editorName).requestId('c'),
        ...(cell.getStructureType() && {
          custom: { structureType: cell.getStructureType() },
        }),
      },
      elements: {
        coords: newCoords1,
        textLines: textLineBlueprints.filter(l =>
          cell1LineIds.includes(l.attributes.id)
        ),
        cornerPoints: this.calcCornerPoints(cell.rowSpan, newColSpan1),
      },
    })
    const newColSpan2 = this.calcSpan(newCoords2.length, cell.rowSpan)
    newCellsBlueprints.push({
      attributes: {
        row: cell.row,
        col: cell.col + newColSpan1,
        rowSpan: cell.rowSpan,
        colSpan: newColSpan2,
        id: IdHandler.instance(this.editorName).requestId('c'),
        ...(cell.getStructureType() && {
          custom: { structureType: cell.getStructureType() },
        }),
      },
      elements: {
        coords: newCoords2,
        textLines: textLineBlueprints.filter(l =>
          cell2LineIds.includes(l.attributes.id)
        ),
        cornerPoints: this.calcCornerPoints(cell.rowSpan, newColSpan2),
      },
    })
  })

  const action = {
    type: ACTION_TYPE.SPLIT_TABLE_COL,
    shape_id: this.id,
    old_cells_blueprints: oldCellsBlueprints,
    new_cells_blueprints: newCellsBlueprints,
  }
  this.actionHandler.newAction(action)
  //this.selectCellIds(newCellsBlueprints.map(c => c.attributes.id))
}

Table.prototype.addHorizontalLine = function (splitLine) {
  const cuttedCells = this.cutCells(splitLine, 'horizontal')
  if (cuttedCells.length === 0) return
  const oldCellsBlueprints = []
  const newCellsBlueprints = []
  cuttedCells.forEach(({ cell, newCoords1, newCoords2 }) => {
    oldCellsBlueprints.push(cell.getBlueprint())
    const cell1LineIds = []
    const cell2LineIds = []
    cell.baselines.forEach(l => {
      if (
        isLineInConvexRegion(
          l.getAreaForced().map(([x, y]) => ({ x, y })),
          newCoords1.map(({ x, y }) => [x, y]).reverse(),
          50
        )
      ) {
        cell1LineIds.push(l.id)
      } else {
        cell2LineIds.push(l.id)
      }
    })
    const textLineBlueprints = cell.baselines.map(l => l.getBlueprint())

    const newRowSpan1 = this.calcSpan(newCoords1.length, cell.colSpan)
    newCellsBlueprints.push({
      attributes: {
        row: cell.row,
        col: cell.col,
        rowSpan: newRowSpan1,
        colSpan: cell.colSpan,
        id: IdHandler.instance(this.editorName).requestId('c'),
        ...(cell.getStructureType() && {
          custom: { structureType: cell.getStructureType() },
        }),
      },
      elements: {
        coords: newCoords1,
        textLines: textLineBlueprints.filter(l =>
          cell1LineIds.includes(l.attributes.id)
        ),
        cornerPoints: this.calcCornerPoints(newRowSpan1, cell.colSpan),
      },
    })
    const newRowSpan2 = this.calcSpan(newCoords2.length, cell.colSpan)
    newCellsBlueprints.push({
      attributes: {
        row: cell.row + newRowSpan1,
        col: cell.col,
        rowSpan: newRowSpan2,
        colSpan: cell.colSpan,
        id: IdHandler.instance(this.editorName).requestId('c'),
        ...(cell.getStructureType() && {
          custom: { structureType: cell.getStructureType() },
        }),
      },
      elements: {
        coords: newCoords2,
        textLines: textLineBlueprints.filter(l =>
          cell2LineIds.includes(l.attributes.id)
        ),
        cornerPoints: this.calcCornerPoints(newRowSpan2, cell.colSpan),
      },
    })
  })

  const action = {
    type: ACTION_TYPE.SPLIT_TABLE_ROW,
    shape_id: this.id,
    old_cells_blueprints: oldCellsBlueprints,
    new_cells_blueprints: newCellsBlueprints,
  }

  this.actionHandler.newAction(action)
  //this.selectCellIds(newCellsBlueprints.map(c => c.attributes.id))
}

/* Table.prototype.splitCellVertical = function (currentCell, splitLine) {
  let [points1, points2, rest] = getSplitCoords(
    currentCell.vertices,
    currentCell.geometricLines,
    splitLine,
    this.editorName
  ).map(intem => intem.vertices)
  points1.push(...rest)
  let last = points2.pop()
  points2 = [last, ...points2]

  let cell1 = this.createCell()
  let cell2 = this.createCell()

  const oldType = currentCell.getStructureType()
  if (oldType) {
    cell1.setStructureType(oldType)
    cell2.setStructureType(oldType)
  }
  //cell1.setVertices(vertices1)
  currentCell.baselines.forEach(l => {
    if (
      isLineInConvexRegion(l, points1.map(({ x, y }) => [x, y]).reverse(), 50)
    ) {
      cell1.baselines.push(l)
    } else {
      cell2.baselines.push(l)
    }
  })
  cell1.text = currentCell.text
  cell1.rowSpan = currentCell.rowSpan
  cell1.colSpan = currentCell.colSpan
  //cell2.setVertices(vertices2)

  cell1.removeFromCanvas()
  cell1.removeChildsFromCanvas()

  cell2.removeFromCanvas()
  cell2.removeChildsFromCanvas()

  const blueprint1 = cell1.getBlueprint()
  const blueprint2 = cell2.getBlueprint()
  blueprint1.elements.coords = points1.map(({ x, y }) => ({ x, y }))
  blueprint2.elements.coords = points2.map(({ x, y }) => ({ x, y }))

  return [blueprint1, blueprint2]
} */

/* Table.prototype.splitCellHorizontal = function (currentCell, splitLine) {
  let [points1, points2, rest] = getSplitCoords(
    currentCell.vertices,
    currentCell.geometricLines,
    splitLine,
    this.editorName
  ).map(intem => intem.vertices)
  points1.push(...rest)

  let cell1 = this.createCell()
  let cell2 = this.createCell()

  const oldType = currentCell.getStructureType()
  if (oldType) {
    cell1.setStructureType(oldType)
    cell2.setStructureType(oldType)
  }

  currentCell.baselines.forEach(l => {
    if (
      isLineInConvexRegion(l, points1.map(({ x, y }) => [x, y]).reverse(), 50)
    ) {
      cell1.baselines.push(l)
    } else {
      cell2.baselines.push(l)
    }
  })
  //cell1.setVertices(vertices1)
  cell1.text = currentCell.text
  cell1.rowSpan = currentCell.rowSpan
  cell1.colSpan = currentCell.colSpan
  //cell2.setVertices(vertices2)

  cell1.removeFromCanvas()
  cell1.removeChildsFromCanvas()

  cell2.removeFromCanvas()
  cell2.removeChildsFromCanvas()

  const blueprint1 = cell1.getBlueprint()
  const blueprint2 = cell2.getBlueprint()
  blueprint1.elements.coords = points1.map(({ x, y }) => ({ x, y }))
  blueprint2.elements.coords = points2.map(({ x, y }) => ({ x, y }))
  return [blueprint1, blueprint2]
} */

Table.prototype.mergeRow = function (position) {
  const cell = this.getCellFromPointer(position)
  const { row } = this.cellMatrix.getPosition(cell)
  const cells = this.cellMatrix.getCellsOfRow(row)
  const first = cells[0]
  const actions = []
  cells.map(c => {
    if (!c.isSpanChild()) return
    const parent = this.cellMatrix.getCellParent(c.row, c.col)
    actions.push(this.getCellSpanAction(parent, 1, 1))
    parent.rowSpan = 1
    parent.colSpan = 1
  })
  const width = this.cellMatrix.getWidth()
  actions.push(this.getCellSpanAction(first, width, 1))
  first.rowSpan = 1
  first.colSpan = width
  this.actionHandler.addAction({
    type: ACTION_TYPE.MULTI_ACTION,
    actions,
  })
  this.update()
  //this.cellMatrix.log()
}

Table.prototype.mergeColumn = function (position) {
  const cell = this.getCellFromPointer(position)
  const { col } = this.cellMatrix.getPosition(cell)
  const cells = this.cellMatrix.getCellsOfColumn(col)
  const first = cells[0]
  const actions = []
  cells.map(c => {
    if (!c.isSpanChild()) return
    const parent = this.cellMatrix.getCellParent(c.row, c.col)
    actions.push(this.getCellSpanAction(parent, 1, 1))
    parent.rowSpan = 1
    parent.colSpan = 1
  })
  const height = this.cellMatrix.getHeight()
  actions.push(this.getCellSpanAction(first, height, 1))
  first.rowSpan = height
  first.colSpan = 1
  this.actionHandler.addAction({
    type: ACTION_TYPE.MULTI_ACTION,
    actions,
  })
  this.update()
  //this.cellMatrix.log()
}

/* Table.prototype.dissolveCells = function (position) {
  let parent = this.getParentFromPointer(position)
  this.actionHandler.newAction(this.getCellSpanAction(parent, 1, 1))
} */

Table.prototype.mergeRightCell = function (position) {
  const cell = this.getCellFromPointer(position)
  const neighbor = this.cellMatrix.getNeighbor(cell.row, cell.col, 'right')
  neighbor && this.mergeNeighbors(cell, neighbor)
}

Table.prototype.mergeLeftCell = function (position) {
  const cell = this.getCellFromPointer(position)
  const neighbor = this.cellMatrix.getNeighbor(cell.row, cell.col, 'left')
  neighbor && this.mergeNeighbors(cell, neighbor)
}
Table.prototype.mergeTopCell = function (position) {
  const cell = this.getCellFromPointer(position)
  const neighbor = this.cellMatrix.getNeighbor(cell.row, cell.col, 'top')
  neighbor && this.mergeNeighbors(cell, neighbor)
}

Table.prototype.mergeBottomCell = function (position) {
  const cell = this.getCellFromPointer(position)
  const neighbor = this.cellMatrix.getNeighbor(cell.row, cell.col, 'bottom')
  neighbor && this.mergeNeighbors(cell, neighbor)
}

Table.prototype.mergeNeighbors = function (cell1, cell2) {
  if (cell1 == null || cell2 == null) return
  const cell1Parent = this.cellMatrix.getCellParent(cell1.row, cell1.col)
  const cell2Parent = this.cellMatrix.getCellParent(cell2.row, cell2.col)
  const { newParent, newRowSpan, newColSpan } = this.cellMatrix.mergeParents(
    cell1Parent,
    cell2Parent
  )
  this.actionHandler.newAction({
    type: ACTION_TYPE.MULTI_ACTION,
    actions: [
      this.getCellSpanAction(cell1Parent, 1, 1),
      this.getCellSpanAction(cell2Parent, 1, 1),
      this.getCellSpanAction(newParent, newRowSpan, newColSpan),
    ],
  })
  /* cell1.rowSpan = 1
    cell1.colSpan = 1
    cell2.rowSpan = 1
    cell2.colSpan = 1
    newParent.rowSpan = newRowSpan
    newParent.colSpan = newColSpan
    this.update() */
}

Table.prototype.getCellSpanAction = function (cell, rowSpan, colSpan) {
  return {
    type: ACTION_TYPE.CHANGE_CELL_SPAN,
    shape_id: this.id,
    cell_id: cell.id,
    old_row_span: cell.rowSpan,
    old_col_span: cell.colSpan,
    new_row_span: rowSpan || cell.rowSpan,
    new_col_span: colSpan || cell.colSpan,
  }
}

Table.prototype.mergeCells = function (cells, direction) {
  /*  let sortedCells = cells.sort((a, b) => {
    let aPostition = this.cellMatrix.getPosition(a)
    let bPostition = this.cellMatrix.getPosition(b)
    return aPostition.row - bPostition.row + aPostition.col - bPostition.col
  }) */

  const mergeTwoCells = ({ cell1, newCoords1, cell2, newCoords2 }) => {
    /*  let vertices
    let sharedVertexIds = this.cellMatrix.getSharedVertexIds(cell1, cell2)
    const removeShared = coords =>
      coords.filter(v => !sharedVertexIds.includes(v.id)) */

    /*   const removeDuplicates = vertices => [...new Set(vertices)] */
    let newCoords
    if (direction === 'vertical') {
      newCoords = [...newCoords1, ...newCoords2]
    }
    /* if (this.cellMatrix.areVerticalNeighbors(cell1, cell2)) {
      let newVertices = [
        ...cell1.getLeftVertices(),
        ...cell2.getLeftVertices(),
        ...cell2.getBottomVertices(),
        ...cell2.getRightVertices().reverse(),
        ...cell1.getRightVertices().reverse(),
        ...cell1.getTopVertices().reverse(),
      ]
      vertices = removeDuplicates(removeShared(newVertices))
    } */

    /* let newCell = this.createCell()
    newCell.setVertices(vertices)
    newCell.baselines = [...cell1.baselines, ...cell2.baselines]
    newCell.text = cell1.text + cell2.text */
    const newColSpan = this.calcSpan(newCoords.length, cell1.rowSpan)
    return {
      attributes: {
        row: cell1.row,
        col: cell1.col,
        rowSpan: cell1.rowSpan,
        colSpan: newColSpan,
        id: IdHandler.instance(this.editorName).requestId('c'),
      },
      elements: {
        coords: newCoords,
        textLines: cell1.elements.textLines,
        cornerPoints: this.calcCornerPoints(cell1.rowSpan, newColSpan),
      },
    }
  }
  return mergeTwoCells(cells)
}

// Table.prototype.verticalMovement = function (line) {
//   let lines = this.verticalLines
//   let index = lines.indexOf(line)
//   let buffer = 10
//   let leftBefore = lines[index - 1]?.left
//   let leftAfter = lines[index + 1]?.left

//   if (line.left < leftBefore + buffer) {
//     line.set('left', leftBefore + buffer)
//   }
//   if (line.left > leftAfter - buffer) {
//     line.set('left', leftAfter - buffer)
//   }
// }

// Table.prototype.horizontalMovement = function (line) {
//   let lines = this.horizontalLines
//   let index = lines.indexOf(line)
//   let buffer = 10
//   let topBefore = lines[index - 1]?.top
//   let topAfter = lines[index + 1]?.top

//   if (line.top < topBefore + buffer) {
//     line.set('top', topBefore + buffer)
//   }
//   if (line.top > topAfter - buffer) {
//     line.set('top', topAfter - buffer)
//   }
// }

Table.prototype.distributePoints = function (p1, p2, n) {
  let points = []
  let deltaX = (p2.x - p1.x) / (n + 1)
  let deltaY = (p2.y - p1.y) / (n + 1)

  for (let i = 1; i <= n; i++) {
    let newX = p1.x + i * deltaX
    let newY = p1.y + i * deltaY
    points.push({ x: newX, y: newY })
  }
  return points
}

Table.prototype.cutCells = function (splitLine, direction) {
  let invalid = false
  const newCutCells = this.allCells()
    .map(cell => {
      const splitSegment = segment(
        splitLine[0][0],
        splitLine[0][1],
        splitLine[1][0],
        splitLine[1][1]
      )
      const locations = []
      const leftCoords = []
      const rightCoords = []
      let currentCoords = leftCoords
      let firstIntersectionPoint = null

      cell.coords.forEach((coord, index) => {
        currentCoords.push(coord)
        const nextCoord = cell.coords[(index + 1) % cell.coords.length]
        const coordSegment = segment(coord.x, coord.y, nextCoord.x, nextCoord.y)
        const intersectionPoint = coordSegment
          .intersect(splitSegment)
          .map(({ x, y }) => ({
            x,
            y,
          }))[0]
        const pointAllowed = () => {
          if (direction === 'vertical') {
            return (
              (cell.getTopCoordsSpanning().includes(coord) &&
                cell.getTopCoordsSpanning().includes(nextCoord)) ||
              (cell.getBottomCoordsSpanning().includes(coord) &&
                cell.getBottomCoordsSpanning().includes(nextCoord))
            )
          } else {
            return (
              (cell.getLeftCoordsSpanning().includes(coord) &&
                cell.getLeftCoordsSpanning().includes(nextCoord)) ||
              (cell.getRightCoordsSpanning().includes(coord) &&
                cell.getRightCoordsSpanning().includes(nextCoord))
            )
          }
        }
        if (intersectionPoint) {
          if (!pointAllowed()) invalid = true
          let gapFillerPoints = []
          if (firstIntersectionPoint) {
            gapFillerPoints = this.distributePoints(
              firstIntersectionPoint,
              intersectionPoint,
              cell.rowSpan - 1
            )
          }
          //const location = cell.getPointsLocation(index, index + 1)
          locations.push(location)
          currentCoords.push(intersectionPoint)
          if (direction === 'vertical') {
            currentCoords.unshift(...gapFillerPoints.toReversed())
          } else {
            currentCoords.push(...gapFillerPoints.toReversed())
          }
          currentCoords =
            leftCoords === currentCoords ? rightCoords : leftCoords
          currentCoords.push(...gapFillerPoints)
          currentCoords.push(intersectionPoint)
          if (!firstIntersectionPoint) {
            firstIntersectionPoint = intersectionPoint
          }
        }
      })
      if (direction === 'vertical') {
        const popped = rightCoords.pop()
        if (popped) rightCoords.unshift(popped)
      }
      const notEven = num => num % 2 !== 0
      if (notEven(leftCoords.length) || notEven(rightCoords.length)) {
        invalid = true
      }
      return { cell, newCoords1: leftCoords, newCoords2: rightCoords }
    })
    .filter(
      ({ newCoords1, newCoords2 }) =>
        newCoords1.length > 0 && newCoords2.length > 0
    )
  if (invalid) return []
  return newCutCells
}

Table.prototype.getCellFromPointer = function (position) {
  return this.cellMatrix
    .allCells()
    .find(cell =>
      pointInPolygon([position.x, position.y], cell.getCellPolygon())
    )
}

Table.prototype.getParentFromPointer = function (position) {
  return this.allCells().find(cell =>
    pointInPolygon([position.x, position.y], cell.getArea())
  )
}

Table.prototype.allVertices = function () {
  return []
}

Table.prototype.setState = function (state) {
  //never allow change of Selection only
  if (this.state === Shape.STATE.SELECTION_ONLY) return
  if (this.state === Shape.STATE.SEARCH_RESULTS) return

  // if (
  //   this.state === Shape.STATE.GROUP_SELECTION &&
  //   state != Shape.STATE.GROUP_SELECTION
  // ) {
  //   this.unGroupSelect()
  // }
  // if (
  //   this.state === Shape.STATE.LARGE_GROUP_SELECTION &&
  //   state != Shape.STATE.LARGE_GROUP_SELECTION
  // ) {
  //   this.unLargeGroupSelect()
  // }
  // if (
  //   state === Shape.STATE.GROUP_SELECTION &&
  //   this.state != Shape.STATE.GROUP_SELECTION
  // ) {
  //   this.groupSelect()
  // }
  // if (
  //   state === Shape.STATE.LARGE_GROUP_SELECTION &&
  //   this.state != Shape.STATE.LARGE_GROUP_SELECTION
  // ) {
  //   this.largeGroupSelect()
  // }
  if (this.activeVertex) {
    this.activeVertex.unSelect()
  }
  if (this.vertices.length > 1) {
    // this.lines = calcLines(this.vertices)
  }
  this.state = state
}

Table.prototype.positionCloseToVertex = function (position) {
  return this.allVertices().some(
    v => distanceBetween(v.left, v.top, position.x, position.y) < 5
  )
}

Table.prototype.updateHoveredLine = function (position) {
  const removeOldLine = () => {
    if (this.hoverLine) {
      this.canvas.remove(this.hoverLine)
      // this.allVertices().map((v) => this.canvas.bringToFront(v))
      this.canvas.requestRenderAll()
      this.hoverLine = null
    }
  }
  const setNewLine = newLine => {
    this.hoverLine = this.myFabric.getHoverLine(newLine.points, newLine.id)
    /* this.hoverLine.relations = this.getRelations(
      this.hoverLine,
      this.hoverLine.vertices
    ) */
    this.hoverLine.on('mousedown', () => {
      this.hoverLine.isDragging = true
      this.tempCoords = [{ x: this.hoverLine.left, y: this.hoverLine.top }]
    })
    this.hoverLine.on('moved', () => {
      //this.updateCircles(this.hoverLine)
      //this.update()
      const vector = {
        x: this.hoverLine.left - this.tempCoords[0].x,
        y: this.hoverLine.top - this.tempCoords[0].y,
      }
      /* const pointIndices = this.hoverLine.vertices.map((v, index) => {
        return this.vertices.indexOf(v)
      })
      const positions = []
      const actions = this.hoverLine.vertices
        .map(v => {
          const foundCell = this.allCells().find(c => {
            return c.vertices.includes(v)
          })
          const index = foundCell.vertices.indexOf(v)
          const col = index > 1 ? foundCell.col + 1 : foundCell.col
          const row = index % 3 === 0 ? foundCell.row : foundCell.row + 1
          if (positions.some(p => p.row === row && p.col === col)) return
          positions.push({ row, col })
          return {
            type: ACTION_TYPE.MOVE_TABLE_POINT,
            shape_id: this.id,
            position: { row, col },
            vector,
          }
        })
        .filter(Boolean)
      console.log(positions) */

      const cellsAndIndices = this.hoverLine.points
        .map(point => {
          return this.getCellsAndIndices(point)
        })
        .flat()

      const actions = cellsAndIndices.map(({ cell, index }) => ({
        type: ACTION_TYPE.MOVE_POINT,
        shape_id: cell.id,
        point_index: index,
        vector,
      }))

      this.actionHandler.newAction({
        type: ACTION_TYPE.MULTI_ACTION,
        actions: actions,
      })

      this.tempCoords = null

      /* console.log({
        type: ACTION_TYPE.MULTI_ACTION,
        actions: actions,
      })
      this.actionHandler.newAction({
        type: ACTION_TYPE.MULTI_ACTION,
        actions: actions,
      }) */

      /* this.actionHandler.newAction({
          type: ACTION_TYPE.MULTI_ACTION,
          actions: this.hoverLine.vertices.map((v, index) => {
            const vector = {
              x: v.left - this.tempCoords[index].left,
              y: v.top - this.tempCoords[index].top,
            }
  
            v.set({
              left: this.tempCoords[index].left,
              top: this.tempCoords[index].top,
            })
            v.setCoords()
  
            return {
              type: ACTION_TYPE.MOVE_POINT,
              shape_id: this.id,
              point_index: this.vertices.indexOf(v),
              vector,
            }
          }),
        }) */
    })
    this.hoverLine.on('mouseup', () => {
      this.hoverLine.isDragging = false
    })
    this.canvas.add(this.hoverLine)
    this.getSelectedCells().forEach(c =>
      c.vertices.forEach(v => this.canvas.bringToFront(v))
    )
  }
  if (this.hoverLine?.isDragging) return
  if (this.positionCloseToVertex(position)) {
    removeOldLine()
    return
  }
  let foundLine = this.findLine(position)
  if (foundLine) {
    if (foundLine.id === this.hoverLine?.id) return
    removeOldLine()
    setNewLine(foundLine)
    return
  }
  removeOldLine()
}

Table.prototype.updateVertexSizes = function (target) {
  if (target?.type === 'circle') {
    if (this.hoveredVertex?.id === target.id) return
    target.set({ radius: target.defaultRadius * 1.5 })
    this.hoveredVertex = target
    this.canvas.requestRenderAll()
  } else {
    if (this.hoveredVertex) {
      this.hoveredVertex.set({ radius: this.hoveredVertex.defaultRadius })
      this.hoveredVertex = null
      this.canvas.requestRenderAll()
    }
  }
}
Table.prototype.showPreviewRect = function (x, y) {
  this.canvas.remove(this.previewRect)
  this.previewRect = this.myFabric.getPreviewRectTable(
    this.initCoord.x,
    this.initCoord.y,
    x,
    y
  )
  this.canvas.add(this.previewRect)
}

Table.prototype.handleMouseMoveEvent = function (event) {
  if (this.state === Shape.STATE.FIRST_DRAW) {
    if (this.initCoord) {
      let mousePosition = this.canvas.getPointer(event.e)
      this.showPreviewRect(mousePosition.x, mousePosition.y)
    }
  }
  if (this.state === Shape.STATE.EDIT) {
    let position = this.canvas.getPointer(event.e)
    this.mousePosition = position
    this.updateHoveredLine(position)
    this.updateVertexSizes(event.target)
  }
}

/* Table.prototype.createDragVertex = function(position) {
    let foundLine = this.findLine(position)
    if (foundLine) {
      let dragVertex = this.myFabric.getDragCircle(position.x, position.y)
      this.canvas.add(dragVertex)
      this.dragVertex = dragVertex
      let lineMatrix = foundLine.calcTransformMatrix()
      let inverseVertexMatrix = fabric.util.invertTransform(
        dragVertex.calcTransformMatrix()
      )
      this.lineRelation = fabric.util.multiplyTransformMatrices(
        inverseVertexMatrix,
        lineMatrix
      )
    }
  } */

Table.prototype.handleClickEvent = function (event, controlPressed) {
  // if (event.button != 1) return
  // let position = this.canvas.getPointer(event.e)
  // if (this.state === Shape.STATE.EDIT) {
  //   let cell = this.getParentFromPointer(position)
  //   if (!cell) return
  //   if (event.target) return
  //   if (controlPressed) {
  //     if (cell.selected) {
  //       cell.unSelect()
  //     } else {
  //       cell.select()
  //     }
  //   } else {
  //     if (cell.selected) {
  //       this.unSelectAllCells()
  //     } else {
  //       this.unSelectAllCells()
  //       cell.select()
  //     }
  //   }
  // }
}

Table.prototype.selectNewCell = function (cell) {
  if (this.markedCell?.id === cell?.id) return
  if (this.markedCell) {
    this.markedCell.unSelect()
    this.markedCell = null
  }
  if (cell) {
    cell.select()
    this.markedCell = cell
  }
}

Table.prototype.getSelectedCells = function () {
  return this.allCells().filter(c => c.selected)
}

Table.prototype.updateCellSelection = function () {
  // this.getSelectedCells().map(cell=>{
  //   if(cell.isSpanChild()){
  //     cell.unSelect()
  //     cell.remove
  //   }
  // })
}

/* Table.prototype.createCell = function (blueprint) {
  const newCell = new Cell(this.canvas, blueprint, this.editorName)
  // newCell.on('selected', () => this.select())
  // newCell.on('unSelected', () => this.unSelect())
  // newCell.on('mouseMove', (event) => this.handleMouseMoveEvent(event))
  return newCell
} */

Table.prototype.findLine = function (position) {
  return this.lines.find(line =>
    line.polygons.some(polygon => {
      return pointInPolygon([position.x, position.y], polygon)
    })
  )
}

Table.prototype.setTableInvalid = function (cells) {
  cells.map(c => c.baselines.map(l => l.removeFromCanvas()))
  this.cellMatrix = new CellMatrix(0, 0, this.editorName)
  this.invalid = true
  this.update = function () {
    if (!this.canvas.contains(this.frameInvalid)) {
      this.canvas.add(this.frameInvalid)
    }
  }
  this.handleClickEvent = () => {}
  this.handleMouseMoveEvent = () => {}
  this.getBaselines = () => []
  this.select = function () {
    if (!this.canvas.contains(this.backgroundInvalid)) {
      this.canvas.add(this.backgroundInvalid)
    }
    if (!this.canvas.contains(this.signInvalid)) {
      this.canvas.add(this.signInvalid)
    }
  }
  this.unSelect = function () {
    this.canvas.remove(this.backgroundInvalid)
    this.canvas.remove(this.signInvalid)
  }
  this.getBlueprint = function () {
    return {
      type: 'table',
      attributes: this.attributes,
      elements: this.elements,
    }
  }
  this.removeFromCanvas = function () {
    this.canvas.remove(this.backgroundInvalid)
    this.canvas.remove(this.signInvalid)
    this.canvas.remove(this.frameInvalid)
  }
  this.getArea = function () {
    return this.elements.coords.map(({ x, y }) => [x, y])
  }

  this.backgroundInvalid = this.myFabric.getInvalidTableBackground(
    this.elements.coords
  )
  this.frameInvalid = this.myFabric.getInvalidTableFrame(this.elements.coords)
  this.signInvalid = this.myFabric.getInvalidTableSign(this.elements.coords)
  this.canvas.add(this.backgroundInvalid)
  this.canvas.add(this.frameInvalid)
  this.unSelect()
}

//Shape overwrite
/* Table.prototype.movePoint = function(pointId, coords) {
    let vertex = this.allVertices().find(v => v.id === pointId)
    if (vertex) {
      vertex.set({ left: coords.left, top: coords.top })
      vertex.setCoords()
      this.cellMatrix.log()
      this.update()
    } else {
    }
  } */

export default Table
