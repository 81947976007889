<template>
  <div class="app-modal__box">
    <div class="app-modal__header">
      <h3 class="app-title">
        {{ $t('Shortcuts') }}
      </h3>
    </div>
    <div class="app-modal__body">
      <div class="scroll-box hover-scrollbar pr-3">
        <div v-for="(section, index) in sections" :key="index">
          <div class="text-base text-[#193060] font-bold my-2">
            {{ section.title }}
          </div>
          <div class="border-t border-[#D1D6DF] pb-3">
            <div
              v-for="(shortcut, i) in section.shortCuts"
              :key="i"
              class="flex justify-between text-sm pt-2"
            >
              <span class="flex items-center">{{ shortcut.title }}</span>
              <div class="flex gap-1">
                <div
                  v-for="(key, index) in getShortCutsKeys(shortcut)"
                  class="flex items-center gap-1"
                >
                  <div
                    v-if="key.svg"
                    class="w-[37px] h-[26px] bg-[#EDEDED] rounded shadow-custom-shadow text-center text-xs flex justify-center items-center"
                    v-html="$svg[key.svg]"
                  ></div>
                  <div
                    v-else-if="key === 'Hold'"
                    class="w-[37px] h-[26px] text-[#7A7A7A] text-center text-xs flex justify-center items-center mr-[-4px]"
                  >
                    {{ key }}
                  </div>
                  <div
                    v-else-if="key.length >= 3 && key.length < 5"
                    class="w-[45px] h-[26px] bg-[#EDEDED] rounded shadow-custom-shadow text-center text-xs flex justify-center items-center"
                  >
                    {{ key }}
                  </div>
                  <div
                    v-else-if="key.length >= 5"
                    class="w-[55px] h-[26px] bg-[#EDEDED] rounded shadow-custom-shadow text-center text-xs flex justify-center items-center"
                  >
                    {{ key }}
                  </div>
                  <div
                    v-else
                    class="w-[30px] h-[26px] bg-[#EDEDED] rounded shadow-custom-shadow text-center text-xs flex justify-center items-center"
                  >
                    {{ key }}
                  </div>
                  <span
                    v-if="
                      index !== getShortCutsKeys(shortcut).length - 1 &&
                      key !== 'Hold' &&
                      !key.svg
                    "
                    >+</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalShowEditorGuide',
  data() {
    return {
      sections: [
        {
          title: 'General',
          shortCuts: [
            {
              title: 'Save page',
              keys: ['CTRL', 'S'],
            },
            {
              title: 'Undo action',
              keys: ['CTRL', 'Z'],
            },
            {
              title: 'Redo action',
              keys: ['CTRL', 'Y'],
            },
            {
              title: 'Copy element',
              keys: ['CTRL', 'C'],
            },
            {
              title: 'Paste element',
              keys: ['CTRL', 'V'],
            },
            {
              title: 'Last page',
              keys: ['ALT', { svg: 'arrowLeft' }],
            },
            {
              title: 'Next page',
              keys: ['ALT', { svg: 'arrowRight' }],
            },
          ],
        },
        {
          title: 'Tagging',
          shortCuts: [
            {
              title: 'Open Tag overlay for selected text',
              keys: ['ENTER'],
            },
            {
              title: 'Bold',
              winKeys: ['CTRL', 'B'],
              macKeys: ['CMD', 'B'],
            },
            {
              title: 'Italic',
              winKeys: ['CTRL', 'I'],
              macKeys: ['CMD', 'I'],
            },
            {
              title: 'Strikethrough',
              winKeys: ['ALT', 'SHIFT', '5'],
              macKeys: ['CMD', 'SHIFT', 'X'],
            },
            {
              title: 'Underline',
              winKeys: ['CTRL', 'U'],
              macKeys: ['CMD', 'U'],
            },
            {
              title: 'Superscript',
              winKeys: ['CTRL', '.'],
              macKeys: ['CMD', '.'],
            },
            {
              title: 'Subscript',
              winKeys: ['CTRL', ','],
              macKeys: ['CMD', ','],
            },
            {
              title: 'Select tag by index',
              keys: ['*NUM*'],
            },
            {
              title: 'Select a structure tag by typing its first letter',
              keys: ['*CHAR*'],
            },
          ],
        },
        {
          title: 'Selection',
          shortCuts: [
            {
              title: 'Selection mode',
              keys: ['O'],
            },
            {
              title: 'Drag mode',
              keys: ['P'],
            },
            {
              title: 'Select all elements',
              keys: ['CTRL', 'A'],
            },
            {
              title: 'Select next element',
              keys: ['TAB'],
            },
            {
              title: 'Select previous element',
              keys: ['SHIFT', 'TAB'],
            },
            {
              title: 'Multiselect elements in area',
              keys: ['Hold', 'CTRL'],
            },
            {
              title: 'Select all lines of a selected region',
              keys: ['CTRL', 'B'],
            },
          ],
        },
        {
          title: 'Create or delete',
          shortCuts: [
            {
              title: 'Create textline',
              keys: ['B'],
            },
            {
              title: 'Create region',
              keys: ['R'],
            },
            {
              title: 'Create table',
              keys: ['T'],
            },
            {
              title: 'Add a point at the end of a baseline',
              keys: ['Hold', 'A'],
            },
            {
              title: 'Delete selected element',
              keys: ['DEL'],
            },
            {
              title: 'Delete selected node',
              keys: ['D'],
            },
          ],
        },
        {
          title: 'Edit',
          shortCuts: [
            {
              title: 'Move and scale elements',
              keys: ['SHIFT'],
            },
            {
              title: 'Merge two elements',
              keys: ['M'],
            },
            {
              title: 'Split element horizontally',
              keys: ['Hold', 'H'],
            },
            {
              title: 'Split element vertically',
              keys: ['Hold', 'V'],
            },
            {
              title: 'Custom split',
              keys: ['Hold', 'C'],
            },
            {
              title: 'Move a custom split line',
              keys: ['W', 'A', 'S', 'D'],
            },
            {
              title: 'Rotate a split line',
              keys: [{ svg: 'arrowLeft' }, { svg: 'arrowRight' }],
            },
          ],
        },
        {
          title: 'Image',
          shortCuts: [
            {
              title: 'Zoom image in',
              keys: ['Q'],
            },
            {
              title: 'Zoom image out',
              keys: ['W'],
            },
            {
              title: 'Center image',
              keys: ['E'],
            },
            {
              title: 'Fit image to width',
              keys: ['F'],
            },
            {
              title: 'Rotate image',
              keys: ['G'],
            },
          ],
        },
        {
          title: 'Text',
          shortCuts: [
            {
              title: 'Select all text',
              winKeys: ['CTRL', 'A'],
              macKeys: ['CMD', 'A'],
            },
            {
              title: 'Go to next line',
              winKeys: ['ENTER'],
              macKeys: ['ENTER'],
            },
            {
              title: 'Copy element',
              keys: ['CTRL', 'C'],
            },
            {
              title: 'Paste element',
              keys: ['CTRL', 'V'],
            },
            {
              title: 'Split and move text to next line',
              keys: ['CTRL', 'ENTER'],
            }
          ],
        },
      ],
      /* instructions: [
        {
          string: this.$t(
            `To add new <strong>baselines</strong> press the baseline-button, click on the canvas multiple times and finish the line with double click or enter`
          ),
        },
        {
          string: this.$t(
            `To add new <strong>regions</strong> press the region-button and click on the canvas twice to create the area`
          ),
        },
        {
          string: this.$t(
            `New <strong>points</strong> can be added by clicking in the middle of a line and dragging the new point to a new position`
          ),
        },
        {
          string: this.$t(
            `Edit Shapes using the <strong>context menu</strong> (right click)`
          ),
        },
        {
          string: this.$t(
            `Hold <div class='description-icon' id='shift-icon'></div> to <strong>move</strong> and <strong>scale</strong> shapes`
          ),
        },
        {
          string: this.$t(
            `Press <div class='description-icon' id='ctrl-icon'></div> + L to select all lines of a selected region`
          ),
        },
        {
          string: this.$t(
            `Hold <div class='description-icon' id='ctrl-icon'></div> or <div class='description-icon' id='cmd-icon'></div> to select <strong>multiple</strong> shapes by click or by spanning a selection rectangle`
          ),
        },
        {
          string: this.$t(
            `Press <div class='description-icon' id='tab-icon' ></div> to select the <strong>next</strong> shape (following the reading order)`
          ),
        },
        {
          string: this.$t(
            `Use [W, A, S, D] to move a custom split line [up, left, down, right]. Press Enter to split`
          ),
        },
      ],
      shortcuts: [
        {
          string: this.$t(
            `Hold <div class='description-icon' id='a-icon' ></div> to add points to the beginning or end of a selected Line`
          ),
        },

        {
          string: this.$t(
            `Hold V to <strong>split</strong> a shape vertically`
          ),
        },
        {
          string: this.$t(
            `Hold H to <strong>split</strong> a shape horizontally`
          ),
        },
        {
          string: this.$t(
            `Hold C to <strong>split</strong> a shape with a custom line (use arrow keys to change the angle)`
          ),
        },
        {
          string: this.$t(
            `Press <div class='description-icon' id='m-icon' ></div> to merge two selected Lines`
          ),
        },
        {
          string: this.$t(
            `Press <div class='description-icon' id='esc-icon' > </div> to leave the current mode and enter selection mode`
          ),
        },
        {
          string: this.$t(
            `Press <div class='description-icon' id='ctrl-icon'></div> + C to copy all selected shapes`
          ),
        },
        {
          string: this.$t(
            `Press <div class='description-icon' id='ctrl-icon'></div> + V to paste copied shapes`
          ),
        },
      ], */
    }
  },
  methods: {
    getShortCutsKeys(shortcut) {
      if (this.$os().mac) {
        return shortcut.macKeys || shortcut.keys
      }
      return shortcut.winKeys || shortcut.keys
    },
  },
}
</script>

<style scoped lang="scss">
.scroll-box {
  height: 60vh;
  overflow-y: auto;
}
/* .hover-scrollbar::-webkit-scrollbar {
  width: 0;
}

.hover-scrollbar:hover::-webkit-scrollbar {
  width: 8px;
}*/

.hover-scrollbar::-webkit-scrollbar-thumb {
  background-color: white;
}
.hover-scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: rgb(150, 162, 183);
}
</style>
<style>
/* .modal-box {
  max-width: 700px;
} */
</style>
