import type { Coord } from './types' // Adjust the path if needed
import { Circle, Line } from 'fabric' // Adjust the path if needed
import { Singleton as Settings } from './singletons/settings.js'

function interpolateColor(
  color1: string,
  color2: string,
  percentage: number
): string {
  // Convert hex colors to RGB
  const rgb1 = hexToRgb(color1)
  const rgb2 = hexToRgb(color2)

  // Interpolate each component (R, G, B)
  const interpolatedColor = {
    r: Math.round(rgb1.r + (rgb2.r - rgb1.r) * percentage),
    g: Math.round(rgb1.g + (rgb2.g - rgb1.g) * percentage),
    b: Math.round(rgb1.b + (rgb2.b - rgb1.b) * percentage),
  }

  // Convert back to hex
  const interpolatedHexColor = rgbToHex(
    interpolatedColor.r,
    interpolatedColor.g,
    interpolatedColor.b
  )

  return interpolatedHexColor
}

function hexToRgb(hex: string): { r: number; g: number; b: number } {
  // Remove the hash if it exists
  hex = hex.replace(/^#/, '')

  // Parse the hex values to RGB
  const bigint = parseInt(hex, 16)
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255

  return { r, g, b }
}

function rgbToHex(r: number, g: number, b: number): string {
  // Convert RGB values to hex
  const toHex = (value: number): string => {
    const hex = value.toString(16)
    return hex.length === 1 ? '0' + hex : hex
  }

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`
}

function getCircleSize() {
  return Settings.instance().get('circleSize')
}

function getRegionColor(opacity?: number) {
  const color = Settings.instance().get('regionColor')
  if (!opacity) return color
  return interpolateColor('#ffffff', color, opacity)
}

export function hexToRgbaString(hex, opacity) {
  const rgb = hexToRgb(hex)

  if (opacity < 0 || opacity > 1) {
    throw new Error('Opacity must be between 0 and 1')
  }

  return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})`
}

export function getRegionSplitCircle(coord: Coord): Circle {
  return new fabric.Circle({
    radius: getCircleSize(),
    fill: '#000',
    strokeWidth: 1,
    stroke: '#000',
    left: coord.x,
    top: coord.y,
    originX: 'center',
    originY: 'center',
    hasControls: false,
    hasBorders: false,
    defaultRadius: getCircleSize(),
    defaultColor: '#FFFFFF',
    selectedColor: '#000',
  })
}

export function getRegionSplitLine(
  x1: number,
  y1: number,
  x2: number,
  y2: number
): Line {
  return new fabric.Line([x1, y1, x2, y2], {
    originX: 'center',
    originY: 'center',
    strokeWidth: 1,
    fill: '#000',
    stroke: '#000',
    selectable: false,
    evented: false,
  })
}
