import {
  Singleton as ActionHandler,
  ACTION_TYPE,
} from '../singletons/actionHandler.js'
import { Singleton as MyFabric } from '../myFabric.js'
import { Singleton as IdHandler } from '../singletons/IdHandler.js'
import { Singleton as Settings } from '../singletons/settings.js'
import { pointRotate, polygonMean } from 'geometric'
import { isEmpty } from 'lodash'
import { calcLines } from '../geometryFunctions.js'
import { isEqual } from 'lodash'

function Shape(canvas, editorName) {
  this.attributes = {}
  this.elements = {}
  this.text = ''

  this.canvas = canvas
  this.state = Shape.STATE.EDIT
  this.selected = false
  this.showStructure = false
  this.vertices = []
  this.prevLines = []
  this.deletedPoints = []
  this.actionHandler = ActionHandler.instance(editorName)
  this.editorName = editorName
  this.myFabric = MyFabric.instance(editorName)
  this.settings = Settings.instance()
  this.geometricLines = []
  this.hoveredVertex = null
  this.preview = true
  this.runningVertex = null
  this.lastMousePosition = { x: 0, y: 0 }
  this.verticesCoordsAfterCalc = []
  this.polygonVerticesCoordsAfterCalc = []
}

Shape.STATE = Object.freeze({
  FIRST_DRAW: 1,
  GROUP_SELECTION: 2,
  ADD_VERTEX: 3,
  REMOVE_VERTEX: 4,
  EDIT: 5,
  SELECTION_ONLY: 6,
  SEARCH_RESULTS: 7,
})

Shape.M_ACTIONS = Object.freeze({
  V_DIV: 1,
  H_DIV: 2,
  REMOVE_DIV: 3,
})

Shape.TYPE = Object.freeze({
  REGION: 'region',
  TABLE: 'table',
  BASELINE: 'baseline',
  CELL: 'cell',
  WORD: 'word,',
  SHAPE: 'shape',
})

Shape.prototype.performAction = function (action) {
  if (
    this.state === Shape.STATE.GROUP_SELECTION
    // this.state === Shape.STATE.LARGE_GROUP_SELECTION
  ) {
    this.setState(Shape.STATE.EDIT)
  }

  if (action) {
    switch (action.type) {
      case ACTION_TYPE.ADD_POINT:
        let newPoint
        this.deletedPoints = this.deletedPoints.filter(point => {
          if (point.id === action.point_id) {
            newPoint = point
            return false
          }
          return true
        })
        this.addPoint(newPoint, action.point_index)
        break

      case ACTION_TYPE.REMOVE_POINT:
        this.removePoint(action.point_id)
        break

      case ACTION_TYPE.MOVE_POINT:
        this.movePoint(action)
        break
      case ACTION_TYPE.MOVE_POINTS:
        this.moveAllPoints(action)
        break
      /* case ACTION_TYPE.MOVE_SHAPE_WITH_VECTOR:
        this.moveAllPointsWithVector(action.vector)
        break */
      case ACTION_TYPE.ADD_TABLE_COLUM:
        this.cellMatrix.createColumn(action.col_index)
        break
      case ACTION_TYPE.REMOVE_TABLE_COLUM:
        this.cellMatrix.deleteColumn(action.col_index)
        break
      case ACTION_TYPE.ADD_TABLE_ROW:
        this.cellMatrix.createRow(action.row_index)
        break
      case ACTION_TYPE.REMOVE_TABLE_ROW:
        this.cellMatrix.deleteRow(action.row_index)
        break
      case ACTION_TYPE.ADD_CELL:
        this.cellMatrix.setCell(action.cell, action.row, action.col)
        break
      case ACTION_TYPE.REMOVE_CELL:
        this.cellMatrix.removeCellById(action.cell.id)
        break
      case ACTION_TYPE.SPLIT_TABLE_COL:
        this.handleColSplit(action)
        break
      case ACTION_TYPE.SPLIT_TABLE_ROW:
        this.handleRowSplit(action)
        break
      case ACTION_TYPE.MERGE_TABLE_COLS:
        this.handleColMerge(action)
        break
      case ACTION_TYPE.MERGE_TABLE_ROWS:
        this.handleRowMerge(action)
        break
      case ACTION_TYPE.CHANGE_CELL_SPAN:
        let cell = this.cellMatrix.getCellById(action.cell_id)
        cell.rowSpan = action.new_row_span
        cell.colSpan = action.new_col_span
        this.update()
        break
      case ACTION_TYPE.SET_STRUCTUR_TYPE:
        this.setStructureType(action.new_type)
        this.update()
        break
      case ACTION_TYPE.SET_TEXT:
        this.text = action.new_text
        this.update()
        break
      case ACTION_TYPE.SET_TAGS:
        this.setTags(action.new_tags)
        this.update()
        break
      case ACTION_TYPE.ADD_TAG:
        this.addTag(action.tag)
        this.update()
        break
      case ACTION_TYPE.REMOVE_TAG:
        this.removeTag(action.tag)
        this.update()
        break
    }
  }
}

Shape.prototype.getCustomWithTagIds = function () {
  let id = 0
  if (this.attributes.custom?.tags == null) {
    return this.attributes.custom
  }
  const tags = this.attributes.custom.tags.map(t => {
    id++
    return {
      ...t,
      id: `${this.id}_${id}`,
    }
  })
  return {
    ...this.attributes.custom,
    tags,
  }
}

Shape.prototype.getReadingOrder = function () {
  return this.index
}

Shape.prototype.getStructureType = function () {
  return this.attributes.custom?.structureType
}

Shape.prototype.setReadingOrder = function (index) {
  /* if (!('custom' in this.attributes)) {
    this.attributes.custom = {}
  }
  this.attributes.custom.readingOrder = index */
}

Shape.prototype.setStructureType = function (type) {
  if (type === 'none') {
    if (!('custom' in this.attributes)) return
    const custom = this.attributes.custom
    if (!('structureType' in custom)) return
    delete custom.structureType
    if (isEmpty(custom)) {
      delete this.attributes.custom
    }
    return
  }
  if (!('custom' in this.attributes)) {
    this.attributes.custom = {}
  }
  this.attributes.custom.structureType = type
}

Shape.prototype.addTag = function (tag) {
  delete tag.id

  if (!('custom' in this.attributes)) {
    this.attributes.custom = {}
  }
  if (!('tags' in this.attributes.custom)) {
    this.attributes.custom.tags = []
  }

  this.attributes.custom.tags.push(tag)
}

Shape.prototype.setTags = function (tags) {
  tags.forEach(t => delete t.id)
  if (!('custom' in this.attributes)) {
    this.attributes.custom = {}
  }
  if (!('tags' in this.attributes.custom)) {
    this.attributes.custom.tags = []
  }
  this.attributes.custom.tags = [...tags]
}

Shape.prototype.removeTag = function (tag) {
  delete tag.id

  const copy = obj => JSON.parse(JSON.stringify(obj))
  const foundIndex = this.attributes.custom.tags.findIndex(t =>
    isEqual(copy(t), copy(tag))
  )
  if (foundIndex < 0) return
  this.attributes.custom.tags.splice(foundIndex, 1)
  if (this.attributes.custom.tags.length === 0) {
    delete this.attributes.custom.tags
  }
  if (isEmpty(this.attributes.custom)) {
    delete this.attributes.custom
  }
}

Shape.prototype.getType = function () {
  return Shape.TYPE.SHAPE
}

/* Shape.prototype.movePoint = function(pointId, coords) {
  let vertex = this.vertices.find(v => v.id === pointId)
  if (vertex) {
    vertex.set({ left: coords.left, top: coords.top })
    vertex.setCoords()
    this.update()
  } else {
  }
} */
Shape.prototype.movePoint = function (action) {
  const vertex = this.vertices[action.point_index]
  const { x, y } = action.vector
  if (vertex) {
    vertex.set({ left: vertex.left + x, top: vertex.top + y })
    vertex.setCoords()
    this.update()
  }
}

Shape.prototype.moveMultiplePoints = function (action) {
  const { point_indices, vector } = action
  point_indices.forEach(index => {
    const vertex = this.vertices[index]
    if (vertex) {
      vertex.set({ left: vertex.left + vector.x, top: vertex.top + vector.y })
      vertex.setCoords()
    }
  })
  this.update()
}

Shape.prototype.moveAllPoints = function (action) {
  const vectors = action.vectors
  this.vertices.forEach((vertex, index) => {
    vertex.set({
      left: vertex.left + vectors[index].x,
      top: vertex.top + vectors[index].y,
    })
    vertex.setCoords()
  })
  this.update()
}

/* Shape.prototype.moveAllPointsWithVector = function({ x, y }) {
  this.vertices.map(vertex => {
    vertex.set({ left: vertex.left + x, top: vertex.top + y })
    vertex.setCoords()
  })
  this.update()
} */

Shape.prototype.addPoint = function (vertex, index, noUpdate) {
  this.canvas.add(vertex)
  this.vertices.splice(index, 0, vertex)
  if (this.vertices.length > 1) {
    if (!noUpdate) {
      this.update()
    }
  }
}

Shape.prototype.addMultiplePoints = function (points) {
  this.canvas.add(...points)

  this.vertices = this.vertices.concat(points)

  if (this.vertices.length > 1) {
    this.update()
  }
}

Shape.prototype.removePoint = function (pointId) {
  let vertex
  this.vertices = this.vertices.filter(v => {
    if (v.id === pointId) {
      vertex = v
      return false
    }
    return true
  })
  this.deletedPoints.push(vertex)
  if (!vertex) {
    console.log('the point id ' + pointId + ' could not be found')
    console.log(this.vertices.map(v => v.id))
    console.log(this.deletedPoints.map(p => p.id))
  }
  vertex.set('radius', vertex.defaultRadius)
  this.canvas.remove(vertex)
  this.update()
  this.canvas.discardActiveObject()
}

Shape.prototype.getVertexIndex = function (id) {
  const index1 = this.vertices.findIndex(v => v.id === id)
  if (index1 >= 0) return index1
  return this.polygonVertices?.findIndex(v => v.id === id)
}

Shape.prototype.getVertexById = function (id) {
  const allVertices = [...this.vertices, ...(this.polygonVertices || [])]
  return allVertices.find(v => {
    return v.id === id
  })
}

Shape.prototype.showDock = function (type) {
  if (this.dock != null) return
  if (!this.isVisible()) return
  this.dock = this.myFabric.getDock(this.getMidPoint(), this.id, type)
  this.canvas.add(this.dock)
}

Shape.prototype.removeDock = function () {
  if (this.dock == null) return
  this.canvas.remove(this.dock)
  this.dock = null
}

Shape.prototype.getMidPoint = function () {
  const coords = this.vertices.map(({ left, top }) => [left, top])
  return polygonMean(coords)
}

Shape.prototype.handleClickEvent = function (event) {}
Shape.prototype.handleDBLClickEvent = function (event, setButtonState) {}
Shape.prototype.handleMouseMoveEvent = function (event) {}
Shape.prototype.handleMouseOut = function (event) {}
Shape.prototype.mouseDownBefore = function () {}
Shape.prototype.handleMouseUpEvent = function (position) {}
Shape.prototype.mouseUp = function () {}

Shape.prototype.update = function () {}

Shape.prototype.select = function () {}

Shape.prototype.unSelect = function () {}

Shape.prototype.setState = function () {}

/* Shape.prototype.showStructureType = function(show) {
  // this.showStructure = show
  // this.update()
} */

// Shape.prototype.setStructureType = function (type) {
//   this.attributes.custom.structureType = type
//   this.update()
// }

Shape.prototype.settingsUpdate = function () {
  // this.updateStructureText(this.vertices, 'Baseline')
  // this.updateReadingOrderText(this.vertices, 'Baseline')
  // this.vertices.map((v) => {
  //   v.set({
  //     radius: this.settings.get('circleSize'),
  //     defaultRadius: this.settings.get('circleSize'),
  //   })
  // })
  // this.canvas.requestRenderAll()
}

Shape.prototype.isVisible = function () {
  switch (this.getType()) {
    case Shape.TYPE.REGION: {
      return this.settings.get('showRegions')
    }
    case Shape.TYPE.TABLE: {
      return this.settings.get('showRegions')
    }
    case Shape.TYPE.CELL: {
      return this.settings.get('showRegions')
    }
    case Shape.TYPE.BASELINE: {
      return (
        this.settings.get('showBaselines') ||
        this.settings.get('showLinePolygons')
      )
    }
    case Shape.TYPE.WORD: {
      return this.settings.get('showWords')
    }
  }
}

Shape.prototype.superUpdate = function () {
  this.updateVertexSize()
  this.updateStructureText()
  this.updateReadingOrderText()
}

/* Shape.prototype.updateVisibility = function() {
  if (!this.isVisible()) {
    this.removeFromCanvas()
  }
} */

Shape.prototype.updateVertices = function () {
  if (this.selected && this.myFabric.isFocused) {
    if (!this.canvas.contains(this.vertices[0])) {
      this.canvas.add(...this.vertices)
    }
  } else {
    this.canvas.remove(...this.vertices)
  }
}

Shape.prototype.scaleBy = function (scale) {
  this.vertices.forEach(v => {
    v.set({ left: v.left * scale, top: v.top * scale })
    v.setCoords()
  })
  if (this.getType() === Shape.TYPE.BASELINE) {
    this.polygonVertices.forEach(v => {
      v.set({ left: v.left * scale, top: v.top * scale })
      v.setCoords()
    })
  }

  this.update()
}

Shape.prototype.rotateBy = function (angle) {
  this.vertices.forEach(v => {
    const [left, top] = pointRotate([v.left, v.top], angle)
    v.set({ left, top })
    v.setCoords()
  })
  this.update()
}

Shape.prototype.updateGeometricLines = function (coords, polyCoords) {
  if (coords) {
    this.geometricLines =
      this.getType() === Shape.TYPE.BASELINE
        ? calcLines(coords).slice(0, -1)
        : calcLines(coords)
  }

  if (polyCoords) {
    this.geometricLinesPolygon = calcLines(polyCoords)
  }
}

Shape.prototype.updateVertexSize = function () {
  const circleSize = this.settings.get('circleSize')
  this.vertices.map(vertex => {
    if (vertex.radius != circleSize) {
      vertex.set({
        radius: circleSize,
        defaultRadius: circleSize,
      })
    }
  })
}

Shape.prototype.areDifferent = function (a, b) {
  return JSON.stringify(a) !== JSON.stringify(b)
}

Shape.prototype.updateIfNeeded = function ({
  showSetting,
  objectName,
  functionName,
  functionArgs,
}) {
  const show = this.settings.get(showSetting)
  if (!show) {
    if (this.canvas.contains(this[objectName])) {
      this.canvas.remove(this[objectName])
    }
    return
  }
  const newObject = this.myFabric[functionName](...functionArgs)
  if (
    this.myFabric.areObjectsIdentical(this[objectName], newObject) &&
    this.canvas.contains(this[objectName])
  ) {
    return
  }
  this.canvas.remove(this[objectName])
  this[objectName] = newObject
  if (this[objectName] != null) {
    this.canvas.add(this[objectName])
  }
}

Shape.prototype.updateStructureText = function (coords) {
  this.canvas.remove(this.structureText)
  if (coords == null) return
  const showStructureTypes = this.settings.get('showStructureTypes')
  const showStructureColors = this.settings.get('showStructureColors')
  const structureType = this.getStructureType()
  if (
    structureType != null &&
    (showStructureTypes || (showStructureColors && this.selected))
  ) {
    this.structureText = this.myFabric.getStructureText(
      this.getStructureType(),
      this.getTopLeftCoord(coords),
      this.attributes.custom?.structureObject?.rules?.find(
        r => r.directive === 'level'
      )?.value
    )
    this.canvas.add(this.structureText)
  }
}

Shape.prototype.updateStructureColor = function (coords) {
  this.canvas.remove(this.structureColorFrame)
  if (coords == null) return
  const showStructureColors = this.settings.get('showStructureColors')
  const structureType = this.getStructureType()
  if (showStructureColors && structureType != null) {
    this.structureColorFrame = this.myFabric.getStructureColorFrame(
      this.getStructureType(),
      coords
    )
    this.canvas.add(this.structureColorFrame)
  }
}

Shape.prototype.updateReadingOrderText = function (coords) {
  this.canvas.remove(this.readingOrderText)
  if (coords == null) return
  const showReadingOrder = this.settings.get('showReadingOrder')
  if (showReadingOrder) {
    this.readingOrderText = this.myFabric.getROText(
      this.getReadingOrder(),
      this.getTopLeftVertex(coords)
    )
    /*   this.readingOrderText = this.myFabric.getIdLabel(
      this.id,
      this.getTopLeftVertex(coords)
    ) */
    this.canvas.add(this.readingOrderText)
  }
}

Shape.prototype.getTopLeftVertex = function (coords) {
  return coords.reduce(
    (acc, v) =>
      Math.abs(acc.x) + Math.abs(acc.y) > Math.abs(v.x) + Math.abs(v.y)
        ? v
        : acc,
    coords[0]
  )
}

Shape.prototype.getTopLeftCoord = function (coords) {
  return coords.reduce((acc, v) =>
    Math.abs(acc.x) + Math.abs(acc.y) > Math.abs(v.x) + Math.abs(v.y) ? v : acc
  )
}

export default Shape
