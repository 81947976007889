import { polygonMean } from 'geometric'
import type {
  Coord,
  RegionGeometric,
  LineGeometric,
  SplittedRegionGeometric,
  SplittedLineGeometric,
} from '../LayoutEditor/components/types'
import {
  aFollowedByB,
  closedPoint,
} from '../LayoutEditor/components/geometryFunctions'

export function cloneObj(obj) {
  return JSON.parse(JSON.stringify(obj))
}

export function getCoordsJson(coords: any) {
  const pointString = getStringfromPointList(coords)
  if (pointString == null) return
  return {
    attributes: {
      points: pointString,
    },
    elements: [],
    name: 'Coords',
    type: 'element',
  }
}

export function getBaselineJson(coords) {
  const pointString = getStringfromPointList(coords)
  if (pointString == null) return
  return {
    attributes: {
      points: pointString,
    },
    elements: [],
    name: 'Baseline',
    type: 'element',
  }
}

export function getStringfromPointList(list) {
  if (list == null || list.length === 0) return
  return list.map(c => `${c.x},${c.y}`).join(' ')
}

export function getAttributesJson(blueprintAttributes) {
  //log(blueprintAttributes)
  const attributes = { ...blueprintAttributes }
  if ('custom' in blueprintAttributes) {
    attributes.custom = stringifyCustom(blueprintAttributes.custom)
  }
  if (attributes.custom.length === 0) {
    delete attributes.custom
  }
  return attributes
}

export function stringifyCustom(custom) {
  //log(custom)
  let string = ''
  if ('readingOrder' in custom) {
    string += ` readingOrder {index:${custom.readingOrder};}`
  }
  if ('structureType' in custom) {
    let asString = ''
    if (custom.structureObject != null) {
      const otherProperties = custom.structureObject.rules.filter(
        o => o.directive != 'type'
      )
      asString = otherProperties.reduce(
        (acc, p) => acc + `${p.directive}:${p.value};`,
        ''
      )
    }
    string += ` structure {type:${custom.structureType};${asString}}`
  }
  if ('relationType' in custom) {
    string += ` relationType {value:${custom.relationType};}`
  }
  if ('relationName' in custom) {
    string += ` relationName {value:${custom.relationName};}`
  }
  if ('id' in custom) {
    string += ` id {value:${custom.id};}`
  }
  if ('tags' in custom) {
    custom.tags.map(tag => {
      const ruleString = tag.rules.reduce(
        (acc, { directive, value }) => acc + `${directive}:${value};`,
        ''
      )
      string += ` ${tag.selector} {${ruleString}}`
    })
  }

  string = string.substring(1)
  return string
}

export function parseCustom(string: string) {
  const customObject: any = {}
  const rules = string
    .split('}')
    .filter(Boolean)
    .map(rule => {
      const [selector, styles] = rule.split('{').map(str => str.trim())
      const styleRules = styles
        .split(';')
        .filter(Boolean)
        .map(style => {
          const [directive, value] = style.split(':').map(str => str.trim())
          return { directive, value }
        })
      return { selector, rules: styleRules }
    })

  rules.forEach(({ selector, rules }) => {
    switch (selector) {
      case 'readingOrder':
        customObject.readingOrder = rules[0].value
        break
      case 'structur':
      case 'structure':
        customObject.structureType =
          rules.find(r => r.directive === 'type')?.value || ''
        //customObject.structureObject = { selector, rules }
        break
      case 'relationType':
        customObject.relationType = rules[0]?.value || ''
        break
      case 'relationName':
        customObject.relationName = rules[0]?.value || ''
        break
      case 'id':
        customObject.id = rules[0]?.value || ''
        break
      default: {
        const offsetRule = rules.find(r => r.directive === 'offset')
        const lengthRule = rules.find(r => r.directive === 'length')
        if (offsetRule && lengthRule) {
          if (!('tags' in customObject)) {
            customObject.tags = []
          }
          customObject.tags.push({ selector, rules })
        } else {
          customObject[selector] = rules
        }
      }
    }
  })

  return customObject
}

export function getShapeText(shape) {
  const textEquiv = shape.elements.find(e => e.name === 'TextEquiv')
  if (textEquiv == null) {
    return
  }
  return textEquiv?.elements?.[0]?.elements?.[0]?.text
}

export function getShapeId(shape) {
  return shape.attributes.id
}

export function getShapeAttributes(shape) {
  const attributes = {
    ...shape.attributes,
  }
  const customString = shape.attributes.custom
  const customAttributes = customString && parseCustom(customString)
  delete attributes.custom
  return {
    ...attributes,
    ...customAttributes,
  }
}

export function getRegionJson(
  id: any,
  coords: any,
  structureType: string,
  relationType?: string,
  relationName?: string
) {
  //attributes
  let attributes = getAttributesJson({
    id,
    custom: {
      ...(structureType != null && { structureType }),
      ...(relationType != null && { relationType }),
      ...(relationName != null && { relationName }),
    },
  })

  //elements
  let elements: any = []
  elements.push(getCoordsJson(coords))
  /*  blueprint.elements.textLines.map(line => {
    elements.push(getTextLineJson(line))
  }) */
  elements.push(getTextEquivJson(''))

  return {
    attributes: attributes,
    elements: elements,
    name: 'TextRegion',
    type: 'element',
  }
}

export function getTextEquivJson(text) {
  return {
    elements: [
      {
        elements:
          text == null || text.length <= 0
            ? []
            : [{ type: 'text', text: text }],
        name: 'Unicode',
        type: 'element',
      },
    ],
    name: 'TextEquiv',
    type: 'element',
  }
}

export function getLineJson(
  id,
  baselineCoords,
  polyCoords,
  text,
  tags,
  structureType,
  relationType?: string,
  relationName?: string
) {
  //attributes
  let attributes = getAttributesJson({
    id,
    custom: {
      ...(tags != null && { tags }),
      ...(structureType != null && { structureType }),
      ...(relationType != null && { relationType }),
      ...(relationName != null && { relationName }),
    },
  })

  //elements
  let elements: any = []
  const coordsJson = polyCoords && getCoordsJson(polyCoords)
  if (coordsJson) elements.push(coordsJson)

  const baselineJson = baselineCoords && getBaselineJson(baselineCoords)
  if (baselineJson) elements.push(baselineJson)

  elements.push(getTextEquivJson(text))

  return {
    attributes: attributes,
    elements: elements,
    name: 'TextLine',
    type: 'element',
  }
}

export function getPage(pageJson: any): any {
  const pcgts = pageJson.elements.find(e => e.name === 'PcGts')
  if (pcgts == null) {
    return
  }
  const page = pcgts.elements.find(e => e.name === 'Page')
  if (page == null) {
    return
  }
  return page
}

export function getPageElements(pageJson: any): any {
  return getPage(pageJson).elements
}

export function getTextAndTableRegions(pageJson: any): any {
  const pageElements = getPageElements(pageJson)
  return pageElements.filter(
    e => e.name === 'TextRegion' || e.name === 'TableRegion'
  )
}

export function getAllLines(pageJson: any): any {
  const regions = getTextAndTableRegions(pageJson)
  return regions
    .map(region => [
      ...getTextLines(region),
      ...getCells(region)
        .map(cell => getTextLines(cell))
        .flat(),
    ])
    .flat()
}

export function getTextRegions(pageJson: any): any {
  const pageElements = getPageElements(pageJson)
  return pageElements.filter(e => e.name === 'TextRegion')
}

export function getTableRegions(pageJson: any): any {
  const pageElements = getPageElements(pageJson)
  return pageElements.filter(e => e.name === 'TableRegion')
}

export function setTextAndTableRegions(regions, pageJson) {
  const page = getPage(pageJson)
  page.elements = page.elements.filter(
    e => e.name !== 'TextRegion' && e.name !== 'TableRegion'
  )
  page.elements = [...page.elements, ...regions]
}

export function getPointListfromString(string: string) {
  if (string === '') return
  const coords = string.split(' ')
  const result = coords.map(c => {
    const list = c.split(',')
    return { x: Number(list[0]), y: Number(list[1]) }
  })
  return result
}

export function getCoordsFromElement(element: any): any {
  const coords = element.elements.find((e: any) => e.name === 'Coords')
  if (coords == null) return
  return getPointListfromString(coords.attributes.points)
}

export function setCoordsForElement(element, points) {
  const coordsElement = element.elements.find((e: any) => e.name === 'Coords')
  coordsElement.attributes.points = getStringfromPointList(points)
}

export function getBaselineFromElement(element: any): any {
  const coords = element.elements.find((e: any) => e.name === 'Baseline')
  if (coords == null) return
  return getPointListfromString(coords.attributes.points)
}

export function setBaselineForElement(element, points) {
  const baselineElement = element.elements.find(
    (e: any) => e.name === 'Baseline'
  )
  baselineElement.attributes.points = getStringfromPointList(points)
}

export function getRegionGeometrics(pageJson): RegionGeometric[] {
  const regions = getTextRegions(pageJson)
  const regionGeometrics = regions.map(region => {
    return {
      id: region.attributes.id,
      regionCoords: getCoordsFromElement(region),
    }
  })
  const tables = getTableRegions(pageJson)
  const cellGeometrics = tables
    .map(table => {
      return getCells(table).map(cell => {
        return {
          id: cell.attributes.id,
          regionCoords: getCoordsFromElement(cell),
        }
      })
    })
    .flat()

  return [...regionGeometrics, ...cellGeometrics]
}

export function getTextLines(region) {
  return region.elements.filter(e => e.name === 'TextLine')
}

export function getLineGeometrics(pageJson): LineGeometric[] {
  const regions = getTextAndTableRegions(pageJson)
  return regions
    .map(region => [
      ...getTextLines(region).map(line => ({
        id: line.attributes.id,
        baselineCoords: getBaselineFromElement(line),
        polygonCoords: getCoordsFromElement(line),
        parentId: region.attributes.id,
      })),
      ...getCells(region)
        .map(cell =>
          getTextLines(cell).map(line => ({
            id: line.attributes.id,
            baselineCoords: getBaselineFromElement(line),
            polygonCoords: getCoordsFromElement(line),
            parentId: cell.attributes.id,
          }))
        )
        .flat(),
    ])
    .flat()
}

export function findElementAndPathById(pageJson: any, targetId: any) {
  let result: any = { element: null, path: null }

  function traverse(node, currentPath) {
    if (node.attributes && node.attributes.id === targetId) {
      result.element = node
      result.path = `/${currentPath.join('/')}`
      return true
    }

    if (node.elements && Array.isArray(node.elements)) {
      for (let i = 0; i < node.elements.length; i++) {
        const child = node.elements[i]
        if (traverse(child, [...currentPath, 'elements', i])) {
          return true
        }
      }
    }

    return false
  }

  traverse(pageJson, [])
  return result
}

export function removeRegion(pageJson, id) {
  const page = getPage(pageJson)
  page.elements = page.elements.filter(e => e.attributes?.id !== id)
}

export function getTextLinesOfRegion(region) {
  return region.elements.filter(e => e.name === 'TextLine')
}

export function getCells(table) {
  return table.elements.filter(e => e.name === 'TableCell')
}

export function getTextlineParent(pageJson, id) {
  const regions = getTextAndTableRegions(pageJson)
  for (let region of regions) {
    const textLines = getTextLinesOfRegion(region)
    for (let line of textLines) {
      if (line.attributes.id === id) {
        return region
      }
    }
    const cells = getCells(region)
    for (let cell of cells) {
      const textLines = getTextLinesOfRegion(cell)
      for (let line of textLines) {
        if (line.attributes.id === id) {
          return cell
        }
      }
    }
  }
}

export function removeLine(pageJson, id) {
  let parent = getTextlineParent(pageJson, id)
  if (parent != null) {
    parent.elements = parent.elements.filter(e => e.attributes?.id !== id)
  }
}

export function expectedRegionIndex(newRegion, regions) {
  const getMean = region => {
    const coords = getCoordsFromElement(region)
    return polygonMean(coords.map(({ x, y }) => [x, y]))
  }
  const newRegionMidpoint = getMean(newRegion)
  const midpoints = regions.map(r => getMean(r))
  const closest = closedPoint(newRegionMidpoint, midpoints)
  if (closest == null) return 0
  const follows = aFollowedByB(closest, newRegionMidpoint)
  if (follows) {
    return midpoints.indexOf(closest) + 1
  }
  return midpoints.indexOf(closest)
}

export function expectedLlineIndex(newLine, lines) {
  const defaultPoint = [0, 0]
  const getMean = line => {
    const baselineCoords = getBaselineFromElement(line)
    const polygonCoords = getCoordsFromElement(line)
    const coords = baselineCoords || polygonCoords
    if (coords == null) return defaultPoint
    return polygonMean(coords.map(({ x, y }) => [x, y]))
  }
  const newLineMidpoint = getMean(newLine)
  newLineMidpoint[0] = 0 // ignore x-coordinate
  const midpoints = lines.map(l => getMean(l))
  midpoints.forEach(m => (m[0] = 0)) // ignore x-coordinate
  const closest = closedPoint(newLineMidpoint, midpoints)
  if (closest == null) return 0
  const follows = aFollowedByB(closest, newLineMidpoint)
  if (follows) {
    return midpoints.indexOf(closest) + 1
  }
  return midpoints.indexOf(closest)
}

export function setTextlinesForRegion(region, textlines) {
  region.elements = region.elements.filter(e => e.name !== 'TextLine')
  region.elements = [...region.elements, ...textlines]
}

export function getShapeById(id, pageJson) {
  const regions = getTextAndTableRegions(pageJson)
  for (let region of regions) {
    if (region.attributes.id === id) {
      return region
    }
    const textLines = getTextLinesOfRegion(region)
    for (let line of textLines) {
      if (line.attributes.id === id) {
        return line
      }
    }
    const cells = getCells(region)
    for (let cell of cells) {
      if (cell.attributes.id === id) {
        return cell
      }
      const textLines = getTextLinesOfRegion(cell)
      for (let line of textLines) {
        if (line.attributes.id === id) {
          return line
        }
      }
    }
  }
}

export function removeShapeById(id, pageJson) {
  removeShapesByIds([id], pageJson)
}

export function removeShapesByIds(ids, pageJson) {
  const regions = getTextAndTableRegions(pageJson)

  const updatedRegions = regions.filter(region => {
    if (ids.includes(region.attributes.id)) {
      return false
    }

    const updatedTextLines = getTextLinesOfRegion(region).filter(
      line => !ids.includes(line.attributes.id)
    )
    setTextlinesForRegion(region, updatedTextLines)

    const cells = getCells(region)
    cells.forEach(cell => {
      const updatedCellTextLines = getTextLinesOfRegion(cell).filter(
        line => !ids.includes(line.attributes.id)
      )
      setTextlinesForRegion(cell, updatedCellTextLines)
    })

    return true
  })

  setTextAndTableRegions(updatedRegions, pageJson)
}

export function getNewIdsWithShift(
  pageJson: any,
  baseId: string,
  newId: string
) {
  if (baseId == null || newId == null) return
  const lines = getAllLines(pageJson)
  let baseIndex = lines.findIndex(l => l.attributes.id === baseId)
  if (baseIndex >= 0) {
    const newIndex = lines.findIndex(l => l.attributes.id === newId)
    if (baseIndex < newIndex) {
      return lines.slice(baseIndex + 1, newIndex + 1).map(l => l.attributes.id)
    }
    if (baseIndex > newIndex) {
      return lines.slice(newIndex, baseIndex).map(l => l.attributes.id)
    }
  }
  const regions = getTextAndTableRegions(pageJson)
  baseIndex = regions.findIndex(r => r.attributes.id === baseId)
  if (baseIndex >= 0) {
    const newIndex = regions.findIndex(r => r.attributes.id === newId)
    if (baseIndex < newIndex) {
      return regions
        .slice(baseIndex + 1, newIndex + 1)
        .map(r => r.attributes.id)
    }
    if (baseIndex > newIndex) {
      return regions.slice(newIndex, baseIndex).map(r => r.attributes.id)
    }
  }
}

export function areConsecutiveShapes(pageJson: any, shapeIds: string[]) {
  const isStrictlyConsecutive = arr => {
    if (arr.length < 2) return false
    for (let i = 1; i < arr.length; i++) {
      if (arr[i] !== arr[i - 1] + 1) {
        return false // If the difference is not exactly 1, return false
      }
    }
    return true
  }
  if (shapeIds.length === 0 || shapeIds.length === 1) return false

  const parent = getTextlineParent(pageJson, shapeIds[0])
  if (parent) {
    const parentLines = getTextLinesOfRegion(parent)
    const parentLineIds = parentLines.map(l => l.attributes.id)
    const indices = shapeIds.map(id => parentLineIds.indexOf(id))
    indices.sort((a, b) => a - b)
    return isStrictlyConsecutive(indices)
  } else {
    const regions = getTextAndTableRegions(pageJson)
    const regionsIds = regions.map(r => r.attributes.id)
    const indices = shapeIds.map(id => regionsIds.indexOf(id))
    indices.sort((a, b) => a - b)
    return isStrictlyConsecutive(indices)
  }
}
