<template>
  <Splitpanes
    :horizontal="horizontal"
    @resized="$emit('paneResized', $event)"
    class="default-theme"
  >
    <Pane style="background: none; position: relative"
      ><slot name="left"></slot
    ></Pane>
    <Pane :size="textEditorSize" style="background: none; position: relative">
      <slot name="right"></slot
    ></Pane>
  </Splitpanes>
</template>

<script>
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import { Singleton as Settings } from '../LayoutEditor/components/singletons/settings.js'

export default {
  components: {
    Splitpanes,
    Pane,
  },
  data() {
    return {
      horizontal: Settings.instance().get('editLayoutHorizontal'),
      textEditorSize: Math.max(5, Settings.instance().get('textEditorSize')),
    }
  },
  created() {
    Settings.instance().on('update', ({ editLayoutHorizontal }) => {
      this.horizontal = editLayoutHorizontal
    })
  },
}
</script>

<style scoped>
.flex-container {
  display: flex;
}

.flex-vertical {
  flex-direction: column;
}

.flex-item {
  flex: 1;
  padding: 10px;
}
</style>
