<template>
  <div class="w-full pointer h-16 bg-primary"></div>
  <div class="w-full h-[90%]">
    <VersionPreview ref="combined"></VersionPreview>
  </div>
</template>

<script>
import model from './Model/model'
import VersionPreview from './Views/VersionPreview.vue'

export default {
  setup() {},
  data() {
    return {}
  },

  props: {
    xml: {
      type: String,
    },
    iiifInfo: {
      type: String,
    },
  },
  components: { VersionPreview },
  computed: {},
  async mounted() {
    const iiifObject = await $fetch(this.iiifInfo)
    const page = {
      imageDimensions: {
        width: iiifObject.width,
        height: iiifObject.height,
      },
      transcripts: [],
      iiifInfo: this.iiifInfo,
    }

    const pageJson = model.xmlStringToJson(this.xml)

    const content = {
      textData: model.getTextData(pageJson),
      layoutData: model.getLayoutData(pageJson),
      pageJson,
    }

    this.$nextTick(() => {
      this.$refs.combined?.updateEditorPage(page)
      this.$refs.combined?.updateEditorVersion(content)
    })
  },

  methods: {},
}
</script>

<style></style>
