let scaleFactor = 1
let rotationAngle = 0

export function setScaleFactor(f) {
  scaleFactor = f
}

export function getScaleFactor() {
  return scaleFactor
}

export function setRotationAngle(a) {
  rotationAngle = a
}

export function getRotationAngle() {
  return rotationAngle
}
