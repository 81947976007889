<template>
  <client-only placeholder="loading...">
    <div class="w-full h-full relative" ref="combinedWrapper">
      <EditorPopup
        :popupParams="popupParams"
        :textualTags="textualTags"
        :isEditable="false"
        @update="updatePopupParams"
        @popupMouseDown="popupMouseDown"
        @updatePopupPosition="updatePopupPosition"
        ref="popup"
      ></EditorPopup>

      <CombinedLayout>
        <template v-slot:left>
          <LayoutEditor
            @editorMouseDown="
              updatePopupParams({ tagIdentifier: null, state: null })
            "
            :isEditable="false"
            :editorName="editorName"
            :viewerName="viewerName"
            ref="layouteditor"
          ></LayoutEditor>
        </template>
        <template v-slot:right>
          <TextEditor
            ref="texteditor"
            :isEditable="false"
            :editorName="editorName"
            @scrolled="textEditorScroll"
            @updatePopupParams="updatePopupParams"
            @updatePopupPosition="updatePopupPosition"
            @textMouseDown="
              updatePopupParams({ tagIdentifier: null, state: null })
            "
            :textualTags="textualTags"
            :characters="characters"
            :compareText="baseVersion != null"
          ></TextEditor> </template
      ></CombinedLayout>
    </div>
  </client-only>
</template>

<script>
import CombinedLayout from './CombinedLayout.vue'
import { Singleton as CollectionConfig } from '../LayoutEditor/components/singletons/collectionConfig.js'
import LayoutEditor from '../LayoutEditor/components/LayoutEditor.vue'
import TextEditor from '../TextEditor/TextEditor.vue'
import EditorPopup from '../EditorPopup.vue'
import { getNewPopupPosition } from '../editor-utils.js'

export default {
  middleware: 'auth',
  components: {
    CombinedLayout,
    LayoutEditor,
    TextEditor,
    EditorPopup,
  },

  data() {
    return {
      textualTags: CollectionConfig.instance().getTextualTags(),
      characters: CollectionConfig.instance().getCharacters(),
      textEditorScrollTop: 0,
      popupParams: {},
      pageUpdated: false,
    }
  },

  methods: {
    updateEditorVersion(version, force) {
      this.$refs.layouteditor.updateEditorVersion(version, force)
      this.$refs.texteditor.updateEditorVersion(version)
    },

    updateEditorPage(page) {
      if (this.pageUpdated) return
      this.pageUpdated = true
      this.$refs.layouteditor.updateEditorPage(page)
    },

    getDiff([line1, line2], dmp) {
      return dmp.diff_main(line2.text || '', line1.text || '')
    },
    getDiffCount(diff) {
      return diff.filter(([op]) => op !== 0).length
    },

    updatePopupParams(params) {
      this.popupParams = { ...this.popupParams, ...params }
    },
    textEditorScroll(scrollTop) {
      if (this.popupParams.y) {
        const scrollTopDelta = this.textEditorScrollTop - scrollTop
        this.popupParams = {
          ...this.popupParams,
          y: this.popupParams.y + scrollTopDelta,
        }
      }
      this.textEditorScrollTop = scrollTop
    },

    updatePopupPosition(rect = this.popupParams.rect) {
      if (rect == null) return

      const { x, y } = getNewPopupPosition(
        rect,
        this.$refs.combinedWrapper.getBoundingClientRect(),
        this.$refs.texteditor.getLineHeightInPixel()
      )

      this.popupParams = {
        ...this.popupParams,
        x,
        y,
        rect,
      }
    },
  },

  computed: {
    editorName() {
      return 'editor2'
    },
    viewerName() {
      return 'viewer2'
    },

    popupPosition() {
      return { x: this.popupParams.x, y: this.popupParams.y }
    },
    collId() {
      return this.$route.params.collectionid || this.colId
    },
  },
}
</script>

<style>
.splitpanes.default-theme .splitpanes__pane {
  padding: 0rem;
}
#loadingDiv .loading-overlay .loading-background {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  background: rgba(0, 0, 0, 0);
}
</style>

<style lang="scss" scoped>
.is-relative {
  width: 100%;
  height: 100%;
  position: relative;
}

.is-covering {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

#loadingDiv {
  position: absolute;
  z-index: 15;
  width: 100%;
  height: 100%;
}
</style>
