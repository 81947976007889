// AddLineOnEnter.ts
import {
  getLineJson,
  getTextLines,
  setTextlinesForRegion,
  getTextlineParent,
} from '../Model/modelUtils.ts'
import BaseAction from './BaseAction.js'

export default class AddLineOnEnter extends BaseAction {
  private newLineId: string

  constructor(pageJson: any) {
    super(pageJson)
    this.newLineId = this.idGenerator.requestId('l')
  }

  apply(params: any) {
    const { lineId } = params

    const parent = getTextlineParent(this.pageJson, lineId)

    const currentIndex = getTextLines(parent).findIndex(
      (line: any) => line.attributes.id === lineId
    )

    const nextIndex = currentIndex + 1

    console.log('currentIndex', currentIndex)

    const textLineJson = getLineJson(this.newLineId, null, null, '', null, null)

    const textLines = [...getTextLines(parent)]
    textLines.splice(nextIndex, 0, textLineJson)

    setTextlinesForRegion(parent, textLines)
  }

  getNewLineId() {
    return this.newLineId
  }
}
