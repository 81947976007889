<template>
  <div
    ref="text-editor-wrapper"
    class="app-text-editor"
    @click.self="clear"
    @mousedown="editorMouseDown"
  >
    <Splitpanes
      @resized="paneResized"
      horizontal
      style="background: white !important; position: relative"
    >
      <Pane
        v-if="isKeyboard"
        :size="keyboardSize"
        min-size="10"
        style="background: white; position: relative; overflow: auto"
      >
        <SimpleKeyboard
          v-if="characters.length > 0"
          @onKeyPress="onKeyPress"
          @mouseup.native="keyboardMouseUp"
          @mousedown.native="keyboardMouseDown"
          :charConfig="characters"
          :editorName="editorName"
        />
      </Pane>

      <Pane style="background-color: white" class="flex flex-col">
        <div v-if="allowDragging" class="w-full px-[6px] py-[6px]">
          <div
            :class="[
              'w-full relative rounded flex py-2 px-3  items-center justify-center text-base ',
              `text-[${'#3e3e3e'}]`,
            ]"
            :style="{
              background: `linear-gradient(to right, ${'#FFA50060'}, ${'#FFA50060'})`,
            }"
          >
            <div
              class="flex flex-row justify-center items-center gap-2 z-20 relative"
            >
              <span>To enable text editing please exit the layout mode: </span>
              <span
                class="w-[32px] h-[32px] rounded"
                :style="{
                  'background-color': '#19306030',
                }"
                @click="disableLayoutMode"
              >
                <label
                  class="flex justify-center items-center my-2 cursor-pointer svg-wrapper"
                  v-html="$svg.layout"
                  :style="{ color: true ? '#193060' : null }"
                ></label>
              </span>
            </div>
          </div>
        </div>
        <draggable
          :modelValue="editorData"
          item-key="id"
          group="regions"
          @end="onRegionDragEnd"
          style="
            background: white;
            position: relative;
            width: 100%;
            overflow: auto;
          "
          class="flex flex-col items-center grow"
          ref="textContainer"
          id="textContainer"
          @click.self="clear"
          v-if="editorData.length > 0"
          @scroll="scrolled"
          :class="{
            'text-center': textAlignment === 'center' && !isSites,
            'text-right': textAlignment === 'right' && !isSites,
            'text-left': textAlignment === 'left' || isSites,
            flex: true,
            'flex-col': true,
            'items-center': true,
          }"
          :disabled="!allowDragging"
        >
          <template #item="{ element: region, index }">
            <component
              :is="region.type === 'Region' ? 'TextRegion' : 'TableRegion'"
              :key="region.id"
              :selected="selectedIds.includes(region.id)"
              :selectedIds="selectedIds"
              :draggable="allowDragging"
              :id="region.id"
              :structureType="region.structureType"
              :structureLevel="region.structureLevel"
              :index="index"
              :isSites="isSites"
              :children="region.children"
              @addNewLineToEmptyParent="addNewLineToEmptyParent"
              @mouseUpWrapper="regionMouseUpWrapper"
              :editorName="editorName"
            >
              <template v-slot:default="slotProps">
                <draggable
                  :modelValue="slotProps.lines"
                  item-key="id"
                  group="lines"
                  @end="onTextLineDragEnd"
                  :id="slotProps.id"
                  :disabled="!allowDragging"
                  :class="{ 'w-full': true, 'min-h-8': allowDragging }"
                >
                  <template #item="{ element: line, index }">
                    <TextLine
                      :searchTerm="searchTerm"
                      :isMobileSize="isMobileSize"
                      :editorName="editorName"
                      :key="line.id"
                      :text="line.text"
                      :diffHtml="line.diffHtml"
                      :customBefore="slotProps.lines[index - 1]?.custom"
                      :customAfter="slotProps.lines[index + 1]?.custom"
                      :custom="line.custom"
                      :id="line.id"
                      :ref="`textline-${line.id}`"
                      :index="index"
                      :selected="selectedIds.includes(line.id)"
                      :draggable="allowDragging"
                      :isContentEditable="line.id === editableId && isEditable"
                      :isEditable="isEditable"
                      :isSites="isSites"
                      :showTags="showTags && tagsAllowed"
                      :textualTags="textualTags"
                      @updatePopupParams="$emit('updatePopupParams', $event)"
                      @updatePopupPosition="
                        $emit('updatePopupPosition', $event)
                      "
                      @updateAttribute="updateTagAttribute"
                      @hideTags="showTags = false"
                      @showTags="showTags = true"
                      @lineKeydown="handleLineKeydown"
                      @createTag="createTag"
                      @deleteTag="deleteTag"
                      @mouseDown="lineMouseDown"
                      @mouseUp="lineMouseUp"
                      @mouseUpWrapper="lineMouseUpWrapper"
                      @jumpCursorToNextLine="
                        moveLines({ up: false, offset: Infinity })
                      "
                      @newLineOnEnter="addNewLineAndFocus(line.id)"
                    />
                  </template>
                </draggable>
              </template>
            </component>
          </template>
        </draggable>
        <div
          class="w-full flex flex-col gap-4 items-center justify-center mt-10"
          v-else-if="hideOnSites"
        >
          <p>
            {{
              $translate(
                'This page is currently not publicy available.',
                language,
                rsCollection?.locales
              )
            }}
          </p>
        </div>
        <div
          class="w-full flex flex-col gap-4 items-center justify-center mt-10"
          v-else-if="initData?.length === 0 || modelTextData?.length === 0"
        >
          <span v-if="isSites" class="text-2xl text">
            {{
              $translate(
                'No transcription for this page',
                language,
                rsCollection?.locales
              )
            }}
          </span>
          <span v-else class="text-2xl text">
            {{ $t('No transcription for this page') }}
          </span>
          <i v-if="!isSites" class="button-icon mdi mdi-file-eye w-5 h-5"></i>
          <BaseButton
            v-if="!isSites && permissions.models?.recognise"
            icon="file"
            :label="'Get transcription'"
            @click="openModelModal"
          ></BaseButton>
        </div>
        <div class="w-full h-5"></div>
      </Pane>

      <Pane
        v-if="isPgMdEditor"
        min-size="10"
        style="background: white; position: relative"
      >
        <!--  <PageMetadataEditor :metadata="metadata" /> -->
      </Pane>
    </Splitpanes>
  </div>
</template>

<script>
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import {
  insertTextAtCaret,
  saveSelection,
  restoreSelection,
  Cursor,
} from './TextEditorUtils.js'

import scrollIntoView from 'scroll-into-view-if-needed'

import {
  Singleton as ActionHandler,
  ACTION_TYPE,
  SELECTION_TYPE,
} from '../LayoutEditor/components/singletons/actionHandler.js'
import SimpleKeyboard from './SimpleKeyboard.vue'

import { Singleton as Settings } from '../LayoutEditor/components/singletons/settings.js'

import draggable from 'vuedraggable'
import TextLine from './TextLine.vue'
import TextRegion from './TextRegion.vue'
import TableRegion from './TableRegion.vue'
import CursorManager from './CursorManager'
import model from '../Model/model.js'
import AddLineOnEnter from '../Actions/AddLineOnEnter.ts'
import AddLineFromText from '../Actions/AddLineFromText.ts'
import DeleteShapes from '../Actions/DeleteShapes.ts'
import ChangeOrder from '../Actions/ChangeOrder.ts'

/* import PageMetadataEditor from './PageMetadataEditor.vue' */

export default {
  props: {
    isMobileSize: {
      type: Boolean,
    },
    isSites: {
      type: Boolean,
    },
    searchTerm: {
      type: String,
    },
    hideOnSites: {
      type: Boolean,
    },
    compareText: {
      type: Boolean,
    },

    /*     metadata: {
      type: Object,
    }, */
    isFocused: {
      type: Boolean,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
    editorName: {
      type: String,
      default: 'editor1',
    },

    textualTags: {
      type: Array,
    },
    characters: {
      type: Array,
    },
  },
  components: {
    SimpleKeyboard,
    draggable,
    TextLine,
    TextRegion,
    TableRegion,
    /*     PageMetadataEditor,
     */ Splitpanes,
    Pane,
  },
  setup() {
    const permissions = usePermissions()
    return { permissions }
  },
  data() {
    return {
      shiftHandler: {},
      controlPressed: false,
      isKeyboard: Settings.instance().get('isKeyboard'),
      isPgMdEditor: Settings.instance().get('showPgMdEditor'),
      fontSize: Settings.instance().get('fontSize'),
      keyboardSize: Settings.instance().get('keyboardSize'),
      showLineRegion: Settings.instance().get('showLineRegion'),
      textAlignment: Settings.instance().get('textAlignment'),
      dragging: Settings.instance().get('dragging'),
      autoCenterLineNoZoom: Settings.instance().get('autoCenterLineNoZoom'),
      autoCenterLineZoom: Settings.instance().get('autoCenterLineZoom'),
      currentHtml: '',
      editableId: null,
      selectedIds: [],
      lastRange: null,
      selectedTagIds: [],
      showTags: false,
      timeOut: null,
      resizeObserver: null,
      resizeContainer: null,
      modelTextData: null,
      initData: null,
      firstShowTagsSet: false,
    }
  },

  mounted() {
    if (this.isEditable) {
      model.setTextHandler(data => {
        this.modelTextData = data
      })
      Settings.instance().on('update', this.handleSettingsChange)
    }

    ActionHandler.instance(this.editorName).on('selection', 'text', action =>
      this.handleSelection(action)
    )
    this.updateListerners()

    this.resizeContainer = this.$refs['text-editor-wrapper']

    this.resizeObserver = new ResizeObserver(entries => {
      this.requestHandlePaneWidthChange()
    })
    this.resizeObserver.observe(this.resizeContainer)

    setTimeout(() => {
      this.showTags = true
      this.firstShowTagsSet = true
    }, 1000)
  },
  computed: {
    tagsAllowed() {
      return !this.compareText
    },
    editorData() {
      if (this.modelTextData) {
        return this.modelTextData
      }
      if (this.initData) {
        return this.initData
      }
      return []
    },
    allowDragging() {
      return this.isEditable && this.dragging
    },
    allLines() {
      return this.flattenChildren(this.editorData)
    },
    computedFontSize() {
      if (this.isMobileSize) {
        return 0.8
      }
      return this.fontSize
    },
  },

  watch: {
    isFocused() {
      this.updateListerners()
    },
  },
  beforeUnmount() {
    this.removeEventListeners()
    Settings.instance().off('update', this.handleSettingsChange)
    this.resizeObserver?.unobserve(this.resizeContainer)
  },
  methods: {
    disableLayoutMode() {
      Settings.instance().set({
        dragging: false,
      })
    },
    addNewLineToEmptyParent(parentId) {
      const params = { parentId }
      const pageJsonCopy = model.getPageJsonCopy()
      const addLineOnEnter = new AddLineFromText(pageJsonCopy)
      addLineOnEnter.apply(params)
      const newLineId = addLineOnEnter.getNewLineId()
      setTimeout(() => {
        this.clear()
        this.select({
          type: SELECTION_TYPE.ADD,
          ids: [newLineId],
        })
        const line = this.getTextlineComponentById(newLineId)
        this.editableId = newLineId
        line.setCursor(0)
      }, 10)
      ActionHandler.instance(this.editorName).newAction({
        type: ACTION_TYPE.PATCH,
        ops: addLineOnEnter.getPatch(),
        reversedOps: addLineOnEnter.getReversedPatch(),
      })
    },
    addNewLineAndFocus(lineId) {
      const params = { lineId }
      const pageJsonCopy = model.getPageJsonCopy()
      const addLineOnEnter = new AddLineOnEnter(pageJsonCopy)
      addLineOnEnter.apply(params)
      const newLineId = addLineOnEnter.getNewLineId()
      setTimeout(() => {
        this.clear()
        this.select({
          type: SELECTION_TYPE.ADD,
          ids: [newLineId],
        })
        const line = this.getTextlineComponentById(newLineId)
        this.editableId = newLineId
        line.setCursor(0)
      }, 10)
      ActionHandler.instance(this.editorName).newAction({
        type: ACTION_TYPE.PATCH,
        ops: addLineOnEnter.getPatch(),
        reversedOps: addLineOnEnter.getReversedPatch(),
      })
    },
    updateEditorVersion(version) {
      // console.log('Update version text editor', version)
      this.modelTextData = null
      this.initData = version?.textData
    },
    requestHandlePaneWidthChange() {
      clearTimeout(this.timeOut)
      this.showTags = false
      if (this.firstShowTagsSet) {
        this.timeOut = setTimeout(() => {
          this.showTags = true
        }, 200)
      }
    },
    lineMouseDown() {
      this.editableId = null
    },
    lineMouseUp(id) {
      if (!this.allowDragging) {
        this.editableId = id
      }
    },
    regionMouseUpWrapper(id) {
      this.selectNew(id)
    },
    lineMouseUpWrapper(id) {
      this.selectNew(id)
      ActionHandler.instance(this.editorName).select({
        type: SELECTION_TYPE.CENTER,
        id: id,
        centerAlways: this.autoCenterLineNoZoom,
        centerAlwaysAndZoom: this.autoCenterLineZoom,
      })
    },
    selectNew(id) {
      let newIds = [id]
      if (
        !this.allowDragging ||
        (!this.controlPressed && !this.shiftHandler.baseId)
      ) {
        ActionHandler.instance(this.editorName).select({
          type: SELECTION_TYPE.CLEAR,
        })
      }
      if (this.shiftHandler.addedLast) {
        ActionHandler.instance(this.editorName).select({
          type: SELECTION_TYPE.REMOVE,
          ids: this.shiftHandler.addedLast,
        })
      }
      const multiSelectionIds = this.getMultiSelectionIds(id)
      if (multiSelectionIds) {
        newIds = multiSelectionIds
      }
      if (this.controlPressed && this.selectedIds.includes(newIds[0])) {
        ActionHandler.instance(this.editorName).select({
          type: SELECTION_TYPE.REMOVE,
          ids: newIds,
        })
        return
      }
      ActionHandler.instance(this.editorName).select({
        type: SELECTION_TYPE.ADD,
        ids: newIds,
      })
    },
    getMultiSelectionIds(id) {
      if (!this.allowDragging) return
      const ids = model.getNewIdsWithShiftModel(this.shiftHandler.baseId, id)
      if (ids == null) return
      this.shiftHandler.addedLast = ids
      return ids
    },
    getIdsInBetween(startId, endId) {
      const allLineIds = this.allLines.map(l => l.id)
      const indexStart = allLineIds.indexOf(startId)
      const indexEnd = allLineIds.indexOf(endId)
      const linesInBetween = allLineIds.filter(
        (_, index) => index > indexStart && index < indexEnd
      )
      return linesInBetween
    },
    getTextlineComponentById(id) {
      return this.$refs[`textline-${id}`]
    },
    combinedDragIds(draggedId, selectedIds) {
      if (!selectedIds.includes(draggedId)) {
        return [draggedId]
      }
      const areConsecutive = model.areConsecutiveShapesModel(selectedIds)
      if (!areConsecutive) {
        return [draggedId]
      }
      return [...selectedIds]
    },
    onRegionDragEnd(event) {
      const draggedIds = this.combinedDragIds(event.item.id, this.selectedIds)
      const change = new ChangeOrder()
      const params = {
        newParentId: null,
        newIndex: event.newIndex,
        draggedIds,
      }
      change.apply(params)
      //change.logXml()
      change.newAction()
    },
    onTextLineDragEnd(event) {
      const draggedIds = this.combinedDragIds(event.item.id, this.selectedIds)
      const change = new ChangeOrder()
      const params = {
        newParentId: event.to.id,
        newIndex: event.newIndex,
        draggedIds,
      }

      change.apply(params)
      //change.logXml()
      change.newAction()
    },
    onLineDragEnd(event) {},

    updateListerners() {
      if (this.isFocused) {
        console.log('Texteditor added listeners')
        this.removeEventListeners()
        this.addEventListeners()
      } else {
        console.log('Texteditor removed listeners')
        this.blur()
        this.removeEventListeners()
      }
    },

    handleSettingsChange({
      textAlignment,
      isKeyboard,
      showPgMdEditor,
      fontSize,
      keyboardSize,
      showLineRegion,
      dragging,
      autoCenterLineNoZoom,
      autoCenterLineZoom,
    }) {
      this.dragging = dragging
      this.autoCenterLineNoZoom = autoCenterLineNoZoom
      this.autoCenterLineZoom = autoCenterLineZoom
      this.textAlignment = textAlignment
      this.fontSize = fontSize
      this.isKeyboard = isKeyboard
      this.isPgMdEditor = showPgMdEditor
      this.keyboardSize = keyboardSize
      this.showLineRegion = showLineRegion
    },
    buttonClick(type) {
      switch (type) {
        case 'showtags':
          this.showTags = true
          break
        case 'hidetags':
          this.showTags = false
          break
      }
    },

    editorMouseDown() {
      this.$emit('textMouseDown')
    },
    scrolled() {
      this.$emit(
        'scrolled',
        this.$refs.textContainer?.targetDomElement?.scrollTop
      )
    },
    keydownHandler(e) {
      console.log(`%cKey down (Text): ${e.key}`, 'color: aqua')
      this.$emit('keyDown', e)
      switch (e.key) {
        case 'Meta':
        case 'Control':
          this.controlPressed = true
          break
        case 'Shift':
          this.shiftHandler.baseId = this.selectedIds.at(-1)
          break
        case 'Escape': {
          this.$emit('updatePopupParams', { tagIdentifier: null, state: null })
          const s = window.getSelection()
          const selectedRange = s.rangeCount > 0 && s.getRangeAt(0)
          if (selectedRange) {
            setTimeout(() => {
              window.getSelection().removeAllRanges()
              window.getSelection().addRange(selectedRange)
            }, 0)
          }
          break
        }
        case 'a':
          if (e.ctrlKey || e.metaKey) {
            e.preventDefault()
            this.clear()
            this.selectTextInLines(this.allLines)
          }
          break
        case 's':
          if (e.ctrlKey || e.metaKey) {
            e.preventDefault()
            this.blur()
            this.$emit('save', 'IN_PROGRESS')
          }
          break
        case 'z':
          if (e.ctrlKey || e.metaKey) {
            e.preventDefault()
            this.blur()
            setTimeout(() => {
              if (e.shiftKey) {
                ActionHandler.instance(this.editorName).redo()
              } else {
                ActionHandler.instance(this.editorName).undo()
              }
            }, 0)
          }
          break
        case 'y':
          if (e.ctrlKey || e.metaKey) {
            e.preventDefault()
            this.blur()
            setTimeout(() => {
              ActionHandler.instance(this.editorName).redo()
            }, 0)
          }
          break

        case 'Backspace':
          if (Settings.instance().get('danWriting')) {
            const s = window.getSelection()

            const lineDiv = this.getLineDivFromNode(s.anchorNode)
            const offset = Cursor.getCurrentCursorPosition(lineDiv)

            if (
              lineDiv.innerText.replace(/\s+/g, '').length === 0 &&
              offset === 0 &&
              s.isCollapsed
            ) {
              console.log(lineDiv.id)
              const lineId = lineDiv.id.replace('text-', '')
              const params = { shapeIds: [lineId] }
              const deleteShape = new DeleteShapes()
              deleteShape.apply(params)
              this.moveLines({ up: true, offset: Infinity })
              deleteShape.newAction()
            }
            break
          }
        case 'Delete':
          if (this.allowDragging) {
            const params = { shapeIds: this.selectedIds }
            const deleteShape = new DeleteShapes()
            deleteShape.apply(params)
            deleteShape.newAction()
            break
          }
          const selection = CursorManager.getSelection()
          if (selection == null) break
          const { startId, startIndex, endId, endIndex } = selection
          const lineIdStart = startId.replace('text-', '')
          const lineIdEnd = endId.replace('text-', '')

          if (lineIdStart === lineIdEnd) break

          let action
          const actions = []
          const allIds = [
            lineIdStart,
            ...this.getIdsInBetween(lineIdStart, lineIdEnd),
            lineIdEnd,
          ]
          allIds.forEach(id => {
            const lineComponent = this.getTextlineComponentById(id)
            if (id == lineIdStart) {
              actions.push(
                lineComponent.getDeleteTextAction(startIndex, Infinity, {
                  all: false,
                }),
                lineComponent.getDeleteTagsAction(startIndex, Infinity, {
                  all: false,
                })
              )
              return
            }
            if (id == lineIdEnd) {
              actions.push(
                lineComponent.getDeleteTextAction(0, endIndex, {
                  all: false,
                }),
                lineComponent.getDeleteTagsAction(0, endIndex, {
                  all: false,
                })
              )
              return
            }
            actions.push(
              lineComponent.getDeleteTextAction(null, null, {
                all: true,
              }),
              lineComponent.getDeleteTagsAction(null, null, {
                all: true,
              })
            )
          })
          action = {
            type: ACTION_TYPE.MULTI_ACTION,
            actions,
          }
          ActionHandler.instance(this.editorName).newAction(action)
          CursorManager.clearSelection()
          break
      }
    },

    keyupHandler(e) {
      switch (e.key) {
        case 'Meta':
        case 'Control':
          this.controlPressed = false
          break
        case 'Shift':
          this.shiftHandler = {}
          break
      }
    },

    flattenChildren(arr) {
      return arr.reduce((acc, r) => {
        if (r.children && r.children.length > 0) {
          return [...acc, ...this.flattenChildren(r.children)]
        } else {
          return [...acc, r]
        }
      }, [])
    },
    moveLines({ up, offset }) {
      const findParentWithChildren = (parentList, index, direction) => {
        const newParent = parentList[index]
        if (newParent == null) {
          return
        }
        if (newParent.children?.length > 0) {
          return newParent
        }
        return findParentWithChildren(parentList, index + direction, direction)
      }
      const getNextLine = (lineId, parentList, direction) => {
        let newLine
        const parent = parentList.find(r =>
          r.children.some(c => c.id === lineId)
        )
        const parentIndex = parentList.findIndex(r => r.id === parent.id)

        // Find the index of the line within the region
        const lineIndex = parent.children.findIndex(line => line.id === lineId)
        const nextInParent = parent.children[lineIndex + direction]
        if (nextInParent) {
          newLine = nextInParent
        }
        if (newLine == null) {
          const nextParentWithChildren = findParentWithChildren(
            parentList,
            parentIndex + direction,
            direction
          )
          if (nextParentWithChildren) {
            newLine = nextParentWithChildren.children.at(
              direction === 1 ? 0 : -1
            )
          }
        }
        if (newLine == null) return

        return newLine
      }

      const direction = up ? -1 : 1

      const selectedId = this.selectedIds[0]

      const cellsAndTextRegions = this.editorData
        .map(r => (r.type === 'Table' ? r.children : r))
        .flat()

      const newLine = getNextLine(selectedId, cellsAndTextRegions, direction)

      if (newLine == null) return

      const newId = newLine?.id

      this.clear()

      // Set center or selection depending on option
      this.select({
        type: SELECTION_TYPE.ADD,
        ids: [newId],
      })
      this.select({
        type: SELECTION_TYPE.CENTER,
        id: newId,
        centerAlways: this.autoCenterLineNoZoom,
        centerAlwaysAndZoom: this.autoCenterLineZoom,
      })

      let newSelected = this.selectedIds[0]
      const line = this.getTextlineComponentById(newSelected)
      this.editableId = newSelected
      line.setCursor(offset)
    },
    openModelModal() {
      this.$emit('openModelModal')
    },
    getLineDivFromNode(node) {
      if (node == null) return
      if (node.tagName?.toUpperCase() !== 'DIV') {
        return this.getLineDivFromNode(node.parentElement)
      }
      return node
    },
    handleLineKeydown(event) {
      const noControl = !event.altKey && !event.metaKey
      if (event.key === 'ArrowLeft' && noControl) {
        const s = window.getSelection()

        const lineDiv = this.getLineDivFromNode(s.anchorNode)
        let offset = Cursor.getCurrentCursorPosition(lineDiv)

        if (offset === 0) {
          this.moveLines({ up: true, offset: Infinity })
        }
        return
      }
      if (event.key === 'ArrowRight' && noControl) {
        const s = window.getSelection()

        const lineDiv = this.getLineDivFromNode(s.anchorNode)
        let offset = Cursor.getCurrentCursorPosition(lineDiv)

        if (lineDiv.innerText.length === offset) {
          this.moveLines({ offset: 0 })
        }
        return
      }
      if (event.key === 'ArrowUp' && noControl) {
        event.preventDefault()
        const s = window.getSelection()
        const lineDiv = this.getLineDivFromNode(s.anchorNode)
        let offset = Cursor.getCurrentCursorPosition(lineDiv)
        this.moveLines({ up: true, offset })
        return
      }
      if (event.key === 'ArrowDown' && noControl) {
        event.preventDefault()
        const s = window.getSelection()
        const lineDiv = this.getLineDivFromNode(s.anchorNode)
        let offset = Cursor.getCurrentCursorPosition(lineDiv)
        this.moveLines({ offset })
        return
      }
      if (event.key === 'Enter') {
        event.preventDefault()
        if (event.ctrlKey || event.metaKey) {
          const selection = CursorManager.getSelection()
          if (
            selection == null ||
            selection.endId != selection.startId ||
            selection.endIndex != selection.startIndex
          ) {
            return
          }

          const lineId = selection.startId.replace('text-', '')
          const parent = this.getLineTextRegionOrCell(lineId)
          const index = parent.children.findIndex(c => c.id === lineId)
          const currentLine = parent.children[index]
          const nextLine = parent.children[index + 1]
          if (nextLine == null) return
          const splitTextAtIndex = (text, index) => {
            let part1 = text.slice(0, index)
            let part2 = text.slice(index)
            return [part1, part2]
          }
          const textFragments = splitTextAtIndex(
            currentLine.text,
            selection.startIndex
          )

          ActionHandler.instance(this.editorName).newAction({
            type: ACTION_TYPE.MULTI_ACTION,
            actions: [
              {
                type: ACTION_TYPE.SET_TEXT,
                shape_id: lineId,
                old_text: currentLine.text,
                new_text: textFragments[0],
              },
              {
                type: ACTION_TYPE.SET_TEXT,
                shape_id: nextLine.id,
                old_text: nextLine.text,
                new_text: textFragments[1] + nextLine.text,
              },
            ],
          })

          return
        }

        return
      }
      if (event.key === 'Tab') {
        event.preventDefault()

        // Get the selection object
        var selection = window.getSelection()
        var range = selection.getRangeAt(0)

        // Create a text node with a tab character
        var tabNode = document.createTextNode('\t')

        // Insert the tab character at the current cursor position
        range.insertNode(tabNode)

        // Move the cursor to after the inserted tab character
        range.setStartAfter(tabNode)
        range.setEndAfter(tabNode)
        selection.removeAllRanges()
        selection.addRange(range)
        return
      }
    },

    getLineHeightInPixel() {
      return this.computedFontSize * 1.8 * 16
    },

    paneResized(event) {
      if (event[0] && event[1] && this.isEditable) {
        Settings.instance().set({
          keyboardSize: event[0].size,
        })
      }
    },
    selectTextInLines(lines) {
      const selection = window.getSelection()
      selection.removeAllRanges()

      if (lines.length === 0) return

      const firstLineElement = document.getElementById(`text-${lines[0].id}`)
      const lastLineElement = document.getElementById(
        `text-${lines[lines.length - 1].id}`
      )

      if (firstLineElement && lastLineElement) {
        const range = document.createRange()
        range.setStart(firstLineElement, 0)
        range.setEnd(lastLineElement, lastLineElement.childNodes.length)
        selection.addRange(range)
      }
    },
    getLineRegion(id) {
      return this.editorData.find(r =>
        r.children.some(c => c.id === id || c.children?.some(c => c.id === id))
      )
    },
    getLineTextRegionOrCell(id) {
      return (
        this.editorData.find(r => r.children.some(c => c.id === id)) ||
        this.getCells().find(c => c.children.some(c => c.id === id))
      )
    },
    getCells() {
      return this.editorData
        .map(r => r.children.filter(c => c.type === 'Cell'))
        .flat()
    },

    findUpperContinuedComponentTagPairs(lineId, selector) {
      const component = this.getTextlineComponentById(lineId)
      const tag = component?.getContinuedTag(selector)
      const upperLineNeighbor = this.getUpperLineNeighbor(lineId)
      if (tag) {
        if (upperLineNeighbor) {
          return [
            { component, tag },
            ...this.findUpperContinuedComponentTagPairs(
              upperLineNeighbor,
              selector
            ),
          ]
        }
        return [{ component, tag }]
      }
      return []
    },

    findLowerContinuedComponentTagPairs(lineId, selector) {
      const component = this.getTextlineComponentById(lineId)
      const tag = component?.getContinuedTag(selector)
      const lowerLineNeighbor = this.getLowerLineNeighbor(lineId)
      if (tag) {
        if (lowerLineNeighbor) {
          return [
            { component, tag },
            ...this.findLowerContinuedComponentTagPairs(
              lowerLineNeighbor,
              selector
            ),
          ]
        }
        return [{ component, tag }]
      }
      return []
    },

    getUpperLineNeighbor(lineId) {
      const parent = this.getLineRegion(lineId)
      const index = parent?.children.findIndex(c => c.id === lineId)
      const neighbor = parent?.children[index - 1]
      return neighbor && neighbor.id
    },

    getLowerLineNeighbor(lineId) {
      const parent = this.getLineRegion(lineId)
      const index = parent?.children.findIndex(c => c.id === lineId)
      const neighbor = parent?.children[index + 1]
      return neighbor && neighbor.id
    },

    getContinuedComponentTagPairs(lineId, selector) {
      const [, ...upper] = this.findUpperContinuedComponentTagPairs(
        lineId,
        selector
      )
      const [, ...lower] = this.findLowerContinuedComponentTagPairs(
        lineId,
        selector
      )
      return [...upper, ...lower]
    },
    deleteTag(lineId, tag) {
      let actions = []

      actions.push(
        this.getTextlineComponentById(lineId).getDeleteTagAction(tag)
      )

      this.getContinuedComponentTagPairs(lineId, tag.selector).forEach(
        ({ component, tag }) => {
          actions.push(component.getDeleteTagAction(tag))
        }
      )

      const action = {
        type: ACTION_TYPE.MULTI_ACTION,
        actions,
      }
      ActionHandler.instance(this.editorName).newAction(action)
      this.selectedTagIds = []
    },
    updateTagAttribute(lineId, tag, attributeName, value, remove) {
      let actions = []
      actions.push(
        this.getTextlineComponentById(lineId).getUpdateTagAttributeAction(
          tag,
          attributeName,
          value,
          remove
        )
      )
      this.getContinuedComponentTagPairs(lineId, tag.selector).forEach(
        ({ component, tag }) => {
          actions.push(
            component.getUpdateTagAttributeAction(
              tag,
              attributeName,
              value,
              remove
            )
          )
        }
      )
      const action = {
        type: ACTION_TYPE.MULTI_ACTION,
        actions,
      }
      ActionHandler.instance(this.editorName).newAction(action)

      this.$nextTick(() => {
        this.$emit('updatePopupPosition')
      })
    },

    createTag({
      type,
      textStyle,
      selection: { startId, startIndex, endId, endIndex },
    }) {
      const lineIdStart = startId.replace('text-', '')
      const lineIdEnd = endId.replace('text-', '')
      if (this.getTextlineComponentById(lineIdStart) == null) return
      if (this.getTextlineComponentById(lineIdEnd) == null) return

      let action

      if (lineIdStart === lineIdEnd) {
        const lineComponent = this.getTextlineComponentById(lineIdStart)
        action = lineComponent.getCreateTagAction(
          type,
          textStyle,
          startIndex,
          endIndex,
          false
        )
      }
      if (lineIdStart !== lineIdEnd) {
        const actions = []
        const allIds = [
          lineIdStart,
          ...this.getIdsInBetween(lineIdStart, lineIdEnd),
          lineIdEnd,
        ]
        allIds.forEach(id => {
          const lineComponent = this.getTextlineComponentById(id)
          const start = id === lineIdStart ? startIndex : 0
          const end =
            id === lineIdEnd ? endIndex : lineComponent.$props.text.length
          actions.push(
            lineComponent.getCreateTagAction(type, textStyle, start, end, true)
          )
        })
        action = {
          type: ACTION_TYPE.MULTI_ACTION,
          actions,
        }
      }
      ActionHandler.instance(this.editorName).newAction(action)

      CursorManager.setSelection(
        {
          startId: lineIdStart,
          endId: lineIdEnd,
          startIndex,
          endIndex,
        },
        { delay: 1 }
      )
    },
    select(selection) {
      ActionHandler.instance(this.editorName).select(selection)
    },
    clear() {
      const selection = {
        type: SELECTION_TYPE.CLEAR,
      }
      ActionHandler.instance(this.editorName).select(selection)
    },
    handleSelection(selection) {
      switch (selection.type) {
        case SELECTION_TYPE.ADD:
          selection.ids.forEach(id => {
            if (this.selectedIds.includes(id)) return
            this.selectedIds.push(id)
          })
          const element = document.getElementById(selection.ids[0])
          if (element != null) {
            setTimeout(() => {
              // Scroll the element into view
              //element.scrollIntoViewIfNeeded({ behavior: 'smooth' })
              const container = document.getElementById('textContainer')
              scrollIntoView(element, {
                scrollMode: 'if-needed',
                block: 'center',
                inline: 'center',
                behavior: 'smooth',
                boundary: container,
              })
            }, 0)
          }

          break
        case SELECTION_TYPE.REMOVE:
          this.selectedIds = this.selectedIds.filter(
            id => !selection.ids.includes(id)
          )
          break
        case SELECTION_TYPE.CLEAR:
          this.selectedIds = []
          break
      }
    },
    keyboardMouseDown(e) {
      //e.preventDefault()
    },
    keyboardMouseUp() {
      /*  if (this.lastRange == null) return

      const { endContainer, endOffset } = this.lastRange
      let range = new Range()

      range.setStart(endContainer, endOffset)
      range.setEnd(endContainer, endOffset)
      restoreSelection(range) */
    },

    hasParentWithId(element, parentId) {
      let parent = element.parentNode
      while (parent) {
        if (parent.id === parentId) {
          return true
        }
        parent = parent.parentNode
      }
      return false
    },

    onKeyPress(value) {
      this.lastRange = saveSelection()
      if (
        this.hasParentWithId(this.lastRange.startContainer, 'textContainer')
      ) {
        insertTextAtCaret(value)
      }
    },

    blur() {
      if (
        document.activeElement != document.body &&
        document.activeElement?.id.includes('text-')
      ) {
        document.activeElement.blur()
      }
    },
    /*  insertTextAtCursor(text) {
      const selection = window.getSelection()
      const activeElement = document.activeElement

      if (
        activeElement &&
        (activeElement.tagName === 'INPUT' ||
          activeElement.tagName === 'TEXTAREA')
      ) {
        const start = activeElement.selectionStart
        const end = activeElement.selectionEnd
        const value = activeElement.value

        activeElement.value = value.slice(0, start) + text + value.slice(end)
        activeElement.selectionStart = activeElement.selectionEnd =
          start + text.length

        this.blur()
      } else if (selection.rangeCount > 0 && activeElement.isContentEditable) {
        const range = selection.getRangeAt(0)
        const newNode = document.createTextNode(text)

        range.insertNode(newNode)
        range.setStartAfter(newNode)
      }
    }, */
    handleMultiLinePaste(event, textFragments) {
      event.preventDefault()
      const lineDiv = this.getLineDivFromNode(event.target)
      const lineId = lineDiv.id.replace('text-', '')
      const parent = this.getLineTextRegionOrCell(lineId)
      const index = parent?.children.findIndex(c => c.id === lineId)
      const textActions = textFragments
        .map((l, fragmentIndex) => {
          const lineId = parent?.children[index + fragmentIndex]?.id
          let newText = l
          if (lineId == null) return
          const nextLineId = parent?.children[index + fragmentIndex + 1]?.id
          if (!nextLineId) {
            newText = textFragments.slice(fragmentIndex).join(' ')
          }
          const lineComponent = this.getTextlineComponentById(lineId)
          return {
            type: ACTION_TYPE.SET_TEXT,
            shape_id: lineComponent.id,
            old_text: lineComponent.text,
            new_text: lineComponent.text + newText,
          }
        })
        .filter(Boolean)
      ActionHandler.instance(this.editorName).newAction({
        type: ACTION_TYPE.MULTI_ACTION,
        actions: textActions,
      })
    },
    handlePaste(e) {
      // cancel paste
      //e.preventDefault()
      let text = (e.originalEvent || e).clipboardData.getData('text/plain')
      let newArray = text.split('\n').filter(item => item.trim() !== '')
      if (newArray.length > 1) {
        this.handleMultiLinePaste(e, newArray)
      }
    },
    addEventListeners() {
      document.addEventListener('paste', this.handlePaste)
      document.addEventListener('keydown', this.keydownHandler)
      document.addEventListener('keyup', this.keyupHandler)
    },
    removeEventListeners() {
      document.removeEventListener('paste', this.handlePaste)
      document.removeEventListener('keydown', this.keydownHandler)
      document.removeEventListener('keyup', this.keyupHandler)
    },
  },
}
</script>

<style>
input,
textarea,
[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}

[contenteditable]:focus {
  outline: 0px solid transparent;
}

.arrow {
  position: absolute;
  top: -20px !important;
  left: 12px;
  width: 28px;
  height: 12px;
  z-index: 2;
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  top: 5px;
  left: 5px;
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-backface-visibility: hidden;
  background-color: #fff;
  -moz-box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.2);
}

.tools {
  height: 30px;
  padding: 5px 10px;
  background: #333;
  border-radius: 3px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  transition: 0.2s all;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tools:after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -5px;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #333;
}
.line-number-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 5px; /* Adjust this value if needed */
  min-width: 30px; /* Adjust this value based on how many digits the line number has */
}
</style>

<style lang="scss" scoped>
.is-fixed-top {
  overflow: hidden;
  background-color: white;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 20;
  margin-bottom: 1rem;
}

#textContainer > :last-child {
  margin-bottom: 30vh; /* Adjust the margin value as needed */
}
</style>
