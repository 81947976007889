<template>
  <div class="app-modal__box">
    <div class="app-modal__header--process">
      <h3 class="app-title">
        {{ $t('Select Wikidata ID for tag ') + term }}
      </h3>
    </div>
    <div class="app-modal__body--process">
      <div class="container">
        <div class="media-content" v-if="selected">
          <b> {{ $t('Selected ') }} : </b>
          {{ selected?.label }}
          <br />
          <small>{{ selected?.description }}</small> -
          <small>{{ selected?.id }}</small>
          <br />
          <small>
            <a :href="selected?.concepturi" target="_blank">{{
              selected?.concepturi
            }}</a></small
          >
        </div>
        <div v-else>
          {{ $t('No Wikidata entry found try another query than: ')
          }}<b>{{ term }}</b>
        </div>
      </div>
      <AutoComplete
        :initTerm="term"
        :data="data"
        :placeholder="'e.g.' + term"
        field="label"
        :loading="isFetching"
        @typing="getAsyncData"
        :open-on-focus="true"
        @selectOption="option => (selected = option)"
      >
        <template v-slot:default="props">
          <div class="media">
            <div class="media-content">
              {{ props.option.label }}
              <br />
              <small>{{ props.option.description }}</small> -
              <small>{{ props.option.id }}</small>
              <br />
              <small>{{ props.option.concepturi }}</small>
            </div>
          </div>
        </template>
      </AutoComplete>
    </div>
    <div class="flex flex-row justify-between">
      <BaseButton
        :label="$t('Close')"
        :type="'secondary'"
        @click="closeWikiModal()"
      ></BaseButton>
      <BaseButton
        v-if="selected"
        :label="$t('Select ') + ' ' + selected?.label + ' - ' + selected?.id"
        :icon="'chevron-right'"
        @click="selectEntry()"
        icon-right
      ></BaseButton>
    </div>
  </div>
</template>

<script>
import AutoComplete from '~/components/TailwindComponents/AutoComplete.vue'

import debounce from 'lodash/debounce'
export default {
  components: {
    AutoComplete,
  },
  data() {
    return {
      suggestions: [],
      data: [],
      selected: null,
      isFetching: false,
    }
  },
  props: {
    term: {
      type: String,
      default: '',
    },
    currentId: {
      type: String,
      default: '',
    },
  },
  setup() {
    const liteApi = useLite()
    return {
      liteApi,
    }
  },
  async mounted() {
    this.liteApi
      .fetchWikidataIDs({ term: this.term })
      .then(suggestions => {
        this.data = suggestions
        if (this.data.length > 0) {
          this.selected = this.data[0]
        } else {
          this.selected = null
        }
      })
      .catch(error => {
        this.data = []
        throw error
      })
  },
  methods: {
    selectEntry() {
      this.$emit('save', this.selected)
      this.closeWikiModal()
    },
    closeWikiModal() {
      this.$bus.emit('close-modal')
    },
    getAsyncData: debounce(function (name) {
      if (!name.length) {
        this.data = []
        return
      }
      this.isFetching = true
      this.liteApi
        .fetchWikidataIDs({ term: name })
        .then(suggestions => {
          this.data = suggestions
        })
        .catch(error => {
          this.data = []
          throw error
        })
        .finally(() => {
          this.isFetching = false
        })
    }, 500),
  },
}
</script>
