<template>
  <div data-testid="dropdown">
    <div>
      <label v-if="label" class="app-select__label">{{ $t(label) }}</label>
    </div>
    <div
      class="app-card__dropdown w-full bg-[#FFFFFF] text-[#193060]"
      :class="[
        { border: block },
        { 'rounded-md': block },
        { 'text-secondaryG-700': !block },
      ]"
    >
      <div
        tabindex="0"
        class="flex rounded-md bg-[#FFFFFF] w-full cursor-pointer px-2"
        :class="[
          { 'py-2': block && !small },
          { 'py-1.5': !block || small },
          { 'px-4': !block },
        ]"
        :style="`width: ${width}`"
      >
        <span v-if="isSelectedOptionIndexDefault">{{ options[selectedOptionIndexDefault].name }}</span>
        <span v-if="hasOptions && !isSelectedOptionIndexDefault">{{ options[selectedOptionIndex].name }}</span>
        <span class="grow"></span><i class="mdi mdi-chevron-down"></i>
      </div>
      <ul tabindex="0" class="p-0 w-full dropdown-content top-12 z-50">
        <li
          v-for="(item, index) in options"
          :key="index"
          :class="['w-full p-2 hover:bg-[#E8EBF1] cursor-pointer']"
          @click="statusChange(index)"
        >
          <label :for="item.for" :class="'w-full cursor-pointer'">
            <span class="cursor-pointer">{{ $t(`${item.name}`) }}</span>
          </label>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    block: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedOptionIndex: 0,
      selectedOptionIndexDefault: 0,
      isSelectedOptionIndexDefault: false,
      hasOptions: false,
    }
  },
  mounted() {
    this.setValue()
    this.hasOptions = this.options.length > 0
  },
  methods: {
    statusChange(index) {
      this.selectedOptionIndex = index
      document.activeElement.blur()
      this.$emit('select', this.options[index].value)
    },
    setValue() {
      if ( this.options === undefined || this.options.length === 0) {
        return
      } 
      if (this.value === '' || this.value === undefined || this.value === null) {
        return
      } else {
        this.selectedOptionIndexDefault = this.options.findIndex(
          (option) => option.value === this.value
        )
        if (this.selectedOptionIndexDefault > -1) {
          this.isSelectedOptionIndexDefault = true
        } 
      }
    },
  },
}
</script>

<style></style>
