import DiffMatchPatch from 'diff-match-patch'

export function getNewPopupPosition(rect, combinedWrapper, currentLineHeight) {
  const popupEl = document.querySelector('#popup')
  const popupWidth = popupEl.clientWidth
  const popupHeight = popupEl.clientHeight

  const definedMargin = 8

  const selectionX = rect.x - combinedWrapper.x
  const selectionY = rect.y - combinedWrapper.y
  const selectionMidX = selectionX + rect.width / 2

  let popupX = selectionMidX - popupWidth / 2
  let popupY = selectionY + rect.height + definedMargin

  // check if popup is outside of the screen (bottom)
  if (popupY + popupHeight > combinedWrapper.height) {
    popupY = popupY - popupHeight - definedMargin
    popupX = popupX - popupWidth / 2 - definedMargin - rect.width / 2
    // check if popup is outside of the screen (left)
    if (popupX < 0) {
      popupX = 0
      popupY = popupY - currentLineHeight
    }
  }
  // check if popup is outside of the screen (right)
  else if (popupX + popupWidth > combinedWrapper.width) {
    //20 is some padding so that the popup is not directly at the edge
    popupX = combinedWrapper.width - popupWidth - 20
  } else {
    // check if popup is outside of the screen (left)
    if (popupX < 0) {
      popupX = 0
    }
  }
  return { x: popupX, y: popupY }
}

function getDiff([line1, line2], dmp) {
  return dmp.diff_main(line2.text || '', line1.text || '')
}

function getDiffCount(diff) {
  return diff.filter(([op]) => op !== 0).length
}

function getLineWithDiff(diff, line) {
  const diffHtml = diff
    .map(([op, data]) => {
      if (op === 1) return `<span class="diff-added">${data}</span>` // Added text (green)
      if (op === -1) return `<span class="diff-removed">${data}</span>` // Removed text (red)
      return data // Unchanged text (default)
    })
    .join('')
  return {
    ...line,
    diffHtml,
  }
}

export function getDiffTextData(textFrom, textTo) {
  //const currentTextData = model.getTextDataFromModel()
  let diffCount = 0
  const dmp = new DiffMatchPatch()

  let versionFROM = cloneObj(textFrom)
  let versionTO = cloneObj(textTo)
  console.log('versionFROM', versionFROM)
  console.log('versionTO', versionTO)

  const regionsFROM = versionFROM
  const regionsTO = versionTO

  const previewTextDataWithDiffs = regionsFROM.map((regionTO, index) => {
    const regionFROM = regionsTO[index]
    if (regionFROM == null) return { ...regionTO }
    return {
      ...regionTO,
      children: regionTO.children.map((childFROM, childIndex) => {
        const cellsFROM = childFROM.children
        if (cellsFROM) {
          return {
            ...childFROM,
            children: cellsFROM.map((lineFROM, cellIndex) => {
              const lineTO =
                regionFROM.children?.[childIndex]?.children?.[cellIndex]
              if (lineTO == null) return { ...lineFROM }
              const diff = getDiff([lineTO, lineFROM], dmp)
              const newCount = getDiffCount(diff)
              diffCount += newCount
              return getLineWithDiff(diff, lineFROM)
            }),
          }
        }
        const lineFROM = childFROM
        const lineTO = regionFROM.children?.[childIndex]
        if (lineTO == null) return { ...lineFROM }
        const diff = getDiff([lineTO, lineFROM], dmp)
        const newCount = getDiffCount(diff)
        diffCount += newCount
        return getLineWithDiff(diff, lineFROM)
      }),
    }
  })
  return {
    newTextData: previewTextDataWithDiffs,
    diffCount,
  }
}
