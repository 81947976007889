<template>
  <!-- this is a major change, changing it back to relative is fine too but then it must be absolute when freeze -->
  <div
    :ref="`${editorName}-box`"
    :id="`${editorName}-box`"
    class="absolute left-0 top-0 bottom-0 right-0"
    :style="{ width: freezeWidth ? widthToFreeze + 'px' : null }"
  >
    <div
      :id="`${editorName}-osd`"
      ref="osd"
      class="is-relative"
      :class="{
        'bg-[#F3F3F3]': true,
      }"
    ></div>

    <div
      v-if="showSpinner"
      class="absolute left-0 top-0 bottom-0 right-0 bg-[#F3F3F3] flex items-center justify-center"
    >
      <div class="w-20 h-20 spinner"></div>
    </div>
  </div>
</template>

<script>
/* import ShowEditorInfo from '~/components/Modals/ShowEditorGuide.vue' */
/* import ShowEditorWarning from '~/components/Modals/ShowEditorWarning.vue' */
import Editor from './editor.js'
import {
  Singleton as ActionHandler,
  ACTION_TYPE,
  SELECTION_TYPE,
} from './singletons/actionHandler.js'
import { Singleton as IdHandler } from './singletons/IdHandler.js'
import { Singleton as Settings } from './singletons/settings.js'
import { Singleton as MyFabric } from './myFabric.js'
import { OpenSeadragon, fabric } from 'openseadragon-fabricjs-overlay-jk'
import { initOverlay } from '../openseadragon-fabricjs-overlay.js'
import ShowEditorInfo from '~/components/Modals/ShowEditorGuide.vue'
import {
  setScaleFactor,
  getRotationAngle,
  setRotationAngle,
} from '../globals.js'
import { pointRotate } from 'geometric'
import model from '../../Model/model.js'

export default {
  data() {
    return {
      timeOut: null,
      freezeWidth: false,
      widthToFreeze: null,
      treeOpen: false,
      layout: [],
      editorState: null,
      vueFunctions: {},
      containerWidth: null,
      canvasHeight: null,
      rotationAngle: 0,
      settings: {},
      resizeObserver: null,
      resizeContainer: null,
      viewPortParams: null,
      imageDimensions: null,
      showSpinner: false,
    }
  },
  setup() {
    const route = useRoute()
    const runtimeConfig = useRuntimeConfig()
    return { route, runtimeConfig }
  },

  emits: [
    'editorMouseDown',
    'publishXml',
    'publishTextInit',
    'stateChange',
    'actionIndexUpdate',
    'newStatus',
  ],

  computed: {
    isSearchOrSites() {
      return this.searchRects || this.isSites
    },
  },
  watch: {
    tileSource(tilesouce) {
      this.handleTileSourceChange(tilesouce)
    },

    isFocused() {
      this.updateListerners()
    },
    isEditable(bool) {
      log('watcher called LE', bool)
      this.handleEditable(bool)
    },
    allowZoom(bool) {
      log('watcher called LE', bool)
      this.handleAllowZoom(bool)
    },
    initData(data) {
      this.handleInitDataChange(data)
    },
  },
  props: {
    xmlKey: {
      type: String,
    },
    isSites: {
      type: Boolean,
      default: false,
    },
    searchRects: {
      type: Array,
    },
    allowZoom: {
      type: Boolean,
      default: true,
    },
    editorName: {
      type: String,
      default: 'editor1',
    },
    viewerName: {
      type: String,
      default: 'viewer1',
    },
    initData: {
      type: Object,
    },

    isFocused: {
      type: Boolean,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    if (this.isEditable) {
      model.setLayoutHandler(data => {
        //console.log('setLayout handler called')
        this.setNewEditorData(data)
      })
    }

    /*     if (this.tileSource != null) {
      this.handleTileSourceChange(this.tileSource)
    }
    if (this.initData != null) {
      this.handleInitDataChange(this.initData)
    } */

    const container = this.$refs[this.editorName + '-box']
    this.resizeContainer = container
    this.containerWidth = container.clientWidth

    this.resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        const cr = entry.contentRect

        if (cr.width > 0) {
          this.requestHandlePaneWidthChange(cr.width)
        }
      }
    })
    this.resizeObserver.observe(this.resizeContainer)

    const showLog = true
    const showWarn = true
    const showProtocolLog = true
    if (showLog) {
      window.log = console.log.bind(window.console)
    } else {
      window.log = () => {}
    }
    if (showProtocolLog) {
      window.protocolLog = console.log.bind(window.console)
    } else {
      window.protocolLog = () => {}
    }
    if (showWarn) {
      window.warn = console.warn.bind(window.console)
    } else {
      window.warn = () => {}
    }

    /* const handleAction = action => {
      switch (action.type) {
        case ACTION_TYPE.MULTI_ACTION:
          action.actions.map(action => {
            handleAction(action)
          })
          break
        case ACTION_TYPE.SET_ROTATION:
          window[this.viewerName].viewport.setRotation(action.new_angle)
          this.rotationAngle = action.new_angle
          break
        case ACTION_TYPE.CHANGE_STATUS:
          this.$emit('newStatus', action.new_status)
          break
      }

      this.updateTextEditor()
    } */

    ActionHandler.instance(this.editorName).on(
      'action-index-update',
      'layout',
      index => this.$emit('actionIndexUpdate', index)
    )

    ActionHandler.instance(this.editorName).on('action', 'layout', action =>
      handleAction(action)
    )

    this.updateListerners()
  },

  beforeUnmount() {
    window[this.editorName]?.removeKeyListeners()
    window[this.editorName]?.removeSettingsListener()
    Settings.instance().off('update', this.handleSettingsChange)
    this.resizeObserver?.unobserve(this.resizeContainer)
    // Deleting the properties from the window object
    delete window[this.viewerName]
    delete window[this.editorName]
  },

  methods: {
    updateEditorVersion(version, force) {
      //console.log('init data changed', version?.layoutData)
      this.$emit('publishTextInit')

      if (version?.layoutData != null && window[this.viewerName] != null) {
        if (window[this.editorName] == null) {
          this.updateScaleFactor()
          this.startEditor(version?.layoutData)
          return
        }
        /*  console.log(
          'Compare keys in update layout version',
          window[this.editorName].xmlKey,
          version?.xmlKey
        ) */
        if (force || window[this.editorName].xmlKey !== version?.xmlKey) {
          this.updateScaleFactor()
          setRotationAngle(0)
          if (!(typeof window[this.editorName].reset === 'function')) {
            console.log('reset function not found')
            console.log(window[this.editorName])
          }
          window[this.viewerName].viewport.setRotation(0)
          window[this.editorName].reset()
          this.setNewEditorData(version?.layoutData)
          window[this.editorName].xmlKey = version?.xmlKey
        }
      }
      if (version?.layoutData == null && window[this.editorName] != null) {
        window[this.editorName].removeAllShapes()
      }
    },
    async getDebugIiifPath() {
      const debugConfig = await $fetch(
        'http://localhost:5000/debug/debug-iiif-path.txt',
        {
          method: 'GET',
        }
      )
      return debugConfig
    },
    async getDebugImage(path) {
      const image = await $fetch(path, {
        method: 'GET',
      })
      return image
    },
    async updateEditorPage(page) {
      this.imageDimensions = page?.imageDimensions
      if (window[this.viewerName] == null) {
        this.initViewer()
      }
      if (this.runtimeConfig.public.debug) {
        const iiifPath = await this.getDebugIiifPath()
        if (iiifPath != null) {
          const image = await this.getDebugImage(iiifPath)
          if (image?.width && image?.height) {
            this.imageDimensions = {
              width: image.width,
              height: image.height,
            }
            this.updateViewer(iiifPath)
            return
          }
        }
      }
      this.updateViewer(page?.iiifInfo)
    },
    /*     handleInitDataChange(data) {},
    handleTileSourceChange(tileSource) {}, */
    getNewContainerWidth() {
      const container = this.$refs[this.editorName + '-box']
      return container?.clientWidth
    },
    updateScaleFactor() {
      const newContainerWidth = this.getNewContainerWidth()
      const newScaleFactor = newContainerWidth / this.imageDimensions?.width
      if (this.isNumber(newScaleFactor)) {
        const rounded = x => Math.round(x * 1000000) / 1000000
        this.scaleFactor = rounded(newScaleFactor)
      } else {
        this.scaleFactor = 1
      }
      this.containerWidth = newContainerWidth
      setScaleFactor(this.scaleFactor)
    },
    isNumber(value) {
      return typeof value === 'number' && !isNaN(value)
    },
    updateTextEditor() {
      const layout = window[this.editorName]?.getLayout?.()
      if (layout) {
        const textInit = JSON.parse(JSON.stringify(layout))
        this.$emit('publishTextInit', textInit)
      }
    },
    handleSettingsChange(settings) {
      this.settings = settings
    },
    clearSelection() {
      ActionHandler.instance(this.editorName).select({
        type: SELECTION_TYPE.CLEAR,
      })
    },
    /*     statusChange(oldStatus, newStatus) {
      const action = {
        type: ACTION_TYPE.CHANGE_STATUS,
        old_status: oldStatus,
        new_status: newStatus,
      }
      ActionHandler.instance(this.editorName).newAction(action)
    }, */
    updateListerners() {
      if (this.isFocused) {
        window[this.editorName]?.removeKeyListeners()
        window[this.editorName]?.addKeyListerns()
        MyFabric.instance(this.editorName).setFocused(true)
        console.log('Layouteditor added listeners')
      } else {
        window[this.editorName]?.removeKeyListeners()
        MyFabric.instance(this.editorName).setFocused(false)
        console.log('Layouteditor removed listeners')
        //this.clearSelection()
      }
    },

    publishTextInit() {
      const layout = window[this.editorName].getLayout()
      this.$emit('publishTextInit', JSON.parse(JSON.stringify(layout)))
    },

    updateViewer(tileSource) {
      this.showSpinner = true
      window[this.viewerName].open(tileSource)
    },
    initViewer() {
      let viewer = OpenSeadragon({
        id: `${this.editorName}-osd`,
        prefixUrl: 'openseadragon/images/',
        tabIndex: '',
        tileSources: [],
        controlsFadeDelay: 60000,
        showZoomControl: false,
        showHomeControl: false,
        showFullPageControl: false,
        minZoomImageRatio: 0.5,
        maxZoomPixelRatio: 10,
        visibilityRatio: 0,
        animationTime: 0.8,
        zoomPerScroll: 1.1,
      })

      viewer.scrollHandler = viewer.innerTracker.scrollHandler

      viewer.addHandler('tile-drawn', event => {
        this.showSpinner = false
      })

      viewer.addHandler('open', () => {
        //console.log('viewer opened')
        if (this.initData != null && window[this.editorName] == null) {
          this.updateScaleFactor()
          this.startEditor(this.initData)
        }
        const center = this.viewPortParams?.center
        const zoom = this.viewPortParams?.zoom
        if (center != null && zoom != null) {
          viewer.viewport.panTo(
            new OpenSeadragon.Point(center.x, center.y),
            true
          )
          viewer.viewport.zoomTo(zoom, null, true)
        } else {
          this.fitToWidth()
        }
      })

      const setCookieParams = () => {
        const center = viewer.viewport.getCenter()
        const zoom = viewer.viewport.getZoom()
        this.viewPortParams = { center, zoom }
      }

      viewer.addHandler('canvas-drag-end', event => {
        setCookieParams()
      })

      viewer.addHandler('canvas-scroll', event => {
        setCookieParams()
      })

      /* viewer.addHandler('update-viewport', event => {
        if (isOpen && !firstViewportUpdate) {
          firstViewportUpdate = true
          const params = this.getParamsFromCookie()
          const center = params?.lastViewportCenter
          const zoom = params?.lastViewportZoom
          const docId = params?.lastViewportDocId
          const currentDocId = this.route?.params?.docid
          if (
            params != null &&
            center != null &&
            zoom != null &&
            docId != null &&
            docId === currentDocId
          ) {
            viewer.viewport.panTo(
              new OpenSeadragon.Point(center.x, center.y),
              true
            )
            viewer.viewport.zoomTo(zoom, null, true)
          } else {
            this.fitToWidth()
          }
        }
      }) */

      viewer.zoomPerClick = 1
      viewer.setControlsEnabled(false)
      window[this.viewerName] = viewer
    },

    updateEditorShapes() {
      window[this.editorName]?.updateAllShapes?.()
    },
    setNewEditorData(data) {
      window[this.editorName]?.setNewBlueprints(
        data,
        this.isSearchOrSites,
        this.searchRects
      )
    },
    startEditor(initData) {
      IdHandler.instance(this.editorName).reset()

      if (this.isSites) {
        initOverlay(OpenSeadragon, fabric, true)
      } else {
        initOverlay(OpenSeadragon, fabric)
      }

      let overlay = window[this.viewerName].fabricjsOverlay({
        scale: 1000,
      })
      let canvas = overlay.fabricCanvas()

      //this.canvas.renderOnAddRemove = false;

      canvas.uniformScaling = false
      canvas.fireRightClick = true
      // canvas.preserveObjectStacking = true
      canvas.uniScaleKey = 'null'
      canvas.centeredKey = 'null'
      canvas.selectionKey = 'null'
      canvas.altActionKey = 'null'
      canvas.altSelectionKey = 'null'

      let tileSourceRatio2 =
        this.imageDimensions.height / this.imageDimensions.width

      this.canvasHeight = canvas.width * tileSourceRatio2

      this.vueFunctions = {
        setEditorState: this.setEditorState,
        save: this.save,
        openModal: this.openModal,
        getRouterBase: this.getRouterBase,
        getCanvasHeight: this.getCanvasHeight,
        getTileSourceWidth: () => this.imageDimensions.width,
        $t: this.$t.bind(this),
        setDrag: this.setDrag,
        focusPolygon: this.focusPolygon,
        isEditable: () => this.isEditable,
        mouseDown: this.editorMouseDown,
        buttonClick: type => this.buttonClick(type),
        updateTextEditor: () => this.updateTextEditor(),
      }

      Settings.instance().on('update', this.handleSettingsChange)
      this.settings = Settings.instance().getAll()

      window[this.editorName] = new Editor(
        canvas,
        initData,
        this.vueFunctions,
        this.editorName
      )
      window[this.editorName].xmlKey = this.xmlKey
      window[this.editorName].reset()

      window[this.editorName].render(this.isSearchOrSites, this.searchRects)

      let buffer = 0
      window[this.viewerName].addHandler('canvas-drag', () => {
        if (buffer++ % 20 === 0) {
          if (!window[this.editorName].canvasDrag) {
            window[this.editorName].canvasDrag = true
          }
        }
      })
      this.handleEditable(this.isEditable)
      this.updateListerners()
    },
    rotate() {
      const angle = getRotationAngle()
      const newAngle = (angle + 90) % 360
      setRotationAngle(newAngle)
      window[this.viewerName].viewport.setRotation(newAngle)
      this.updateEditorShapes()
    },
    editorMouseDown() {
      this.$emit('editorMouseDown')
    },
    requestHandlePaneWidthChange() {
      clearTimeout(this.timeOut)
      window[this.editorName]?.removeAllShapes()
      this.timeOut = setTimeout(() => {
        this.handlePaneWidthChange()
      }, 200)
    },
    handlePaneWidthChange() {
      this.updateScaleFactor()
      this.updateEditorShapes()
    },
    handleEditable(editable) {
      if (editable) {
        window[this.editorName].setState(Editor.STATE.EDIT)
      } else {
        /*  console.log('disabled layouteditor')
        if (this.isSearchOrSites) {
          window[this.editorName].setState(Editor.STATE.SEARCH_RESULTS)
        } else {
          window[this.editorName].setState(Editor.STATE.SELECTION_ONLY)
        } */
      }
    },
    handleAllowZoom(allow) {
      if (allow) {
        window[this.viewerName].innerTracker.scrollHandler =
          window[this.viewerName].scrollHandler
      } else {
        window[this.viewerName].innerTracker.scrollHandler = false
      }
    },
    focusPolygon(polygon, midPoint, { centerAlways, centerAlwaysAndZoom }) {
      const viewPortPolygon = polygon.map(point =>
        this.fabricToViewportCoordinates({
          x: point.x,
          y: point.y,
        })
      )

      const isVisible = viewPortPolygon.some(point =>
        this.isInRect(point, window[this.viewerName].viewport.getBounds())
      )

      if (!isVisible || centerAlways) {
        const { x, y } = this.fabricToViewportCoordinates({
          x: midPoint[0],
          y: midPoint[1],
        })
        window[this.viewerName].viewport.panTo(new OpenSeadragon.Point(x, y))
      }

      if (centerAlwaysAndZoom) {
        const minX = Math.min(...viewPortPolygon.map(point => point.x))
        const maxX = Math.max(...viewPortPolygon.map(point => point.x))
        const minY = Math.min(...viewPortPolygon.map(point => point.y))
        const maxY = Math.max(...viewPortPolygon.map(point => point.y))

        const width = maxX - minX
        const widthWithPadding = width / 0.7
        const additionalWidthPerSide = (widthWithPadding - width) / 2
        const rect = new OpenSeadragon.Rect(
          minX - additionalWidthPerSide,
          minY,
          widthWithPadding,
          maxY - minY
        )
        window[this.viewerName].viewport.fitBounds(rect)
      }
    },
    isInRect(point, rect) {
      const { x, y } = point
      const { x: xRect, y: yRect, width, height } = rect
      const padding = 0.05
      return (
        x > xRect + padding &&
        x < xRect + width - padding &&
        y > yRect + padding &&
        y < yRect + height - padding
      )
    },
    fabricToViewportCoordinates({ x, y }) {
      const xScaled = x / this.scaleFactor
      const yScaled = y / this.scaleFactor

      const rotatedPoint = pointRotate(
        [xScaled, yScaled],
        360 - getRotationAngle()
      ).reduce((x, y) => ({
        x,
        y,
      }))

      const xRelative = rotatedPoint.x / this.imageDimensions.width
      const yRelative = rotatedPoint.y / this.imageDimensions.width
      return { x: xRelative, y: yRelative }
    },
    panTo(point, immediate = true) {
      const { x, y } = this.fabricToViewportCoordinates(point)
      window[this.viewerName].viewport
        .panTo(new OpenSeadragon.Point(x, y), immediate)
        .applyConstraints(immediate)
    },
    zoomTo(value) {
      window[this.viewerName].viewport.zoomTo(value, null, true)
    },
    setDrag(enable) {
      window[this.viewerName].setMouseNavEnabled(enable)
    },
    getParamsFromCookie() {
      const { lastViewportCenter, lastViewportZoom, lastViewportDocId } =
        Settings.instance().getAll()
      return { lastViewportCenter, lastViewportZoom, lastViewportDocId }
    },

    //This can be moved to settings.js
    saveParamsToCookie(params) {
      if (this.$cookies == null) return
      const config = this.$cookies.get('transkribus-config') || {}
      config.layoutParameters = { ...config?.layoutParameters, ...params }
      this.$cookies.set('transkribus-config', JSON.stringify(config))
    },

    openInfoModal() {
      const modal = {
        modal: {
          modalContent: {
            component: ShowEditorInfo,
          },
        },
        props: {
          width: '700px',
        },
      }
      this.$bus.emit('open-modal', modal)
    },

    treeClick() {
      this.editorMouseDown()
    },

    layoutTreeOpen() {
      this.fetchLayout()
      this.treeOpen = !this.treeOpen
    },

    fetchLayout() {
      const layout = window[this.editorName].getLayout()
      this.layout = layout
    },
    openModal(heading, text) {
      const modal = {
        modalContent: {
          component: ShowEditorWarning,
          props: {
            heading: heading,
            text: text,
          },
        },
      }
      this.$bus.emit('open-modal', { modal: modal })
    },
    getRouterBase() {
      return this.$router?.options?.base || ''
    },
    getCanvasHeight() {
      return this.canvasHeight
    },
    buttonClick(type) {
      switch (type) {
        case 'undo':
          window[this.editorName].buttonClick(Editor.BUTTON.UNDO)
          break
        case 'redo':
          window[this.editorName].buttonClick(Editor.BUTTON.REDO)
          break
        case 'drag':
          window[this.editorName].buttonClick(Editor.BUTTON.DRAG)
          break
        case 'select':
          window[this.editorName].buttonClick(Editor.BUTTON.POINTER)
          break
        case 'addLine':
          window[this.editorName].buttonClick(Editor.BUTTON.ADD_BASELINES)
          break
        case 'addRegion':
          window[this.editorName].buttonClick(Editor.BUTTON.ADD_REGIONS)
          break
        case 'addFreeForm':
          window[this.editorName].buttonClick(Editor.BUTTON.ADD_FREE_FORM)
          break
        case 'addTable':
          window[this.editorName].buttonClick(Editor.BUTTON.ADD_TABLES)
          break
        case 'openGuide':
          this.openInfoModal()
          break
        /*  case 'openLayout':
          this.layoutTreeOpen()
          break */
        case 'zoomIn':
          this.zoomIn()
          break
        case 'zoomOut':
          this.zoomOut()
          break
        case 'center':
          this.setHome()
          break
        case 'fitToWidth':
          this.fitToWidth()
          break
        case 'rotate':
          this.rotate()
          break
        case 'versionSelectOpen':
          this.freezeWidth = true
          this.widthToFreeze = this.containerWidth
          break
        case 'versionSelectClosed':
          this.freezeWidth = false
          break
      }
    },
    setEditorState(state) {
      this.editorState = state
      switch (state) {
        case Editor.STATE.EDIT:
        case Editor.STATE.MULTI_SELECT:
        case Editor.STATE.GROUP_SELECTION:
          this.$emit('stateChange', 'select')
          break
        case Editor.STATE.ADD_BASELINES:
          this.$emit('stateChange', 'addLine')
          break
        case Editor.STATE.ADD_REGIONS:
          this.$emit('stateChange', 'addRegion')
          break
        case Editor.STATE.ADD_TABLES:
          this.$emit('stateChange', 'addTable')
          break
        case Editor.STATE.DRAG:
          this.$emit('stateChange', 'drag')
          break
      }
    },
    save() {
      this.$emit('publishXml', 'IN_PROGRESS')
    },
    getButtonStyle(button) {
      //TODO: use switch instead
      if (
        ((this.editorState === Editor.STATE.EDIT ||
          this.editorState === Editor.STATE.MULTI_SELECT ||
          this.editorState === Editor.STATE.GROUP_SELECTION) &&
          button === Editor.BUTTON.POINTER) ||
        (this.editorState === Editor.STATE.ADD_BASELINES &&
          button === Editor.BUTTON.ADD_BASELINES) ||
        (this.editorState === Editor.STATE.ADD_REGIONS &&
          button === Editor.BUTTON.ADD_REGIONS) ||
        (this.editorState === Editor.STATE.ADD_TABLES &&
          button === Editor.BUTTON.ADD_TABLES) ||
        (this.editorState === Editor.STATE.ADD_RELATION &&
          button === Editor.BUTTON.ADD_RELATION)
      ) {
        return {
          'background-color': '	#C0C0C0',
        }
      }
      return {
        'background-color': 'white',
      }
    },
    setHome() {
      window[this.viewerName].viewport.goHome()
    },

    fitToWidth() {
      /*
       ** as seen on https://github.com/openseadragon/openseadragon/issues/839#issuecomment-181968800
       */
      const viewportBounds = window[this.viewerName].viewport.getBounds()
      const viewportAspect = viewportBounds.height / viewportBounds.width
      const newBounds = window[this.viewerName].world.getHomeBounds()
      newBounds.height = newBounds.width * viewportAspect
      window[this.viewerName].viewport.fitBounds(newBounds, true)
    },
    zoomIn() {
      window[this.viewerName].viewport.zoomBy(
        1.2,
        new OpenSeadragon.Point(0.5, 0.5)
      )
    },
    zoomOut() {
      window[this.viewerName].viewport.zoomBy(
        0.8,
        new OpenSeadragon.Point(0.5, 0.5)
      )
    },
  },
}
</script>

<style lang="scss">
.spinner {
  position: relative;
  a {
    opacity: 0;
    transition: all 0s ease-in-out;
  }
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 32px;
    height: 32px;
    margin: -8px 0 0 -8px;
    border: 2px solid #193060;
    border-top-color: transparent;
    border-radius: 50%;
    animation: spin 0.6s linear infinite;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

#ctx-merge-col {
  background-image: url('/images/ctx_merge_col.svg');
}

#ctx-dissolve {
  background-image: url('/images/ctx_dissolve.svg');
}

#ctx-merge-row {
  background-image: url('/images/ctx_merge_row.svg');
}

#ctx-select-all {
  background-image: url('/images/ctx_select_all.svg');
}

#ctx-delete {
  background-image: url('/images/ctx_delete.svg');
}

#ctx-assign-to-region {
  background-image: url('/images/ctx_assign_to_region.svg');
}

#ctx-custom-split {
  background-image: url('/images/ctx_custom_split.svg');
}

#ctx-duplicate {
  background-image: url('/images/ctx_duplicate.svg');
}

#ctx-relation {
  background-image: url('/images/ctx_relation.svg');
}

#ctx-split-horizontal {
  background-image: url('/images/ctx_split-horizontal.svg');
}

#ctx-split-vertical {
  background-image: url('/images/ctx_split-vertical.svg');
}

#ctx-structure-tags {
  background-image: url('/images/ctx_structure_tags.svg');
}

.is-relative {
  position: relative;
  width: 100%;
  height: 100%;
}

#sidebar {
  margin: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

#settings-panel {
  width: 300px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  line-height: 1.2;

  ul {
    list-style: none;
    font-size: 15px;
    margin-left: 10px;
    li {
      margin-top: 10px;
    }
  }
}

#tree-dropdown .dropdown-menu {
  left: 50px;
  top: -200px;
  z-index: 1;
}

#tree-div {
  background-color: white;
  padding: 5px;
  position: absolute;
  left: 70px;
  top: 20px;
  z-index: 1;
}

#tree-dropdown .dropdown-item {
  outline: none;
}

.description {
  width: 800px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding-left: 5px;
  line-height: 1.2;
  font-size: 20px;

  ul {
    list-style: circle;
    margin-left: 10px;
  }
}

.description p {
  font-size: 8px;
}

#navigator-overlay {
  position: absolute;
  z-index: 10;
  right: 1.25rem;
  top: 1.25rem;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border-width: 1px;
  border-color: #193060;
}

#editor-overlay {
  position: absolute;
  z-index: 10;
  top: 1.25rem;
  left: 1.25em;
  display: flex;

  flex-direction: column;
  background-color: #ffffff;

  border-radius: 4px;

  button span {
    position: relative;
    margin: 1px 1px 1px 1px;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: 30px;
    background-position: center;
  }

  > div {
    border-radius: 4px;
  }
}

#select-button {
  width: 50px;
  height: 50px;
}

.editor-button {
  position: relative;
  margin: 3px 3px 3px 3px;
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-size: 30px;
  background-position: center;
}

$prefix: '../../../../../';
@if not variable-exists(editor-only) {
  $prefix: '~';
}

#pointer {
  background-image: url($prefix + 'assets/images/Selection-Tool.svg');
}

#add-textline {
  background-image: url($prefix + 'assets/images/Pen-Tool.svg');
}

#add-textregion {
  background-image: url($prefix + 'assets/images/Region-Tool.svg');
}

#add-table {
  background-image: url($prefix + 'assets/images/Table-Tool.svg');
}

#shift-icon {
  background: url($prefix + 'assets/images/Shift.svg');
}

#a-icon {
  background: url($prefix + 'assets/images/A.svg');
}

#backspace-icon {
  background: url($prefix + 'assets/images/Backspace.svg');
}

#ctrl-icon {
  background: url($prefix + 'assets/images/CTRL.svg');
}

#cmd-icon {
  background: url($prefix + 'assets/images/Cmd.svg');
}

#d-icon {
  background: url($prefix + 'assets/images/D.svg');
}

#delete-icon {
  background: url($prefix + 'assets/images/Del.svg');
}

#escape-icon {
  background: url($prefix + 'assets/images/Esc.svg');
}

#m-icon {
  background: url($prefix + 'assets/images/M.svg');
}

#r-icon {
  background: url($prefix + 'assets/images/R.svg');
}

#tab-icon {
  background: url($prefix + 'assets/images/Tab.svg');
}

#esc-icon {
  background: url($prefix + 'assets/images/Esc.svg');
}

#arrowUp-icon {
  background: url('/images/arrowUp.svg');
}
#arrowDown-icon {
  background: url('/images/arrowDown.svg');
}
#arrowLeft-icon {
  background: url('/images/arrowLeft.svg');
}
#arrowRight-icon {
  background: url('/images/arrowRight.svg');
}

.description-icon {
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-bottom: -3px;
}
.ctx-menu-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-bottom: -3px;
  margin-right: 12px;
  margin-left: -3px;
}

.ctxmenu {
  border-radius: 10px;
  background-color: rgb(242, 242, 242);
  border: 0px solid white;
  padding: 0px;
  font-family: inherit;
  li {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 12px;
    margin: 0px;
    cursor: pointer;
    span {
      cursor: pointer;
    }
  }
}

.ctxmenu li.submenu::after {
  right: 1em;
}
</style>
