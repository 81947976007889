<template>
  <div
    v-show="show"
    id="popup"
    class="absolute z-30 bg-white rounded shadow-2xl p-4 flex flex-col w-[304px]"
    :style="{
      top: popupParams.y + 'px',
      left: popupParams.x + 'px',
      'border-width': stateEdit ? '2px' : '0px',
      'border-color':
        stateEdit && getTagInfo(tag)?.color
          ? getTagInfo(tag)?.color + '70'
          : 'white',
    }"
    @mousedown="$emit('popupMouseDown')"
  >
    <div v-if="stateCreate" class="w-full h-full">
      <div
        class="w-full flex items-center justify-between"
        id="textStyleWrapper"
      >
        <BaseTooltip
          v-for="(format, index) in Object.values(formats)"
          :tip="format?.name"
          :note="getFormatShortcut(format)"
          :key="index"
          small
        >
          <template v-slot:content>
            <button
              v-html="$svg[format.icon]"
              :tabindex="index + 10"
              :class="[
                'rounded w-[32px] h-[32px] flex items-center justify-center app-animation--scale cursor-pointer ',
              ]"
              @click="createTag('textStyle', format?.name)"
            ></button>
          </template>
        </BaseTooltip>
        <div class="relative">
          <BaseTooltip tip="More options" right small>
            <template v-slot:content>
              <i
                @click="showExtendedTextStyles = !showExtendedTextStyles"
                class="button-icon-clock mdi mdi-dots-vertical cursor-pointer"
              ></i>
            </template>
          </BaseTooltip>
          <div
            v-if="showExtendedTextStyles"
            class="flex absolute top-6 right-0 border bg-white"
          >
            <BaseTooltip
              v-for="(format, index) in Object.values(extendetFormats)"
              :tip="format?.toolTip"
              :note="getFormatShortcut(format)"
              :key="index"
              small
              class="p-2"
            >
              <template v-slot:content>
                <button
                  v-html="$svg[format.icon]"
                  :tabindex="index + 10"
                  :class="[
                    'rounded w-[32px] h-[32px] flex items-center justify-center app-animation--scale cursor-pointer ',
                  ]"
                  @click="createTag('textStyle', format?.name)"
                ></button>
              </template>
            </BaseTooltip>
          </div>
        </div>
      </div>
      <div class="w-full mt-4 border-t border-[#DEDEDE]">
        <ul
          class="app-text-editor__popup__content__list py-4 max-h-[30vh] overflow-auto"
        >
          <li
            class="app-text-editor__popup__content__list__item"
            v-for="(tag, index) in displayedTags"
            :key="index"
          >
            <button
              :tabindex="index + 20"
              class="border-0 app-animation--scale rounded text-xs px-3 py-1.5"
              @click="createTag(tag?.name, null, tag?.level)"
              :style="` background : ${tag?.color}${70} !important;`"
              :data-testid="tag?.name"
            >
              {{ `${tag?.name}${tag?.level ? `-${tag?.level}` : ''}` }}
            </button>
          </li>
        </ul>

        <div
          class="w-full flex flex-row items-center justify-between text-sm text-[#193060]"
        >
          <span v-if="isEditable" class="cursor-pointer">
            <label
              class="cursor-pointer"
              :for="'AdvancedModal'"
              @click="$emit('buttonClick', 'showSettings-textual-tags')"
            >
              {{ $t('Tag Settings') }}</label
            >
          </span>
          <span :tabindex="102" @focus="cycleFocus()"> </span>

          <span
            class="cursor-pointer"
            @click="$emit('update', { tagIdentifier: null, state: null })"
          >
            {{ $t('Close') }}
          </span>
        </div>
      </div>
    </div>

    <div v-if="stateEdit" class="w-full h-full">
      <div
        v-if="
          Object.values(formats).some(f => isButtonActive(f?.name)) ||
          Object.values(extendetFormats).some(f => isButtonActive(f?.name))
        "
        class="w-full flex items-center justify-between"
        id="textStyleWrapper"
      >
        <BaseTooltip
          v-for="(format, index) in Object.values(formats)"
          :tip="format?.name"
          :note="getFormatShortcut(format)"
          :key="index"
          small
        >
          <template v-slot:content>
            <button
              v-html="$svg[format.icon]"
              :tabindex="index + 10"
              :class="[
                'rounded w-[32px] h-[32px] flex items-center justify-center app-animation--scale cursor-pointer',
                isButtonActive(format?.name) && 'bg-[#19306014]',
              ]"
              @click="toggleFormat(format?.name)"
              :disabled="!isEditable"
            ></button>
          </template>
        </BaseTooltip>
        <div class="relative">
          <BaseTooltip tip="More options" right small>
            <template v-slot:content>
              <i
                @click="showExtendedTextStyles = !showExtendedTextStyles"
                class="button-icon-clock mdi mdi-dots-vertical cursor-pointer"
              ></i>
            </template>
          </BaseTooltip>
          <div
            v-if="
              showExtendedTextStyles ||
              Object.values(extendetFormats).some(f => isButtonActive(f?.name))
            "
            class="flex absolute top-6 right-0 border bg-white"
          >
            <BaseTooltip
              v-for="(format, index) in Object.values(extendetFormats)"
              :tip="format?.toolTip"
              :note="getFormatShortcut(format)"
              :key="index"
              small
              class="p-2"
            >
              <template v-slot:content>
                <button
                  v-html="$svg[format.icon]"
                  :tabindex="index + 10"
                  :class="[
                    'rounded w-[32px] h-[32px] flex items-center justify-center app-animation--scale cursor-pointer ',
                    isButtonActive(format?.name) && 'bg-[#19306014]',
                  ]"
                  @click="toggleFormat(format?.name)"
                  :disabled="!isEditable"
                ></button>
              </template>
            </BaseTooltip>
          </div>
        </div>
      </div>
      <div :class="[`w-full`]">
        <div
          class="flex flex-col w-full gap-3"
          v-if="tag?.selector !== 'textStyle'"
          id="textStyleWrapper"
        >
          <button
            class="border-2 app-animation--scale rounded text-xs px-3 py-1.5 self-start"
            :tabindex="10"
            :style="` background : ${
              getTagInfo(tag)?.color
            }${70} !important; border-color: ${getTagInfo(tag)?.color}`"
          >
            {{
              `${getTagInfo(tag)?.name}${
                getTagInfo(tag)?.level ? `-${getTagInfo(tag)?.level}` : ''
              }`
            }}
          </button>

          <button
            v-if="isEditable"
            class="w-full rounded border border-[#DEDEDE] text-xs h-7"
            @click="openWikiModal(tag)"
            :tabindex="20"
          >
            Wikidata ID
          </button>
          <div
            v-if="getAttributeValueFromTag(tag, 'wikiData')"
            class="app-form__input app-form__input--small app-form__input--floating-label h-7"
          >
            <label>Wikidata</label>
            <input
              :tabindex="21"
              style="border-radius: 4px"
              @blur="updateAttribute(tag, 'wikiData', $event.target.value)"
              @keydown="attributeKeyDown"
              :value="getAttributeValueFromTag(tag, 'wikiData')"
              :disabled="!isEditable"
              type="text"
              autocomplete="on"
            />
          </div>

          <div
            class="app-text-editor__popup__content__block__field max-h-fit pt-1"
          >
            <div
              class="app-form__input app-form__input--small app-form__input--floating-label"
              v-for="(attribute, i) in findTagAttributes(tag)"
              :key="i"
            >
              <label>{{ attribute }}</label>
              <div
                class="w-full border border-gray-100 rounded p-2"
                v-if="
                  isValidHttpUrl(getAttributeValueFromTag(tag, attribute)) &&
                  !isEditable
                "
              >
                <a
                  target="_blank"
                  :href="getAttributeValueFromTag(tag, attribute)"
                  class="cursor-pointer hover:text-[rgb(0,0,0)]"
                  >{{ getAttributeValueFromTag(tag, attribute) }}</a
                >
              </div>
              <input
                v-else-if="
                  getAttributeValueFromTag(tag, attribute)?.length < 50
                "
                :tabindex="i + 30"
                style="border-radius: 4px"
                @blur="updateAttribute(tag, attribute, $event.target.value)"
                @keydown="attributeKeyDown"
                :value="getAttributeValueFromTag(tag, attribute)"
                :disabled="!isEditable"
                type="text"
                autocomplete="on"
                :data-testid="attribute"
              />
              <textarea
                v-else
                :tabindex="i + 30"
                class="auto-resize-textarea w-full border border-gray-300 transition-all duration-300 ease-in-out rounded-md p-2"
                @blur="updateAttribute(tag, attribute, $event.target.value)"
                @keydown="attributeKeyDown"
                @input="adjustHeight($event.target)"
                :value="getAttributeValueFromTag(tag, attribute)"
                :disabled="!isEditable"
                autocomplete="on"
                rows="1"
                ref="textarea"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="w-full flex flex-row items-center justify-between text-sm text-[#193060] mt-3"
      >
        <span
          v-if="isEditable"
          class="cursor-pointer text-[#AD2828]"
          @click="deleteTag(tag)"
          data-testid="delete-tag"
        >
          {{ $t('Remove Tag') }}
        </span>
        <span :tabindex="102" @focus="cycleFocus()"> </span>

        <span
          class="cursor-pointer"
          @click="$emit('update', { tagIdentifier: null, state: null })"
          data-testid="close-tag"
        >
          {{ $t('Close') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import ReportModal from '~/components/ReadShare/ReportModal.vue'
import WikidataSuggestionModalVue from './WikidataSuggestionModal.vue'

import { decodeCSSChars, Cursor } from './TextEditor/TextEditorUtils.js'

import { Singleton as Settings } from './LayoutEditor/components/singletons/settings.js'

export default {
  watch: {
    popupParams: {
      deep: true,
      handler(newVal, oldVal) {
        this.showExtendedTextStyles = false
        if (
          newVal.tagIdentifier !== oldVal.tagIdentifier ||
          newVal.state !== oldVal.state
        ) {
          setTimeout(() => {
            const div = document.querySelector(
              '#textStyleWrapper > div > div > button'
            )
            div?.focus?.()
            // Get all textareas with the 'auto-resize-textarea' class
            const textareas = this.$refs.textarea

            // Ensure that `textareas` is an array
            if (Array.isArray(textareas)) {
              // Adjust the height for each textarea
              textareas.forEach(textarea => {
                this.adjustHeight(textarea)
              })
            } else if (textareas) {
              // If there is only one textarea
              this.adjustHeight(textareas)
            }
          }, 20)
        }
      },
    },
  },
  data() {
    return {
      showConfigure: false,
      showExtendedTextStyles: false,
      tagsDisplayArray: ['abbrev', 'date', 'person'],
      formats: {
        Bold: {
          name: 'bold',
          icon: 'bold',
          winShortcut: 'Ctrl + B',
          macShortcut: 'Cmd + B',
        },
        Italic: {
          name: 'italic',
          icon: 'italic',
          winShortcut: 'Ctrl + I',
          macShortcut: 'Cmd + I',
        },
        Strike: {
          name: 'strikethrough',
          icon: 'strikethrough',
          winShortcut: 'Alt + Shift + 5',
          macShortcut: 'Cmd + Shift + X',
        },
        Underline: {
          name: 'underlined',
          icon: 'underline',
          winShortcut: 'Ctrl + U',
          macShortcut: 'Cmd + U',
        },
        Subscript: {
          name: 'subscript',
          icon: 'subscript',
          winShortcut: 'Ctrl + ,',
          macShortcut: 'Cmd + ,',
        },
        Superscript: {
          name: 'superscript',
          icon: 'superscript',
          winShortcut: 'Ctrl + .',
          macShortcut: 'Cmd + .',
        },
      },
      extendetFormats: {
        Monospace: {
          toolTip: 'Monospace',
          name: 'monospace',
          icon: 'monospace',
        },
        SmallCaps: {
          toolTip: 'Small Caps',
          name: 'smallCaps',
          icon: 'smallCaps',
        },
        LetterSpaced: {
          toolTip: 'Letter Spacing',
          name: 'letterSpaced',
          icon: 'rightLeft',
        },
      },
    }
  },
  props: {
    isEditable: {
      type: Boolean,
      default: false,
    },
    textualTags: {
      type: Array,
    },
    popupParams: {
      type: Object,
    },
  },
  computed: {
    show() {
      return this.tag || this.stateCreate
    },
    tag() {
      return this.eventHandlers?.getTag?.(this.popupParams?.tagIdentifier)
    },
    tagText() {
      return this.eventHandlers?.getTagText?.(this.popupParams?.tagIdentifier)
    },
    displayedTags() {
      return this.textualTags.filter(element =>
        this.tagsDisplayArray.includes(element.name)
      )
    },
    eventHandlers() {
      return this.popupParams.eventHandlers
    },
    stateCreate() {
      return this.popupParams.state === 'CREATE'
    },
    stateEdit() {
      return this.popupParams.state === 'EDIT'
    },
  },

  mounted() {
    Settings.instance().on('update', this.handleSettingsChange)
    this.tagsDisplayArray = Settings.instance().get('tagsDisplayArray')
  },
  beforeDestroy() {
    Settings.instance().off('update', this.handleSettingsChange)
  },

  methods: {
    adjustHeight(textarea) {
      textarea.style.height = ''
      textarea.style.height = textarea.scrollHeight + 'px'
    },
    getFormatShortcut(format) {
      if (this.$os().mac) {
        return format.macShortcut
      }
      return format.winShortcut
    },
    textKeyDown(event) {
      const updateTextStyle = style => {
        if (this.stateCreate) {
          this.createTag('textStyle', style)
        }
        if (this.stateEdit) {
          this.toggleFormat(style)
        }
      }
      const tryCreateTag = tag => {
        if (this.stateCreate && tag) {
          this.createTag(tag?.name, null, tag?.level)
        }
      }
      const isIntegerValue = value => {
        const parsedValue = parseInt(value)
        return !isNaN(parsedValue)
      }
      if (isIntegerValue(event.key)) {
        tryCreateTag(this.displayedTags[parseInt(event.key) - 1])
      }

      switch (event.key) {
        case 'b':
          if (event.ctrlKey || event.metaKey) {
            event.preventDefault()
            updateTextStyle('bold')
          }
          break
        case 'i':
          if (event.ctrlKey || event.metaKey) {
            event.preventDefault()
            updateTextStyle('italic')
          }
          break
        case 'u':
          if (event.ctrlKey || event.metaKey) {
            event.preventDefault()
            updateTextStyle('underlined')
          }
          break
        case '%':
          if (event.altKey && event.shiftKey) {
            event.preventDefault()
            updateTextStyle('strikethrough')
          }
          break
        case 'x':
          if (event.metaKey && event.shiftKey) {
            event.preventDefault()
            updateTextStyle('strikethrough')
          }
          break

        case '.':
          if (event.metaKey || event.ctrlKey) {
            event.preventDefault()
            updateTextStyle('superscript')
          }
          break

        case ',':
          if (event.metaKey || event.ctrlKey) {
            event.preventDefault()
            updateTextStyle('subscript')
          }
          break
      }
    },
    handleSettingsChange({ tagsDisplayArray }) {
      this.tagsDisplayArray = tagsDisplayArray
    },
    cycleFocus() {
      const div = document.querySelector(
        '#textStyleWrapper > div > div > button'
      )
      div?.focus?.()
    },
    toggleFormat(name) {
      if (this.isButtonActive(name)) {
        // 4th paramerter is true to remove the attribute
        this.eventHandlers.updateAttribute(this.tag, name, null, true)
      } else {
        this.eventHandlers.updateAttribute(this.tag, name, true)
      }
    },
    attributeKeyDown(event) {
      /*  if (event.key === 'Enter') {
        this.blur()
        return
      } */
    },

    createTag(type, textStyle, level) {
      let tagName = type
      if (level != null) {
        tagName = `${type}#level#${level}`
      }
      this.eventHandlers.createTag(tagName, textStyle)
    },
    deleteTag(tag) {
      this.eventHandlers.deleteTag(tag)
    },
    updateAttribute(tag, attributeName, value) {
      if (this.isValidHttpUrl(value)) {
        value = encodeURI(value)
      }
      this.eventHandlers.updateAttribute(tag, attributeName, value)
    },
    getTagInfo(tag) {
      return this.textualTags.find(element => element.name === tag?.selector)
    },
    findTagAttributes(tag) {
      if (tag?.selector === 'textStyle') {
        return []
      }
      const tagDefinition = this.textualTags.find(
        element => element.name === tag?.selector
      )
      if (!this.isEditable) {
        let arr = tag.rules
          .filter(
            t =>
              t.directive !== 'offset' &&
              t.directive !== 'length' &&
              t.directive !== 'continued' &&
              t.directive !== 'wikiData'
          )
          .map(t => t.directive)
        return arr
      }

      return (
        tagDefinition?.attributes?.filter(
          a => a !== 'offset' && a !== 'length' && a !== 'continued'
        ) || []
      )

      /* else {
        if (!found) {
          let obj = {}
          found = tag.rules
            .filter(t => t.directive !== 'offset' && t.directive !== 'length')
            .map(t => {
              obj[t.value] = t.directive
            })
          found = obj
        }

        return found
      } */
    },

    isValidHttpUrl(string) {
      let url
      try {
        url = new URL(string)
      } catch (_) {
        return false
      }
      return url.protocol === 'http:' || url.protocol === 'https:'
    },
    getAttributeValueFromTag(tag, attributeName) {
      const value =
        tag?.rules?.find(r => r.directive === attributeName)?.value ?? ''
      return decodeCSSChars(String(value))
    },
    openWikiModal(tag) {
      const wikiDataId = this.getAttributeValueFromTag(tag, 'wikiData')
      const modal = {
        modalContent: {
          component: WikidataSuggestionModalVue,
          props: {
            term: wikiDataId || this.tagText,
          },
          events: {
            save: value => {
              this.$nextTick(() => {
                this.updateAttribute(tag, 'wikiData', value.id)
                //this.$bus.emit('close-modal')
              })
            },
          },
        },
      }
      this.$bus.emit('open-modal', { modal: modal })
    },
    /* findTagStyle(tag) {
      if (tag.selector === 'textStyle') {
        let type = tag?.rules?.find(r => r.value === true)?.directive
        return this.mapping.find(t => t.type === type)
      }
      return this.mapping.find(t => t.name === tag.selector)
    }, */
    /* toggleTextStyle(style) {
      this.createTag('textStyle', style)
    }, */
    isButtonActive(style) {
      return (
        this.tag?.selector === 'textStyle' &&
        this.tag?.rules.some(r => r.directive === style)
      )
    },
    reportPage() {
      const modal = {
        modalContent: {
          component: ReportModal,
          props: {
            link: 'http://localhost:5000/share/',
          },
        },
      }
      this.$bus.emit('open-modal', {
        modal: modal,
      })
    },
  },
}
</script>

<style>
.footer-logo {
  height: 3rem;
}

.auto-resize-textarea {
  overflow: hidden; /* Prevents scrollbar from appearing */
  resize: none; /* Prevents manual resizing */
}

.auto-resize-textarea:focus,
.auto-resize-textarea:not(:placeholder-shown) {
  height: auto; /* Reset height to auto */
  min-height: 0; /* Set the minimum height to 0 */
  height: fit-content; /* Let the height grow according to the content */
}
</style>
