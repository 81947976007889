import { DEFAULT_SETTINGS } from '../editor-config.js'
var Singleton = (function () {
  var instance

  function createInstance() {
    var object = new Settings()
    return object
  }

  return {
    instance: function () {
      if (!instance) {
        instance = createInstance()
      }
      return instance
    },
  }
})()

function Settings() {
  this.eventHandlers = {
    update: [],
  }
  this.init()
}

Settings.prototype.setDebugConfig = async function () {
  const debugConfig = await $fetch(
    'http://localhost:5000/debug/debug-user-config.json',
    {
      method: 'GET',
    }
  )
  if (debugConfig) {
    this.settings = { ...DEFAULT_SETTINGS, ...debugConfig.config }
    this.emit('update', this.settings)
  }
}

Settings.prototype.init = async function () {
  const configStore = useConfig()
  const runtimeConfig = useRuntimeConfig()

  let editorSettings = configStore.config || {}
  if (runtimeConfig.public.debug) {
    this.setDebugConfig()
  }
  this.settings = { ...DEFAULT_SETTINGS, ...editorSettings }
}

Settings.prototype.setDefaults = function () {
  this.settings = { ...DEFAULT_SETTINGS }
  this.emit('update', this.settings)
}

Settings.prototype.saveToCookie = function () {
  const config = useCookie('editor-settings')
  config.value = {
    ...config.value,
    ...this.settings,
  }
}

Settings.prototype.saveToStore = function () {
  const configStore = useConfig()
  configStore.updateConfig(this.settings)
}

Settings.prototype.set = function (settings, noUpdate) {
  const runtimeConfig = useRuntimeConfig()

  if (settings == null) return
  this.settings = { ...this.settings, ...settings }
  console.log(`%csetting changed: %o`, 'color: lime', settings)
  if (noUpdate !== 'noUpdate') {
    this.emit('update', this.settings)
  }
  if (!runtimeConfig.public.debug) {
    this.saveToStore()
  }
}

Settings.prototype.reset = function (key) {
  this.set({ [key]: DEFAULT_SETTINGS[key] })
}

Settings.prototype.get = function (key) {
  if (key == null) return
  return this.settings[key]
}

Settings.prototype.getAll = function () {
  return { ...this.settings }
}

Settings.prototype.on = function (key, handler) {
  this.eventHandlers[key].push(handler)
}

Settings.prototype.off = function (key, handler) {
  const index = this.eventHandlers[key].indexOf(handler)
  if (index > -1) {
    this.eventHandlers[key].splice(index, 1)
  }
}

Settings.prototype.emit = function (key, event) {
  this.eventHandlers[key].map(h => h(event))
}

Settings.prototype.resetEventHandlers = function () {
  this.eventHandlers = {
    update: [],
  }
}

export { Singleton }
