import { Singleton as IdHandler } from '../singletons/IdHandler.js'

function Relation(blueprint, editorName) {
  this.attributes = {
    type: Relation.TYPE.LINK,
  }
  this.elements = {
    ids: [],
  }
  if (blueprint) {
    this.attributes = blueprint.attributes
    if (!('custom' in this.attributes)) {
      this.attributes.custom = {}
    }
    if (!('relationType' in this.attributes.custom)) {
      this.attributes.custom.relationType = Relation.TYPE.LINK
    }

    if ('id' in this.attributes.custom) {
      this.id = IdHandler.instance(editorName).requestId(
        this.attributes.custom.id
      )
    } else {
      this.id = IdHandler.instance(editorName).requestId('rel')
    }

    this.elements.ids = blueprint.elements.ids
  } else {
    this.id = IdHandler.instance(editorName).requestId('rel')
  }
}

Relation.TYPE = Object.freeze({
  LINK: 'link',
  JOIN: 'join',
  SAME_AS: 'same as',
  FOLLOWED_BY: 'followed by',
})

Relation.prototype.getBlueprint = function () {
  this.attributes.custom.id = this.id
  return { attributes: this.attributes, elements: this.elements }
}

Relation.prototype.setIds = function (ids) {
  this.elements.ids = [...ids]
}

Relation.prototype.getIds = function () {
  return [...this.elements.ids]
}

Relation.prototype.setType = function (type) {
  if (!('custom' in this.attributes)) {
    this.attributes.custom = {}
  }
  this.attributes.custom.relationType = type
}

Relation.prototype.setName = function (name) {
  if (!('custom' in this.attributes)) {
    this.attributes.custom = {}
  }
  this.attributes.custom.relationName = name
}

Relation.prototype.getType = function () {
  return this.attributes.custom.relationType
}

Relation.prototype.getName = function () {
  return this.attributes.custom.relationName
}

//from: https://stackoverflow.com/questions/1484506/random-color-generator
const getRandomColor = () => {
  var letters = '0123456789ABCDEF'
  var color = '#'
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

export default Relation
