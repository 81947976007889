<template>
  <client-only>
    <TailwindComponentsLoading
      v-if="isLoading"
      fullScreen
    ></TailwindComponentsLoading>
    <div v-else>
      <BaseDropdown
        v-if="$features.orchestratorTest"
        :label="$t('Type')"
        class="my-2 mb-4"
        :options="jobType"
        :value="jobSelected"
        @select="setSelected"
      ></BaseDropdown>
      <BaseButton
        v-if="$features.orchestratorTest && jobSelected == 'orchestrator'"
        :label="'Refresh'"
        @click="getJobs"
        data-testid="refresh-button"
      ></BaseButton>
      <h2 v-else class="text-primary">
        {{ $t('Transkribus Server Jobs') }}
      </h2>

      <div class="flex justify-end">
        <NuxtLink
          :to="
            localePath({
              name: 'jobs',
            })
          "
        >
          <BaseButton
            :label="$t('Open Full Jobs Table >')"
            @click="openJobs"
            :type="'text'"
            small
            icon="chevron"
          />
        </NuxtLink>
      </div>

      <BaseTable :data="jobs" :columns="columns" v-if="$features.newUI">
        <template #action-field="{ row, column }">
          <div
            class="app-card__dropdown app-card__dropdown--top content-center max-h-8"
          >
            <label tabindex="0" class="app-card__dropdown__btn">
              <i
                class="mdi mdi-dots-horizontal app-card__dropdown__btn__icon"
              ></i>
            </label>
            <ul
              tabindex="0"
              class="app-card__dropdown__menu dropdown-content app-card__dropdown__menu--jobs z-10"
            >
              <li v-if="row['state'] === 'FINISHED'">
                <a
                  v-if="row['type'] === 'Export Document'"
                  :href="row['result']"
                  target="_blank"
                >
                  <i class="mdi mdi-download"></i>
                  <span>{{ $t('Download') }}</span>
                </a>

                <NuxtLink
                  v-else
                  :to="getPathForNuxtLink(row)"
                  @click="selected(row)"
                  ><i class="mdi mdi-open-in-new"></i>
                  <span>{{ $t('Open') }}</span></NuxtLink
                >
              </li>
              <li v-if="row['state'] == 'FINISHED' && canUndo(row)">
                <span @click="jobsApi.undoJob(row)">
                  <i class="mdi mdi-undo"></i>
                  <span>{{ $t('Undo') }}</span>
                </span>
              </li>
              <li v-if="showRestart(row)">
                <span @click="restartJob(row)">
                  <i class="mdi mdi-restart"></i>
                  <span>{{ $t('Restart') }}</span>
                </span>
              </li>
              <li v-if="showCancel(row)">
                <span @click="cancelJob(row)">
                  <i class="mdi mdi-cancel"></i>
                  <span>{{ $t('Cancel') }}</span>
                </span>
              </li>
              <li
                v-if="
                  row['state'] != 'FINISHED' &&
                  !showRestart(row) &&
                  !showCancel(row)
                "
              >
                <span>{{ $t('Actions not possible') }}</span>
              </li>
            </ul>
          </div>
        </template>
        <template #title-field="{ row, column }">
          <NuxtLink
            :to="getPathForNuxtLink(row)"
            @click="selected(row)"
            class="text-primary font-bold"
            v-if="row[column.field]"
            >{{ truncate(row[column.field], 20) }}</NuxtLink
          >
          <TailwindComponentsLoading small v-else></TailwindComponentsLoading>
        </template>
        <template #jobtype-field="{ row, column }">
          <BaseTooltip
            :tip="descriptionValue(row[column.field])"
            small
            v-if="descriptionValue(row[column.field])?.length > 25"
          >
            <template v-slot:content>
              {{ truncate(descriptionValue(row[column.field]), 25) }}
            </template>
          </BaseTooltip>

          <span v-else class="truncate max-w-xs">{{
            descriptionValue(row[column.field])
          }}</span>
        </template>
        <template #start-field="{ row, column }">
          <BaseDate :value="row[column.field]" showTime />
        </template>
        <template #status-field="{ row, column }">
          <div
            v-if="
              row[column.field] === 'RUNNING' || row[column.field] === 'CREATED'
            "
            class="px-3 py-1 text-xs font-medium leading-none text-center text-blue-800 bg-blue-200 rounded-full animate-pulse dark:bg-blue-900 dark:text-blue-200"
          >
            {{ row[column.field] }} ...
          </div>
          <span v-else :class="statusTag(row[column.field])">{{
            row[column.field]
          }}</span>
        </template>
      </BaseTable>
      <TailwindComponentsTable
        v-else
        :data="jobs"
        :columns="columns"
        :total="total"
      >
        <template #action-field="{ row, column }">
          <div
            class="app-card__dropdown app-card__dropdown--top content-center max-h-8"
          >
            <label tabindex="0" class="app-card__dropdown__btn">
              <i
                class="mdi mdi-dots-horizontal app-card__dropdown__btn__icon"
              ></i>
            </label>
            <ul
              tabindex="0"
              class="app-card__dropdown__menu dropdown-content app-card__dropdown__menu--jobs z-10"
            >
              <li v-if="row['state'] === 'FINISHED'">
                <a
                  v-if="row['type'] === 'Export Document'"
                  :href="row['result']"
                  target="_blank"
                >
                  <i class="mdi mdi-download"></i>
                  <span>{{ $t('Download') }}</span>
                </a>

                <NuxtLink
                  v-else
                  :to="getPathForNuxtLink(row)"
                  @click="selected(row)"
                  ><i class="mdi mdi-open-in-new"></i>
                  <span>{{ $t('Open') }}</span></NuxtLink
                >
              </li>
              <li v-if="row['state'] == 'FINISHED' && canUndo(row)">
                <span @click="jobsApi.undoJob(row)">
                  <i class="mdi mdi-undo"></i>
                  <span>{{ $t('Undo') }}</span>
                </span>
              </li>
              <li v-if="showRestart(row)">
                <span @click="restartJob(row)">
                  <i class="mdi mdi-restart"></i>
                  <span>{{ $t('Restart') }}</span>
                </span>
              </li>
              <li v-if="showCancel(row)">
                <span @click="cancelJob(row)">
                  <i class="mdi mdi-cancel"></i>
                  <span>{{ $t('Cancel') }}</span>
                </span>
              </li>
              <li
                v-if="
                  row['state'] != 'FINISHED' &&
                  !showRestart(row) &&
                  !showCancel(row)
                "
              >
                <span>{{ $t('Actions not possible') }}</span>
              </li>
            </ul>
          </div>
        </template>
        <template #title-field="{ row, column }">
          <NuxtLink
            :to="getPathForNuxtLink(row)"
            @click="selected(row)"
            class="text-primary font-bold"
            v-if="row[column.field]"
            >{{ truncate(row[column.field], 20) }}</NuxtLink
          >
          <TailwindComponentsLoading small v-else></TailwindComponentsLoading>
        </template>
        <template #jobtype-field="{ row, column }">
          <BaseTooltip
            :tip="descriptionValue(row[column.field])"
            small
            v-if="descriptionValue(row[column.field])?.length > 25"
          >
            <template v-slot:content>
              {{ truncate(descriptionValue(row[column.field]), 25) }}
            </template>
          </BaseTooltip>

          <span v-else class="truncate max-w-xs">{{
            descriptionValue(row[column.field])
          }}</span>
        </template>
        <template #start-field="{ row, column }">
          <BaseDate :value="row[column.field]" showTime />
        </template>
        <template #status-field="{ row, column }">
          <div
            v-if="
              row[column.field] === 'RUNNING' || row[column.field] === 'CREATED'
            "
            class="px-3 py-1 text-xs font-medium leading-none text-center text-blue-800 bg-blue-200 rounded-full animate-pulse dark:bg-blue-900 dark:text-blue-200"
          >
            {{ row[column.field] }} ...
          </div>
          <span v-else :class="statusTag(row[column.field])">{{
            row[column.field]
          }}</span>
        </template>
      </TailwindComponentsTable>
    </div>
  </client-only>
</template>

<script>
import jobTypeConfig from '~/assets/job-types.json'
import EditorModal from '../Editor-single-page/EditorModal.vue'
import { useApi } from '~/composables/api/useApi'

export default {
  setup() {
    const jobsApi = useJobs()
    const runtimeConfig = useRuntimeConfig()
    const { fetchWithAuth } = useApi()
  
    return {
      jobsApi,
      runtimeConfig,
      fetchWithAuth
    }
  },
  watch: {
    status(value) {
      this.$nextTick(function () {
        this.getCount()
        this.getJobs()
      })
    },
    jobSelected(value) {
      this.getJobs()
    },
  },
  data() {
    return {
      jobs: [],
      jobOptions: [],
      isLoading: false,
      total: 12,
      polling: null,
      timeout: null,
      collId: null,
      filterType: null,
      status: undefined,
      filterByUser: true,
      sortField: 'jobId',
      sortOrder: 'desc',
      jobType: [
        { name: 'Transkribus Server', value: 'transkribus' },
        { name: 'Orchestrator', value: 'orchestrator' },
      ],
      jobSelected: this.jobSliderType || 'transkribus',
      columns: [
        {
          field: 'colId',
          label: this.$t('Collection ID'),
          visible: false,
        },
        {
          field: 'jobId',
          label: 'Job Id',
          visible: false,
        },
        {
          field: 'userName',
          label: this.$t('User'),
          visible: false,
        },
        {
          field: 'docId',
          label: this.$t('Document ID'),
          visible: false,
        },
        {
          field: 'docTitle',
          label: this.$t('Title'),
          numeric: false,
          searchable: false,
          visible: true,
          display: true,
          custom: true,
          slotName: 'title-field',
        },
        {
          field: 'jobImpl',
          label: this.$t('Job'),
          numeric: false,
          visible: true,
          display: true,
          custom: true,
          slotName: 'jobtype-field',
        },
        {
          field: 'started',
          label: this.$t('Start'),
          visible: true,
          display: true,
          custom: true,
          slotName: 'start-field',
        },
        {
          field: 'ended',
          label: this.$t('End'),
          visible: false,
        },
        {
          field: 'pages',
          label: this.$t('Pages'),
          width: 2,
          visible: true,
        },
        {
          field: 'state',
          label: this.$t('Status'),
          visible: true,
          display: true,
          custom: true,
          slotName: 'status-field',
        },
        {
          field: 'nrOfErrors',
          label: this.$t('Errors'),
          visible: false,
        },
        {
          field: 'description',
          label: this.$t('Description'),
          visible: true,
        },
        {
          field: 'action',
          label: this.$t('Action'),
          custom: true,
          display: true,
          slotName: 'action-field',
        },
      ],
    }

  },
  props: {
    jobSliderType: {
      type: String,
    },
  },
  mounted() {
    if (this.jobSliderType !== undefined && this.jobSliderType !== null && this.jobSliderType !== '') {
      this.setSelected(this.jobSliderType)
    }

    this.jobOptions = [...jobTypeConfig]
    this.$nextTick(function () {
      this.getCount()
      this.getJobs()
    })
    this.$bus.on('slideOver', () => {
      this.getCount()
      this.getJobs()
    })
  },
  beforeDestroy() {
    this.stopPolling()
    this.$bus.off('slideOver')
  },
  methods: {
    async getCount() {
      let data
      try {
        data = await this.jobsApi.fetchJobsCount({
          filterByUser: this.filterByUser,
          type: this.filterType,
          status: this.status,
        })
      } catch (err) {
        this.$sentry.captureException(err)
        return
      }
      this.total = parseInt(data)
    },
    async getJobs() {
      this.isLoading = true
      let data

      try {
        if (this.jobSelected === 'transkribus') {
          // Existing Transkribus logic:
          data = await this.jobsApi.fetchJobs({
            index: 0,
            nValues: 12,
            sortDirection: this.sortOrder.toUpperCase(),
            sortColumn: this.sortField,
            filterByUser: this.filterByUser,
            collId: this.collId,
            type: this.filterType,
            status: this.status,
          })
               
          this.jobs = data
        } else {
          // Orchestrator logic:
          data = await this.jobsApi.fetchJobsOrchestrator()
          if (data.length === 0 || data === undefined) {
            data = []
          }

          // Because the Orchestrator response structure is different,
          // you might want to map it into the same shape your table expects:
          data = data.map(job => {
            return {
              // Map fields from the Orchestrator API to your table’s columns
              jobId: job.uuid,
              docTitle: job.uuid, // or something else
              jobImpl: 'Orchestrator', // or job.config?
              state: job.status,
              // Fill or omit other fields as needed:
              docId: -1,
              started: null,
              ended: null,
              pages: null,
              job: job,
              // etc.
            }
          })

          this.jobs = data

          try {
            if (this.jobs) {
              let that = this
              this.jobs.forEach(jobData => {
                let longpollLink = jobData.job.links.find(link => link.title === 'Status Longpoll')
           
                if (longpollLink !== undefined && longpollLink !== null && longpollLink !== '') {
                  that.startLongPolling(jobData)
                }
              })
            }
          } catch (err) {
            this.$sentry.captureException(err)
          }
        }
      } catch (err) {
        this.$sentry.captureException(err)
        return
      }

      // Handle the rest of your UI updates, e.g. polling logic, isLoading, etc.
      this.$nextTick(() => {
        this.isLoading = false
      })
      this.setLoading(false)
    },
    statusTag(value) {
      if (value == 'FINISHED') {
        return 'badge rounded-full bg-success text-white'
      }
      if (value == 'FAILED') {
        return 'badge rounded-full bg-danger text-white'
      } else {
        return 'badge rounded-full bg-warning text-white'
      }
    },
    setLoading(value) {
      this.$nextTick(() => (this.isLoading = value))
    },
    setSelected(value) {
      this.jobSelected = value
    },
    descriptionValue(value) {
      if (value === undefined) {
        return value
      }

      let typeFound = this.jobOptions.find(val => val.value === value)

      if (typeFound) {
        return typeFound.name
      } else return value
    },
    async cancelJob(job) {
      let notification

      try {
        if (this.jobSelected === 'orchestrator') {
          // Orchestrator logic:
          await this.jobsApi.cancelJobOrchestrator(job.jobId)
        } else {
          await this.jobsApi.updateJob({
            jobId: job.jobId,
          })
        }

        notification = {
          message: this.$t('Job was cancelled'),
          type: 'success',
        }
        await this.getJobs()

        this.$bus.emit('notification', notification)
      } catch (err) {
        this.$sentry.captureException(err)
        return
      }
    },
    async restartJob(job) {
      let notification

      try {
        await this.jobsApi.updateJob({
          jobId: job.jobId,
          state: 'CREATED',
        })
        notification = {
          message: this.$t('Restart a job ' + job.jobId),
          type: 'success',
        }
        await this.getJobs()

        this.$bus.emit('notification', notification)
      } catch (err) {
        this.$sentry.captureException(err)
        return
      }
    },
    async undoJob(job) {
      let notification

      try {
        await this.jobsApi.undoJob({
          jobId: job.jobId,
        })

        notification = {
          message: this.$t('Undo a job ' + job.jobId),
          type: 'success',
        }
        await this.getJobs()

        this.$bus.emit('notification', notification)
      } catch (err) {
        this.$sentry.captureException(err)
        return
      }
    },
    canUndo(job) {
      return (
        job.jobImpl === 'TrHtrJob' ||
        job.jobImpl === 'FinereaderLaJob' ||
        job.jobImpl === 'KrakenLaJobMultiThread' ||
        job.jobImpl === 'LayoutAnalysis2Job' ||
        job.jobImpl === 'PyLaiaDecodingJob' ||
        job.jobImpl === 'PyLaiaKwsDecodingJob' ||
        job.jobImpl === 'PyLaiaOcrJob' ||
        job.jobImpl === 'P2PaLAJobMultiThread' ||
        job.jobImpl === 'ReplaceJob' ||
        job.jobImpl === 'TableRecognitionJob' ||
        job.jobImpl === 'TranskribusLaJobMultiThread'
      )
    },
    startLongPolling(job) {
      if (job === undefined || job === null || job === '') {
        return
      }
      let jobUUID = job.jobId
      if (jobUUID === undefined || jobUUID === null || jobUUID === '') {
        return
      }

      this.jobsApi.fetchJobOrchestratorLongpoll(jobUUID).then(response => {

          if (response === undefined || response === null || response === '') {
            return
          }
         
          if (response.statusCode === 408) {
            this.startLongPolling(job)
          } 

          if (this.jobs === undefined || this.jobs === null || this.jobs === '' || this.jobs.length === 0) {
            return  
          }
          this.jobs = this.jobs.map(j => {
            if (j.jobId ===  response.uuid && j.state !== response.status) {
              j.state = response.status
              j.job = response
            }
            return j
          })
          
          if (response.links === undefined || response.links === null || response.links === '') {
            return
          }

          let longpollLink = response.links.find(link => link.title === 'Status Longpoll')
          if (longpollLink === undefined || longpollLink === null || longpollLink === '') {
            return
          }

          this.startLongPolling(job)
        }, error => {
          console.log('🚀 ~ getJobs long poll ~ error:', error)
      })
    },  
    // comment polling use refresh button
    async startPolling() {
      let data
      const that = this
      this.polling = setInterval(async function () {
        try {
          const jobsApi = useJobs()
          data = await jobsApi.fetchJobs({ status: 'UNFINISHED' })
        } catch (err) {
          console.log(err)
          // this.$sentry.captureException(err)
          return
        }

        for (const temp in data) {
          const job = data[temp]
          const index = that.jobs.findIndex(el => el.jobId === job.jobId)
          that.$nextTick(() => {
            that.jobs.splice(index, 1)
            that.jobs.splice(index, 0, job)
          })
        }
        if (data.length == 0) {
          return that.stopPolling()
        }
      }, 20000)
    },
    stopPolling() {
      clearInterval(this.polling)
      this.getJobs()
    },
    openJobs() {
      this.$bus.emit('slideOverClose')
    },
    getPathForNuxtLink(item) {
      if (this.jobSelected === 'orchestrator') {
        return
      }
      if (item.docId == -1) {
        return ''
      } else {
        if (item.pages) {
          const pageNumber = item.pages.split(/[, -]+/)[0]
          return this.localePath(
            `/collection/${item.colId}/doc/${item.docId}/edit?pageid=${pageNumber}`
          )
        } else {
          return this.localePath(`/collection/${item.colId}/doc/${item.docId}`)
        }
      }
    },
    openEditor(xml, iiifKey) {
      const modal = {
        modalContent: {
          component: EditorModal,
          props: {
            xml: xml,
            iiifInfo: `${this.runtimeConfig.public.fileStore}/iiif/2/${iiifKey}/info.json`,
          },
        },
      }
      this.$bus.emit('open-modal', {
        modal: modal,
        props: {
          isFullscreen: true,
        },
      })
    },

    async selected(item) {
      console.log('🚀 ~ selected ~ item:', item)
      if (this.jobSelected === 'orchestrator') {
        const pageResult = await this.fetchWithAuth(
          `${this.runtimeConfig.public.REDIRECT_URI}/api/orchestrator/result/${item.jobId}`,
          'GET',
          {}
        )
        const parser = new DOMParser()
        const xmlDoc = parser.parseFromString(pageResult, 'application/xml')

        const pageElement = xmlDoc.querySelector('Page') // or xmlDoc.getElementsByTagName('Page')[0]
        const imageFilename = pageElement
          ? pageElement.getAttribute('imageFilename')
          : null
        const iiifKey = imageFilename.split('.')[0]
        this.openEditor(pageResult, iiifKey)

        // const blob = new Blob([pageResult], { type: 'application/xml' })

        // // Create a download link in memory
        // const link = document.createElement('a')
        // link.href = URL.createObjectURL(blob)
        // link.download = `page-${item.jobId}.xml`
        // link.click()
        // URL.revokeObjectURL(link.href)
        return
      }
      if (item.docId == -1) {
        let notification = {
          message: this.$t('Jobs does not reference document'),
          type: 'error',
        }
        this.$bus.emit('notification', notification)
      } else if (
        item.state == 'FINISHED' &&
        item.docTitle !== 'Deleted Document'
      ) {
        // Check if document is already deleted

        this.$emit('closeModal')
      } else if (item.docTitle === 'Deleted Document') {
        let notification = {
          message: this.$t('Document has been deleted'),
          type: 'error',
        }
        this.$bus.emit('notification', notification)
      } else {
        let notification = {
          message: this.$t('Job failed or is not finnished'),
          type: 'error',
        }
        this.$bus.emit('notification', notification)
      }
    },
    truncate(str, n) {
      const truncateText = useTruncate(str, n)
      return truncateText
    },
    showCancel(job) {
      const jobState = job.state

      if (this.jobSelected === 'orchestrator') {
        return jobState !== 'FINISHED'
      }

      return (
        (job.userId === this.userId || this.isAdmin) &&
        (jobState === 'CREATED' ||
          jobState === 'WAITING' ||
          jobState === 'RUNNING')
      )
    },
    showRestart(job) {
      if (this.jobSelected === 'orchestrator') {
        return false
      }
      const jobState = job.state

      return (
        this.isAdmin &&
        (jobState === 'FINISHED' ||
          jobState === 'CANCELED' ||
          jobState === 'FAILED')
      )
    },
  },
  computed: {
    isAdmin() {
      const { userProfile } = useKeycloak()
      return userProfile?.IsAdmin
    },
    userId() {
      const { userProfile } = useKeycloak()
      return userProfile?.UserId
    },
  },
}
</script>
