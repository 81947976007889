<template>
  <div>
    <!--     <select :value="selectedKeyboardId" @change="changeKeyboard">
      <option :key="k.name" :value="k.id" v-for="k in keyboards">
        {{ k.name }}
      </option>
    </select>
    <h1>{{ selectedKeyboard?.keys }}</h1> -->
    <div :class="keyboardClass"></div>
  </div>
</template>

<script>
import Keyboard from 'simple-keyboard'
import 'simple-keyboard/build/css/index.css'

export default {
  name: 'SimpleKeyboard',
  props: {
    editorName: {
      type: String,
    },

    input: {
      type: String,
    },

    charPerRow: {
      default: 15,
      type: Number,
    },

    charConfig: {
      type: Array,
    },
  },
  data: () => ({
    keyboard: null,
    selectedKeyboardId: null,
    keyboards: [
      { id: 1, name: 'abcName', keys: ['a', 'b', 'c'] },
      { id: 2, name: '123Name', keys: ['1', '2', '3'] },
      { id: 3, name: 'xyzName', keys: ['x', 'y', 'z'] },
    ],
    layout: {
      default: [
        '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
        '{tab} q w e r t y u i o p [ ] \\',
        "{lock} a s d f g h j k l ; ' {enter}",
        '{shift} z x c v b n m , . / {shift}',
        '.com @ {space}',
      ],
    },
    defaultCharacters: ['a', 'b', 'c', '¬ '],
  }),
  computed: {
    keyboardClass() {
      return this.editorName + 'simple-keyboard'
    },
    characters() {
      return this.charConfig || this.defaultCharacters
    },
    selectedKeyboard() {
      return this.keyboards.find(k => k.id === this.selectedKeyboardId)
    },

    charactersLength() {
      return this.characters.length
    },
    canManageColl() {
      if (this.colRole === 'Transcriber' || this.colRole === 'Reader') {
        return false
      } else {
        return true
      }
    },
  },

  mounted() {
    // TODO get the selected keyboard id from the user settings
    this.selectedKeyboardId = 1

    this.load()
  },
  methods: {
    changeKeyboard(event) {
      // TODO save the selected keyboard id to the user settings
      this.selectedKeyboardId = parseInt(event.target.value)
    },
    load() {
      this.keyboard = null
      if (this.characters) {
        this.layout.default = []
        var keyboardLines = this.characters.length / this.charPerRow
        for (let i = 0; i < keyboardLines; i++) {
          let start = i * this.charPerRow
          this.layout.default[i] = this.characters
            .slice(start, start + this.charPerRow)
            .join(' ')
        }
      }
      this.keyboard = new Keyboard(this.keyboardClass, {
        onChange: this.onChange,
        onKeyPress: this.onKeyPress,
        layout: this.layout,
      })
    },
    onChange(input) {
      this.$emit('onChange', input)
    },
    onKeyPress(button) {
      this.$emit('onKeyPress', button)

      /**
       * If you want to handle the shift and caps lock buttons
       */
      if (button === '{shift}' || button === '{lock}') this.handleShift()
    },
    handleShift() {
      let currentLayout = this.keyboard.options.layoutName
      let shiftToggle = currentLayout === 'default' ? 'shift' : 'default'

      this.keyboard.setOptions({
        layoutName: shiftToggle,
      })
    },
  },
  watch: {
    /* input(input) {
      this.keyboard.setInput(input)
    },
    tagDefs: {
      handler: function (newValue, oldValue) {
        this.layout = {}
        this.layout.default = []
        var keyboardLines = this.characters.length / this.charPerRow
        for (let i = 0; i < keyboardLines; i++) {
          let start = i * this.charPerRow
          this.layout.default[i] = this.characters
            .slice(start, start + this.charPerRow)
            .join(' ')
        }
        this.keyboard.setOptions({
          layout: this.layout,
        })
        // this.$nextTick(() => {
        //   this.keyboard.setOptions({
        //     layout: this.layout
        //   })
        //   this.$forceUpdate()
        // })
        // this.$forceUpdate()
      },
      deep: true,
    }, */
  },
}
</script>

<style>
.hg-standardBtn {
  width: auto !important;
}
</style>
