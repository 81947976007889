import Cell from './Cell'
import {
  getRectCoords,
  getClosestLine,
  getSplitCoords,
  whatSideOfLine,
  getMergePartners,
} from '../geometryFunctions.js'

import { lineIntersectsLine } from 'geometric'

function CellMatrix(rowNumber, colNumber, editorName) {
  this.matrix = [...Array(rowNumber)].map(e => Array(colNumber).fill(null))
  this.deletedCells = []
  this.editorName = editorName
}

CellMatrix.ROWSPAN = Object.freeze({
  span: true,
  rowspan: true,
})
CellMatrix.COLSPAN = Object.freeze({
  span: true,
  colspan: true,
})

CellMatrix.SPAN_PACK = Object.freeze({
  pack: true,
})

CellMatrix.prototype.log = function () {
  let stringMatrix = this.matrix.map((row, rowIndex) =>
    row.map(c => {
      if (!c) return 'null'
      if (!c.id) return JSON.stringify(c)
      let string = c.vertices.reduce((string, v) => {
        let newString = `${string}${v.id}, `
        return newString
      }, '')
      return `[${c.id}] ${string.slice(0, -2)}`
    })
  )
  console.table(stringMatrix)
}

CellMatrix.prototype.setCell = function (cell, row, col) {
  if (row > this.matrix.length - 1 || col > this.matrix[0].length - 1) return
  this.removeCell(row, col)
  this.matrix[row][col] = cell
}

CellMatrix.prototype.removeCell = function (row, col) {
  let oldCell = this.matrix[row]?.[col]
  if (oldCell == null) return
  oldCell.unSelect()
  oldCell.removeFromCanvas()
  oldCell.removeChildsFromCanvas()
  this.deletedCells.push({ object: oldCell, row, col })
  this.matrix[row][col] = null
  return oldCell
}

CellMatrix.prototype.removeCellById = function (id) {
  let { row, col } = this.allCells().find(c => c?.id === id)
  this.removeCell(row, col)
}

CellMatrix.prototype.getCell = function (row, col) {
  if (!this.matrix[row]) return
  return this.matrix[row][col]
}

CellMatrix.prototype.getCellById = function (id) {
  return this.allCells().find(c => c?.id === id)
}

CellMatrix.prototype.getWidth = function () {
  return this.matrix[0].length
}

CellMatrix.prototype.getHeight = function () {
  return this.matrix.length
}

CellMatrix.prototype.getCellsOfColumn = function (col) {
  let transposed = this.getTransposedMatrix()
  return transposed[col]
}

CellMatrix.prototype.getCellsOfRow = function (row) {
  return this.matrix[row]
}

CellMatrix.prototype.createColumn = function (colIndex) {
  this.matrix.map(row => {
    row.splice(colIndex, 0, null)
  })
}

CellMatrix.prototype.createRow = function (rowIndex) {
  this.matrix.splice(
    rowIndex,
    0,
    this.matrix[0].map(r => null)
  )
}

CellMatrix.prototype.deleteColumn = function (colIndex) {
  this.matrix.map((row, rowIndex) => {
    this.removeCell(rowIndex, colIndex)
    row.splice(colIndex, 1)
  })
}

CellMatrix.prototype.deleteRow = function (rowIndex) {
  this.matrix[rowIndex].map((_, colIndex) => {
    this.removeCell(rowIndex, colIndex)
  })
  this.matrix.splice(rowIndex, 1)
}

CellMatrix.prototype.noVerticalSplit = function (splitLine, cells) {
  const horizontalCellLines = cells.reduce(
    (acc, cell) => [
      ...acc,
      cell
        .getTopVertices()
        .reduce((points, v) => [...points, [v.left, v.top]], []),
      cell
        .getBottomVertices()
        .reduce((points, v) => [...points, [v.left, v.top]], []),
    ],
    []
  )
  //log(horizontalCellLines)
  return horizontalCellLines.some(line => {
    //log(!lineIntersectsLine(line, splitLine))
    //log(line)
    //log(splitLine)
    return !lineIntersectsLine(line, splitLine)
  })
}

CellMatrix.prototype.noHorizontalSplit = function (splitLine, cells) {
  const verticalCellLines = cells.reduce(
    (acc, cell) => [
      ...acc,
      cell
        .getLeftVertices()
        .reduce((points, v) => [...points, [v.left, v.top]], []),
      cell
        .getRightVertices()
        .reduce((points, v) => [...points, [v.left, v.top]], []),
    ],
    []
  )

  return verticalCellLines.some(line => !lineIntersectsLine(line, splitLine))
}

CellMatrix.prototype.splitForbidden = function (line, cells) {
  let bools = cells.map(cell => {
    let coords = getSplitCoords(
      cell.vertices,
      cell.geometricLines,
      line,
      this.editorName
    ).map(intem => intem.vertices)

    if (coords.length != 3) return true
    let verticalCorrect =
      coords[0].length === 3 && coords[1].length === 4 && coords[2].length === 1
    let horizontalCorrect =
      coords[0].length === 2 && coords[1].length === 4 && coords[2].length === 2
    if (verticalCorrect || horizontalCorrect) return false
    return true
  })
  return bools.some(bool => bool)
}

CellMatrix.prototype.removeForbidden = function (lineIndex, isVertical) {
  if (lineIndex <= 0) return true
  let lastIndex = isVertical ? this.matrix[0].length : this.matrix.length
  return lastIndex <= lineIndex
}

CellMatrix.prototype.areVerticalNeighbors = function (cell1, cell2) {
  let position1 = this.getPosition(cell1)
  let position2 = this.getPosition(cell2)
  let neighborRow =
    position1.row === position2.row + 1 || position1.row === position2.row - 1
  let sameCol = position1.col === position2.col
  return neighborRow && sameCol
}

CellMatrix.prototype.areHorizontalNeighbors = function (cell1, cell2) {
  let position1 = this.getPosition(cell1)
  let position2 = this.getPosition(cell2)
  let neighborCol =
    position1.col === position2.col + 1 || position1.col === position2.col - 1
  let sameRow = position1.row === position2.row
  return neighborCol && sameRow
}

CellMatrix.prototype.getSharedVertexIds = function (cell1, cell2) {
  return cell1.vertices.filter(v => cell2.vertices.includes(v)).map(v => v.id)
}

CellMatrix.prototype.updateCellProperties = function () {
  if (this.allCells().some(cell => cell == null)) {
    warn('Error: invalid Table (missing cell definition)')
  }
  this.allCells().map(c => {
    c.setType(Cell.TYPE.REGULAR)
  })
  this.matrix.map((row, rowIndex) => {
    row.map((c, colIndex) => {
      c.row = rowIndex
      c.col = colIndex
      if (c.rowSpan != 1 || c.colSpan != 1) {
        if (this.incorrectValues(c)) {
          c.rowSpan = 1
          c.colSpan = 1
          return
        }
        let newVertices = this.getSpanVertices(
          rowIndex,
          colIndex,
          c.rowSpan,
          c.colSpan
        )
        c.setSpanVertices(newVertices)
        c.setType(Cell.TYPE.PARENT)
        this.getSpanChilds(rowIndex, colIndex, c.rowSpan, c.colSpan).map(
          child => {
            child.setType(Cell.TYPE.CHILD)
            c.baselines.push(...child.baselines)
            c.text = c.text + child.text
            child.baselines = []
            child.text = ''
            child.unSelect()
            child.removeFromCanvas()
          }
        )
      }
    })
  })
}

CellMatrix.prototype.getSpanVertices = function (row, col, rowspan, colspan) {
  let rowSteps = Array.from({ length: rowspan }, (_, i) => i)
  let colSteps = Array.from({ length: colspan }, (_, i) => i)
  let vertices = []
  rowSteps.map(step => {
    vertices.push(...this.getCell(row + step, col).getLeftVertices())
  })
  colSteps.map(step => {
    vertices.push(
      ...this.getCell(row + rowSteps.length - 1, col + step).getBottomVertices()
    )
  })
  rowSteps.reverse().map(step => {
    vertices.push(
      ...this.getCell(row + step, col + colSteps.length - 1).getRightVertices()
    )
  })
  colSteps.reverse().map(step => {
    vertices.push(...this.getCell(row, col + step).getTopVertices())
  })
  return [...new Set(vertices)]
}

CellMatrix.prototype.getSpanChilds = function (row, col, rowspan, colspan) {
  let rowSteps = Array.from({ length: rowspan }, (_, i) => i)
  let colSteps = Array.from({ length: colspan }, (_, i) => i)
  let childs = []
  rowSteps.map(rStep => {
    colSteps.map(cStep => {
      if (rStep > 0 || cStep > 0) {
        childs.push(this.getCell(row + rStep, col + cStep))
      }
    })
  })
  return childs
}

CellMatrix.prototype.incorrectValues = function (cell) {
  let rowSteps = Array.from({ length: cell.rowSpan }, (_, i) => i)
  let colSteps = Array.from({ length: cell.colSpan }, (_, i) => i)
  let { row, col } = this.getPosition(cell)

  let foundError = rowSteps.some(rStep =>
    colSteps.some(cStep => {
      let child = this.getCell(row + rStep, col + cStep)
      return child == null || child.isSpanParent() || child.isSpanChild()
    })
  )
  return foundError
}

CellMatrix.prototype.getPosition = function (cell) {
  let position = {}
  this.matrix.find((row, rowIndex) => {
    return row.find((c, colIndex) => {
      position.row = rowIndex
      position.col = colIndex
      return c === cell
    })
  })
  return position
}

CellMatrix.prototype.allCells = function () {
  return this.matrix.flat()
}

CellMatrix.prototype.allVertices = function () {
  return [
    ...this.allCells().reduce(
      (all, c) => new Set([...all, ...c.vertices]),
      new Set()
    ),
  ]
}

CellMatrix.prototype.getTransposedMatrix = function () {
  return this.matrix[0].map((_, colIndex) =>
    this.matrix.map(row => row[colIndex])
  )
}

CellMatrix.prototype.rowExists = function (row) {
  return this.matrix[row] ? true : false
}

CellMatrix.prototype.colExists = function (col) {
  return this.matrix[0][col] ? true : false
}

CellMatrix.prototype.getVerticalCircles = function () {
  let takenVertices = []

  let list = []
  this.getTransposedMatrix().map((col, index, cols) => {
    list.push(
      col.reduce((list, cell) => {
        let leftVertices = cell
          .getLeftVertices()
          .filter(v => !takenVertices.includes(v))
        takenVertices.push(...leftVertices)
        return [...list, ...leftVertices]
      }, [])
    )

    if (index >= cols.length - 1) {
      list.push(
        col.reduce((list, cell) => {
          let rightVertices = cell
            .getRightVertices()
            .filter(v => !takenVertices.includes(v))
          takenVertices.push(...rightVertices)
          return [...list, ...rightVertices]
        }, [])
      )
    }
  })
  return list
}

CellMatrix.prototype.getHorizontalCircles = function () {
  let takenVertices = []

  let list = []
  this.matrix.map((row, index, rows) => {
    list.push(
      row.reduce((list, cell) => {
        let topVertices = cell
          .getTopVertices()
          .filter(v => !takenVertices.includes(v))
        takenVertices.push(...topVertices)
        return [...list, ...topVertices]
      }, [])
    )
    if (index >= rows.length - 1) {
      list.push(
        row.reduce((list, cell) => {
          let bottomVertices = cell
            .getBottomVertices()
            .filter(v => !takenVertices.includes(v))
          takenVertices.push(...bottomVertices)
          return [...list, ...bottomVertices]
        }, [])
      )
    }
  })
  return list
}

CellMatrix.prototype.getCellParent = function (row, col) {
  return this.allCells().find(p => {
    let found =
      row >= p.row &&
      row < p.row + p.rowSpan &&
      col >= p.col &&
      col < p.col + p.colSpan

    return found
  })
}

CellMatrix.prototype.getNeighbor = function (row, col, direction) {
  const currentParent = this.getCellParent(row, col)
  let neighbor
  switch (direction) {
    case 'right':
      neighbor = this.getCell(row, col + 1)
      break
    case 'left':
      neighbor = this.getCell(row, col - 1)
      break
    case 'top':
      neighbor = this.getCell(row - 1, col)
      break
    case 'bottom':
      neighbor = this.getCell(row + 1, col)
      break
  }
  if (neighbor == null) return
  const newParent = this.getCellParent(neighbor.row, neighbor.col)
  if (currentParent === newParent) {
    return this.getNeighbor(neighbor.row, neighbor.col, direction)
  }
  return neighbor
}

CellMatrix.prototype.mergeParents = function (cell1, cell2) {
  const minRow = Math.min(cell1.row, cell2.row)
  const minCol = Math.min(cell1.col, cell2.col)
  const maxRow = Math.max(
    cell1.row + cell1.rowSpan - 1,
    cell2.row + cell2.rowSpan - 1
  )
  const maxCol = Math.max(
    cell1.col + cell1.colSpan - 1,
    cell2.col + cell2.colSpan - 1
  )
  const newParent = this.getCell(minRow, minCol)

  const newRowSpan = maxRow - minRow + 1
  const newColSpan = maxCol - minCol + 1
  return { newParent, newRowSpan, newColSpan }
}

CellMatrix.prototype.getAllParents = function () {
  return this.allCells().filter(c => c.isSpanParent())
}

CellMatrix.prototype.getCellsOfVertex = function (id) {
  return this.allCells().filter(cell => cell.vertices.some(v => v.id === id))
}

export default CellMatrix
