import { calcLines } from '../geometryFunctions.js'
import { Singleton as IdHandler } from '../singletons/IdHandler.js'
import { Singleton as MyFabric } from '../myFabric.js'

import Shape from './Shape.js'
import Baseline from './Baseline.js'
import { polygonHull, pointRotate, polygonMean } from 'geometric'
import { getRotationAngle, getScaleFactor } from '../../globals.js'
import { ACTION_TYPE } from '../singletons/actionHandler.js'

function Cell(canvas, blueprint, editorName, getActiveShapeIds, emitPointMove) {
  Shape.call(this, canvas, editorName)
  this.canvas = canvas
  this.row = null
  this.col = null
  this.rowSpan = 1
  this.colSpan = 1
  this.spanVertices = null
  this.area = null
  this.vertices = []
  this.invisibleVertices = []
  this.baselines = []
  this.selected = false
  this.tableSelected = false
  this.type = Cell.TYPE.REGULAR
  this.getActiveShapeIds = getActiveShapeIds
  this.emitPointMove = emitPointMove

  if (blueprint) {
    this.attributes = blueprint.attributes
    let { row, col, rowSpan, colSpan } = blueprint.attributes
    this.row = parseInt(row)
    this.col = parseInt(col)
    this.rowSpan = rowSpan ? parseInt(rowSpan) : 1
    this.colSpan = colSpan ? parseInt(colSpan) : 1
    this.elements = blueprint.elements
    this.initMatrixCoords = blueprint.matrixCoords
    this.cornerPoints = this.elements.cornerPoints
    this.id = blueprint.attributes.id
    IdHandler.instance(this.editorName).addId(this.id)

    this.baselines = blueprint.elements.textLines.map(
      (blueprint, i) =>
        new Baseline(
          this.canvas,
          blueprint,
          editorName,
          false,
          i,
          getActiveShapeIds
        )
    )
    this.update()
  }
}
Cell.prototype = Object.create(Shape.prototype)
Cell.prototype.constructor = Cell

Cell.TYPE = Object.freeze({
  REGULAR: 0,
  PARENT: 1,
  CHILD: 2,
})

Cell.prototype.getNextPointRight = function (point) {
  const pointIndex = this.coords.findIndex(
    p => p.x === point.x && p.y === point.y
  )
  if (pointIndex === 0) return this.coords[3]
  if (pointIndex === 1) return this.coords[2]
}

Cell.prototype.getNextPointDown = function (point) {
  const pointIndex = this.coords.findIndex(
    p => p.x === point.x && p.y === point.y
  )
  if (pointIndex === 0) return this.coords[1]
  if (pointIndex === 3) return this.coords[2]
}

Cell.prototype.hasPoint = function (point) {
  return this.coords.some(p => p.x === point.x && p.y === point.y)
}

Cell.prototype.hasPointLeft = function (point) {
  return (
    this.hasPoint(point) &&
    (this.indexOfPoint(point) === 0 || this.indexOfPoint(point) === 1)
  )
}

Cell.prototype.hasPointTop = function (point) {
  return (
    this.hasPoint(point) &&
    (this.indexOfPoint(point) === 0 || this.indexOfPoint(point) === 3)
  )
}

Cell.prototype.indexOfPoint = function (point) {
  return this.coords.findIndex(p => p.x === point.x && p.y === point.y)
}

Cell.prototype.getBlueprint = function (myIndex) {
  //this.attributes.id = keepId ? this.id : `${parentId}c${myIndex + 1}`
  this.attributes.id = this.id
  this.attributes.row = this.row
  this.attributes.col = this.col
  this.attributes.rowSpan = this.rowSpan
  this.attributes.colSpan = this.colSpan

  /* this.elements.coords = this.coords

  this.elements.textLines = this.baselines.map((line, index) =>
    line.getBlueprint(index)
  )
  this.elements.cornerPoints = this.getCornerPointsIndices() */

  return {
    attributes: this.attributes,
    elements: {
      coords: this.coords,
      textLines: this.baselines.map((line, index) => line.getBlueprint(index)),
      cornerPoints: this.getCornerPointsIndices(),
    },
  }
}

Cell.prototype.getVertexByCoords = function (
  x,
  y,
  tableVertices,
  tolerance = 0
) {
  const round = x => Math.round(x * 1000000) / 1000000
  return tableVertices.find(c => {
    const { left, top } = {
      left: round(c.left),
      top: round(c.top),
    }
    return Math.abs(left - x) <= tolerance && Math.abs(top - y) <= tolerance
  })
}

Cell.prototype.getType = function () {
  return Shape.TYPE.CELL
}

Cell.prototype.getLayout = function () {
  return {
    type: 'Cell',
    id: this.id,
    children: this.baselines.map(l => l.getLayout()),
    structureType: this.getStructureType(),
    custom: this.attributes.custom,
    position: {
      row: this.row,
      col: this.col,
      rowSpan: this.rowSpan,
      colSpan: this.colSpan,
    },
  }
}

// Cell.prototype.on = function (key, handler) {
//   this.eventHandlers[key].push(handler)
// }

// Cell.prototype.emit = function (key, event) {
//   this.eventHandlers[key].map((h) => h(event))
// }

Cell.prototype.select = function () {
  /*   let vertices = this.isSpanParent() ? this.spanVertices : this.vertices
  if (this.selected) return
  this.selected = true
  this.baselines.map(line => {
    line.polygon?.set('fill', 'purple')
  })
  this.canvas.add(...vertices)
  this.updateBackground(vertices) */
}

Cell.prototype.unSelect = function () {
  /*   if (!this.selected) return
  let vertices = this.isSpanParent() ? this.spanVertices : this.vertices
  this.selected = false
  this.baselines.map(line => {
    line.polygon?.set('fill', 'blue')
  })
  this.canvas.remove(...vertices)
  this.updateBackground(vertices)
  if (!this.tableSelected) {
    this.showFrameOnly()
  }
  this.updateStructureText() */
}

Cell.prototype.handleMouseMoveEvent = function (event) {
  // this.emit('mouseMove', event)
}

Cell.prototype.updateLine = function (coords) {
  this.canvas.remove(this.frame)
  this.frame = this.myFabric.getCellFrame(coords)
  this.canvas.add(this.frame)
  this.canvas.sendToBack(this.frame)
}

Cell.prototype.updateBackground = function (coords) {
  this.canvas.remove(this.background)
  this.background = this.myFabric.getCellBackground(
    coords,
    this.getColor(),
    0.3
  )
  if (this.selected) {
    this.canvas.add(this.background)
    this.canvas.sendToBack(this.background)
  }
}

Cell.prototype.isSpanParent = function () {
  return this.rowSpan > 1 || this.colSpan > 1
}

Cell.prototype.isSpanChild = function () {
  return this.elements.coords.length === 0
}

Cell.prototype.getCornerPointsIndices = function () {
  return [
    0,
    this.rowSpan,
    this.rowSpan + this.colSpan,
    this.rowSpan * 2 + this.colSpan,
  ]
}

Cell.prototype.getColor = function () {
  if (this.selected) return 'selected'
  if (this.isSpanParent()) return 'spanning'
  return (this.row + this.col) % 2 === 0 ? 'even' : 'odd'
}

Cell.prototype.getMidPoint = function () {
  let coords
  if (this.vertices.length > 0) {
    coords = this.vertices.map(v => [v.left, v.top])
  } else {
    coords = this.getScaledCoords().map(c => [c.x, c.y])
  }
  return polygonMean(coords)
}

Cell.prototype.mark = function () {
  this.canvas.remove(this.background)
  this.background = this.myFabric.getCellBackground(
    this.getScaledCoords(),
    this.getColor(),
    0.3
  )
  this.canvas.add(this.background)
}

Cell.prototype.unMark = function () {
  this.canvas.remove(this.background)
}

Cell.prototype.getScaledCoords = function () {
  const factor = getScaleFactor()
  const rotationAngle = getRotationAngle()
  this.area = []
  return this.elements.coords.map(c => {
    const scaled = pointRotate([c.x * factor, c.y * factor], rotationAngle)
    this.area.push(scaled)
    return { x: scaled[0], y: scaled[1] }
  })
}

Cell.prototype.getScaledMatrixCoords = function () {
  const factor = getScaleFactor()
  const rotationAngle = getRotationAngle()
  return this.initMatrixCoords.map(c => {
    const scaled = pointRotate([c.x * factor, c.y * factor], rotationAngle)
    return { x: scaled[0], y: scaled[1] }
  })
}

Cell.prototype.updateVertices = function (coords) {
  this.canvas.remove(...this.vertices)
  if (!(this.selected && this.myFabric.isFocused)) return
  this.vertices = coords.map(coord => this.getVertex(coord.x, coord.y)) || []
  this.canvas.add(...this.vertices)
}

Cell.prototype.getVertex = function (x, y, id) {
  let vertex = this.myFabric.getRegionCircle(x, y, id)

  this.lastAddedVertex = vertex
  let me = this

  vertex.select = function () {
    if (me.activeVertex) {
      me.activeVertex.set('fill', this.defaultColor)
    }
    me.activeVertex = this
    me.activeVertex.set('fill', this.selectedColor)
  }

  vertex.unSelect = function () {
    if (me.activeVertex) {
      me.activeVertex.set('fill', this.defaultColor)
      me.activeVertex = null
    }
  }

  vertex.on('mousedown', o => {
    me.tempCoords = { left: vertex.left, top: vertex.top }
    vertex.select()
  })
  vertex.on('moving', function (o) {
    //me.update({ geometricLines: false })
  })
  vertex.on('mouseup', o => {
    if (
      this.tempCoords &&
      this.state != Shape.STATE.GROUP_SELECTION &&
      (this.tempCoords.left != this.left || this.tempCoords.top != this.top)
    ) {
      const vector = {
        x: vertex.left - this.tempCoords.left,
        y: vertex.top - this.tempCoords.top,
      }

      vertex.set({ left: this.tempCoords.left, top: this.tempCoords.top })
      vertex.setCoords()

      this.emitPointMove(
        this.row,
        this.rowSpan,
        this.col,
        this.colSpan,
        { x: vertex.left, y: vertex.top },
        vector
      )
      this.tempCoords = null
    }
  })
  // vertex.on('mouseover', function (o) {
  //   if (
  //     this.radius != this.largeSize &&
  //     (me.state === Shape.STATE.EDIT || me.state === Shape.STATE.REMOVE_VERTEX)
  //   ) {
  //     this.set({ radius: this.largeSize })
  //     this.bringToFront()
  //     me.canvas.requestRenderAll()
  //     me.hoveredVertex = this
  //     if (me.state === Shape.STATE.REMOVE_VERTEX) {
  //       this.set({ fill: this.deleteColor })
  //     }
  //   }
  // })
  return vertex
}

Cell.prototype.setState = function (state) {
  //never allow change of Selection only
  if (this.state === Shape.STATE.SELECTION_ONLY) return
  if (this.state === Shape.STATE.SEARCH_RESULTS) return

  this.state = state
}

Cell.prototype.update = function () {
  const coords = this.getScaledCoords()
  this.matrixCoords = this.getScaledMatrixCoords()
  this.coords = [...coords]

  /*   console.log('Region update', coords)
   */
  this.selected = this.getActiveShapeIds?.().includes(this.id)
  if (!this.isVisible()) {
    this.removeFromCanvas()
    return
  }
  if (this.isSpanChild()) return
  this.updateVertices(coords)
  /* this.updateVertexSize() */
  this.updateStructureColor(coords)
  this.updateStructureText(coords)
  this.updateLine(coords)
  this.updateBackground(coords)

  /* if (this.selected) {
    this.updateGeometricLines(this.vertices)
  } */
  if (this.state === Shape.STATE.SELECTION_ONLY) {
    this.canvas.remove(...this.vertices)
  }
  if (this.state === Shape.STATE.SEARCH_RESULTS) {
    this.removeFromCanvas()
  }
}

Cell.prototype.setType = function (type) {
  this.type = type
}

Cell.prototype.addBaseline = function (line, index) {
  if (index == null) {
    let newLineY = line.vertices[0].top
    let yValues = this.baselines
      .map(parentLine => parentLine.vertices[0].top)
      .sort(function (a, b) {
        return a - b
      })
    let newIndex = yValues.findIndex(value => value > newLineY)
    newIndex = newIndex === -1 ? yValues.length : newIndex
    this.baselines.splice(newIndex, 0, line)
    return
  }
  this.baselines.splice(index, 0, line)
}

Cell.prototype.removeBaseline = function (id) {
  this.baselines = this.baselines.filter(l => l.id != id)
}

Cell.prototype.expectedIndex = function (coords) {
  let newLineY = coords[0].y
  let yValues = this.baselines
    .map(parentLine => parentLine.baselineCoords?.[0]?.y)
    .sort(function (a, b) {
      return a - b
    })
  let newIndex = yValues.findIndex(value => value > newLineY)
  return newIndex === -1 ? yValues.length : newIndex
}

Cell.prototype.removeFromCanvas = function () {
  this.canvas.remove(this.background)
  this.canvas.remove(this.frame)
  this.canvas.remove(...this.vertices)
  this.canvas.remove(this.structureText)
  this.canvas.remove(this.structureColorFrame)
  this.canvas.remove(this.readingOrderText)
}

Cell.prototype.removeChildsFromCanvas = function () {
  this.baselines.map(bl => {
    bl.unSelect()
    bl.removeFromCanvas()
    bl.removeChildsFromCanvas()
  })
}

Cell.prototype.getGroup = function () {
  const coords = this.getScaledCoords()

  this.vertices = coords.map(coord =>
    this.myFabric.getRegionCircle(coord.x, coord.y)
  )

  let vertices = this.vertices.map(v =>
    this.myFabric.clone(v).set({ radius: 0 })
  )

  let members = [this.frame, ...vertices]

  return new fabric.Group(members, {
    id: this.id,
    hasControls: true,
    subTargetCheck: true,
    cornerStyle: 'circle',
    padding: 0,
    cornerSize: 8,
    cornerColor: 'transparent',
    transparentCorners: true,
    cornerStrokeColor: 'green',
    lockSkewingX: true,
    lockSkewingY: true,
  }).setControlsVisibility({
    mtr: false,
  })
}

Cell.prototype.showFrameOnly = function () {
  let vertices = this.isSpanParent() ? this.spanVertices : this.vertices
  this.canvas.remove(this.background)
  this.canvas.remove(...vertices)
  if (this.isSpanChild()) {
    this.canvas.remove(this.frame)
  } else {
    if (!this.canvas.contains(this.frame)) {
      if (this.frame == null) {
        this.frame = this.myFabric.getCellFrame(
          vertices.map(({ left, top }) => ({ x: left, y: top }))
        )
      }

      this.canvas.add(this.frame)
    }
  }
}

Cell.prototype.getArea = function () {
  return this.area
}

Cell.prototype.getAreaForced = function () {
  return this.area
}

Cell.prototype.getCellPolygon = function () {
  return this.coords.map(p => [p.x, p.y])
}

Cell.prototype.setVertices = function (vertices) {
  this.vertices = [...vertices]
}

Cell.prototype.setSpanVertices = function (vertices) {
  this.spanVertices = [...vertices]
}

Cell.prototype.getLeftCoords = function () {
  let [first, second] = this.matrixCoords
  return [first, second]
}
Cell.prototype.getBottomCoords = function () {
  let [, second, third] = this.matrixCoords

  return [second, third]
}
Cell.prototype.getRightCoords = function () {
  let [, , third, fourth] = this.matrixCoords
  return [fourth, third]
}

Cell.prototype.getTopCoords = function () {
  let [first, , , fourth] = this.matrixCoords
  return [first, fourth]
}

/* Cell.prototype.getTopCoordsSpanning = function () {
  let [first, , , fourth] = this.matrixCoords
  return [first, fourth]
} */

Cell.prototype.getLeftCoordsSpanning = function () {
  return this.coords.slice(0, 1 + this.rowSpan)
}
Cell.prototype.getBottomCoordsSpanning = function () {
  return this.coords.slice(this.rowSpan, this.rowSpan + this.colSpan + 1)
}
Cell.prototype.getRightCoordsSpanning = function () {
  return this.coords.slice(
    this.rowSpan + this.colSpan,
    this.rowSpan * 2 + this.colSpan + 1
  )
}
Cell.prototype.getTopCoordsSpanning = function () {
  return this.coords
    .slice(0, 1)
    .concat(this.coords.slice(-this.colSpan).toReversed())
}

Cell.prototype.getPointsLocation = function (index1, index2) {}

export default Cell
