import { xml2js, js2xml } from 'xml-js'
import { parseCustom, stringifyCustom } from './blueprint'
import { ACTION_TYPE } from '../LayoutEditor/components/singletons/actionMethods'
import {
  getTextLineBlueprint,
  getTextLineJson,
  getRegionBlueprint,
  getRegionJson,
  getTableJson,
  getRelationJson,
  jsonToBlueprint,
  getRegionRefFromId,
  getCellJson,
  getTextEquivJson,
  validateCells,
  processTableRegions,
  getRegionJsonWithParams,
} from '~/public/workers/worker2'
import { array_move } from '../LayoutEditor/components/geometryFunctions'
import { applyPatch, compare } from 'fast-json-patch'
import { areConsecutiveShapes, getNewIdsWithShift } from './modelUtils.ts'
import { Singleton as ActionHandler } from '../LayoutEditor/components/singletons/actionHandler.js'

let pageJson = null
let layoutHandler = null
let textHandler = null
let navbarHandler = null
let content = null
let blueprints = null

function setPageJson(json) {
  console.log('Set initial page json:', cloneObj(json))
  if (json == null) {
    pageJson = null
  } else {
    pageJson = cloneObj(json)
  }
}

function xmlStringToJson(xmlString) {
  const data = xml2js(xmlString, {
    compact: false,
    trim: false,
    alwaysArray: true,
    alwaysChildren: true,
    //This is used to remove line breaks from the text of the Unicode tag
    textFn: (text, parent) => {
      if (parent.name === 'Unicode') {
        return text.replace(/\n/g, '')
      }
      return text
    },
  })
  processTableRegions(data)
  return data
}

function jsonToXmlString(pageJson) {
  return js2xml(pageJson, {
    compact: false,
    ignoreComment: true,
    spaces: 4,
    attributesFn: (attributes, parent) => {
      if (attributes.imgUrl) {
        attributes.imgUrl = attributes.imgUrl.replace(/&(?!amp;)/g, '&amp;')
      }
      if (attributes.imageFilename) {
        attributes.imageFilename = attributes.imageFilename.replace(
          /&(?!amp;)/g,
          '&amp;'
        )
      }

      return attributes
    },
  })
}

function applyActions(actions) {
  actions.forEach(action => {
    handleAction(action, false)
  })
  update(actions.map(a => a.type))
}

function getTextData(pageJson) {
  return getRegions(pageJson).map(r => {
    if (r.name === 'TextRegion') {
      return getContentTextRegion(r)
    }
    if (r.name === 'TableRegion') {
      return getContentTableRegion(r)
    }
  })
}

function getTextDataFromModel() {
  return getRegions(pageJson).map(r => {
    if (r.name === 'TextRegion') {
      return getContentTextRegion(r)
    }
    if (r.name === 'TableRegion') {
      return getContentTableRegion(r)
    }
  })
}

function getLayoutData(pageJson) {
  return jsonToBlueprint(pageJson)
}

function createContent() {
  content = getTextDataFromModel()
  updateTextEditor()
}

function updateNavbar() {
  navbarHandler(getTranskribusMetadata(pageJson))
}

function startBlueprintWorker() {
  /* const worker = new Worker('/workers/worker.js')
  worker.postMessage(pageJson)

  worker.onmessage = function (event) {
    blueprints = event.data
    updateLayouteditor(blueprints)
  }

  worker.onerror = function (error) {
    console.error('Error in worker:', error.message)
  } */
  blueprints = jsonToBlueprint(pageJson)
  updateLayouteditor(blueprints)
}
function orderLinesOfParent(parent) {
  const otherElements = parent.elements.filter(e => e.name !== 'TextLine')
  const textLines = parent.elements.filter(e => e.name === 'TextLine')
  parent.elements = [
    ...otherElements,
    ...textLines.map((l, index) => {
      const custom = parseCustom(l.attributes.custom || '')
      custom.readingOrder = index
      l.attributes.custom = stringifyCustom(custom)
      return l
    }),
  ]
}
function getXmlAndStatus() {
  const page = getPage(pageJson)
  const regions = getTextAndTableRegions()
  const otherElements = page.elements.filter(
    region =>
      region.name != 'TextRegion' &&
      region.name != 'TableRegion' &&
      region.name != 'ReadingOrder'
  )
  page.elements = [
    ...otherElements,
    ...regions.map((r, index) => {
      const custom = parseCustom(r.attributes.custom || '')
      custom.readingOrder = index
      r.attributes.custom = stringifyCustom(custom)
      orderLinesOfParent(r)
      const cells = r.elements.filter(e => e.name === 'TableCell')
      cells.forEach(cell => {
        orderLinesOfParent(cell)
      })
      return r
    }),
  ]

  return {
    xml: js2xml(pageJson, {
      compact: false,
      ignoreComment: true,
      spaces: 4,
      attributesFn: (attributes, parent) => {
        if (attributes.imgUrl) {
          attributes.imgUrl = attributes.imgUrl.replace(/&(?!amp;)/g, '&amp;')
        }
        if (attributes.imageFilename) {
          attributes.imageFilename = attributes.imageFilename.replace(
            /&(?!amp;)/g,
            '&amp;'
          )
        }

        return attributes
      },
    }),
    status: getTranskribusMetadata(pageJson)?.attributes?.status,
  }
}

function getContent() {
  return content
}

function updateTextEditor() {
  //console.log('content:', content)
  textHandler(content)
}

function createBlueprintFromId(id) {
  const shape = getShapeById(id)
  if (shape.name === 'TextLine') {
    return getTextLineBlueprint(shape)
  }
}

function updateLayouteditor(data) {
  layoutHandler(data)
}

function getStructureType(shape) {
  let selectors = parseCustomModel(shape.attributes?.custom || '')
  const structureSelector = selectors.find(
    rule => rule.selector === 'structure'
  )
  const typeRule = structureSelector?.rules.find(
    rule => rule.directive === 'type'
  )
  return typeRule?.value
}

function getStructureLevel(shape) {
  let selectors = parseCustomModel(shape.attributes?.custom || '')
  const structureSelector = selectors.find(
    rule => rule.selector === 'structure'
  )
  const levelRule = structureSelector?.rules.find(
    rule => rule.directive === 'level'
  )
  return levelRule?.value
}

function getContentTextRegion(region) {
  return {
    type: 'Region',
    id: region.attributes.id,
    children: getTextLines(region).map(l => getContentTextLine(l)),
    structureType: getStructureType(region),
    structureLevel: getStructureLevel(region),
  }
}

function getContentTableRegion(table) {
  return {
    type: 'Table',
    id: table.attributes.id,
    children: getCells(table).map(c => getContentCell(c)),
    structureType: getStructureType(table),
  }
}

function getContentTextLine(line) {
  return {
    type: 'TextLine',
    text: getContentText(line),
    id: line.attributes.id,
    custom: parseCustom(line.attributes.custom || ''),
  }
}

function getContentCell(cell) {
  return {
    type: 'Cell',
    id: cell.attributes.id,
    children: getTextLines(cell).map(l => getContentTextLine(l)),
    position: {
      row: Number(cell.attributes.row),
      col: Number(cell.attributes.col),
      rowSpan: Number(cell.attributes.rowSpan),
      colSpan: Number(cell.attributes.colSpan),
    },
  }
}

function getTextlineParent(id) {
  const regions = getTextAndTableRegions()
  for (let region of regions) {
    const textLines = getTextLinesOfRegion(region)
    for (let line of textLines) {
      if (line.attributes.id === id) {
        return region
      }
    }
    const cells = getCells(region)
    for (let cell of cells) {
      const textLines = getTextLinesOfRegion(cell)
      for (let line of textLines) {
        if (line.attributes.id === id) {
          return cell
        }
      }
    }
  }
}

function getContentText(textLine) {
  const textEquiv = textLine.elements.find(e => e.name === 'TextEquiv')
  if (
    textEquiv?.elements?.length > 0 &&
    textEquiv.elements[0].elements.length > 0
  ) {
    return textEquiv.elements[0].elements[0].text
  }
  return ''
}

function getPageElements(pageJson) {
  return getPage(pageJson).elements
}

function getPage(pageJson) {
  const pcgts = pageJson.elements.find(e => e.name === 'PcGts')
  if (pcgts == null) {
    warn('Error: not PcGts tag in pageXml')
    return
  }
  const page = pcgts.elements.find(e => e.name === 'Page')
  if (page == null) {
    warn('Error: not Page tag in pageXml')
    return
  }
  return page
}

function getTranskribusMetadata(pageJson) {
  const metaData = getMetadata(pageJson)
  if (metaData == null) {
    warn('Error: not Page tag in pageXml')
    return
  }
  const transkribusMetadata = metaData.elements.find(
    e => e.name === 'TranskribusMetadata'
  )
  if (transkribusMetadata == null) {
    return
  }
  return transkribusMetadata
}

function getMetadata(pageJson) {
  const pcgts = pageJson.elements.find(e => e.name === 'PcGts')
  if (pcgts == null) {
    warn('Error: not PcGts tag in pageXml')
    return
  }
  const metaData = pcgts.elements.find(e => e.name === 'Metadata')
  return metaData
}

function setTranskribusMetadata(transkribusMetadata) {
  const metaData = getMetadata(pageJson)
  metaData.elements = metaData.elements.filter(
    e => e.name !== 'TranskribusMetadata'
  )
  metaData.elements.push(transkribusMetadata)
}

function getTextRegions(pageJson) {
  const pageElements = getPageElements(pageJson)
  return pageElements.filter(e => e.name === 'TextRegion')
}

function getRegions(pageJson) {
  const pageElements = getPageElements(pageJson)
  return pageElements.filter(
    e => e.name === 'TextRegion' || e.name === 'TableRegion'
  )
}

function getTableRegions(pageJson) {
  const pageElements = getPageElements(pageJson)
  return pageElements.filter(e => e.name === 'TableRegion')
}

function getRelations(pageJson) {
  const pageElements = getPageElements(pageJson)
  return pageElements.find(e => e.name === 'Relations')
}

function orderCells(cells) {
  return cells.toSorted((a, b) => {
    if (a.attributes.row < b.attributes.row) return -1
    if (a.attributes.row > b.attributes.row) return 1
    if (a.attributes.col < b.attributes.col) return -1
    if (a.attributes.col > b.attributes.col) return 1
    return 0
  })
}

function setRelations(relations) {
  const page = getPage(pageJson)
  page.elements = page.elements.filter(e => e.name !== 'Relations')
  page.elements = [relations, ...page.elements]
}

function getTextAndTableRegions(currentPageJson) {
  const pageElements = getPageElements(currentPageJson || pageJson)
  return pageElements.filter(
    e => e.name === 'TextRegion' || e.name === 'TableRegion'
  )
}

function setTextAndTableRegions(regions) {
  const page = getPage(pageJson)
  page.elements = page.elements.filter(
    e => e.name !== 'TextRegion' && e.name !== 'TableRegion'
  )
  page.elements = [...page.elements, ...regions]
}

function setTextlinesForRegion(region, textlines) {
  region.elements = region.elements.filter(e => e.name !== 'TextLine')
  region.elements = [...region.elements, ...textlines]
}

function getTextLinesOfRegion(region) {
  return region.elements.filter(e => e.name === 'TextLine')
}

function getTextLines(region) {
  if (region) {
    return region.elements.filter(e => e.name === 'TextLine')
  }
  const textRegions = getTextRegions(pageJson)
  return textRegions
    .map(tr => tr.elements.filter(e => e.name === 'TextLine'))
    .flat()
}

function getCells(table) {
  return table.elements.filter(e => e.name === 'TableCell')
}

function setCellsForTable(table, cells) {
  table.elements = table.elements.filter(e => e.name !== 'TableCell')
  table.elements = [...table.elements, ...cells]
}

function getAllCells() {
  const tables = getTableRegions(pageJson)
  return tables
    .map(tr => tr.elements.filter(e => e.name === 'TableCell'))
    .flat()
}

function getAllShapes() {
  return [...getTextAndTableRegions(), ...getTextLines(), ...getAllCells()]
}

function getBaselineElement(textLine) {
  return textLine.elements.find(e => e.name === 'Baseline')
}
function getBaseline(id) {
  return getPointListfromString(
    getAttributes(getBaselineElement(getShapeById(id))).points
  )
}

function setBaseline(id, baseline) {
  const baselineAttributes = getAttributes(getBaselineElement(getShapeById(id)))
  baselineAttributes.points = getStringfromPointList(baseline)
}

function getCoordsElement(textLine) {
  return textLine.elements.find(e => e.name === 'Coords')
}

function getAttributes(element) {
  return element.attributes
}

function getPointListfromString(string) {
  var coords = string.split(' ')
  let result = coords.map(c => {
    let list = c.split(',')
    return { x: list[0], y: list[1] }
  })
  return result
}

function getStringfromPointList(list) {
  let result = list.map(c => `${c.x},${c.y}`).join(' ')
  return result
}

function getShapeById(id, currentPageJson) {
  const regions = getTextAndTableRegions(currentPageJson)
  for (let region of regions) {
    if (region.attributes.id === id) {
      return region
    }
    const textLines = getTextLinesOfRegion(region)
    for (let line of textLines) {
      if (line.attributes.id === id) {
        return line
      }
    }
    const cells = getCells(region)
    for (let cell of cells) {
      if (cell.attributes.id === id) {
        return cell
      }
      const textLines = getTextLinesOfRegion(cell)
      for (let line of textLines) {
        if (line.attributes.id === id) {
          return line
        }
      }
    }
  }
}

function setLayoutHandler(handler) {
  layoutHandler = handler
}

function setNavbarHandler(handler) {
  navbarHandler = handler
}

function setTextHandler(handler) {
  textHandler = handler
}

function getCoords(id) {
  return getPointListfromString(
    getAttributes(getCoordsElement(getShapeById(id))).points
  )
}

function setCoords(id, coords) {
  const coordsAttributes = getAttributes(getCoordsElement(getShapeById(id)))
  coordsAttributes.points = getStringfromPointList(coords)
}

function getRemoveRegionOpWithReverse(id, page) {
  const region = page.elements.find(e => e.attributes?.id === id)
  const index = page.elements.indexOf(region)
  const removePatch = {
    op: 'remove',
    path: `/elements/0/elements/1/elements/${index}`,
  }
  const addPatch = {
    op: 'add',
    path: `/elements/0/elements/1/elements/${index}`,
    value: cloneObj(region),
  }
  return { op: removePatch, reversedOp: addPatch }
}

function getPageJsonCopy() {
  return structuredClone(pageJson)
}
function getPageJson() {
  return pageJson
}

function newAction(patch, reversePatch) {
  ActionHandler.instance().newAction({
    type: ACTION_TYPE.PATCH,
    ops: patch,
    reversedOps: reversePatch,
  })
}

function handleAction(action, updateEditor = true, { popLastAction } = {}) {
  if (action.type === ACTION_TYPE.MULTI_ACTION) {
    action.actions.forEach(action => handleAction(action, false))
    update(action.actions.map(a => a.type))
    return
  }
  if (action.type === ACTION_TYPE.PATCH) {
    pageJson = applyPatch(cloneObj(pageJson), cloneObj(action.ops)).newDocument
  }
  if (action.type === ACTION_TYPE.MOVE_POLYGON_POINT) {
    const coords = getCoords(action.shape_id)

    const newCoords = coords.map((p, index) => {
      if (index === action.point_index) {
        return {
          x: Math.round(Number(p.x) + action.vector.x),
          y: Math.round(Number(p.y) + action.vector.y),
        }
      } else {
        return p
      }
    })
    setCoords(action.shape_id, newCoords)
  }
  if (action.type === ACTION_TYPE.MOVE_POLYGON_POINTS) {
    const coords = getCoords(action.shape_id)
    const newCoords = coords.map((p, index) => {
      return {
        x: Math.round(Number(p.x) + action.vectors[index].x),
        y: Math.round(Number(p.y) + action.vectors[index].y),
      }
    })
    setCoords(action.shape_id, newCoords)
  }
  if (action.type === ACTION_TYPE.MOVE_POINTS) {
    const shape = getShapeById(action.shape_id)
    if (shape.name === 'TextRegion') {
      const coords = getCoords(action.shape_id)
      const newCoords = coords.map((p, index) => {
        return {
          x: Math.round(Number(p.x) + action.vectors[index].x),
          y: Math.round(Number(p.y) + action.vectors[index].y),
        }
      })
      setCoords(action.shape_id, newCoords)
    }
    if (shape.name === 'TableCell') {
      const coords = getCoords(action.shape_id)
      const newCoords = coords.map((p, index) => {
        return {
          x: Math.round(Number(p.x) + action.vectors[index].x),
          y: Math.round(Number(p.y) + action.vectors[index].y),
        }
      })
      setCoords(action.shape_id, newCoords)
    }
    if (shape.name === 'TextLine') {
      const baseline = getBaseline(action.shape_id)
      if (baseline) {
        const newBaseline = baseline.map((p, index) => {
          return {
            x: Math.round(Number(p.x) + action.vectors[index].x),
            y: Math.round(Number(p.y) + action.vectors[index].y),
          }
        })
        setBaseline(action.shape_id, newBaseline)
      }
    }
  }
  if (action.type === ACTION_TYPE.MOVE_POINT) {
    const shape = getShapeById(action.shape_id)
    if (shape.name === 'TextRegion' || shape.name === 'TableCell') {
      const coords = getCoords(action.shape_id)
      const newCoords = coords.map((p, index) => {
        if (index === action.point_index) {
          return {
            x: Math.round(Number(p.x) + action.vector.x),
            y: Math.round(Number(p.y) + action.vector.y),
          }
        } else {
          return p
        }
      })
      setCoords(action.shape_id, newCoords)
    }
    if (shape.name === 'TextLine') {
      const baseline = getBaseline(action.shape_id)
      if (baseline) {
        const newBaseline = baseline.map((p, index) => {
          if (index === action.point_index) {
            return {
              x: Math.round(Number(p.x) + action.vector.x),
              y: Math.round(Number(p.y) + action.vector.y),
            }
          } else {
            return p
          }
        })
        setBaseline(action.shape_id, newBaseline)
      }
    }
  }
  if (action.type === ACTION_TYPE.ADD_POLYGON_POINT) {
    const coords = getCoords(action.shape_id)
    const newCoords = coords.toSpliced(action.point_index, 0, {
      x: action.point_coord.x,
      y: action.point_coord.y,
    })
    setCoords(action.shape_id, newCoords)
  }
  if (action.type === ACTION_TYPE.ADD_POINT) {
    const shape = getShapeById(action.shape_id)

    if (shape.name === 'TextRegion') {
      const coords = getCoords(action.shape_id)
      const newCoords = coords.toSpliced(action.point_index, 0, {
        x: action.point_coord.x,
        y: action.point_coord.y,
      })
      setCoords(action.shape_id, newCoords)
    }
    if (shape.name === 'TextLine') {
      const baseline = getBaseline(action.shape_id)
      if (baseline) {
        const newBaseline = baseline.toSpliced(action.point_index, 0, {
          x: action.point_coord.x,
          y: action.point_coord.y,
        })
        setBaseline(action.shape_id, newBaseline)
      }
    }
  }
  if (action.type === ACTION_TYPE.REMOVE_POLYGON_POINT) {
    const coords = getCoords(action.shape_id)
    const newCoords = coords.toSpliced(action.point_index, 1)
    setCoords(action.shape_id, newCoords)
  }
  if (action.type === ACTION_TYPE.REMOVE_POINT) {
    const shape = getShapeById(action.shape_id)
    if (shape.name === 'TextRegion') {
      const coords = getCoords(action.shape_id)
      const newCoords = coords.toSpliced(action.point_index, 1)
      setCoords(action.shape_id, newCoords)
    }
    if (shape.name === 'TextLine') {
      const baseline = getBaseline(action.shape_id)
      if (baseline) {
        const newBaseline = baseline.toSpliced(action.point_index, 1)
        setBaseline(action.shape_id, newBaseline)
      }
    }
  }
  if (action.type === ACTION_TYPE.MOVE_MULTIPLE_POINTS) {
    const baseline = getBaseline(action.shape_id)
    if (baseline) {
      const newBaseline = baseline.map((p, index) => {
        if (action.point_indices.includes(index)) {
          return {
            x: Math.round(Number(p.x) + action.vector.x),
            y: Math.round(Number(p.y) + action.vector.y),
          }
        }
        return p
      })
      setBaseline(action.shape_id, newBaseline)
    }
  }
  if (action.type === ACTION_TYPE.CHANGE_READING_ORDER) {
    if (action.old_parent_id == null && action.new_parent_id == null) {
      const regions = [...getTextAndTableRegions()]
      array_move(regions, action.old_index, action.new_index)
      setTextAndTableRegions(regions)
    } else {
      const oldParent = getShapeById(action.old_parent_id)
      const newParent = getShapeById(action.new_parent_id)

      if (oldParent.attributes.id === newParent.attributes.id) {
        const lines = [...getTextLines(oldParent)]
        array_move(lines, action.old_index, action.new_index)
        setTextlinesForRegion(oldParent, lines)
      } else {
        const oldParentLines = [...getTextLines(oldParent)]
        const [movedShape] = oldParentLines.splice(action.old_index, 1)
        setTextlinesForRegion(oldParent, oldParentLines)
        const newParentLines = [...getTextLines(newParent)]
        newParentLines.splice(action.new_index, 0, movedShape)
        setTextlinesForRegion(newParent, newParentLines)
      }
    }
  }
  if (
    action.type === ACTION_TYPE.REMOVE_REGION ||
    action.type === ACTION_TYPE.REMOVE_TABLE
  ) {
    const page = getPage(pageJson)
    page.elements = page.elements.filter(
      e => e.attributes?.id !== action.blueprint.attributes.id
    )
  }
  if (action.type === ACTION_TYPE.REMOVE_BASELINE) {
    let parent = getTextlineParent(action.blueprint.attributes.id)
    if (parent != null) {
      parent.elements = parent.elements.filter(
        e => e.attributes?.id !== action.blueprint.attributes.id
      )
    }
  }
  if (action.type === ACTION_TYPE.ADD_BASELINE) {
    let parent = getShapeById(action.parent_id)
    const textLineJson = getTextLineJson(action.blueprint)
    const textLines = [...getTextLines(parent)]
    textLines.splice(action.index, 0, textLineJson)
    setTextlinesForRegion(parent, textLines)
  }
  if (action.type === ACTION_TYPE.ADD_REGION) {
    const regionJson = getRegionJson(action.blueprint)
    const regions = [...getTextAndTableRegions()]
    regions.splice(action.index, 0, regionJson)
    setTextAndTableRegions(regions)
  }
  if (action.type === ACTION_TYPE.ADD_TABLE) {
    const regionJson = getTableJson(action.blueprint)
    const regions = [...getTextAndTableRegions()]
    regions.splice(action.index, 0, regionJson)
    setTextAndTableRegions(regions)
  }

  if (action.type === ACTION_TYPE.SET_STRUCTUR_TYPE) {
    const shape = getShapeById(action.shape_id)
    let selectors = parseCustomModel(shape.attributes?.custom || '')

    selectors = selectors.filter(rule => rule.selector !== 'structure')
    if (action.new_type != null && action.new_type !== 'none') {
      let type = action.new_type
      let level = null
      if (type.includes('#level#')) {
        const split = type.split('#level#')
        type = split[0]
        level = split[1]
      }
      selectors.push({
        selector: 'structure',
        rules: [
          { directive: 'type', value: type },
          ...(level ? [{ directive: 'level', value: level }] : []),
        ],
      })
    }
    shape.attributes.custom = stringifyCustomModel(selectors)
  }
  if (action.type === ACTION_TYPE.ADD_RELATION) {
    let relations = getRelations(pageJson)
    if (relations == null) {
      relations = {
        type: 'element',
        name: 'Relations',
        elements: [],
        attributes: {},
      }
    }

    relations.elements.push(getRelationJson(action.relation))

    setRelations(relations)
  }

  if (action.type === ACTION_TYPE.REMOVE_RELATION) {
    let relations = getRelations(pageJson)
    if (relations == null) return

    const actionIds = JSON.stringify(action.relation.elements.ids)

    relations.elements = relations.elements.filter(e => {
      const currentIds = JSON.stringify(
        e.elements.map(e => e.attributes.regionRef)
      )
      return currentIds !== actionIds
    })
    setRelations(relations)
  }
  if (action.type === ACTION_TYPE.UPDATE_RELATION_IDS) {
    let relations = getRelations(pageJson)
    if (relations == null) return

    relations.elements = relations.elements.map((e, index) => {
      if (index === action.index) {
        return {
          ...e,
          elements: action.new_ids.map(id => getRegionRefFromId(id)),
        }
      }
      return e
    })
    setRelations(relations)
  }

  if (action.type === ACTION_TYPE.SET_TEXT) {
    const textLine = getShapeById(action.shape_id)
    if (textLine) {
      const textEquiv = textLine.elements.find(e => e.name === 'TextEquiv')
      if (textEquiv?.elements?.[0]?.elements?.[0] == null) {
        const newTextEquiv = getTextEquivJson(action.new_text)
        textLine.elements = textLine.elements.filter(
          e => e.name !== 'TextEquiv'
        )
        textLine.elements.push(newTextEquiv)
      } else {
        const textObj = textEquiv.elements[0].elements[0]
        textObj.text = action.new_text
      }
    } else {
      console.log('TextLine not found!')
    }
  }

  if (action.type === ACTION_TYPE.SET_TAGS) {
    const textLine = getShapeById(action.shape_id)
    if (textLine) {
      let newTags = action.new_tags
      const levelTags = newTags.filter(t => t.selector.includes('#level#'))
      if (levelTags.length > 0) {
        newTags = newTags.filter(t => !t.selector.includes('#level#'))
        const splittedLevelTags = levelTags.map(t => {
          const newTag = { ...t }
          const split = t.selector.split('#level#')
          newTag.selector = split[0]
          newTag.rules.push({ directive: 'level', value: split[1] })
          return newTag
        })
        newTags = [...newTags, ...splittedLevelTags]
      }
      const selectors = parseCustomModel(textLine.attributes?.custom || '')
      const otherSelectors = selectors.filter(
        s =>
          s.selector === 'readingOrder' ||
          s.selector === 'structure' ||
          s.selector === 'relationType' ||
          s.selector === 'relationName'
      )
      textLine.attributes.custom = stringifyCustomModel([
        ...otherSelectors,
        ...newTags,
      ])
    } else {
      console.log('TextLine not found!')
    }
  }

  if (action.type === ACTION_TYPE.CHANGE_STATUS) {
    const metaData = getTranskribusMetadata(pageJson)
    if (metaData) {
      metaData.attributes.status = action.new_status
    } else {
      setTranskribusMetadata({
        attributes: { status: action.new_status },
        elements: [],
        name: 'TranskribusMetadata',
        type: 'element',
      })
    }
  }

  /* const action = {
    type: ACTION_TYPE.MOVE_POINT_TABLE,
    shape_id: this.id,
    cell_ids: cellsAndIndices.map(({ id }) => id),
    point_indices: cellsAndIndices.map(({ index }) => index),
    vector,
  } */

  if (action.type === ACTION_TYPE.MOVE_POINT_TABLE) {
    const table = getShapeById(action.shape_id)
    let curentCells = getCells(table)
    curentCells.forEach(cell => {
      if (action.cell_ids.includes(cell.attributes.id)) {
        const coords = getCoords(cell.attributes.id)
        const pointIndex =
          action.point_indices[action.cell_ids.indexOf(cell.attributes.id)]

        const newCoords = coords.map((p, index) => {
          if (index === pointIndex) {
            return {
              x: Math.round(Number(p.x) + action.vector.x),
              y: Math.round(Number(p.y) + action.vector.y),
            }
          } else {
            return p
          }
        })
        setCoords(cell.attributes.id, newCoords)
      }
    })
  }

  if (action.type === ACTION_TYPE.SPLIT_TABLE_COL) {
    const maxCol = action.new_cells_blueprints.reduce(
      (number, cell) =>
        cell.attributes.col >= number ? cell.attributes.col : number,
      0
    )
    const table = getShapeById(action.shape_id)
    let curentCells = getCells(table)
    let cells = []
    curentCells.forEach(c => {
      const cell = cloneObj(c)
      if (Number(cell.attributes.col) >= Number(maxCol)) {
        cell.attributes.col = Number(cell.attributes.col) + 1
      }
      if (
        !action.old_cells_blueprints.find(
          b => b.attributes.id === cell.attributes.id
        )
      ) {
        cells.push(cell)
      }
    })
    cells = [...cells, ...action.new_cells_blueprints.map(c => getCellJson(c))]

    cells = orderCells(cells)
    const valid = validateCells(cells)
    if (valid) {
      setCellsForTable(table, cells)
    } else {
      addSentry(action, cells)
      window.emitEditorNotification({
        message: 'Invalid table action',
        type: 'error',
      })
      popLastAction()
    }
  }

  if (action.type === ACTION_TYPE.SPLIT_TABLE_ROW) {
    const maxRow = action.new_cells_blueprints.reduce(
      (number, cell) =>
        cell.attributes.row >= number ? cell.attributes.row : number,
      0
    )
    const table = getShapeById(action.shape_id)
    let curentCells = getCells(table)
    let cells = []
    curentCells.forEach(c => {
      const cell = cloneObj(c)
      if (Number(cell.attributes.row) >= Number(maxRow)) {
        cell.attributes.row = Number(cell.attributes.row) + 1
      }
      if (
        !action.old_cells_blueprints.find(
          b => b.attributes.id === cell.attributes.id
        )
      ) {
        cells.push(cell)
      }
    })
    cells = [...cells, ...action.new_cells_blueprints.map(c => getCellJson(c))]

    cells = orderCells(cells)

    const valid = validateCells(cells)
    if (valid) {
      setCellsForTable(table, cells)
    } else {
      addSentry(action, cells)
      window.emitEditorNotification({
        message: 'Invalid table action',
        type: 'error',
      })
      popLastAction()
    }
  }
  if (action.type === ACTION_TYPE.MERGE_TABLE_COLS) {
    const maxCol = action.new_cells_blueprints.reduce(
      (number, cell) =>
        cell.attributes.col >= number ? cell.attributes.col : number,
      0
    )
    const table = getShapeById(action.shape_id)
    let curentCells = getCells(table)
    let cells = []
    curentCells.forEach(c => {
      const cell = cloneObj(c)
      if (Number(cell.attributes.col) >= Number(maxCol)) {
        cell.attributes.col = Number(cell.attributes.col) - 1
      }
      if (
        !action.old_cells_blueprints.find(
          b => b.attributes.id === cell.attributes.id
        )
      ) {
        cells.push(cell)
      }
    })
    cells = [...cells, ...action.new_cells_blueprints.map(c => getCellJson(c))]
    cells = orderCells(cells)
    const valid = validateCells(cells)
    if (valid) {
      setCellsForTable(table, cells)
    } else {
      addSentry(action, cells)
      window.emitEditorNotification({
        message: 'Invalid table action',
        type: 'error',
      })
      popLastAction()
    }
  }
  if (action.type === ACTION_TYPE.MERGE_TABLE_ROWS) {
    const maxRow = action.new_cells_blueprints.reduce(
      (number, cell) =>
        cell.attributes.row >= number ? cell.attributes.row : number,
      0
    )
    const table = getShapeById(action.shape_id)
    let curentCells = getCells(table)
    let cells = []
    curentCells.forEach(c => {
      const cell = cloneObj(c)
      if (Number(cell.attributes.row) >= Number(maxRow)) {
        cell.attributes.row = Number(cell.attributes.row) - 1
      }
      if (
        !action.old_cells_blueprints.find(
          b => b.attributes.id === cell.attributes.id
        )
      ) {
        cells.push(cell)
      }
    })
    cells = [...cells, ...action.new_cells_blueprints.map(c => getCellJson(c))]
    cells = orderCells(cells)

    const valid = validateCells(cells)
    if (valid) {
      setCellsForTable(table, cells)
    } else {
      addSentry(action, cells)
      window.emitEditorNotification({
        message: 'Invalid table action',
        type: 'error',
      })
      popLastAction()
    }
  }

  if (action.type === ACTION_TYPE.UPDATE_CELLS) {
    const table = getShapeById(action.shape_id)
    let curentCells = getCells(table)
    let cells = []
    curentCells.forEach(cell => {
      if (
        !action.old_cells_blueprints.find(
          b => b.attributes.id === cell.attributes.id
        )
      ) {
        cells.push(cell)
      }
    })
    cells = [...cells, ...action.new_cells_blueprints.map(c => getCellJson(c))]
    cells = orderCells(cells)

    const valid = validateCells(cells)
    if (valid) {
      setCellsForTable(table, cells)
    } else {
      addSentry(action, cells)
      window.emitEditorNotification({
        message: 'Invalid table action',
        type: 'error',
      })
      popLastAction()
    }
  }
  if (updateEditor) {
    update([action.type])
  }
}

function update(types) {
  let updateLayouteditor = false
  let updateTextEditor = false
  let updateNavbarFlag = false
  types.forEach(type => {
    switch (type) {
      case ACTION_TYPE.MOVE_POLYGON_POINT:
      case ACTION_TYPE.MOVE_POLYGON_POINT:
      case ACTION_TYPE.MOVE_POINTS:
      case ACTION_TYPE.MOVE_POINT:
      case ACTION_TYPE.ADD_POLYGON_POINT:
      case ACTION_TYPE.ADD_POINT:
      case ACTION_TYPE.REMOVE_POLYGON_POINT:
      case ACTION_TYPE.REMOVE_POINT:
      case ACTION_TYPE.MOVE_MULTIPLE_POINTS:
      case ACTION_TYPE.ADD_RELATION:
      case ACTION_TYPE.REMOVE_RELATION:
      case ACTION_TYPE.UPDATE_RELATION_IDS:
      case ACTION_TYPE.MOVE_POINT_TABLE:
        updateLayouteditor = true
        break
      case ACTION_TYPE.CHANGE_STATUS:
        updateNavbarFlag = true
        break
      default:
        updateLayouteditor = true
        updateTextEditor = true
    }
  })
  if (updateLayouteditor) {
    startBlueprintWorker()
  }
  if (updateTextEditor) {
    createContent()
  }
  if (updateNavbarFlag) {
    updateNavbar()
  }
}

function getNewIdsWithShiftModel(baseId, id) {
  return getNewIdsWithShift(pageJson, baseId, id)
}

function parseCustomModel(string) {
  const rules = string
    .split('}')
    .filter(Boolean)
    .map(rule => {
      const [selector, styles] = rule.split('{').map(str => str.trim())
      const styleRules = styles
        .split(';')
        .filter(Boolean)
        .map(style => {
          const [directive, value] = style.split(':').map(str => str.trim())
          return { directive, value }
        })
      return { selector, rules: styleRules }
    })

  return rules
}

function stringifyCustomModel(rules) {
  return rules
    .map(rule => {
      const styles = rule.rules
        .map(style => `${style.directive}: ${style.value};`)
        .join(' ')
      return `${rule.selector} { ${styles} }`
    })
    .join(' ')
}

function addSentry(action, cells) {
  const nuxtApp = useNuxtApp()
  nuxtApp.$sentry.withScope(scope => {
    scope.setTag('table-error', 'table-error')
    // Create and add an attachment for sentry event in local scope
    const fileAttachment = {
      filename: 'tableCells.json',
      data: JSON.stringify({ action, cells }),
    }
    scope.addAttachment(fileAttachment)

    // Capture the error, regardless of its status
    nuxtApp.$sentry.captureException('table-error')
  })
}

function areConsecutiveShapesModel(selectedIds) {
  return areConsecutiveShapes(pageJson, selectedIds)
}

export default {
  handleAction,
  setPageJson,
  setLayoutHandler,
  setTextHandler,
  setNavbarHandler,
  getContent,
  getXmlAndStatus,
  xmlStringToJson,
  jsonToXmlString,
  getTextData,
  getLayoutData,
  getPageJsonCopy,
  getPageJson,
  getTextDataFromModel,
  applyActions,
  getNewIdsWithShiftModel,
  newAction,
  areConsecutiveShapesModel,
}
