import {
  getShapeById,
  getLineJson,
  setTextlinesForRegion,
} from '../Model/modelUtils.ts'
import BaseAction from './BaseAction.js'

export default class AddLineFromText extends BaseAction {
  private newLineId: string

  constructor(pageJson: any) {
    super(pageJson)
    this.newLineId = this.idGenerator.requestId('l')
  }

  apply(params: any) {
    const { parentId } = params
    const parent = getShapeById(parentId, this.pageJson)
    const textLineJson = getLineJson(this.newLineId, null, null, '', null, null)
    setTextlinesForRegion(parent, [textLineJson])
  }
  getNewLineId() {
    return this.newLineId
  }
}
